import { FC, useEffect, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { propertiesState } from 'recoil/properties'
import { userState } from 'recoil/user'
import { track as legacyTrack } from 'utils/analytics'

const AnalyticsAccountCreationTracker: FC = () => {
  const user = useRecoilValue(userState)
  const properties = useRecoilValue(propertiesState)

  const addedProperty = useMemo(() => {
    // There should only be one property in this user's list of properties,
    // since to be in complete profile, it should be the first property
    // added for a new account. Grab from the list of props
    if (!properties) return null
    if (!properties.length) return null

    return properties[0]
  }, [properties])

  useEffect(() => {
    const _ = async () => {
      if (!user) return

      const trackAccountCreation = sessionStorage.getItem('rails_flash_track_account_creation')
      sessionStorage.removeItem('rails_flash_track_account_creation')
      if (!trackAccountCreation) return

      const is_marketplace =
        typeof addedProperty?.marketplace === 'boolean' ? { is_marketplace: addedProperty?.marketplace } : {}

      legacyTrack('account creation', { email: user?.email, withProperty: !!addedProperty, ...is_marketplace })
    }
    _()
  }, [user, addedProperty])

  return null
}

export default AnalyticsAccountCreationTracker
