import React, { FC, useCallback } from 'react'
import classNames from 'classnames'

import { ProjectCustomizationOption, ProjectSelection, SizeType, cloneProjectSelection } from 'recoil/projectTemplates'

import Input from 'components/Input'

import styles from './Section.module.scss'
import sizeStyles from './SizeSection.module.scss'

function calculateScaleTotal(parentSize: number, scale: number, type: SizeType) {
  const raw = parentSize * scale
  return type == SizeType.UnitCount ? Math.floor(raw) : raw
}

function calculateSizeTotal(size: [number, number], type: SizeType) {
  const raw = size[0] * size[1]
  return type == SizeType.UnitCount ? Math.floor(raw) : raw
}

interface Props {
  option: ProjectCustomizationOption
  value: ProjectSelection
  onChange: (selection: ProjectSelection) => void
  parentSize?: number
}

const SizeSection: FC<Props> = ({ option, value, onChange, parentSize }) => {
  const handleChangeX = useCallback(
    (sSizeX) => {
      const sizeX = parseInt(sSizeX)

      onChange({ ...cloneProjectSelection(value), size: [sizeX, value.size[1]] })
    },
    [value, onChange]
  )

  const handleChangeY = useCallback(
    (sSizeY) => {
      const sizeY = parseInt(sSizeY)
      onChange({ ...cloneProjectSelection(value), size: [value.size[0], sizeY] })
    },
    [value, onChange]
  )

  const handleChangeScale = useCallback(
    (sScale) => {
      const scale = parseInt(sScale)
      onChange({ ...cloneProjectSelection(value), scale: scale })
    },
    [value, onChange]
  )

  const part = option.part
  const scale = part.scale
  // We can only let the user change scale if we are flag to show_scale,
  // AND we have a parent size to scale off of.
  const isScaling = option.show_scale && typeof parentSize == 'number'

  // If it the user can't scale, and it isn't flagged to let the user
  // change the size, then don't give them the option
  if (!isScaling && !option.show_size) return null

  let valueTotal = isScaling
    ? calculateScaleTotal(parentSize || 0, value.scale || 0, part.size_type)
    : calculateSizeTotal(value.size, part.size_type)
  const tooBig = valueTotal > part.size.max
  const tooSmall = valueTotal < part.size.min
  const invalid = tooBig || tooSmall
  // Round this off so we're not dealing with so many digits.
  valueTotal = Math.round((valueTotal + Number.EPSILON) * 100) / 100

  return (
    <section className={classNames(styles.section, sizeStyles.sizeSection)}>
      {part.size_type == 'unit_count' ? (
        <>
          <h3>{option.size_name || 'Count'}</h3>
          <p>{option.size_description || 'Specify the number of items'}</p>
          <div className={sizeStyles.shunter}>
            {isScaling ? (
              <div className={sizeStyles.field}>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${(value.scale || 0) * 100}`}
                  min={scale?.min}
                  max={scale?.max}
                  onChange={handleChangeScale}
                />
                <span className={sizeStyles.units}>{'% of parent'}</span>
              </div>
            ) : (
              <div className={sizeStyles.field}>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${value.size[0]}`}
                  min={part.size.min}
                  max={part.size.max}
                  onChange={handleChangeX}
                />
              </div>
            )}
            {isScaling ? (
              <span className={classNames(sizeStyles.total, invalid ? sizeStyles.invalid : null)}>
                <span className={classNames(sizeStyles.prime)}>
                  <span className={sizeStyles.label}>{'Total:'}</span>
                  {`${valueTotal}`}
                </span>
              </span>
            ) : null}
          </div>
        </>
      ) : part.size_type == 'length' ? (
        <>
          <h3>{option.size_name || 'Length'}</h3>
          <p>{option.size_description || 'Specify the length of your project'}</p>
          <div className={sizeStyles.shunter}>
            {isScaling ? (
              <div className={sizeStyles.field}>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${(value.scale || 0) * 100}`}
                  min={scale?.min}
                  max={scale?.max}
                  onChange={handleChangeScale}
                />
                <span className={sizeStyles.units}>{'% of parent'}</span>
              </div>
            ) : (
              <div className={sizeStyles.field}>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${value.size[0]}`}
                  min={part.size.min}
                  max={part.size.max}
                  onChange={handleChangeX}
                />
                <span className={sizeStyles.units}>{'feet'}</span>
              </div>
            )}
            {isScaling ? (
              <span className={classNames(sizeStyles.total, invalid ? sizeStyles.invalid : null)}>
                <span className={classNames(sizeStyles.prime)}>
                  <span className={sizeStyles.label}>{'Total:'}</span>
                  {`${valueTotal} ft`}
                </span>
              </span>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <h3>{option.size_name || 'Size'}</h3>
          <p>{option.size_description || 'Specify the size of your project'}</p>
          <div className={sizeStyles.shunter}>
            {isScaling ? (
              <div className={sizeStyles.field}>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${(value.scale || 0) * 100}`}
                  min={scale?.min}
                  max={scale?.max}
                  onChange={handleChangeScale}
                />
                <span className={sizeStyles.units}>{'% of parent'}</span>
              </div>
            ) : (
              <div className={sizeStyles.field}>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${value.size[0]}`}
                  min={0}
                  max={32768}
                  onChange={handleChangeX}
                />
                <span className={sizeStyles.by}>{'x'}</span>
                <Input
                  className={sizeStyles.input}
                  type="number"
                  value={`${value.size[1]}`}
                  min={0}
                  max={32768}
                  onChange={handleChangeY}
                />
                <span className={sizeStyles.units}>{'feet'}</span>
              </div>
            )}
            <span className={classNames(sizeStyles.total, invalid ? sizeStyles.invalid : null)}>
              <span className={classNames(sizeStyles.prime)}>
                <span className={sizeStyles.label}>{'Total:'}</span>
                {`${valueTotal} sq ft`}
              </span>
              {invalid ? (
                <span className={sizeStyles.error}>
                  {tooSmall ? `(minimum ${part.size.min})` : `(maximum ${part.size.max})`}
                </span>
              ) : null}
            </span>
          </div>
        </>
      )}
    </section>
  )
}

export default SizeSection
