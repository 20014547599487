import React, { FC, PropsWithChildren, useCallback } from 'react'

export interface SubmittableProps {
  className?: string
  onSubmit?: () => void
}

const Submittable: FC<PropsWithChildren<SubmittableProps>> = ({ className, children, onSubmit }) => {
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit && onSubmit()
    },
    [onSubmit]
  )

  return (
    <form className={className} onSubmit={handleSubmit}>
      <button type="submit" className="tw-hidden" />
      {children}
    </form>
  )
}

export default Submittable
