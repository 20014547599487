import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { alertState } from 'recoil/alert'

// map rails flash keys to Alert keys
const keyMapping = {
  alert: 'ERROR',
  danger: 'ERROR',
  notice: 'SUCCESS',
  warning: 'WARNING',
  success: 'SUCCESS',
}

const useRailsFlash = (): void => {
  const setAlert = useSetRecoilState(alertState)
  useEffect(() => {
    const el = document.querySelector('[data-flash]')
    if (!el) return
    const flash = JSON.parse(el.textContent || '')
    for (const [key, value] of Object.entries(flash)) {
      const type = keyMapping[key]
      if (type) {
        setAlert({ type, message: value as string, id: Date.now() })
      } else {
        // set in session storage so that it can be read after a refresh
        // this is used for tracking SSO signups
        sessionStorage.setItem(`rails_flash_${key}`, value as string)
      }
    }
  })
}

export default useRailsFlash
