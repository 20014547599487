import React, { FC, useEffect, useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import AnalyticsPageTracker from 'non-rendering/AnalyticsPageTracker'
import onboardingRoutes from 'non-rendering/onboardingRoutes'

import OnboardingLayout from 'layouts/Onboarding'

import OnboardingFlowRoutes from './OnboardingFlowRoutes'

import PageTitle from 'components/PageTitle'

import styles from './styles.module.scss'
import { useOnboardingProperty } from 'recoil/onboarding'

const Onboarding: FC = () => {
  const { search } = useLocation()
  const { onboarding } = useOnboardingProperty()

  useEffect(() => {
    const pathAfterSignup = new URLSearchParams(search).get('pas')
    if (pathAfterSignup) {
      window.sessionStorage.setItem('path-after-signup', pathAfterSignup)
    }
  }, [search])

  const customParams: Record<string, any> = useMemo(() => {
    return { unserviceable: onboarding.unavailable }
  }, [onboarding.unavailable])

  return (
    <OnboardingLayout>
      <div className={styles.content}>
        <PageTitle title="Onboarding" />
        <OnboardingFlowRoutes />
        <AnalyticsPageTracker routes={onboardingRoutes} customParams={customParams} />
      </div>
    </OnboardingLayout>
  )
}

export default Onboarding
