import React, { FC, useCallback, useReducer, useState } from 'react'
import { Redirect, Link, useLocation } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { formatAddressCasing } from 'utils/formatting'

import { signUp, SignUpResponse } from 'recoil/user'
import { onboardingState, OnboardingState } from 'recoil/onboarding'
import { alertState } from 'recoil/alert'

import EnsureLoggedOut from 'layouts/EnsureLoggedOut'
import Auth from 'layouts/Auth'

import PageTitle from 'components/PageTitle'
import CreatingProperty from 'components/Auth/CreatingProperty'
import { Prediction } from 'components/FullAddressInput'

import SignupForm, { SignupFormFields } from 'pages/Signup/SignupForm'

export interface SignupState {
  attomId: string | null
  errors: Record<string, string[]>
  form: SignupFormFields
  isLoading: boolean
  placeId?: string
  propertyId?: number
}

const initState = ({ search, onboarding }: { search?: string; onboarding: OnboardingState }): SignupState => {
  const attomId =
    new URLSearchParams(search).get('attom_id') || (!onboarding.property ? onboarding.attomId : null) || null
  return {
    attomId,
    errors: {},
    form: {
      full_name: '',
      ambassador_name: '',
      email: '',
      password: '',
      entered_address: attomId ? `attom_id:${attomId}` : '',
    },
    isLoading: false,
    propertyId: onboarding.property?.id,
  }
}

export type SignupAction = {
  type: 'setFields' | 'setIsLoading' | 'setErrors' | 'setPlaceId'
  payload?: any
}

const reducer = (state: SignupState, action: SignupAction) => {
  switch (action.type) {
    case 'setFields':
      return { ...state, form: { ...state.form, ...action.payload } }
    case 'setErrors':
      return { ...state, errors: action.payload, isLoading: false }
    case 'setIsLoading':
      return { ...state, isLoading: action.payload as boolean }
    case 'setPlaceId': {
      const newErrors = { ...state.errors }
      delete newErrors['entered_address']
      return { ...state, placeId: action.payload as string, errors: newErrors }
    }
    default:
      throw new Error()
  }
}

const Signup: FC = () => {
  const [creatingPropertyToken, setCreatingPropertyToken] = useState<string>()
  const setAlert = useSetRecoilState(alertState)
  const location = useLocation()
  const [onboarding, setOnboarding] = useRecoilState(onboardingState)
  const [state, dispatch] = useReducer(reducer, { search: location.search, onboarding }, initState)

  let address
  let campaignId
  if (window) {
    const params = new URLSearchParams(window.location.search)

    // Get the address from the url
    const encodedAddress = params.get('address')
    address = encodedAddress && decodeURIComponent(encodedAddress)

    // Get the marketing campaign from the url
    campaignId = params.get('mc')
  }
  if (!address && onboarding.property) {
    address = onboarding.property.delivery_line_1
  }
  if (!address && onboarding.publicPropertyData) {
    address = onboarding.publicPropertyData.public_property.propertyaddressfull
  }

  const handleSubmit = useCallback(async () => {
    dispatch({ type: 'setIsLoading', payload: true })

    let response: SignUpResponse | null = null
    try {
      response = await signUp(
        {
          ...state.form,
          onboarding: onboarding,
          propertyId: state.propertyId,
          attomId: state.attomId,
          placeId: state.placeId,
        },
        campaignId
      )
    } catch (error) {
      dispatch({ type: 'setErrors', payload: error })
      if (error.base) {
        setAlert({ type: 'ERROR', message: error.base.join(', '), id: Date.now() })
      }
      return
    }

    // reset onboarding state after signup
    setOnboarding({})

    if (response.pollable_job_token) {
      setCreatingPropertyToken(response.pollable_job_token)
    } else if (response.property_id) {
      // assign to location.href here rather than using react-router, in order
      // to reload tracking libraries such as gtm and segment
      window.location.href = `/dashboard${campaignId ? `?mc=${campaignId}` : ''}`
    }
  }, [
    onboarding,
    setOnboarding,
    dispatch,
    setAlert,
    state.attomId,
    state.form,
    state.placeId,
    state.propertyId,
    campaignId,
  ])

  const handleChange = useCallback(
    (fieldName: keyof SignupFormFields) => {
      return (value: any) => {
        dispatch({
          type: 'setFields',
          payload: {
            [fieldName]: value,
          },
        })
      }
    },
    [dispatch]
  )

  const handlePredictionSelected = useCallback((prediction: Prediction | null) => {
    if (prediction) {
      dispatch({ type: 'setFields', payload: { entered_address: prediction.description } })
    }
    dispatch({ type: 'setPlaceId', payload: prediction?.placeId })
  }, [])

  const handlePropertyCreated = useCallback(() => {
    // assign to location.href here rather than using react-router, in order
    // to reload tracking libraries such as gtm and segment
    const path = `/dashboard${campaignId ? `?mc=${campaignId}` : ''}`
    window.setTimeout(() => (window.location.href = path), 750)
  }, [campaignId])

  const hasAddress = Boolean(state.attomId || state.propertyId)

  // If we don't have an address redirect to onboarding
  // But stick around here if it is a marketing campaign.
  if (!hasAddress && !campaignId) {
    return <Redirect to="/onboarding?n" />
  }

  if (creatingPropertyToken) {
    return <CreatingProperty token={creatingPropertyToken} onSuccess={handlePropertyCreated} />
  }

  return (
    <EnsureLoggedOut>
      <PageTitle title="Sign Up" />
      <Auth>
        <Auth.Head>
          <Auth.Head.Header>
            {campaignId
              ? 'Enter for a chance to win'
              : address != null
              ? `Get more data on ${formatAddressCasing(address)}`
              : 'Get more data'}
          </Auth.Head.Header>
          <Auth.Head.Subheader>
            <p>
              {campaignId ? (
                <>
                  {'Create a free account to enter '}
                  <a
                    className="tw-text-base-offyellow visited:tw-text-base-offyellow hover:tw-text-white"
                    href="/resources/library/october-giveaway-rules"
                    target="_blank"
                  >
                    {'our giveaway'}
                  </a>
                  {` and get access to all the data you need to make smart choices for ${formatAddressCasing(
                    address
                  )}. All of our calculations are customized to your specific property.`}
                </>
              ) : (
                'Your free account unlocks access to your property analysis which includes project specific % Recoup estimates. All of our calculations are customized to your specific property.'
              )}
            </p>
            <p className="mt-2">
              {
                'We will never share your information with third parties without your consent. Already have an account? '
              }
              <Link
                className="tw-text-base-offyellow visited:tw-text-base-offyellow hover:tw-text-white"
                to="/users/sign_in"
              >
                {'Sign in'}
              </Link>
            </p>
          </Auth.Head.Subheader>
        </Auth.Head>

        <Auth.Body className="tw--mt-5">
          <SignupForm
            noAddress={hasAddress}
            showAmbassadorField={location.pathname === '/users/ambassador_sign_up'}
            ctaCopy={campaignId ? 'Enter the giveaway' : 'Access my project planner'}
            form={state.form}
            errors={state.errors}
            onChange={handleChange}
            onPredictionSelected={handlePredictionSelected}
            onSubmit={handleSubmit}
            isLoading={state.isLoading}
          />
        </Auth.Body>
      </Auth>
    </EnsureLoggedOut>
  )
}

export default Signup
