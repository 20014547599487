import React, { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function isOnBlacklist(list, path) {
  return list.some((r) => {
    if (r instanceof RegExp) return r.test(path)
    else if (typeof r === 'string') return r.toLowerCase() == path.toLowerCase()
    return false
  })
}

export const IntercomManager: FC = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    const w = window as any
    if (!w) return
    if (!w.Intercom) return
    if (!w.intercomBlacklist) return

    const hide = isOnBlacklist(w.intercomBlacklist, pathname)
    w.Intercom('update', {
      hide_default_launcher: hide,
    })
  }, [pathname])

  return <></>
}

export default IntercomManager
