// Third party dependencies
import React, { FC } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Default from 'layouts/Default'
import Secure from 'layouts/Secure'
import HasProperty from 'layouts/HasProperty'

// Components
import HasPropertyPlan from './HasPropertyPlan'
import PlanHome from './PlanHome'
import Customize from './Customize'

const PropertyPlan: FC = () => {
  const match = useRouteMatch()
  return (
    <Secure>
      <HasProperty>
        <HasPropertyPlan>
          <Default>
            <Switch>
              <Route path={`${match.path}/projects/:projectId`} component={Customize} />
              <Route path={`${match.path}`} component={PlanHome} />
            </Switch>
          </Default>
        </HasPropertyPlan>
      </HasProperty>
    </Secure>
  )
}

export default PropertyPlan
