import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { getJSON } from 'utils/fetch'

import { Payment } from 'recoil/payments'

import Default from 'layouts/Default'
import Secure from 'layouts/Secure'

import Card from 'components/Card'
import PageTitle from 'components/PageTitle'
import Spinner from 'components/Spinner'
import PaymentCard from 'pages/Payment/PaymentCard'
import CoreButton from 'components/Core/CoreButton'
import CreditCard from '@mui/icons-material/CreditCard'

dayjs.extend(relativeTime)

// 'started' and 'canceled' payments have to do with self-service flow
// and don't need to be shown to users
const STATUSES_TO_SHOW = ['pending', 'failed', 'succeeded']

const Inner: FC = () => {
  const [payments, setPayments] = useState<Payment[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    let didCancel = false
    const _ = async () => {
      const payments = await getJSON<Payment[]>('/api/v1/payments')
      if (didCancel) return

      const filtered = payments.filter((p) => STATUSES_TO_SHOW.includes(p.status))

      setPayments(filtered)
      setIsLoading(false)
    }
    _()
    return () => {
      didCancel = true
    }
  }, [])

  if (isLoading) {
    return (
      <div className="tw-text-center">
        <Spinner size="xl" />
      </div>
    )
  }

  return (
    <>
      <PageTitle title="Payments" />
      <h2 className="tw-mb-8 tw-text-3xl">{'Payments'}</h2>
      <div className="tw-mb-5 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
        {(payments || []).length === 0 && (
          <Card className="tw-text-center tw-py-32">
            <p className="tw-text-2xl tw-mb-4">{'No payments yet.'}</p>
            <p>{"You'll be able to see your payments here later on."}</p>
          </Card>
        )}
        {(payments || []).map((payment) => (
          <PaymentCard key={`payment-${payment.id}`} compact payment={payment}>
            <div className="tw-space-y-4 tw-justify-self-end">
              {payment.status == 'pending' && (
                <Link to={`/payments/${payment.id}`} type="button" className="tw-w-full">
                  <CoreButton icon={<CreditCard />} size="m" kind="significant" text="Pay" className="tw-w-full" />
                </Link>
              )}
              <div className="tw-text-xs tw-text-tertiary-black">{`Requested ${dayjs(
                payment.created_at
              ).fromNow()}`}</div>
            </div>
          </PaymentCard>
        ))}
      </div>
    </>
  )
}

const Payments: FC = () => {
  return (
    <Secure>
      <Default>
        <Inner />
      </Default>
    </Secure>
  )
}

export default Payments
