import React, { FC } from 'react'
import classNames from 'classnames'

import CallToAction from 'components/CallToAction'
import styles from './styles.module.scss'

const FakeTemplate: FC = () => (
  <li className={classNames(styles.template, styles.fake)}>
    <div className={styles.item}>
      <div className={styles.img}></div>
      <div className={styles.row}>
        <div className={styles.content}>
          <div className={styles.details}>
            <h3 className={styles.name}>{'X'}</h3>
            <p className={styles.description}>{'X'}</p>
          </div>
          <div className={styles.actions}>
            <CallToAction className={styles.cta} value={`Add X points`} />
          </div>
        </div>
      </div>
    </div>
  </li>
)

export default FakeTemplate
