import React, { FC, useCallback } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, useParams, generatePath } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'

import AnalyticsPageTracker from 'non-rendering/AnalyticsPageTracker'
import useRailsFlash from 'non-rendering/useRailsFlash'
import IntercomManager from 'non-rendering/IntercomManager'
import { AutoShowOrHideAdjustBanner } from 'non-rendering/ShowOrHideAdjustBanner'
import { PROPERTY_PATH, PROPERTY_ID_PATH_PART } from 'utils/propertyPath'

import Root from 'pages/Root'
import Payment from 'pages/Payment'
import Signin from 'pages/Signin'
import Signup from 'pages/Signup'
import ChangePassword from 'pages/ChangePassword'
import MagicSignin from 'pages/MagicSignin'
import MissingInformation from 'pages/MissingInformation'
import ForgotPassword from 'pages/ForgotPassword'
import Dashboard from 'pages/Dashboard'
import PropertyPlan from 'pages/PropertyPlan'
import EditProperty from 'pages/EditProperty'
import Contact from 'pages/Contact'
import Properties from 'pages/Properties'
import Payments from 'pages/Payments'
import Profile from 'pages/Profile'
import Packages from 'pages/Packages'
import FinanceHub from 'pages/FinanceHub'
import Onboarding from 'pages/Onboarding'
import AnalyticsAccountCreationTracker from 'non-rendering/AnalyticsAccountCreationTracker'

const RedirectToDashboard: FC = () => {
  const { propertyId } = useParams<{ propertyId: string }>()
  return <Redirect to={generatePath(`${PROPERTY_PATH}/dashboard`, { [PROPERTY_ID_PATH_PART]: propertyId })} />
}

export const routes: Array<RouteProps & { name?: string; ignoreTracking?: boolean }> = [
  /* Sign-in */
  { path: '/users/sign_in', component: Signin, name: 'signin' },
  { path: '/users/password/edit', component: ChangePassword, name: 'change-password' },
  { path: '/users/password/magic_sign_in', component: MagicSignin, name: 'magic-signin' },
  { path: '/users/password', component: ForgotPassword, name: 'forgot-password' },
  { path: '/users/sign_up', component: Signup, name: 'signup' },
  { path: '/users/ambassador_sign_up', component: Signup, name: 'ambassador-signup' },
  /* onboarding */
  { path: '/onboarding', component: Onboarding, name: 'onboading', ignoreTracking: true },
  /* Vendor payments */
  { path: '/vendor-payments/:token', component: Payment, name: 'vendor-payment' },
  /* Multi-property paths */
  { path: `${PROPERTY_PATH}/missing-information`, component: MissingInformation, name: 'missing-information' },
  { path: `${PROPERTY_PATH}/dashboard`, component: Dashboard, name: 'dashboard' },
  {
    path: `${PROPERTY_PATH}/property-plans/:propertyPlanId`,
    component: PropertyPlan,
    name: 'plan',
  },
  {
    path: [`${PROPERTY_PATH}/property-plans`, `${PROPERTY_PATH}/property-plan`],
    component: PropertyPlan,
    name: 'plan',
    ignoreTracking: true,
  },
  { path: `${PROPERTY_PATH}/reports`, component: Packages, name: 'reports' },
  { path: `${PROPERTY_PATH}/finance-hub`, component: FinanceHub, name: 'finance-hub' },
  { path: `${PROPERTY_PATH}/edit`, component: EditProperty, name: 'edit-property' },
  { path: `${PROPERTY_PATH}`, component: RedirectToDashboard },
  { path: '/properties', component: Properties, name: 'properties' },
  /* Single property paths */
  { path: '/missing-information', component: MissingInformation, name: 'missing-information' },
  { path: '/dashboard', component: Dashboard, name: 'dashboard' },

  {
    path: '/property-plans/:propertyPlanId',
    component: PropertyPlan,
    name: 'plan',
  },
  {
    path: ['/property-plans', '/property-plan'],
    component: PropertyPlan,
    name: 'plan',
    ignoreTracking: true,
  },
  { path: '/reports', component: Packages, name: 'reports' },
  { path: '/finance-hub', component: FinanceHub, name: 'finance-hub' },
  { path: '/edit', component: EditProperty, name: 'edit-property' },
  /* User paths */
  { path: '/contact', component: Contact, name: 'contact' },
  { path: '/payments/:id', component: Payment, name: 'payment' },
  { path: '/payments', component: Payments, name: 'payments' },
  { path: '/profile', component: Profile, name: 'profile' },
  /* Root */
  { path: '/', component: Root },
]

export const MainRouter: FC = () => {
  useRailsFlash()

  const redirectWithoutTrailingSlash = useCallback(
    ({ location, history }) => (
      <Redirect
        to={{
          pathname: `${location.pathname.substr(0, location.pathname.length - 1)}${location.search}${location.hash}`,
          state: Object.assign({}, location.state, { redirectOriginalAction: history.action }),
        }}
      />
    ),
    []
  )

  return (
    <Router>
      <Switch>
        {/* We need to disallow paths ending in '/' in order to property navigate */}
        <Route exact strict path="(.+/)" render={redirectWithoutTrailingSlash} />
        {routes.map((route) => (
          <Route
            key={Array.isArray(route.path) ? route.path.join(',') : `${route.path}`}
            path={route.path}
            component={route.component}
            exact={route.exact}
            strict={route.strict}
          />
        ))}
      </Switch>
      <AnalyticsPageTracker routes={routes} />
      <AnalyticsAccountCreationTracker />
      <IntercomManager />
      <AutoShowOrHideAdjustBanner />
    </Router>
  )
}

export default MainRouter
