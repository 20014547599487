import { START_STATE, END_STATE } from '../states'
import { Flow } from '.'

const flow: Flow = {
  [START_STATE]: {
    to: [
      {
        state: 'payment-welcome',
      },
    ],
  },
  ['payment-welcome']: {
    url: '/payment-welcome',
    next: [
      {
        state: 'payment-download',
      },
    ],
  },
  ['payment-download']: {
    url: '/payment-download',
    next: [
      {
        state: END_STATE,
      },
    ],
  },
}

export default flow
