import React, { FC, PropsWithChildren } from 'react'
import ScrollToTop from 'non-rendering/ScrollToTop'
import GlobalAlert from '../components/GlobalAlert'
import OverlayMount from '../components/OverlayMount'
import Navigation from '../components/Navigation'
import cx from 'classnames'

import styles from './styles.module.scss'

interface AuthPartProps {
  className?: string
}

const HeadHeader: FC<PropsWithChildren<AuthPartProps>> = ({ className, children }) => (
  <h1 className={cx('tw-font-normal tw-text-4xl', className)}>{children}</h1>
)

const HeadSubheader: FC<PropsWithChildren<AuthPartProps>> = ({ className, children }) => (
  <div className={cx('tw-mt-2 tw-leading-tight', className)}>{children}</div>
)

const Head: FC<PropsWithChildren<AuthPartProps>> & { Header: typeof HeadHeader; Subheader: typeof HeadSubheader } = ({
  className,
  children,
}) => (
  <div
    className={cx(
      styles.head,
      'tw-bg-moss tw-text-white tw-px-4 tw-py-8 sm:tw-px-16 sm:tw-pb-16 tw-rounded-t-3xl',
      className
    )}
  >
    {children}
  </div>
)

Head.Header = HeadHeader
Head.Subheader = HeadSubheader

const BodyHeader: FC<PropsWithChildren<AuthPartProps>> = ({ className, children }) => (
  <h1 className={cx('tw-text-4xl tw-text-base-charcoal tw-font-normal', className)}>{children}</h1>
)

const BodySubheader: FC<PropsWithChildren<AuthPartProps>> = ({ className, children }) => (
  <p className={cx('tw-mt-2 tw-text-gray-500', className)}>{children}</p>
)

const Body: FC<PropsWithChildren<AuthPartProps>> & { Header: typeof BodyHeader; Subheader: typeof BodySubheader } = ({
  className,
  children,
}) => (
  <div className={cx('tw-bg-white tw-rounded-3xl tw-py-6 sm:tw-py-10 tw-px-4 sm:tw-px-16', className)}>{children}</div>
)

Body.Header = BodyHeader
Body.Subheader = BodySubheader

const Auth: FC<PropsWithChildren> & { Head: typeof Head; Body: typeof Body } = ({ children }) => {
  const isWebview = !!document.body.dataset.webview

  return (
    <>
      <ScrollToTop />
      <GlobalAlert />
      <div className="tw-flex tw-flex-col tw-min-h-screen tw-font-sans-open-sans tw-tracking-tight tw-antialiased">
        <OverlayMount>
          {!isWebview && <Navigation blank />}
          <div className="tw-grow tw-flex tw-items-center tw-justify-center">
            <div className="container tw-mx-auto">
              <div className="tw-mx-auto tw-max-w-xl tw-rounded-3xl">{children}</div>
              <p className="tw-my-12 tw-text-center tw-text-xs tw-text-base-gray-three">{`© ${new Date().getFullYear()} Realm. All rights reserved.`}</p>
            </div>
          </div>
        </OverlayMount>
      </div>
    </>
  )
}
Auth.Head = Head
Auth.Body = Body

export default Auth
