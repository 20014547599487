import React, { FC, PropsWithChildren } from 'react'

import ScrollToTop from 'non-rendering/ScrollToTop'

import GlobalAlert from '../components/GlobalAlert'
import OverlayMount from '../components/OverlayMount'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

export const FullBleedHero: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <ScrollToTop />
      <GlobalAlert />
      <div className="tw-flex tw-flex-col tw-min-h-screen">
        <OverlayMount>
          <Navigation />
          {children}
          <Footer />
        </OverlayMount>
      </div>
    </>
  )
}

export default FullBleedHero
