import React, { FC, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { Property, TopProject } from 'recoil/properties'
import { useBaseProjectTemplates } from 'recoil/baseProjectTemplates'

import { track } from 'utils/analytics'

import ArrowLink from 'components/ArrowLink'
import Tooltip from 'components/Tooltip'

import ArrowForward from '@mui/icons-material/ArrowForward'
import { useProjects } from 'recoil/projects'
import ProjectCard from 'pages/Dashboard/PropertyPlan/ProjectCard'
import { useRecoilValue } from 'recoil'
import { userState } from 'recoil/user'

import styles from './styles.module.scss'
import sharedStyles from '../styles.module.scss'

interface PropertyPlanProps {
  className?: string
  property: Property
}

type PropertyPlanFilter = 'you-need-to-do' | 'in-your-plan'

const PropertyPlan: FC<PropertyPlanProps> = ({ className, property }) => {
  const { projectTemplates } = useBaseProjectTemplates(property)
  const { projects, addProject } = useProjects()

  const user = useRecoilValue(userState)

  const [index, setIndex] = useState<number>(0)
  const [filter, setFilter] = useState<PropertyPlanFilter>('you-need-to-do')

  const topProjects = property.top_projects

  const getTemplateForTopProject = useCallback(
    (topProject: TopProject) => {
      return projectTemplates.find((t) => t.id == topProject?.id)
    },
    [projectTemplates]
  )

  const getTopProjectAddedToPlan = useCallback(
    (topProject: TopProject) => {
      const template = getTemplateForTopProject(topProject)
      if (!template) return false

      return projects.some((p) => p.project_template_id == template.id)
    },
    [projects, getTemplateForTopProject]
  )

  const filteredProjects = useMemo(() => {
    switch (filter) {
      case 'you-need-to-do':
        return topProjects
      case 'in-your-plan':
        return topProjects.filter((project) => getTopProjectAddedToPlan(project))
    }
  }, [filter, topProjects, getTopProjectAddedToPlan])

  const activeProjects = useMemo(() => {
    if (!filteredProjects) return []

    let normalizedIndex = index % filteredProjects.length
    normalizedIndex = normalizedIndex < 0 ? filteredProjects.length + normalizedIndex : normalizedIndex

    const partOne = filteredProjects.slice(normalizedIndex, normalizedIndex + 4)

    if (partOne.length == filteredProjects.length) return partOne

    const partTwo = filteredProjects.slice(0, Math.min(filteredProjects.length, 4) - partOne.length)

    return [...partOne, ...partTwo]
  }, [index, filteredProjects])

  const handleCtaClick = useCallback(
    (location: string) => () => {
      track('click cta', { cta: 'property-plan', module: 'property-plan', location: location })
    },
    []
  )

  const handleClickNext = useCallback(() => {
    setIndex(index + 1)
  }, [index])

  const handleClickPrevious = useCallback(() => {
    setIndex(index - 1)
  }, [index])

  const handleClickFilter = useCallback(
    (val: PropertyPlanFilter) => {
      return () => {
        setFilter(val)
        setIndex(0)
      }
    },
    [setFilter]
  )

  const handleClickAddProjectToPlan = useCallback(
    (topProject: TopProject) => {
      return () => {
        const template = getTemplateForTopProject(topProject)
        if (!template) return
        if (!user) return

        track(
          'submit form',
          {
            section: 'dashboard',
            form: 'property-plan',
            action: 'create',
            template: {
              id: template.id,
              kind: template.kind,
            },
          },
          {
            id: 24231,
            args: {
              orderId: `project-${user.id}`,
              customerId: user.id,
            },
          }
        )
        addProject({ project_template_id: template.id })
      }
    },
    [getTemplateForTopProject, addProject, user]
  )

  return (
    <>
      <div className={styles.heading}>
        <div className={sharedStyles.cardTitle}>
          <h2>{'Projects for you'}</h2>
          <h3>
            {'Add projects to see their cost and impact on home value, all in your '}
            <Link to="property-plans" onClick={handleCtaClick('sub-header')}>
              {'Project Planner'}
            </Link>
            {'.'}
          </h3>
        </div>
        <div className={styles.controls}>
          <div className={styles.filters}>
            <span>{'Sort by:'}</span>
            <button
              type="button"
              className={classNames(filter === 'you-need-to-do' && styles.active)}
              onClick={handleClickFilter('you-need-to-do')}
            >
              {'Projects you need to do'}
            </button>
            <span>{'/'}</span>
            <button
              type="button"
              className={classNames(filter === 'in-your-plan' && styles.active)}
              onClick={handleClickFilter('in-your-plan')}
            >
              {'Projects from your Project Planner'}
            </button>
          </div>
          <Tooltip
            overlay={
              <>
                {
                  'We calculate impact to value based on nearby comparable properties and cost estimates based on local labor & material pricing. See a project you’ve already done? '
                }
                <Link to="edit" onClick={handleCtaClick('tooltip')}>
                  {'Update your recent projects here'}
                </Link>{' '}
                {'for better recommendations.'}
              </>
            }
          />
        </div>
      </div>
      <div className={classNames(styles.propertyPlan, className)}>
        <div className={styles.projects}>
          {activeProjects.length > 0 ? (
            <>
              <button type="button" className={styles.previous} onClick={handleClickPrevious}>
                <div className={styles.icon}>
                  <ArrowForward />
                </div>
              </button>
              <ul className={classNames(styles.ideas)}>
                {activeProjects.map((project, i) => (
                  <ProjectCard
                    primary={i === 0}
                    key={project.id}
                    topProject={project}
                    template={getTemplateForTopProject(project)}
                    addedToPlan={getTopProjectAddedToPlan(project)}
                    addToPlan={handleClickAddProjectToPlan(project)}
                  />
                ))}
              </ul>
              <button type="button" className={styles.next} onClick={handleClickNext}>
                <div className={styles.icon}>
                  <ArrowForward />
                </div>
              </button>
            </>
          ) : (
            <div className={styles.noResults}>{'No results match your filter'}</div>
          )}
        </div>
        <div className={styles.action}>
          <ArrowLink to="property-plans" onClick={handleCtaClick('explore-projects')} className={styles.cta}>
            {'Explore projects'}
          </ArrowLink>
        </div>
      </div>
    </>
  )
}

export default PropertyPlan
