// TODO: This is currently 'standalone' and doesn't
// utilize a layout.

import CardPayButton from 'pages/Payment/CardPayButton'
import Footer from 'layouts/components/Footer'
import { getJSON } from 'utils/fetch'
import PageTitle from 'components/PageTitle'
import { poll } from 'utils/poll'
import PlaidLinkButton from 'pages/Payment/PlaidLinkButton'
import { Payment } from 'recoil/payments'
import React, { FC, useEffect, useMemo, useState } from 'react'
import Spinner from 'components/Spinner'
import { useLocation, useParams } from 'react-router-dom'
import qs from 'qs'
import PaymentCard, { PAYMENT_MAX_CARD_AMOUNT } from 'pages/Payment/PaymentCard'
import PaymentSuccess from 'pages/Payment/PaymentSuccess'
import TermsConsentMessage from 'components/TermsConsentMessage'

const Payment: FC = () => {
  const { id, token } = useParams<{ id: string; token: string }>()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [payment, setPayment] = useState<Payment | null>(null)
  const [error, setError] = useState<string | null>(null)

  const idOrToken = useMemo(() => id || token, [id, token])

  const checkSuccess = useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true }).cs
  }, [location.search])

  useEffect(() => {
    let didCancel = false
    const _ = async () => {
      setIsLoading(true)
      if (checkSuccess) {
        try {
          const payment = await poll<Payment & { reset_password_token: string }>({
            fn: async () => await getJSON<Payment & { reset_password_token: string }>(`/api/v1/payments/${idOrToken}`),
            validate: (payment: Payment) => payment.status === 'succeeded',
            interval: 2000,
            maxAttempts: 10,
          })
          if (didCancel) return
          setPayment(payment)
          setIsLoading(false)
        } catch (e) {
          alert('Error: Timeout waiting for payment to process')
          window.location.href = '/'
        }
      } else {
        try {
          const response = await getJSON<Payment>(`/api/v1/payments/${idOrToken}`)
          if (didCancel) return
          setPayment(response)
          setIsLoading(false)
        } catch (e) {
          // payment not found, redirect away
          window.location.href = '/'
        }
      }
    }
    _()
    return () => {
      didCancel = true
    }
  }, [idOrToken, location, checkSuccess])

  if (!payment) return null

  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-min-h-screen">
      <PageTitle title="Payment" />
      {isLoading ? (
        <div className="tw-grow tw-flex tw-flex-col tw-justify-center tw-items-center">
          <Spinner size="xl" />
        </div>
      ) : payment.status == 'succeeded' ? (
        <PaymentSuccess
          idOrToken={idOrToken}
          referralLink={payment?.user?.referral_link}
          referralPayouts={payment?.user?.referral_payouts || payment?.default_referral_payouts || []}
        />
      ) : (
        <div className="tw-grow tw-flex tw-justify-center tw-items-center">
          <PaymentCard className="tw-max-w-2xl tw-mx-4 tw-my-8" error={error} payment={payment}>
            {!payment.stripe_charge_ref && (
              <TermsConsentMessage verb="purchasing this package" className="tw-text-sm tw-text-tertiary-black" />
            )}
            {!payment.stripe_charge_ref ? (
              <>
                <CardPayButton
                  idOrToken={idOrToken}
                  setError={setError}
                  disabled={payment.amount_in_cents > PAYMENT_MAX_CARD_AMOUNT}
                />
                <PlaidLinkButton
                  idOrToken={idOrToken}
                  payment={payment}
                  setError={setError}
                  setIsLoading={setIsLoading}
                  setPayment={setPayment}
                />
              </>
            ) : (
              !payment.stripe_charge_ref && !error && <Spinner />
            )}
          </PaymentCard>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Payment
