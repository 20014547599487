import React, { FC } from 'react'
import type { MarkdownToJSX } from 'markdown-to-jsx'

import { useDynamicContent, useDynamicContents } from 'recoil/dynamicContent'

import Markdown from 'components/Markdown'

const JustADiv: FC<React.PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <div className={className}>{children}</div>
)

interface PrefetchProps {
  contentKey: string
  className?: string
  loading?: () => React.ReactNode
  missing?: () => React.ReactNode
}

// Support control to easily allow prefetching data for an entire section.
// The key must be a parent key, not a specific item key.
export const DynamicPrefetch: FC<React.PropsWithChildren<PrefetchProps>> = ({
  children,
  className,
  contentKey,
  loading,
  missing,
}) => {
  const { isLoading, checked, isMissing } = useDynamicContents(contentKey)

  if (isLoading || !checked) {
    return <>{loading ? loading() : null}</>
  } else if (isMissing) {
    return <>{missing ? missing() : null}</>
  }

  if (className) return <JustADiv className={className}>{children}</JustADiv>
  return <>{children}</>
}

interface Props {
  contentKey: string
  className?: string
  loading?: () => React.ReactNode
  missing?: () => React.ReactNode
  overrides?: MarkdownToJSX.Overrides
}

const DynamicContent: FC<Props> = ({ className, contentKey, loading, missing, overrides }) => {
  const { dynamicContent, isLoading, checked, isMissing } = useDynamicContent(contentKey)

  if (isLoading || !checked) {
    return <>{loading ? loading() : null}</>
  } else if (isMissing) {
    return <>{missing ? missing() : null}</>
  }

  const wrapper = className ? JustADiv : React.Fragment
  const options = overrides ? { overrides, wrapper } : { wrapper }

  return (
    <Markdown className={className} options={options}>
      {dynamicContent?.content || ''}
    </Markdown>
  )
}

export default DynamicContent
