import Card from 'components/Card'
import ProfileForm from './ProfileForm'
import PageTitle from 'components/PageTitle'
import React, { FC, useCallback } from 'react'
import { postFile } from 'utils/fetch'
import { userState } from 'recoil/user'
import Camera from 'svgs/camera'

import Default from 'layouts/Default'
import Secure from 'layouts/Secure'

import styles from './styles.module.scss'
import { useRecoilState } from 'recoil'

const Profile: FC = () => {
  const [user, setUser] = useRecoilState(userState)

  const attachImage = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>
    ): Promise<{
      profile_image: string
    } | void> => {
      if (e.target.files && e.target.files[0]) {
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        const res = await postFile(`/api/v1/users/profile_image`, formData)

        if (res.isError) {
          const error = new Error(`Non-200 status code: ${res.code}.`)
          throw Object.assign(error, { body: res.jsonBody })
        }
        setUser({ ...user, profile_image: res.jsonBody?.profile_image || '' } as any)
        return res.jsonBody
      }
    },
    [setUser, user]
  )

  return (
    <Secure>
      <Default>
        <div className={styles.container}>
          <PageTitle title="Profile" />
          <h2 className={styles.title}>{'Your Profile'}</h2>
          <Card className="tw-p-8">
            <div className="tw-block md:tw-flex">
              <div className="tw-mr-5 tw-mb-4 md:tw-mb-0">
                <div className={styles.imageContainer}>
                  <input type="file" onChange={attachImage} accept="image/*" value="" />
                  <Camera className={styles.icon} />
                  {user?.profile_image && (
                    <div className={styles.image} style={{ backgroundImage: `url(${user.profile_image})` }} />
                  )}
                </div>
              </div>
              <div className="tw-grow">
                <ProfileForm />
              </div>
            </div>
          </Card>
        </div>
      </Default>
    </Secure>
  )
}

export default Profile
