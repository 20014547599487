import React, { FC, useCallback, useRef } from 'react'
import cx from 'classnames'

interface Props {
  id?: string
  className?: string
  color?: 'green' | 'blue'
  offColor?: 'gray' | 'red'
  disabled?: boolean
  onChange: (value: boolean) => void
  value: boolean
}

const ToggleSwitchButton: FC<Props> = ({
  id,
  className,
  color = 'green',
  offColor = 'gray',
  disabled,
  onChange,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const handleChange = useCallback(() => {
    onChange(!value)
  }, [value, onChange])

  return (
    <div className={cx('tw-flex', className)}>
      <input
        id={id}
        type="checkbox"
        ref={inputRef}
        onChange={handleChange}
        checked={value}
        disabled={disabled}
        className={cx(
          'tw-hidden tw-peer [&:checked+button>div]:!tw-left-1/2 [&:disabled+button>div]:tw-bg-neutrals-300',
          {
            '[&:checked+button]:tw-bg-green-600 [&:checked+button]:tw-border-green-600': color == 'green',
            '[&:checked+button]:tw-bg-blue-600 [&:checked+button]:tw-border-blue-600': color == 'blue',
          }
        )}
      />
      <button
        type="button"
        onClick={handleClick}
        className={cx(
          'tw-relative tw-inline tw-items-center tw-justify-center tw-m-0 tw-h-6 tw-w-12 tw-rounded-3xl tw-box-content tw-border-2 tw-transition-colors tw-duration-100 tw-ease-in tw-bg-neutrals-400 tw-border-neutrals-400 tw-outline-none',
          {
            'tw-bg-neutrals-400 tw-border-neutrals-400': offColor == 'gray' && !value,
            'tw-bg-red-600 tw-border-red-600': offColor == 'red' && !value,
            'peer-focus:tw-border-green-600 focus:tw-border-green-600': color == 'green',
            'peer-focus:tw-border-blue-600 focus:tw-border-blue-600': color == 'blue',
          }
        )}
      >
        <div className="tw-absolute tw-top-0 tw-left-0 tw-w-6 tw-h-6 tw-rounded-3xl tw-transition-[left] tw-duration-100 tw-ease-in tw-shadow tw-bg-white" />
      </button>
    </div>
  )
}

export default ToggleSwitchButton
