import { getJSON } from 'utils/fetch'

import { DynamicContent } from 'recoil/dynamicContent'

function path(...pathParts: Array<string>): string {
  return (
    '/' +
    pathParts
      .map((p) => {
        const ret = p.endsWith('/') ? p.substring(0, p.length - 1) : p
        return ret.startsWith('/') ? ret.substring(1) : ret
      })
      .join('/')
  )
}

export default class DynamicContentApi {
  private pathPrefix: string

  constructor() {
    this.pathPrefix = `/api/v1/dynamic_contents`
  }

  async listDynamicContent(filter: string): Promise<Array<DynamicContent>> {
    if (!filter) throw "No valid 'filter' supplied with call"
    return await getJSON(path(this.pathPrefix, `/?filter=${filter}`))
  }

  async getDynamicContent(key: string): Promise<DynamicContent> {
    return await getJSON(path(this.pathPrefix, `/${key}`))
  }
}
