import React, { FC, useRef, useEffect, useState } from 'react'
import classNames from 'classnames'

import AddIcon from 'svgs/add'

import styles from './CallToAction.module.scss'

interface Props {
  className?: string
  value: string
  negative?: boolean
}

const CallToAction: FC<Props> = ({ className, negative, value }) => {
  const textRef = useRef<HTMLSpanElement | null>(null)
  const [width, setWidth] = useState(100)

  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.clientWidth)
    }
  }, [value])

  return (
    <span className={classNames(styles.callToAction, negative ? styles.negative : null, className)}>
      <CTABackground className={styles.background} width={width} />
      <span className={styles.sleeve}>
        <span className={styles.icon}>
          <AddIcon className="tw-w-6 tw-h-6" />
        </span>
        <span className={styles.value} ref={textRef}>
          {value}
        </span>
      </span>
    </span>
  )
}

const FRONT_WIDTH = 62
const BACK_WDITH = 22
const PRE_WIDTH = FRONT_WIDTH + BACK_WDITH

const CTABackground: FC<{ className?: string; width: number }> = ({ className, width }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={`${PRE_WIDTH + width}`}
    height="42"
    viewBox={`0 0 ${PRE_WIDTH + width} 42`}
  >
    <g fill="none" fillRule="evenodd">
      {/* Front */}
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        transform="translate(1 1)"
        d="m58.75,0c-6.935,0 -13.035,3.574 -16.559,8.98c-1.011,0.789 -1.943,1.153 -2.941,1.153c-0.986,0 -1.91,-0.349 -2.782,-1.02c-3.929,-5.585 -9.726,-8.97 -16.302,-9.109c-5.652,-0.002 -10.668,2.219 -14.286,5.808c-3.65,3.623 -5.88,8.641 -5.88,14.188c0,5.521 2.21,10.52 5.78,14.137c3.575,3.623 8.515,5.863 13.97,5.863c6.89,0 12.958,-3.573 16.493,-8.992c1.061,-0.773 2,-1.141 3.007,-1.141c0.981,0 1.902,0.346 2.771,1.012c3.703,5.557 9.802,9.121 16.729,9.121l1.25,0l0,-40l-1.25,0z"
      />
      {/* Center */}
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        transform={`translate(${FRONT_WIDTH} 1)`}
        d={`m0,0l${width},0l0,40l${-width},0z`}
      />
      {/* Back */}
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        transform={`translate(${FRONT_WIDTH + width}, 1)`}
        d="m0,40c5.454,0 10.391,-2.21 13.965,-5.785c3.575,-3.574 5.785,-8.511 5.785,-13.965l0,0l0,-0.5c0,-5.454 -2.21,-10.391 -5.785,-13.965c-3.574,-3.575 -8.511,-5.785 -13.965,-5.785l0,0l0,40z"
      />
    </g>
  </svg>
)

export default CallToAction
