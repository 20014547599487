import cx from 'classnames'
import React, { FC } from 'react'

interface SSOLogoGoogleProps {
  className?: string
}

const SSOLogoGoogle: FC<SSOLogoGoogleProps> = ({ className }) => (
  <svg className={cx('sso-logo-google', className)} preserveAspectRatio="xMidYMid meet" viewBox="0 0 44 44">
    <g fill="none" fillRule="evenodd">
      <path
        d="M30.64 22.205c0-.639-.057-1.252-.164-1.841H22v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
        fill="#4285F4"
      />
      <path
        d="M27.956 28.82l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0 0 22 31c2.43 0 4.467-.806 5.956-2.18z"
        fill="#34A853"
      />
      <path
        d="M16.964 23.71a5.41 5.41 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0 0 13 22c0 1.452.348 2.827.957 4.042l3.007-2.332z"
        fill="#FBBC05"
      />
      <path
        d="M22 16.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C26.463 13.891 24.426 13 22 13a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71z"
        fill="#EA4335"
      />
    </g>
  </svg>
)

export default SSOLogoGoogle
