import React, { FC, useCallback, useReducer, useState } from 'react'
import { userState, User } from 'recoil/user'
import { FormErrors } from 'utils/forms'
import Input from 'components/Input'
import { postJSON } from 'utils/fetch'
import Button from 'components/Button2'
import { alertState } from 'recoil/alert'
import { useRecoilState, useSetRecoilState } from 'recoil'
import Submittable from 'components/Submittable'

import styles from './styles.module.scss'

export type ProfileFormFields = {
  full_name: string
  email: string
  phone_number: string
  current_password: string
  password: string
  password_confirmation: string
  social_facebook?: string
  social_instagram?: string
  social_linkedin?: string
  social_twitter?: string
  has_password: boolean
}

type ProfileFormAction = {
  type: 'setLoading' | 'setState' | 'setFields'
  payload?: Record<string, unknown>
  fieldName?: string
}

export interface ProfileFormState {
  user: ProfileFormFields
  errors: FormErrors
  isLoading: boolean
}

const userRemoteToForm = (user: User): ProfileFormFields => ({
  ...(user as any),
  current_password: '',
  password: '',
  password_confirmation: '',
})

const initState = (user: User): ProfileFormState => ({
  user: userRemoteToForm(user),
  errors: {},
  isLoading: false,
})

const reducer = (state: ProfileFormState, action: ProfileFormAction): ProfileFormState => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, isLoading: true }
    case 'setState':
      return { ...state, ...action.payload }
    case 'setFields':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload?.f as string]: action.payload?.val,
        },
      }
    default:
      throw new Error()
  }
}

export const ProfileForm: FC = () => {
  const [user, setUser] = useRecoilState(userState)
  const [state, dispatch] = useReducer(reducer, user, initState)

  const [showPasswordFields, setShowPasswordFields] = useState(false)
  const togglePasswordFields = useCallback(() => setShowPasswordFields(!showPasswordFields), [showPasswordFields])

  const setAlert = useSetRecoilState(alertState)

  const updateUser = useCallback(
    async (params, successMsg, errorMsg) => {
      dispatch({ type: 'setLoading' })
      const response = await postJSON('/api/v1/users/me', {
        user: params,
      })
      if (response.isError) {
        dispatch({
          type: 'setState',
          payload: {
            errors: response.jsonBody ? response.jsonBody : {},
            isLoading: false,
          },
        })
        setAlert({ type: 'ERROR', message: errorMsg, id: Date.now() })
        return
      }
      setUser(response.jsonBody.user)
      dispatch({
        type: 'setState',
        payload: {
          errors: {},
          user: userRemoteToForm(response.jsonBody.user),
          isLoading: false,
        },
      })
      setAlert({ type: 'SUCCESS', message: successMsg, id: Date.now() })
    },
    [setAlert, setUser]
  )

  const handleSubmit = useCallback(async () => {
    const params = {
      full_name: state.user.full_name,
      email: state.user.email,
      phone_number: state.user.phone_number,
      current_password: state.user.current_password,
      password: state.user.password,
      password_confirmation: state.user.password_confirmation,
      social_facebook: state.user.social_facebook,
      social_instagram: state.user.social_instagram,
      social_linkedin: state.user.social_linkedin,
      social_twitter: state.user.social_twitter,
    }
    return await updateUser(params, 'Successfully updated profile.', 'Error updating profile.')
  }, [state.user, updateUser])

  const cancelEmailChange = useCallback(async () => {
    const params = { unconfirmed_email: null }
    return await updateUser(params, 'Successfully canceled email change.', 'Error while canceling email change.')
  }, [updateUser])

  const handleChange = useCallback(
    (f: keyof ProfileFormState['user']) => {
      return (val) => {
        dispatch({
          type: 'setFields',
          payload: { f, val },
        })
      }
    },
    [dispatch]
  )
  const fieldProps = (f: keyof ProfileFormState['user']): { onChange: (value: any) => void; value: any } => {
    return {
      onChange: handleChange(f),
      value:
        f === 'email' && user?.unconfirmed_email ? `${state.user[f]} → ${user.unconfirmed_email}` : state.user[f] || '',
    }
  }

  return (
    <Submittable onSubmit={handleSubmit}>
      <section>
        <Input label="Full name" errors={state.errors.full_name} className="tw-mb-4" {...fieldProps('full_name')} />
        <Input
          className="tw-mb-4"
          errors={state.errors.email}
          helperText={
            user?.unconfirmed_email && (
              <>
                <p className="tw-mt-2">
                  {'An email has been sent to '}
                  <a href={`mailto:${user.unconfirmed_email}`}>{user.unconfirmed_email}</a>
                  {'. Once you click to confirm that email, the email change will be complete.'}
                </p>
                <button
                  className="tw-text-realm-green-400 hover:tw-underline"
                  onClick={cancelEmailChange}
                  type="button"
                >
                  {'Cancel change request'}
                </button>
              </>
            )
          }
          label="E-mail"
          readOnly={!!user?.unconfirmed_email}
          {...fieldProps('email')}
        />
        <Input
          label="Phone Number"
          errors={state.errors.phone_number}
          className="tw-mb-4"
          placeholder="212-555-1234"
          inputMode="tel"
          {...fieldProps('phone_number')}
        />
      </section>
      <section>
        {showPasswordFields ? (
          <>
            {state.user.has_password ? (
              <Input
                label="Current password"
                errors={state.errors.current_password}
                className="tw-mb-4"
                type="password"
                {...fieldProps('current_password')}
              />
            ) : null}
            <Input
              label="New password"
              errors={state.errors.password}
              className="tw-mb-4"
              type="password"
              {...fieldProps('password')}
            />
            <Input
              label="Confirm password"
              errors={state.errors.password_confirmation}
              className="tw-mb-4"
              type="password"
              {...fieldProps('password_confirmation')}
            />
          </>
        ) : (
          <button type="button" className={styles.toggle} onClick={togglePasswordFields}>
            {'Update password'}
          </button>
        )}
      </section>
      <section>
        <Button type="submit" loading={state.isLoading}>
          {'Save'}
        </Button>
      </section>
    </Submittable>
  )
}

export default ProfileForm
