import CoreList from 'components/Core/CoreList'
import React, { FC, useCallback } from 'react'
import { ProjectTemplateStub } from 'recoil/projectTemplateStubs'

interface ListIemsProps {
  project: ProjectTemplateStub
  onClick: (key: ProjectTemplateStub) => void
  selected: boolean
}

function cropImage(path: string, width: number, height: number): string {
  return `${path}?width=${width}&height=${height}&fit=crop`
}

const ProjectListItem: FC<ListIemsProps> = ({ project, onClick, selected }) => {
  const handlePick = useCallback(() => {
    onClick(project)
  }, [project, onClick])

  return (
    <CoreList.Item
      className="tw-rounded-2xl tw-bg-white tw-p-6"
      key={project.id}
      text={project.name}
      onClick={handlePick}
      selected={selected}
      imageSrc={cropImage(project.image, 40, 40)}
      rightCheck
    />
  )
}

export default ProjectListItem
