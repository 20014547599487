import CoreButton from 'components/Core/CoreButton'
import CoreText from 'components/Core/CoreText'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { MeetingAvailability, useAdvisorBooking, useOnboardingCalendlyEvent } from 'recoil/advisorBooking'
import { useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { Experiments, useExperiment } from 'experiments'

import moment from 'moment'

import styles from './BookLarge.module.scss'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { prepareBoth } from 'utils/analyticsV2'

const [trackClick, trackImpression] = prepareBoth({ family: 'projects', screen: 'onboarding-projects' })
import Spinner from 'components/Spinner'
import TermsConsentMessage from 'components/TermsConsentMessage'

const DEFAULT_CURSOR = new Date()

const BookLarge: FC = () => {
  const { next } = useFlow()
  const { setOnboarding } = useOnboardingProperty()
  const calendlyUri = useOnboardingCalendlyEvent()
  const { preloadedTimes, fetchAvailableTimes } = useAdvisorBooking(calendlyUri)
  const [cursor, setCursor] = useState<Date | null>(null)
  const [availableTimes, setAvailableTimes] = useState<MeetingAvailability[]>()
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedVariant, enrollUserInExperiment] = useExperiment(Experiments.RestrictMeetingTimes.id)

  useEffect(() => enrollUserInExperiment(), [enrollUserInExperiment])

  const availableTimesN72Hr = useMemo(() => {
    if (!availableTimes) return []

    return availableTimes.filter((availability) =>
      moment(availability.start_time).isBetween(moment(), moment().add(72, 'hours'))
    )
  }, [availableTimes])

  const restrictTimes = useMemo(() => {
    if (selectedVariant != Experiments.RestrictMeetingTimes.variants.Variant) return false
    if (!availableTimesN72Hr || availableTimesN72Hr.length < 5) return false

    return true
  }, [selectedVariant, availableTimesN72Hr])

  const groupedTimes = useMemo(() => {
    const timesToDisplay = restrictTimes ? availableTimesN72Hr : availableTimes
    if (!timesToDisplay) return []

    const allGroups = timesToDisplay.reduce(
      (groups: { date: string; times: MeetingAvailability[] }[], current: MeetingAvailability) => {
        const date = moment(current.start_time).format('ddd, MMM D')
        const match = groups.find((group) => group.date == date)

        if (match) {
          match.times.push(current)
        } else {
          groups.push({ date, times: [current] })
        }
        return groups
      },
      []
    )

    if (allGroups.length < 4) return allGroups
    return allGroups.slice(0, 3)
  }, [availableTimes, availableTimesN72Hr, restrictTimes])

  useEffect(() => {
    trackImpression({ code: 'advisor-contact', section: 'advisor-chat' })
  })

  const handleClickTime = useCallback(
    (time: MeetingAvailability) => {
      return () => {
        trackClick({ code: 'advisor-contact', section: 'advisor-chat', content_text: time.start_time })
        setOnboarding((prev) => ({ ...prev, meetingTime: time }))
        next()
      }
    },
    [setOnboarding, next]
  )

  const handleClickNext = useCallback(() => {
    trackClick({ code: 'next', section: 'advisor-chat' })
    if (groupedTimes && groupedTimes.length > 0) {
      const lastGroup = groupedTimes[groupedTimes.length - 1]
      const lastTime = lastGroup.times[lastGroup.times.length - 1]
      setCursor(moment(lastTime.start_time).toDate())
    }
  }, [groupedTimes])

  const handleClickBack = useCallback(() => {
    trackClick({ code: 'back', section: 'advisor-chat' })
    const firstGroup = groupedTimes[0]
    const firstTime = firstGroup.times[0]

    const start = moment(firstTime.start_time).add(-3, 'days')

    if (start < moment()) setCursor(DEFAULT_CURSOR)
    else setCursor(start.toDate())
  }, [groupedTimes])

  useEffect(() => {
    if (!cursor) setCursor(DEFAULT_CURSOR)
  }, [cursor])

  useEffect(() => {
    ;(async () => {
      if (!cursor) return
      if (cursor == DEFAULT_CURSOR && preloadedTimes.length > 0) {
        setAvailableTimes([...preloadedTimes])
        setError(null)
        setLoading(false)
        return
      }

      setLoading(true)
      try {
        setError(null)
        setAvailableTimes(await fetchAvailableTimes(cursor, 7))
      } catch (e) {
        setError('Error fetching meeting availability. Try again later.')
      } finally {
        setLoading(false)
      }
    })()
  }, [cursor, preloadedTimes, fetchAvailableTimes])

  useEffect(() => {
    if (loading) return
    if (groupedTimes && groupedTimes.length == 0) setError('No available meeting times. Try again later.')
  }, [loading, groupedTimes])

  useEffect(() => {
    if (error) {
      trackImpression({ code: 'no-meeting-times' })
    }
  }, [error])

  useEffect(() => {
    trackImpression({ code: 'meeting-times', flexfield_1: { count: groupedTimes.length.toString() } })
  }, [groupedTimes])

  if (error) {
    return (
      <div>
        <CoreText.Headline weight="heavy">{error}</CoreText.Headline>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.timePicker}>
        {groupedTimes.map(({ date, times }) => {
          return (
            <div key={date} className={styles.row}>
              <CoreText.Paragraph color="secondaryBlack" className={styles.timeLabel}>
                {date}
              </CoreText.Paragraph>
              <div className={styles.timesWrapper}>
                <div className={styles.times}>
                  {loading ? (
                    <CoreButton size="m" className={styles.timeSpinner} icon={<Spinner color="white" size="sm" />} />
                  ) : (
                    <>
                      {times.map((time) => {
                        const timestamp = moment(time.start_time).format('h:mma')

                        return (
                          <CoreButton
                            size="m"
                            key={timestamp}
                            text={timestamp}
                            onClick={handleClickTime(time)}
                            className={styles.time}
                            disabled={loading}
                          />
                        )
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {!restrictTimes && (
        <div className={styles.nav}>
          <CoreButton
            icon={<ArrowBack />}
            kind="secondary"
            disabled={cursor == DEFAULT_CURSOR}
            size="m"
            onClick={handleClickBack}
          />
          <CoreButton icon={<ArrowForward />} kind="secondary" size="m" onClick={handleClickNext} />
        </div>
      )}
      <TermsConsentMessage className={styles.disclaimer} verb="booking a meeting and signing up for Realm" />
    </div>
  )
}

export default BookLarge
