import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import CoreText from 'components/Core/CoreText'
import styles from './TellMeMore.module.scss'
import CoreInput from 'components/Core/CoreInput'
import { useOnboardingProperty } from 'recoil/onboarding'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import { prepareBoth } from 'utils/analyticsV2'

interface TellMoreMoreProps {
  onDismiss: (additionalInfo?: string) => void
}

const [trackClick, trackImpression] = prepareBoth({ family: 'projects', screen: 'project-info' })

const TellMeMore: FC<TellMoreMoreProps> = ({ onDismiss }) => {
  const { onboarding, setOnboarding } = useOnboardingProperty()
  const [additionalInfo, setMoreInfo] = useState(onboarding.additionalInfo || '')

  const handleSave = useCallback(() => {
    setOnboarding((prev) => {
      return { ...prev, additionalInfo: additionalInfo }
    })
    trackImpression({ code: 'save', section: 'additonal-info' })
    onDismiss(additionalInfo)
  }, [setOnboarding, additionalInfo, onDismiss])

  const handleDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  useEffect(() => {
    trackImpression({ code: 'project-info', section: 'additonal-info' })
    trackImpression({ code: 'save', section: 'additonal-info' })
  }, [])

  const handleChangeMore = useCallback((value: string) => {
    trackClick({ code: 'project-info', section: 'additonal-info', content_text: value })
    setMoreInfo(value)
  }, [])

  const advisorName = useMemo(() => {
    if (!onboarding.bookingDetails) return 'us'

    return onboarding.bookingDetails.advisor.name.split(' ')[0]
  }, [onboarding.bookingDetails])

  const footer = useMemo(() => {
    return {
      text: 'Save',
      onClick: handleSave,
    }
  }, [handleSave])

  return (
    <Wrapper onDismiss={handleDismiss} footer={footer}>
      <CoreText.Headline
        size="xs"
        weight="heavy"
        className={styles.heading}
      >{`Tell ${advisorName} more about your renovation`}</CoreText.Headline>
      <CoreInput.TextArea rows={8} placeholder="Type here" value={additionalInfo} onChangeText={handleChangeMore} />
    </Wrapper>
  )
}

export default TellMeMore
