import { Payment } from 'recoil/payments'
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { postJSON } from 'utils/fetch'
import CoreButton from 'components/Core/CoreButton'
import AccountBalance from '@mui/icons-material/AccountBalance'
import Spinner from 'components/Spinner'

interface InnerPlaidLinkButtonProps {
  disabled?: boolean
  onExit: (error, metadata) => void
  onStart: () => void
  onSuccess: (token, metadata) => void
  token: string
}

const InnerPlaidLinkButton: FC<PropsWithChildren<InnerPlaidLinkButtonProps>> = ({
  disabled,
  onExit,
  onStart,
  onSuccess,
  token,
}) => {
  const { open } = usePlaidLink({ token, onSuccess, onExit })

  const handleClick = useCallback(() => {
    onStart()
    open()
  }, [onStart, open])

  return (
    <CoreButton
      icon={<AccountBalance />}
      disabled={disabled}
      onClick={handleClick}
      text="Pay with ACH"
      className="tw-w-full"
      size="m"
    />
  )
}

interface PlaidLinkButtonProps {
  disabled?: boolean
  idOrToken: string
  payment: Payment
  setError: (msg: string | null) => void
  setIsLoading: (value: boolean) => void
  setPayment: (payment: Payment) => void
}

const PlaidLinkButton: FC<PlaidLinkButtonProps> = ({
  disabled,
  idOrToken,
  payment,
  setError,
  setIsLoading,
  setPayment,
}) => {
  const [plaidToken, setPlaidToken] = useState<string | null>(null)

  useEffect(() => {
    if (!payment || payment.stripe_charge_ref) return
    let didCancel = false
    const _ = async () => {
      const response = await postJSON(`/api/v1/payments/${idOrToken}/auth_bank_account`, {})
      if (didCancel) return
      if (response.code === 200) {
        setPlaidToken(response.jsonBody.link_token)
      } else {
        setError('An unexpected error happened which prevents payment at this time.')
      }
    }
    _()
    return () => {
      didCancel = true
    }
  }, [idOrToken, payment, setError])

  const handleSuccess = useCallback(
    async (publicToken, metadata) => {
      setIsLoading(true)
      const response = await postJSON(`/api/v1/payments/${idOrToken}/pay`, { metadata, public_token: publicToken })
      if (response.code === 200) {
        setPayment(response.jsonBody)
      } else {
        setError('An unexpected error happened while processing your payment. We will contact you and get this solved.')
      }
      setIsLoading(false)
    },
    [idOrToken, setError, setIsLoading, setPayment]
  )

  const handleExit = useCallback(
    (error) => {
      if (error && error.display_message) {
        setError(error.display_message)
      }
    },
    [setError]
  )

  const handleStart = useCallback(() => {
    setError(null)
  }, [setError])

  if (!plaidToken) {
    return (
      <CoreButton
        className="tw-w-full"
        size="m"
        disabled
        kind="primary"
        text="Pay with ACH"
        icon={<Spinner size="md" color="white" />}
      />
    )
  }

  return (
    <InnerPlaidLinkButton
      disabled={disabled}
      onExit={handleExit}
      onStart={handleStart}
      onSuccess={handleSuccess}
      token={plaidToken}
    />
  )
}

export default PlaidLinkButton
