import React, { FC } from 'react'

interface CameraProps {
  className?: string
}

const Camera: FC<CameraProps> = ({ className }) => (
  <svg
    className={className}
    width="22px"
    height="18px"
    viewBox="0 0 22 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M18,3.00000085 L16.54,3.00000085 L15.72,1.78000169 C14.9775086,0.667133064 13.7278226,-0.000867240498 12.39,8.45039672e-07 L9.61,8.45039672e-07 C8.27217739,-0.000867240498 7.02249142,0.667133064 6.28,1.78000169 L5.46,3.00000085 L4,3.00000085 C1.790861,3.00000085 0,4.79086269 0,7.00000085 L0,14.0000008 C0,16.2091407 1.790861,18.0000008 4,18.0000008 L18,18.0000008 C20.209139,18.0000008 22,16.2091407 22,14.0000008 L22,7.00000085 C22,4.79086269 20.209139,3.00000085 18,3.00000085 Z M11,14.5000017 C8.51471863,14.5000017 6.5,12.4852831 6.5,10.0000017 C6.5,7.51472032 8.51471863,5.50000169 11,5.50000169 C13.4852814,5.50000169 15.5,7.51472032 15.5,10.0000017 C15.4945005,12.4830015 13.4829999,14.4945022 11,14.5000017 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

export default Camera
