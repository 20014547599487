import React, { FC } from 'react'

interface HouseFilledProps {
  className?: string
}

const HouseFilled: FC<HouseFilledProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99766 16.3275V11.3275H11.9977V16.3275C11.9977 16.8775 12.4477 17.3275 12.9977 17.3275H15.9977C16.5477 17.3275 16.9977 16.8775 16.9977 16.3275V9.32749H18.6977C19.1577 9.32749 19.3777 8.75749 19.0277 8.45749L10.6677 0.927485C10.2877 0.587485 9.70766 0.587485 9.32766 0.927485L0.967657 8.45749C0.627657 8.75749 0.837657 9.32749 1.29766 9.32749H2.99766V16.3275C2.99766 16.8775 3.44766 17.3275 3.99766 17.3275H6.99766C7.54766 17.3275 7.99766 16.8775 7.99766 16.3275Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default HouseFilled
