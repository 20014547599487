import React, { FC, useCallback } from 'react'
import Spinner from 'components/Spinner'

import styles from './styles.module.scss'
import { RenovationImage } from 'recoil/renovations'

interface CarouselImageProps {
  image: RenovationImage
  isLoading: boolean
  remove: (image: RenovationImage) => void
  navToLast: () => void
  editing: boolean
}

const CarouselImage: FC<CarouselImageProps> = ({ image, isLoading, remove, navToLast, editing }) => {
  const handleRemove = useCallback(() => {
    remove(image)
  }, [image, remove])

  return (
    <div className={styles.imgGp}>
      <div className={styles.imgContainer}>
        {isLoading ? (
          <div className={styles.loading}>
            <Spinner size="xl" />
          </div>
        ) : (
          <a href={image.url} target="_blank" rel="noopener noreferrer">
            <img src={image.url} alt={image.name} />
          </a>
        )}
      </div>
      {editing && (
        <div className={styles.imageActions}>
          <button type="button" className={styles.textButton} onClick={handleRemove} disabled={isLoading}>
            {'Remove image'}
          </button>
          <button type="button" className={styles.textButton} onClick={navToLast}>
            {'Add another'}
          </button>
        </div>
      )}
    </div>
  )
}

export default CarouselImage
