import React, { FC } from 'react'

import { useDynamicContents } from 'recoil/dynamicContent'

import DynamicContent from 'components/DynamicContent'

// Styles
import styles from './Testimonials.module.scss'

const Testimonials: FC = () => {
  const { dynamicContents } = useDynamicContents('app_reports_testimonials')

  const contentKeys = Object.keys(dynamicContents).sort()

  return (
    <section className={styles.testimonials}>
      <DynamicContent contentKey="app_reports_testimonials" />

      {contentKeys.length > 0 ? (
        <ul className={styles.testimonialList}>
          {contentKeys.map((contentKey) => (
            <li key={contentKey} className={styles.testimonial}>
              <DynamicContent contentKey={contentKey} />
            </li>
          ))}
        </ul>
      ) : null}
    </section>
  )
}

export default Testimonials
