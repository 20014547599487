import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { Property, UProperty, useProperties } from 'recoil/properties'

import { track } from 'utils/analytics'
import cx from 'classnames'
import styles from './styles.module.scss'
import Checkbox from 'components/Checkbox'
import { useSetRecoilState } from 'recoil'
import { editStatusState } from 'recoil/editStatus'
import FancyButton from 'components/FancyButton'
import Spinner from 'components/Spinner'
import editStyles from '../../styles.module.scss'

// This list is now ordered by array index.
const ALL_FEATURES = [
  { key: 'attic', value: 'Attic' },
  { key: 'finished_attic', value: 'Finished Attic' },
  { key: 'basement', value: 'Basement' },
  { key: 'finished_basement', value: 'Finished Basement' },
  { key: 'hvac_central_air', value: 'Central Air (HVAC)' },
  { key: 'hvac_heating', value: 'Heating (HVAC)' },
  // { key: 'water_heater', value: 'Water Heater' },
  { key: 'deck', value: 'Deck' },
  { key: 'pool', value: 'Pool' },
  { key: 'fencing', value: 'Fencing' },
  { key: 'firepit', value: 'Firepit' },
  { key: 'landscaping', value: 'Landscaping' },
  { key: 'solar', value: 'Solar' },
  { key: 'outdoor_kitchen', value: 'Outdoor Kitchen' },
  { key: 'backyard_gym', value: 'Backyard Gym' },
  { key: 'existing_guest_suite_or_adu', value: 'Backyard Home' },
  { key: 'backyard_home_office', value: 'Backyard Home Office' },
]

interface Features {
  pool: boolean
  backyard_gym: boolean
  backyard_home_office: boolean
  deck: boolean
  fencing: boolean
  firepit: boolean
  hvac_central_air: boolean
  hvac_heating: boolean
  landscaping: boolean
  outdoor_kitchen: boolean
  solar: boolean
  water_heater: boolean
  existing_guest_suite_or_adu: boolean
  attic: boolean
  finished_attic: boolean
  basement: boolean
  finished_basement: boolean
}

function initFeatures(property: Property | null): Features {
  return {
    pool: !!(property?.pool || 0),
    backyard_gym: property?.backyard_gym || false,
    backyard_home_office: property?.backyard_home_office || false,
    deck: property?.deck || false,
    fencing: property?.fencing || false,
    firepit: property?.firepit || false,
    hvac_central_air: property?.hvac_central_air || false,
    hvac_heating: property?.hvac_heating || false,
    landscaping: property?.landscaping || false,
    outdoor_kitchen: property?.outdoor_kitchen || false,
    solar: property?.solar || false,
    water_heater: property?.water_heater || false,
    existing_guest_suite_or_adu: property?.existing_guest_suite_or_adu || false,
    attic: !!(property?.attic || 0),
    finished_attic: !!(property?.finished_attic || 0),
    basement: !!(property?.basement || 0),
    finished_basement: !!(property?.finished_basement || 0),
  }
}
function getPropertyUpdate(features: Features): UProperty {
  return {
    ...features,
    pool: features.pool ? 1 : 0,
    attic: features.attic ? 1 : 0,
    finished_attic: features.finished_attic ? 1 : 0,
    basement: features.basement ? 1 : 0,
    finished_basement: features.finished_basement ? 1 : 0,
  }
}

function featuresChanged(newFeatures: Features, oldFeatures: Features): boolean {
  return Object.keys(newFeatures).reduce((prev, key) => prev || newFeatures[key] != oldFeatures[key], false)
}

export const PropertyFeaturesForm: FC = () => {
  const { selectedProperty, updateSelectedProperty } = useProperties()

  const [features, setFeatures] = useState(initFeatures(selectedProperty))
  const [originalFeatures, setOriginalFeatures] = useState(initFeatures(selectedProperty))
  const [interacted, setInteracted] = useState(false)

  const [featuresExpanded, setFeaturesExpanded] = useState(false)
  const setStatus = useSetRecoilState(editStatusState)
  const [loading, setLoading] = useState<boolean>(false)

  const setValue = useCallback(
    (feature: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setInteracted(true)
      const checked = e.target.checked

      const featureChanges: { [feature: string]: boolean } = { [feature]: checked }
      // Keep attic + finished_attic in concert.
      if (feature == 'finished_attic' && checked) featureChanges['attic'] = true
      else if (feature == 'attic' && !checked) featureChanges['finished_attic'] = false

      // Keep basement + finished_basement in concert.
      if (feature == 'finished_basement' && checked) featureChanges['basement'] = true
      else if (feature == 'basement' && !checked) featureChanges['finished_basement'] = false

      setFeatures((prev) => ({ ...prev, ...featureChanges }))
    },
    []
  )

  const update = useCallback(async () => {
    try {
      setLoading(true)
      const edited = featuresChanged(features, originalFeatures)
      track('submit form', {
        section: 'edit',
        form: 'property-features',
        edited: edited,
        interacted: interacted,
        features: Object.keys(features).filter((key) => features[key]),
      })

      const updated = getPropertyUpdate(features)
      await updateSelectedProperty(updated)
      setStatus({ type: 'success', text: 'Saved your property details' })
      // Reset interacted and set originalFeatures to the now applied features
      setInteracted(false)
      setOriginalFeatures(features)
    } catch (e) {
      setStatus({ type: 'error', text: 'Something went wrong, please try again' })
    }
    setLoading(false)
  }, [features, originalFeatures, interacted, updateSelectedProperty, setStatus])

  const featuresToDisplay = useMemo(() => {
    return featuresExpanded ? ALL_FEATURES : ALL_FEATURES.slice(0, 8)
  }, [featuresExpanded])

  const handleClickExpand = useCallback(() => {
    setFeaturesExpanded(true)
  }, [setFeaturesExpanded])

  return (
    <>
      <h3>{'Features'}</h3>
      <h4>{'Additional features of your property'}</h4>
      <div className={styles.propertyFeatures}>
        {featuresToDisplay.map((feature) => {
          const key = feature.key
          return (
            <div className={styles.feature} key={key}>
              <Checkbox id={key} checked={features[key]} onChange={setValue(key)} />
              <label htmlFor={key}>{feature.value}</label>
            </div>
          )
        })}
        {!featuresExpanded && (
          <button type="button" className={styles.showAll} onClick={handleClickExpand}>
            {'Show all'}
          </button>
        )}
      </div>
      <div className={cx(editStyles.buttonWithStatus, styles.buttonRow)}>
        <FancyButton className={cx(editStyles.button)} onClick={update}>
          <div className={styles.saveButton}>
            <div>{'Save'}</div>
            {loading && <Spinner />}
          </div>
        </FancyButton>
      </div>
    </>
  )
}

export default PropertyFeaturesForm
