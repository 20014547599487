import React, { FC } from 'react'
import cx from 'classnames'

import styles from './Arrow.module.scss'

interface Props {
  className?: string
}

// This arrow version is more shallow than the other
const Svg: FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="41"
      viewBox="0 0 38 41"
      width="38"
      className={cx(styles.arrow, className)}
    >
      <g stroke="currentColor" strokeWidth="3" strokeLinecap="round" fill="none" fillRule="evenodd">
        <path d="m1.6042,39.51038c-1.20139,-12.98146 11.72683,-32.97448 34.7916,-37.39576" className={styles.shaft} />
        <path
          d="m22.43749,1.69795c5.19212,-0.5625 14.14121,0.30758 14.02077,0.90621c-0.03704,0.73995 -2.98062,1.18965 -7.1736,5.6736"
          className={styles.head}
        />
      </g>
    </svg>
  )
}
export default Svg
