import React, { FC, PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { atom, useRecoilState } from 'recoil'
import { useFlow } from 'recoil/onboardingFlows'
import styles from './FlowAnimator.module.scss'
import cx from 'classnames'

const ANIMATED_FLOWS = ['camv1', 'camv2', 'camv3', 'homeshow', 'default']

// keeping here (and not in recoil dir), as it only applies to this page
const storedProgressState = atom<number | undefined>({
  key: 'onboardingV3animationState',
  default: undefined,
})

const FORWARD_CLASSES = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  enterDone: styles.enterDone,
  exit: styles.exit,
  exitActive: styles.exitActive,
  exitDone: styles.exitDone,
}

const BACKWARD_CLASSES = {
  enter: cx(styles.enter, styles.back),
  enterActive: cx(styles.enterActive, styles.back),
  enterDone: cx(styles.enterDone, styles.back),
  exit: cx(styles.exit, styles.back),
  exitActive: cx(styles.exitActive, styles.back),
  exitDone: cx(styles.exitDone, styles.back),
}

const FlowAnimator: FC<PropsWithChildren> = ({ children }) => {
  const { flow, currentState } = useFlow()
  const [storedProgress, setStoredProgress] = useRecoilState(storedProgressState)

  const goingBack = useCallback(() => {
    if (!currentState) return false
    if (!storedProgress) return false
    if (currentState.progressStep == storedProgress) return false
    if ((currentState.progressStep || 0) - storedProgress > 0) return false

    return true
  }, [currentState, storedProgress])

  // keep the progress in recoil, and only update it on render
  // this will make it animate
  useEffect(() => {
    setStoredProgress(currentState?.progressStep)
  }, [setStoredProgress, currentState?.progressStep])

  /** `animated` controls whether a `TransitionGroup` and its associated `CSSTransition` classes
   * are applied to this container; it's up to the child pages in the flow to react to these
   * CSS classes and create the animation.
   */
  const animated = useMemo(() => {
    if (!flow) return false

    return ANIMATED_FLOWS.includes(flow)
  }, [flow])

  const childFactory = useCallback(
    (child: ReactElement) => {
      const classNames = goingBack() ? BACKWARD_CLASSES : FORWARD_CLASSES

      return React.cloneElement(child, {
        classNames,
      })
    },
    [goingBack]
  )

  const nodeRef = useRef(null)

  if (!animated) return <div className={styles.flow}>{children}</div>

  return (
    <TransitionGroup component="div" className={styles.flow} childFactory={childFactory}>
      <CSSTransition nodeRef={nodeRef} key={location.pathname} timeout={600}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

export default FlowAnimator
