import React, { FC } from 'react'

interface ConstructionFlippedProps {
  className?: string
}

const ConstructionFlipped: FC<ConstructionFlippedProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6901 17.523L14.9701 12.803C14.4901 12.323 13.9801 12.213 13.3901 12.213L10.8501 14.753C10.8501 15.343 10.9601 15.863 11.4401 16.333L16.1601 21.053C16.5501 21.443 17.1801 21.443 17.5701 21.053L19.6901 18.933C20.0801 18.553 20.0801 17.913 19.6901 17.523Z"
        fill="currentColor"
      />
      <path
        d="M14.6501 8.84297C15.0401 9.23297 15.6701 9.23297 16.0601 8.84297L16.7701 8.13297L18.8901 10.253C20.0601 9.08297 20.0601 7.18297 18.8901 6.01297L16.0601 3.18297C15.6701 2.79297 15.0401 2.79297 14.6501 3.18297L13.9401 3.89297V1.35297C13.9401 0.732973 13.1801 0.402973 12.7301 0.852973L10.1901 3.39297C9.74008 3.84297 10.0701 4.60297 10.6901 4.60297H13.2301L12.5201 5.31297C12.1301 5.70297 12.1301 6.33297 12.5201 6.72297L12.8701 7.07297L9.98008 9.96297L5.87008 5.83297V4.83297C5.87008 4.56297 5.76008 4.31297 5.58008 4.12297L3.56008 2.09297C3.17008 1.70297 2.54008 1.70297 2.15008 2.09297L0.730078 3.51297C0.340078 3.90297 0.340078 4.53297 0.730078 4.92297L2.75008 6.95297C2.94008 7.14297 3.19008 7.24297 3.46008 7.24297H4.46008L8.59008 11.373L7.74008 12.223H6.44008C5.91008 12.223 5.40008 12.433 5.03008 12.813L0.310078 17.533C-0.0799219 17.923 -0.0799219 18.553 0.310078 18.943L2.43008 21.063C2.82008 21.453 3.45008 21.453 3.84008 21.063L8.56008 16.343C8.94008 15.963 9.15008 15.463 9.15008 14.933V13.643L14.3001 8.49297L14.6501 8.84297Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ConstructionFlipped
