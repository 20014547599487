import React, { FC, InputHTMLAttributes, useCallback, useRef } from 'react'
import cx from 'classnames'

import RadioButtonImage from 'svgs/property-plan/radio-button'

import styles from './RadioButton.module.scss'

const RadioButton: FC<InputHTMLAttributes<HTMLInputElement>> = ({ className, ...others }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  return (
    <span className={cx(styles.radioButton, className)}>
      <input type="radio" {...others} ref={inputRef} />
      <button type="button" onClick={handleClick}>
        <RadioButtonImage className={styles.button} />
        <RadioButtonImage className={styles.check} checked />
      </button>
    </span>
  )
}

export default RadioButton
