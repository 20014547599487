import { ConfirmModalType } from 'pages/Onboarding/screens/CamV3Confirm'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import CoreText from 'components/Core/CoreText'
import { TIMES_TO_START, useOnboardingProperty } from 'recoil/onboarding'
import CoreButton from 'components/Core/CoreButton'

import HouseFilled from 'svgs/house-filled'
import ConstructionFlipped from 'svgs/construction-flipped'
import DocumentFilled from 'svgs/document-filled'
import Clock from 'svgs/clock'

import { useProperties } from 'recoil/properties'
import styles from './styles.module.scss'
import RenovationDetailRow from './RenovationDetailRow'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import Add from '@mui/icons-material/Add'

import { titleCase } from 'utils/formatting'
import { useProjectTemplateStubs } from 'recoil/projectTemplateStubs'
import { prepareBoth } from 'utils/analyticsV2'

const [trackClick, trackImpression] = prepareBoth({ screen: 'meeting-confirmation', family: 'projects' })

interface RenovationDetailsProps {
  onOptionSelected: (type: ConfirmModalType) => void
  expanded: boolean
  onClickExpand: () => void
}

const RenovationDetails: FC<RenovationDetailsProps> = ({ onOptionSelected, expanded, onClickExpand }) => {
  const { onboarding } = useOnboardingProperty()
  const { selectedProperty: property } = useProperties()
  const { projectTemplates } = useProjectTemplateStubs()

  useEffect(() => {
    trackImpression({ code: 'add-projects', section: 'projects' })
    trackImpression({ code: 'renovation-info', section: 'more-info' })
  }, [])

  const handleClickRow = useCallback(
    (modal: ConfirmModalType) => {
      return () => onOptionSelected(modal)
    },
    [onOptionSelected]
  )

  const handleClickCta = useCallback(
    (cta: 'projects' | 'more') => {
      return () => {
        const code = cta == 'projects' ? 'add-projects' : 'renovation-info'
        const section = cta == 'projects' ? 'projects' : 'more-info'

        trackClick({ code, section })
        onOptionSelected(cta)
      }
    },
    [onOptionSelected]
  )

  const timeToStartFull = useMemo(() => {
    return TIMES_TO_START.find((x) => x.key == onboarding.timeToStart)
  }, [onboarding.timeToStart])

  const detailsShort = useMemo(() => {
    const parts: string[] = []
    if (property) parts.push(property.delivery_line_1)
    if (timeToStartFull) parts.push(timeToStartFull.short)
    if (onboarding.selectedProjects?.length) parts.push(`${onboarding.selectedProjects.length} projects`)

    return parts.join(', ')
  }, [property, timeToStartFull, onboarding.selectedProjects])

  const projectsLong = useMemo(() => {
    if (!projectTemplates) return
    if (!onboarding.selectedProjects) return

    const projects = onboarding.selectedProjects.map((project) => {
      const match = projectTemplates?.find((x) => x.id == project)
      if (!match) return

      return match.name
    })

    return projects.filter((x) => !!x).join(', ')
  }, [projectTemplates, onboarding.selectedProjects])

  const showAddDetails = useMemo(() => {
    if (!onboarding.selectedProjects) return true
    if (onboarding.selectedProjects.length == 0) return true
    if (!onboarding.additionalInfo) return true

    return false
  }, [onboarding.additionalInfo, onboarding.selectedProjects])

  return (
    <>
      <div
        onClick={onClickExpand}
        role="button"
        className={styles.renovationDetails}
        onKeyDown={onClickExpand}
        tabIndex={0}
      >
        <CoreText.Paragraph className={styles.label} color="tertiaryBlack">
          {'Renovation Details'}
        </CoreText.Paragraph>
        {expanded ? (
          <ExpandLess className={styles.icon} sx={{ fontSize: '1.25rem' }} />
        ) : (
          <ExpandMore className={styles.icon} sx={{ fontSize: '1.25rem' }} />
        )}

        {/* <ArrowForward className={cx(styles.icon, expanded ? styles.contract : styles.expand)} /> */}
      </div>
      {expanded ? (
        <div className={styles.expanded}>
          {property && (
            <RenovationDetailRow
              icon={<HouseFilled />}
              line1={property.delivery_line_1}
              line2={`${titleCase(property.city)}, ${property.state} ${property.zip5}`}
            />
          )}
          {timeToStartFull && (
            <RenovationDetailRow
              icon={<Clock />}
              line1={timeToStartFull.phrase}
              onClick={handleClickRow('time_to_start')}
            />
          )}
          {projectsLong && (
            <RenovationDetailRow
              icon={<ConstructionFlipped />}
              line1={projectsLong}
              onClick={handleClickRow('projects')}
            />
          )}
          {onboarding.additionalInfo && (
            <RenovationDetailRow
              icon={<DocumentFilled />}
              line1={onboarding.additionalInfo}
              onClick={handleClickRow('more')}
            />
          )}
        </div>
      ) : (
        <div onClick={onClickExpand} role="button" tabIndex={0} onKeyDown={onClickExpand}>
          <CoreText.Paragraph weight="light">{detailsShort}</CoreText.Paragraph>
        </div>
      )}
      {showAddDetails && (
        <div className={styles.addDetails}>
          {!onboarding.selectedProjects ||
            (onboarding.selectedProjects.length == 0 && (
              <CoreButton
                icon={<Add />}
                text="Add projects"
                onClick={handleClickCta('projects')}
                kind="secondary"
                size="l"
              />
            ))}
          {!onboarding.additionalInfo && (
            <CoreButton
              icon={<Add />}
              text="Provide more info"
              onClick={handleClickCta('more')}
              kind="secondary"
              size="l"
            />
          )}
        </div>
      )}
    </>
  )
}

export default RenovationDetails
