import React, { FC, useCallback, useState } from 'react'

import { trackClick } from 'utils/analyticsV2'
import { postJSON } from 'utils/fetch'

import Card from 'components/Card'

import Default from 'layouts/Default'
import Secure from 'layouts/Secure'

import CalendlyWidget from './CalendlyWidget'
import MeetingScheduled from './MeetingScheduled'

const Contact: FC = () => {
  const [isMeetingScheduled, setIsMeetingScheduled] = useState(false)

  const onMeetingScheduled = useCallback(async (eventUri, inviteeUri) => {
    setIsMeetingScheduled(true)

    // Save our scheduled meeting to the backend.
    const { jsonBody } = await postJSON('/api/v1/advisor_booking/save_booking', {
      event_uri: eventUri,
      invitee_uri: inviteeUri,
    })

    trackClick({
      code: 'advisor-schedule',
      family: 'advisory',
      section: 'advisory',
      ...(jsonBody && { flexfield_1: { meeting_time: jsonBody.event.start_time } }),
    })
  }, [])

  return (
    <Secure>
      <Default>
        {isMeetingScheduled ? (
          <MeetingScheduled />
        ) : (
          <>
            <CalendlyWidget onEventScheduled={onMeetingScheduled} />
            <h2 className="tw-mb-8 tw-text-4xl">{'Contact Us'}</h2>
            <div className="tw-flex tw-flex-col tw-gap-6 md:tw-flex-row tw-mb-6">
              <div className="md:tw-w-1/2">
                <h2 className="tw-text-2xl tw-border-b tw-border-realm-silver tw-font-medium tw-pb-2 tw-mb-5 tw-text-gray-800">
                  {'Schedule a consultation'}
                </h2>
                <p>
                  {
                    "You're almost ready to take the next step and start maximizing the value of your property. Schedule some time with us using the form on the right, and we'll get started."
                  }
                </p>
              </div>
              <div className="md:tw-w-1/2">
                <Card className="tw-p-0">
                  <div id="schedule-widget" style={{ height: 650 }} />
                </Card>
              </div>
            </div>
          </>
        )}
      </Default>
    </Secure>
  )
}

export default Contact
