import { useState, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

import sharedAction from 'utils/sharedAction'

import PackageAPI from 'apis/packageApi'

export interface Package {
  id: number
  name: string
  description: string
  details: string
  price: PackagePrice
  click_behavior: 'purchase' | 'read_only' | 'cta'
  sample?: string
}

export interface PackagePrice {
  price: number
  interval: PackagePriceIntervalType
  interval_count: number
}

export enum PackagePriceIntervalType {
  OneTime = 'one_time',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export const packagesState = atom<Array<Package> | null>({
  key: 'Pacakges',
  default: null,
})

export const usePackages = (): {
  packages: Array<Package>
  isLoading: boolean
} => {
  const [packages, setPackages] = useRecoilState(packagesState)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const runme = async () => {
      // If we don't have our packages yet...
      if (packages == null) {
        setIsLoading(true)

        // Get our packages from the API
        const newPackages = await sharedAction('getPackages', () => getPackages())

        // Store in recoil
        setPackages(newPackages)

        setIsLoading(false)
      }
    }
    runme()
  }, [setPackages, packages])

  return { packages: packages || [], isLoading }
}

export const getPackages = async (): Promise<Array<Package>> => {
  const api = new PackageAPI()
  return await api.listPackages()
}
