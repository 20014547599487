import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { prepareBoth } from 'utils/analyticsV2'
import { alertState } from 'recoil/alert'
import { useOnboardingProperty } from 'recoil/onboarding'
import { signUp, SignUpResponse, SignUpState } from 'recoil/user'
import { findPhoneNumberFromCalendlyBooking } from 'recoil/advisorBooking'
import CoreInput from 'components/Core/CoreInput'
import CoreButton from 'components/Core/CoreButton'
import Loading from 'components/Loading'
import TermsConsentMessage from 'components/TermsConsentMessage'
import styles from './EmailSignup.module.scss'
import { useUtmParams } from 'utils/analytics'

const [trackClick, trackImpression] = prepareBoth({ family: 'projects', screen: 'sign-up' })

interface EmailSignupProps {
  onSignup: (token?: string) => void
}

const EmailSignup: FC<EmailSignupProps> = ({ onSignup }) => {
  const { onboarding, setOnboarding } = useOnboardingProperty()
  const setAlert = useSetRecoilState(alertState)

  const [fullName, setFullName] = useState(onboarding.bookingDetails?.invitee.name || '')
  const [email, setEmail] = useState(onboarding.email || onboarding.bookingDetails?.invitee.email || '')
  const [phoneNumber, setPhoneNumber] = useState(findPhoneNumberFromCalendlyBooking(onboarding?.bookingDetails))
  const [isLoading, setIsLoading] = useState(false)
  const submitRef = useRef<HTMLButtonElement>(null)

  const errors = useMemo(() => onboarding.userErrors || {}, [onboarding.userErrors])
  const setErrors = useCallback(
    (value) => setOnboarding((onboarding) => ({ ...onboarding, userErrors: value })),
    [setOnboarding]
  )

  useEffect(() => {
    trackImpression({ code: 'advisor-contact', section: 'sign-up' })
  }, [])

  const validate = useCallback((): boolean => {
    const validationErrors = {}
    setErrors({})

    if (!(fullName.length > 0)) {
      validationErrors['full_name'] = ["can't be blank"]
    }

    if (!/^\s*[^@\s]+@[^@\s]+\s*/.test(email)) {
      validationErrors['email'] = ['must be a valid email']
    }

    setErrors(validationErrors)
    return Object.keys(validationErrors).length == 0
  }, [fullName, email, setErrors])

  const utmParams = useUtmParams()

  const handleSubmit = useCallback(
    async (e) => {
      trackClick({ code: 'advisor-contact', section: 'sign-up' })

      e.preventDefault()

      if (!validate()) return

      setIsLoading(true)
      let response: SignUpResponse | null = null
      try {
        const attomId = !onboarding.property ? onboarding.attomId : null

        const request = {
          full_name: fullName,
          phoneNumber: phoneNumber,
          email: email,
          entered_address: attomId ? `attom_id:${attomId}` : onboarding.enteredAddress,
          ambassador_name: '',
          onboarding: onboarding,
          propertyId: onboarding.property?.id,
          attomId: attomId || '',
          utmParams,
        } as SignUpState

        if (onboarding.unavailable) request.non_app_signup_type = 'unserviceable'

        response = await signUp(request)
      } catch (error) {
        setErrors(error)
        if (error.base) {
          setAlert({ type: 'ERROR', message: error.base.join(', '), id: Date.now() })
        }
        setIsLoading(false)
        return
      }

      setOnboarding((onboarding) => {
        return { ...onboarding, user: response?.user }
      })

      if (response.pollable_job_token) {
        onSignup(response.pollable_job_token)
      } else if (response.property_id) {
        onSignup()
      } else if (onboarding.unavailable) {
        onSignup()
      }
    },
    [validate, setOnboarding, onboarding, fullName, phoneNumber, email, utmParams, setErrors, setAlert, onSignup]
  )

  const getError = useCallback(
    (field: string) => {
      if (!errors[field]) return

      return errors[field].join(', ')
    },
    [errors]
  )

  const getKind = useCallback(
    (field: string) => {
      if (errors[field]) return 'alert'
    },
    [errors]
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <form className={styles.inputs} onSubmit={handleSubmit}>
      <fieldset disabled={isLoading}>
        <div className={styles.inputs}>
          <CoreInput.Text
            id="full_name"
            value={fullName}
            label="Name"
            onChangeText={setFullName}
            hint={getError('full_name')}
            kind={getKind('full_name')}
          />
          <CoreInput.Text
            id="email"
            label="Email"
            value={email}
            type="email"
            onChangeText={setEmail}
            hint={getError('email')}
            kind={getKind('email')}
          />
          <CoreInput.Text
            id="phone_number"
            value={phoneNumber}
            onChangeText={setPhoneNumber}
            label="Phone"
            autoComplete="tel"
            type="tel"
            hint={getError('phone_number') || 'We will never share this with 3rd parties'}
            kind={getKind('phone_number')}
          />
        </div>
        <CoreButton text="Continue" type="submit" className={styles.signUpButton} ref={submitRef} />
        <TermsConsentMessage className={styles.disclaimer} />
      </fieldset>
    </form>
  )
}

export default EmailSignup
