import React, { ChangeEvent, FC, PropsWithChildren, useCallback } from 'react'
import cx from 'classnames'
import Upload from 'svgs/upload'
import styles from './CarouselPhoto.module.scss'
import Spinner from 'components/Spinner'

interface CarouselPhotoProps {
  photoId?: number
  onAddPhoto: (e: ChangeEvent<HTMLInputElement>) => void
  onRemovePhoto?: (id: number) => void
  isLoading: boolean
}

const CarouselPhoto: FC<PropsWithChildren<CarouselPhotoProps>> = ({
  children,
  isLoading,
  onAddPhoto,
  onRemovePhoto,
  photoId,
}) => {
  const handleRemove = useCallback(() => {
    if (onRemovePhoto && photoId) onRemovePhoto(photoId)
  }, [onRemovePhoto, photoId])

  return (
    <div className="tw-relative">
      {children}
      <div className="tw-flex tw-gap-2 tw-absolute tw-top-0 tw-right-0 tw-m-4 tw-text-white tw-text-sm">
        {isLoading && (
          <div className="tw-flex tw-flex-col tw-justify-center">
            <Spinner size="sm" />
          </div>
        )}
        {onRemovePhoto && photoId && (
          <button
            type="button"
            onClick={handleRemove}
            disabled={isLoading}
            className={cx('tw-rounded tw-p-2', styles.removePhotos)}
          >
            {'Remove'}
          </button>
        )}
        <div className={cx('tw-flex tw-rounded tw-p-2', styles.addPhotos)}>
          <Upload />
          <div className="tw-ml-2">{'Add Photos'}</div>
          <input
            accept="image/*"
            className="tw-absolute tw-inset-0 tw-opacity-0 tw-z-10"
            disabled={isLoading}
            onChange={onAddPhoto}
            type="file"
          />
        </div>
      </div>
    </div>
  )
}

export default CarouselPhoto
