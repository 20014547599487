import React, { FC } from 'react'

const WIDTH = 1280
const HALF_WIDTH = WIDTH / 2
const DIAMETER = Math.sqrt(2 * Math.pow(WIDTH, 2))
const RADIUS = DIAMETER / 2
const NUM_LINES = 51

const PATH_STARTS = new Array(NUM_LINES).fill(0).map((r, i) => (i * DIAMETER) / NUM_LINES)

const PATH = PATH_STARTS.map((s) => `M${s} 0 L${s} ${DIAMETER}`).join(' ')

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="1282px" height="1282px" viewBox="0 0 1282 1282">
    <g
      fill="none"
      fillRule="evenodd"
      strokeWidth="1.5"
      stroke="currentColor"
      transform={`translate(${HALF_WIDTH - RADIUS}, ${HALF_WIDTH - RADIUS}) rotate(-45, ${RADIUS}, ${RADIUS})`}
    >
      <path d={PATH} />
    </g>
  </svg>
)

export default Svg
