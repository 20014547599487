import React, { FC, useCallback } from 'react'
import classNames from 'classnames'

import { formatCurrency, formatNumber } from 'utils/formatting'
import { trackSync } from 'utils/analytics'

import { useProperties } from 'recoil/properties'
import { PropertyPlan } from 'recoil/propertyPlans'
import { useProjects } from 'recoil/projects'

import Info from 'svgs/info'

import Card from 'components/Card'
import { PropertyAvatar } from 'components/PropertyAvatar'
import IntercomCta from 'components/IntercomCta'

import styles from './styles.module.scss'

export interface Info {
  homeValue: number
  squareFootage: number
}

interface Props {
  propertyPlan: PropertyPlan | null
  isSwitching: boolean
}

const Overview: FC<Props> = ({ propertyPlan, isSwitching }) => {
  // Recoil state
  const { selectedProperty } = useProperties()
  // const { selectedPropertyPlan, isSwitching } = usePropertyPlans()
  const { projects } = useProjects()

  const handleLearnMoreClick = useCallback(async () => {
    // We're using an anchor, not a Link, so we need to track synchronously
    trackSync('click cta', { cta: 'edit', module: 'overview', location: 'learn-more' })
  }, [])

  if (!selectedProperty) return null
  const propertyInfo = {
    homeValue: selectedProperty.estimated_value,
    squareFootage: selectedProperty.square_footage,
  }
  if (!propertyPlan) return null

  // A property plan with no projects should be ignored here.
  const planInfo: Info | null =
    !isSwitching && projects.length
      ? {
          homeValue:
            typeof propertyPlan.estimated_value !== 'undefined'
              ? propertyPlan.estimated_value
              : selectedProperty.estimated_value,
          squareFootage: propertyPlan.square_footage,
        }
      : null

  const hasPlan = !!planInfo

  const currentInfo = hasPlan ? planInfo : propertyInfo

  const totalRecoup =
    hasPlan && propertyPlan.cost_estimate > 0
      ? Math.max(0, ((planInfo?.homeValue || 0) - propertyInfo.homeValue) / propertyPlan.cost_estimate)
      : 0

  return (
    <div className={classNames(styles.overview, hasPlan ? styles.withPlan : null)}>
      <header className={styles.mobile}>
        <h2 className={styles.address}>{selectedProperty.delivery_line_1}</h2>
        <div className={styles.cityStateZip}>
          {`${selectedProperty.city}, ${selectedProperty.state}, ${selectedProperty.zip5}`}
        </div>
      </header>
      <Card className={classNames(styles.card, { [styles.noFooter]: !selectedProperty.marketplace })}>
        <header className={styles.desktop}>
          <PropertyAvatar property={selectedProperty} width={160} height={160} className={styles.avatar} mapbox />
          <h2 className={styles.address}>{selectedProperty.delivery_line_1}</h2>
          <div className={styles.cityStateZip}>
            {`${selectedProperty.city}, ${selectedProperty.state}, ${selectedProperty.zip5}`}
          </div>
          <a href="/how-it-works/homeowners" onClick={handleLearnMoreClick} className={styles.info} target="_blank">
            <Info />
          </a>
        </header>
        <a
          href="/how-it-works/homeowners"
          onClick={handleLearnMoreClick}
          className={classNames(styles.info, styles.mobile)}
        >
          <Info />
        </a>
        <section className={styles.stats}>
          <div className={styles.line}>
            <div className={styles.labels}>
              <span className={styles.label}>{`${hasPlan ? 'New ' : ''}Home Value`}</span>
              {hasPlan && <span className={styles.old}>{formatCurrency(propertyInfo.homeValue)}</span>}
            </div>
            <div className={styles.values}>
              {hasPlan ? (
                <span className={styles.new}>{formatCurrency(currentInfo?.homeValue || 0)}</span>
              ) : (
                <span className={styles.current}>{formatCurrency(propertyInfo.homeValue)}</span>
              )}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.labels}>
              <span className={classNames(styles.label, styles.mobile)}>{`${hasPlan ? 'New ' : ''}Livable Sq Ft`}</span>
              <span className={classNames(styles.label, styles.desktop)}>{`${
                hasPlan ? 'New ' : ''
              }Livable Square Feet`}</span>
              {hasPlan && <span className={styles.old}>{`${formatNumber(propertyInfo.squareFootage)} sqft`}</span>}
            </div>
            <div className={styles.values}>
              {hasPlan ? (
                <span className={styles.new}>{`${formatNumber(currentInfo?.squareFootage || 0)} sqft`}</span>
              ) : (
                <span className={styles.current}>{`${formatNumber(propertyInfo.squareFootage)} sqft`}</span>
              )}
            </div>
          </div>
        </section>
        {hasPlan ? (
          <section className={styles.totals}>
            <div className={styles.line}>
              <div className={styles.labels}>
                <span className={styles.label}>{'Total Cost'}</span>
              </div>
              <div className={styles.values}>
                <span className={styles.new}>{`${formatCurrency(propertyPlan.cost_estimate_low)} - ${formatCurrency(
                  propertyPlan.cost_estimate_high
                )}`}</span>
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.labels}>
                <span className={styles.label}>{'% Recoup'}</span>
              </div>
              <div className={styles.values}>
                <span className={styles.new}>{`${Math.round(totalRecoup * 100)}%`}</span>
              </div>
            </div>
          </section>
        ) : null}
        {selectedProperty.marketplace && (
          <footer>
            {'Questions about projects?'}
            <IntercomCta className={styles.advisorCta} location="in-page">
              {'Chat with a Realm Advisor'}
            </IntercomCta>
          </footer>
        )}
      </Card>
    </div>
  )
}

export default Overview
