import React, { FC } from 'react'

interface Props {
  className?: string
}

const SparklesThree: FC<Props> = (props) => (
  <svg width="20px" height="20px" viewBox="0 0 20 22" {...props}>
    <filter id="a" colorInterpolationFilters="auto">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
    </filter>
    <path
      fill="currentColor"
      d="m14.274 0-.122.122-.002.908c0 2.546-.257 2.804-2.811 2.804h-.911l-.122.122v.88l.122.122.91.003c2.556 0 2.815.255 2.815 2.8v.907l.123.122h.883l.122-.122.003-.908c0-2.546.256-2.804 2.81-2.804h.91l.123-.122v-.88l-.123-.121-.91-.002c-2.556 0-2.815-.256-2.815-2.801v-.908l-.122-.122zm2.027 15.299-.093.092-.002.692c0 1.942-.195 2.138-2.143 2.138h-.695l-.093.093v.67l.093.093.694.002c1.949 0 2.146.195 2.146 2.136v.693l.093.092h.673l.093-.092.002-.692c0-1.942.195-2.139 2.143-2.139h.695l.093-.092v-.671l-.093-.093-.694-.001c-1.949 0-2.146-.195-2.146-2.136v-.693l-.093-.092zm-10.153-9.469-.183.182-.003 1.366c0 3.833-.562 4.397-4.406 4.397h-1.373l-.183.183v.975l.183.182 1.37.004c3.847 0 4.412.56 4.412 4.39v1.368l.183.183h.979l.183-.183.003-1.366c0-3.832.562-4.396 4.406-4.396h1.373l.183-.182v-.975l-.183-.182-1.37-.004c-3.847 0-4.412-.56-4.412-4.39v-1.369l-.183-.182h-.979z"
      filter="url(#a)"
    />
  </svg>
)

export default SparklesThree
