import React, { FC } from 'react'
import PageTitle from 'components/PageTitle'
import FunkyCard from 'components/FunkyCard'
import PropertyDetailsForm from 'pages/EditProperty/PropertyDetails/PropertyDetailsForm'
import RenovationsAndAdditionsForm from 'pages/EditProperty/Renovations/RenovationsAndAdditionsForm'
import CommentsForm from 'pages/EditProperty/Comments/CommentsForm'
import DocumentsForm from 'pages/EditProperty/Documents/DocumentsForm'
import { useProperties } from 'recoil/properties'
import Default from 'layouts/Default'
import Secure from 'layouts/Secure'
import HasProperty from 'layouts/HasProperty'
import PropertyCompleteness from 'pages/EditProperty/PropertyCompleteness'
import Waves from 'svgs/edit-property/patterns-waves'
import Circles from 'svgs/edit-property/patterns-circles'
import Lines from 'svgs/edit-property/patterns-lines'
import ArrowLink from 'components/ArrowLink'

import styles from './styles.module.scss'
import Checklist from 'pages/EditProperty/Checklist'
import PropertyFeaturesForm from 'pages/EditProperty/PropertyDetails/PropertyFeaturesForm'
import PageHeader from 'components/PageHeader'

const Inner: FC = () => {
  const { selectedProperty } = useProperties()

  if (!selectedProperty) return null

  return (
    <>
      <div className={styles.sidebar}>
        <PropertyCompleteness />
      </div>
      <div className={styles.content}>
        <Checklist />
        <FunkyCard
          className={styles.card}
          title="Property details"
          description="Confirm the basic details about your property"
          background={<Waves className={styles.waves} />}
        >
          <PropertyDetailsForm />
          <PropertyFeaturesForm />
        </FunkyCard>
        <FunkyCard
          className={styles.card}
          title={`Feature & Project details`}
          description="Tell us about any projects that have been done"
          background={<Lines className={styles.lines} />}
        >
          <RenovationsAndAdditionsForm />
        </FunkyCard>
        <FunkyCard
          className={styles.card}
          title="Documents"
          description="Sharing property related documents with help increase your insights"
          background={<Circles className={styles.circles} />}
        >
          <DocumentsForm property={selectedProperty} />
        </FunkyCard>
        <FunkyCard
          className={styles.card}
          title="Comments"
          description="Anything else we should know?"
          background={<Circles className={styles.circles} />}
        >
          <CommentsForm />
        </FunkyCard>
        <div className={styles.dashboardNudge}>
          <div className={styles.head}>{'Done editing?'}</div>
          <div className={styles.sub}>
            {'See how these edits to your property impact your home value and recommendations on your Dashboard.'}
          </div>
          <ArrowLink className={styles.cta} to={`dashboard`}>
            {'View your dashboard'}
          </ArrowLink>
        </div>
      </div>
    </>
  )
}

const EditProperty: FC = () => {
  return (
    <Secure>
      <HasProperty>
        <Default>
          <PageTitle title="Edit my property" />
          <div className={styles.wrapper}>
            <PageHeader
              className={styles.header}
              header={'Edit my property'}
              subheader={
                'Fine tune your property value + Recommendations by helping us understand the current state of your home.'
              }
            />
            <Inner />
          </div>
        </Default>
      </HasProperty>
    </Secure>
  )
}

export default EditProperty
