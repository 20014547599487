import cx from 'classnames'
import React, { FC } from 'react'

interface PencilProps {
  className?: string
}

const Pencil: FC<PencilProps> = ({ className }) => (
  <svg viewBox="0 0 20 20" fill="currentColor" className={cx('pencil', className)}>
    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
  </svg>
)

export default Pencil
