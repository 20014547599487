import React, { FC, Fragment, useCallback, useMemo } from 'react'
import { Contractor, useProperties } from 'recoil/properties'

import styles from './styles.module.scss'
import sharedStyles from '../styles.module.scss'
import cx from 'classnames'
import { track } from 'utils/analytics'
import ArrowLink from 'components/ArrowLink'
import Card from 'components/Card'

const getSpecialities = (contractor: Contractor): string => {
  if (contractor.project_1 && contractor.project_2) {
    return `Specializes in ${contractor.project_1} and ${contractor.project_2} projects`
  } else if (contractor.project_1) {
    return `Specializes in ${contractor.project_1} projects`
  }
  return ''
}

const Contractors: FC = () => {
  const { selectedProperty } = useProperties()

  const handleContactClick = useCallback(() => {
    track('click cta', { cta: 'contact', module: 'contractors' })
  }, [])

  const hasData = useMemo(() => {
    return selectedProperty?.contractors && selectedProperty.contractors.length > 0
  }, [selectedProperty?.contractors])

  const contractors = useMemo(() => {
    if (!selectedProperty) return []
    if (!hasData) return []

    return selectedProperty.contractors.slice(0, 3)
  }, [hasData, selectedProperty])

  if (!selectedProperty) return null

  if (!hasData) return null

  return (
    <Card className={sharedStyles.card}>
      <div className={sharedStyles.cardTitle}>
        <h2>{"Your neighbors' top contractors"}</h2>
        <h3>
          {`Top Contractors in ${selectedProperty.zip5}`}
          {selectedProperty.marketplace && ' are available on request '}
        </h3>
      </div>
      {selectedProperty.marketplace ? (
        <>
          <ArrowLink className={styles.contactCta} onClick={handleContactClick} to="/contact">
            {'Schedule a meeting to learn about contractors in your area'}
          </ArrowLink>
        </>
      ) : (
        <div className={styles.contractors}>
          <div className={cx(styles.list, !hasData && styles.noData)}>
            {contractors &&
              contractors.map((contractor, i) => {
                const name = contractor.contractor || ''
                const projects = `${contractor.count} recent projects nearby`
                const specialities = getSpecialities(contractor as Contractor) || ''

                return (
                  <Fragment key={i}>
                    <div className={styles.name}>{name}</div>
                    <div>{projects}</div>
                    <div>{specialities}</div>
                  </Fragment>
                )
              })}
          </div>
        </div>
      )}
    </Card>
  )
}

export default Contractors
