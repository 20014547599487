import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

import { Model } from 'recoil/model'
import { Property, useProperties } from 'recoil/properties'
import { Renovation, useRenovations } from 'recoil/renovations'

export type Level = 'Empty' | 'Beginner' | 'Intermediate' | 'Advanced' | 'All-Star'

export interface Score {
  level: Level
  val: number
}

const EMPTY_SCORE: Score = {
  level: 'Empty',
  val: 0,
}

const BEGINNER_SCORE: Score = {
  level: 'Beginner',
  val: 1,
}

const INTERMEDIATE_SCORE: Score = {
  level: 'Intermediate',
  val: 2,
}

const ADVANCED_SCORE: Score = {
  level: 'Advanced',
  val: 3,
}

const ALL_STAR_SCORE: Score = {
  level: 'All-Star',
  val: 4,
}

export interface PropertyCompleteness extends Model {
  steps: { [key: string]: number }
  score: Score
  maxScore: number
}

export const propertyCompletenessState = atom<PropertyCompleteness>({
  key: 'PropertyCompleteness',
  default: {
    steps: {},
    score: EMPTY_SCORE,
    maxScore: 4,
  },
})

const propertyDetailsScore = (property: Property) => {
  return property.edit_state?.dirty ? 1 : 0
}

const renovationsScore = (renovations: Renovation[]) => {
  return renovations.filter((x) => !!x.year).length > 0 ? 1 : 0
}

const documentsScore = (property: Property) => {
  let totalDocuments = 0

  if (property.docs.hoa_ccrs) {
    totalDocuments += 1
  }
  if (property.docs.home_appraisal) {
    totalDocuments += 1
  }
  if (property.docs.home_inspection_report) {
    totalDocuments += 1
  }

  totalDocuments += property.attachments.length

  return totalDocuments > 0 ? 1 : 0
}

const translateScore = (rawScore: number): Score => {
  if (rawScore < 1) return EMPTY_SCORE
  if (rawScore < 2) return BEGINNER_SCORE
  if (rawScore < 3) return INTERMEDIATE_SCORE
  if (rawScore < 4) return ADVANCED_SCORE
  return ALL_STAR_SCORE
}

export const usePropertyCompleteness = (): {
  completeness: PropertyCompleteness
} => {
  const { selectedProperty } = useProperties()
  const { renovations } = useRenovations()
  const [completeness, setCompleteness] = useRecoilState(propertyCompletenessState)

  useEffect(() => {
    if (!selectedProperty) return

    const steps: { [key: string]: number } = {}

    steps['propertyDetails'] = propertyDetailsScore(selectedProperty)
    steps['renovations'] = renovationsScore(renovations)
    steps['documents'] = documentsScore(selectedProperty)

    const rawScore = Object.values(steps).reduce<number>((previous, current) => {
      return previous + current
    }, 0)

    const score = translateScore(rawScore)

    setCompleteness({
      steps,
      score,
      maxScore: 3,
    })
  }, [selectedProperty, renovations, setCompleteness])

  return { completeness }
}
