import { FOState } from '../'
import { DEFAULT_FLOW, DEFAULT_INTERNAL_FLOW } from '../states'
import processFlows, { FlowCollection as FlowCollectionObjs } from '../processFlows'

import DefaultInternalFlow from './defaultInternal'
import CamV3 from './camv3'
import Payment from './payment'

export interface FlowCollection {
  [flowName: string]: Flow
}

export interface Flow {
  [stateName: string]: FlowState | FlowRedirect | string
}

export interface FlowRedirect {
  to: Array<FlowRef>
}

export interface FlowState {
  url: string
  progressStep?: number
  progressMax?: number
  next: Array<FlowRef>
}
export interface FlowRef {
  state: string
  if?: (state: FOState) => boolean
}

const flows: FlowCollectionObjs = processFlows({
  [DEFAULT_FLOW]: CamV3,
  [DEFAULT_INTERNAL_FLOW]: DefaultInternalFlow,
  // Other flows go here.
  ['camv1']: CamV3,
  ['camv2']: CamV3,
  ['camv3']: CamV3,
  ['payment']: Payment,
})

export default flows
