import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-442 -887) translate(200 791) translate(0 76)">
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="translate(242 20)" />
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M19 13L13 13 13 19 11 19 11 13 5 13 5 11 11 11 11 5 13 5 13 11 19 11z"
                transform="translate(242 20)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
