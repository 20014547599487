import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-1022 -257) translate(200 140) translate(672 48) translate(16 37)">
              <g>
                <path d="M0 0H24V24H0z" transform="translate(134 32)" />
                <path
                  fill="currentColor"
                  fillRule="nonzero"
                  d="M5 9L6.41 10.41 11 5.83 11 22 13 22 13 5.83 17.59 10.42 19 9 12 2z"
                  transform="translate(134 32)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
