import React, { FC, useCallback, useReducer } from 'react'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button2'
import Input from 'components/Input'
import { HandledCheckbox as Checkbox } from 'components/Checkbox'
import { Link } from 'react-router-dom'
import Auth from 'layouts/Auth'
import SsoLinks from 'components/Auth/SsoLinks'
import EnsureLoggedOut from 'layouts/EnsureLoggedOut'
import { track } from 'utils/analytics'

interface SigninState {
  form: {
    email: string
    password: string
    remember_me: boolean
  }
  isLoading: boolean
}

interface SigninAction {
  type: 'setFields' | 'setIsLoading'
  payload: any
}

const reducer = (state: SigninState, action: SigninAction): SigninState => {
  switch (action.type) {
    case 'setFields':
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.key as keyof SigninState['form']]: action.payload.val,
        },
      }
    case 'setIsLoading':
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      throw new Error()
  }
}

const initialState: SigninState = {
  form: {
    email: '',
    password: '',
    remember_me: false,
  },
  isLoading: false,
}

const Signin: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleSubmit = useCallback(() => {
    track('signin attempt', { via: 'form' })
    // we let the event's default behaviour proceed, which will submit the actual form
  }, [])

  const handleChange = useCallback(
    (key: keyof SigninState['form']) => (val: any) => {
      dispatch({ type: 'setFields', payload: { key, val } })
    },
    [dispatch]
  )

  const csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
  const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')

  return (
    <EnsureLoggedOut>
      <PageTitle title="Sign In" />
      <Auth>
        <Auth.Body>
          <Auth.Body.Header>{'Sign in'}</Auth.Body.Header>
          <Auth.Body.Subheader>
            {"Don't have an account yet? "}
            <Link className="hover:tw-text-moss" to="/users/sign_up">
              {'Sign up'}
            </Link>
          </Auth.Body.Subheader>

          <hr className="tw-my-6" />

          <SsoLinks actionLabel="Sign in" />

          <form action="/users/sign_in" className="tw-space-y-6" method="post" onSubmit={handleSubmit}>
            <fieldset disabled={state.isLoading}>
              <input type="hidden" value={csrfToken || ''} name={csrfParam || ''} />
              <div className="tw-space-y-3">
                <Input
                  autoComplete="email"
                  autoFocus
                  name="user[email]"
                  onChange={handleChange('email')}
                  placeholder="E-mail"
                  required
                  type="email"
                  value={state.form.email}
                />

                <Input
                  name="user[password]"
                  onChange={handleChange('password')}
                  placeholder="Password"
                  required
                  type="password"
                  value={state.form.password}
                />
              </div>
            </fieldset>

            <div className="tw-space-y-3 sm:tw-space-y-0 sm:tw-flex">
              <input type="hidden" value="0" name="user[remember_me]" />
              <span className="tw-grow">
                <Checkbox id="cbxRememberMe" onChange={handleChange('remember_me')} checked={state.form.remember_me} />
                <label htmlFor="cbxRememberMe">{'Remember me'}</label>
              </span>
              <div>
                <Link className="hover:tw-text-moss" to="/users/password/new">
                  {'Forgot password?'}
                </Link>
              </div>
            </div>

            <div className="tw-flex tw-justify-center">
              <Button type="submit" loading={state.isLoading}>
                {'Sign in'}
              </Button>
            </div>
          </form>
        </Auth.Body>
      </Auth>
    </EnsureLoggedOut>
  )
}

export default Signin
