import { postJSON } from 'utils/fetch'

interface UnserviceableAddress {
  email: string
  address?: string
  attom_id?: string
}

export const recordUnserviceable = async (address: UnserviceableAddress): Promise<void> => {
  const body = { unserviceable_address: address }

  await postJSON('/users/unserviceable', body as Record<string, unknown>)
}
