import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import Alert from 'svgs/alert'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

const ErrorEntry: FC<PropsWithChildren<Props>> = ({ className, children }) => (
  <div className={classNames(styles.errorEntry, className)}>
    <Alert />
    <div>{children}</div>
  </div>
)

export default ErrorEntry
