import React, { FC, useCallback, useState } from 'react'
import classNames from 'classnames'

import { track } from 'utils/analytics'

import { useProjectTemplates } from 'recoil/projectTemplates'

import SearchIcon from 'svgs/search'

import Input from 'components/Input'

import styles from './styles.module.scss'

export const ALL_FILTER = '_all_'

interface FilterType {
  name: string
  value?: string
  count: number
  isSelected: boolean
}

interface FilterProps {
  filter: FilterType
  onChange: (name) => void
}

const Filter: FC<FilterProps> = ({ filter, onChange }) => {
  const { name, value, isSelected, count } = filter
  const onClick = useCallback(() => {
    onChange(value)
  }, [onChange, value])

  return (
    <li className={classNames(styles.filter, isSelected ? styles.selected : null)}>
      <button type="button" onClick={onClick}>
        <span className={styles.filterName}>{name}</span>
        <span className={styles.filterCount}>{count}</span>
      </button>
    </li>
  )
}

interface SearchProps {
  search: string
  onChange: (search) => void
  onClear: () => void
}
const Search: FC<SearchProps> = ({ search, onChange, onClear }) => {
  return (
    <div className={styles.searchBox}>
      <label htmlFor="tbxSearch">{'Search'}</label>
      <Input id="tbxSearch" className={styles.input} value={search} onChange={onChange} autoFocus />
      <div className={styles.clear}>
        <button type="button" onClick={onClear}>
          {'Clear'}
        </button>
      </div>
    </div>
  )
}

export interface Filter {
  tag?: string
  search: string
}

interface Props {
  className?: string
  tags: Array<string>
  filter: Filter
  onChange: (filter: Filter) => void
}

const Filters: FC<Props> = ({ className, tags, filter, onChange }) => {
  const { latestProjectTemplates: projectTemplates } = useProjectTemplates()
  const [showSearch, setShowSearch] = useState(false)

  const handleFilterChange = useCallback(
    (tag: string) => {
      track('property-plan filter templates', { filter: tag })
      onChange({ tag: tag, search: '' })
    },
    [onChange]
  )

  const handleSearchClick = useCallback(() => {
    track('property-plan search opened')
    setShowSearch(true)
    onChange({ search: '' })
  }, [onChange])

  const handleSearchChange = useCallback(
    (search: string) => {
      onChange({ search: search })
    },
    [onChange]
  )

  const handleCloseClick = useCallback(() => {
    track('property-plan search closed')
    setShowSearch(false)
    onChange({ tag: ALL_FILTER, search: '' })
  }, [onChange])

  // Annotate the filters.
  const annotatedFilters: Array<FilterType> = tags
    .map((f) => ({
      name: f,
      value: f,
      isSelected: filter.tag == f,
      count: projectTemplates.reduce((prev, template) => (prev += template.tags.includes(f) ? 1 : 0), 0),
    }))
    // And sort them
    .sort((a, b) => b.count - a.count || a.name.localeCompare(b.name))

  if (showSearch) {
    return <Search onChange={handleSearchChange} search={filter.search} onClear={handleCloseClick} />
  }

  return (
    <ul className={classNames(styles.listFilters, className)}>
      <li className={classNames(styles.filter, styles.search)}>
        <button type="button" onClick={handleSearchClick}>
          <span className={styles.filterName}>{'Search'}</span>
          <SearchIcon className={styles.searchIcon} />
        </button>
      </li>
      <Filter
        filter={{
          name: 'View All',
          value: ALL_FILTER,
          count: projectTemplates.length,
          isSelected: filter.tag == ALL_FILTER,
        }}
        onChange={handleFilterChange}
      />
      {annotatedFilters.map((f) => (
        <Filter key={f.name} filter={f} onChange={handleFilterChange} />
      ))}
    </ul>
  )
}

export default Filters
