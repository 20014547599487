import CoreText from 'components/Core/CoreText'
import CamV3Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import styles from './styles.module.scss'
import CoreInput from 'components/Core/CoreInput'
import CoreButton from 'components/Core/CoreButton'
import { useFlow } from 'recoil/onboardingFlows'
import { useOnboardingProperty } from 'recoil/onboarding'
import Check from 'svgs/property-plan/check'
import Spinner from 'components/Spinner'
import { recordUnserviceable } from 'apis/unserviceable'
import CoreList from 'components/Core/CoreList'

import AttachMoney from '@mui/icons-material/AttachMoney'
import Handyman from '@mui/icons-material/Handyman'
import Home from '@mui/icons-material/Home'
import Edit from '@mui/icons-material/Edit'
import IconWrapper from 'pages/Onboarding/screens/CamV3Unserviceable/IconWrapper'
import ItemContent from 'pages/Onboarding/screens/CamV3Unserviceable/ItemContent'
import { prepareBoth } from 'utils/analyticsV2'

const [_, trackImpression] = prepareBoth({ family: 'onboarding', screen: 'unserviceable' })

const CamV3Unserviceable: FC = () => {
  const [email, setEmail] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { next } = useFlow()
  const { onboarding, setOnboarding } = useOnboardingProperty()

  useEffect(() => {
    trackImpression({
      code: 'unserviceable',
      flexfield_1: {
        country: onboarding.country,
        marketplace: onboarding.marketplace,
        unserviceable: onboarding.unavailable,
        qualifiedProjects: onboarding.qualifiedProjects,
        budget: onboarding.budget,
      },
    })
  }, [
    onboarding.country,
    onboarding.marketplace,
    onboarding.unavailable,
    onboarding.qualifiedProjects,
    onboarding.budget,
  ])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (!email) return

      setLoading(true)
      await recordUnserviceable({
        email,
        address: onboarding.enteredAddress,
        attom_id: onboarding.attomId,
      })
      setLoading(true)
      setSubmitted(true)
    },
    [email, onboarding.enteredAddress, onboarding.attomId]
  )

  const handleChangeBudget = useCallback(() => {
    setOnboarding((onboarding) => {
      return { ...onboarding, reset: 'budget' }
    })
    next()
  }, [setOnboarding, next])

  const handleChangeProjects = useCallback(() => {
    setOnboarding((onboarding) => {
      return { ...onboarding, reset: 'projects' }
    })
    next()
  }, [setOnboarding, next])

  const handleChangeAddress = useCallback(() => {
    setOnboarding((onboarding) => {
      return { ...onboarding, reset: 'address' }
    })
    next()
  }, [setOnboarding, next])

  const projectsLabel = useMemo(() => {
    if (!onboarding.selectedProjects) return ''

    return `${onboarding.selectedProjects?.length} project${onboarding.selectedProjects.length > 1 ? 's' : ''} added`
  }, [onboarding.selectedProjects])

  return (
    <CamV3Wrapper progressStyle="error" backStyle="active" forwardStyle="disabled">
      {onboarding.country && onboarding.country != 'US' ? (
        <>
          <CoreText.Headline size="xs" weight="heavy">
            {'Realm is not available in your location'}
          </CoreText.Headline>
          <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack" className={styles.subhead}>
            {'Realm is available for single family homes inside the US.'}
          </CoreText.Paragraph>
        </>
      ) : (
        <>
          <CoreText.Headline size="xs" weight="heavy">
            {"Looks like Realm isn't available yet for your property or your renovation goals"}
          </CoreText.Headline>
          <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack" className={styles.subhead}>
            {"Enter your email and we'll let you know when it's covered"}
          </CoreText.Paragraph>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={loading || submitted}>
              <CoreInput.Text
                type="email"
                required
                value={email}
                onChangeText={setEmail}
                label="Email"
                className={styles.emailInput}
              />
              {submitted ? (
                <CoreButton
                  kind="secondary"
                  icon={<Check className={styles.checkIcon} />}
                  text="We'll let you know"
                  disabled
                  iconAlignment="center"
                  className={styles.submitButton}
                />
              ) : (
                <CoreButton
                  text="Submit"
                  type="submit"
                  icon={loading && <Spinner size="sm" color="white" />}
                  className={styles.submitButton}
                />
              )}
            </fieldset>
          </form>
          <div className={styles.divider}>
            <div className={styles.line} />
          </div>
          <CoreList className="tw-space-y-5">
            {onboarding.budget == 'below_45k' && (
              <CoreList.Item
                leftIcon={
                  <IconWrapper>
                    <AttachMoney />
                  </IconWrapper>
                }
                className="!tw-bg-transparent !tw-px-0 !tw-py-0"
                center={<ItemContent title="Your budget is too low" description="Budget is below $45k" />}
                onClick={handleChangeBudget}
                rightIcon={<Edit className="tw-text-neutrals-500" sx={{ fontSize: '1rem' }} />}
              />
            )}
            {onboarding.qualifiedProjects == false && (
              <CoreList.Item
                leftIcon={
                  <IconWrapper>
                    <Handyman />
                  </IconWrapper>
                }
                className="!tw-bg-transparent !tw-px-0 !tw-py-0"
                center={
                  <ItemContent title="Your projects are not covered by Realm yet" description={projectsLabel} reverse />
                }
                onClick={handleChangeProjects}
                rightIcon={<Edit className="tw-text-neutrals-500" sx={{ fontSize: '1rem' }} />}
              />
            )}
            {onboarding.unavailable && (
              <CoreList.Item
                leftIcon={
                  <IconWrapper>
                    <Home />
                  </IconWrapper>
                }
                className="!tw-bg-transparent !tw-px-0 !tw-py-0"
                center={
                  <ItemContent
                    description={onboarding.enteredAddress?.split(',')[0] || ''}
                    title="Your address is not covered by Realm"
                    reverse
                  />
                }
                onClick={handleChangeAddress}
                rightIcon={<Edit className="tw-text-neutrals-500" sx={{ fontSize: '1rem' }} />}
              />
            )}
          </CoreList>
        </>
      )}
    </CamV3Wrapper>
  )
}

export default CamV3Unserviceable
