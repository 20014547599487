import React, { FC } from 'react'

interface InfoProps {
  className?: string
}

const Info: FC<InfoProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={className}>
    <path
      fill="currentColor"
      transform="translate(-5,-4)"
      d="M10,7 L8,7 L8,8 L7,8 C6.45,8 6,8.45 6,9 L6,9 L6,12 C6,12.55 6.45,13 7,13 L7,13 L10,13 L10,14 L6,14 L6,16 L8,16 L8,17 L10,17 L10,16 L11,16 C11.55,16 12,15.55 12,15 L12,15 L12,12 C12,11.45 11.55,11 11,11 L11,11 L8,11 L8,10 L12,10 L12,8 L10,8 L10,7 Z M18,9 L16,9 L16,11 L14,11 L14,13 L16,13 L16,15 L18,15 L18,13 L20,13 L20,11 L18,11 L18,9 Z"
    />
  </svg>
)

export default Info
