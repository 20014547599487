import { ProjectTemplate } from 'recoil/projectTemplates'

export const EXPIRES_GRACE_PERIOD = 5

function preSort(a: ProjectTemplate, b: ProjectTemplate): number | undefined {
  // We always want to separate out unavailable projects from available
  if (a.available.success != b.available.success) {
    if (!a.available.success) return 1
    if (!b.available.success) return -1
  }
  // Then separate out into unexpired and expired/expirable
  if (a.expires_in != b.expires_in) {
    const a_expirable = typeof a.expires_in !== 'undefined'
    const b_expirable = typeof b.expires_in !== 'undefined'
    const a_expired = typeof a.expires_in !== 'undefined' && a.expires_in < EXPIRES_GRACE_PERIOD
    const b_expired = typeof b.expires_in !== 'undefined' && b.expires_in < EXPIRES_GRACE_PERIOD
    const a_unexpired = a_expirable && !a_expired
    const b_unexpired = b_expirable && !b_expired

    if (a_unexpired && !b_unexpired) return 1
    if (b_unexpired && !a_unexpired) return -1
  }

  // Otherwise no sorting gained from presort.
  return
}

export function frequency_sort(a: ProjectTemplate, b: ProjectTemplate): number {
  // Presort first
  const ps = preSort(a, b)
  if (typeof ps !== 'undefined') return ps

  // Then by recommended position
  if (a.position != b.position) return (a.position || Number.MAX_SAFE_INTEGER) - (b.position || Number.MAX_SAFE_INTEGER)
  // Last by score
  return b.additional_score - a.additional_score
}

export function recoup_sort(a: ProjectTemplate, b: ProjectTemplate): number {
  // Presort first
  const ps = preSort(a, b)
  if (typeof ps !== 'undefined') return ps

  // then by % recoup.
  const recoup_a = a.cost_estimate ? Math.max(0, a.additional_home_value / a.cost_estimate) : 0
  const recoup_b = b.cost_estimate ? Math.max(0, b.additional_home_value / b.cost_estimate) : 0
  return recoup_b - recoup_a
}

export function recommended_sort(a: ProjectTemplate, b: ProjectTemplate): number {
  // Presort first
  const ps = preSort(a, b)
  if (typeof ps !== 'undefined') return ps

  // Order by home value increase, as long as those increases are
  // within 5% of each other. Use percentage difference for this value
  const percDiff =
    (Math.abs(a.additional_home_value - b.additional_home_value) * 2) /
    (a.additional_home_value + b.additional_home_value)

  if (percDiff > 0.05) return b.additional_home_value - a.additional_home_value

  // Then order by recommended position
  if (a.position != b.position) return (a.position || Number.MAX_SAFE_INTEGER) - (b.position || Number.MAX_SAFE_INTEGER)

  // Then by score because why not...
  return b.additional_score - a.additional_score
}

// Deprecated
export function priority_sort(a: ProjectTemplate, b: ProjectTemplate): number {
  // Presort first
  const ps = preSort(a, b)
  if (typeof ps !== 'undefined') return ps

  // expires_in is first
  // For sorting, we only care if they're within 5 years.
  const a_expires_in = cap_expiration(a.expires_in)
  const b_expires_in = cap_expiration(b.expires_in)
  if (a_expires_in != b_expires_in) {
    if (typeof b_expires_in === 'undefined') return -1
    if (typeof a_expires_in === 'undefined') return 1
    return a_expires_in - b_expires_in // Closest to expiring first.
  }
  // Then by score
  return b.additional_score - a.additional_score
}

export function cost_sort(a: ProjectTemplate, b: ProjectTemplate): number {
  // Presort first
  const ps = preSort(a, b)
  if (typeof ps !== 'undefined') return ps

  // Then by cost.
  return (b.cost_estimate || 0) - (a.cost_estimate || 0)
}

export function cap_expiration(expires_in: number | undefined): number | undefined {
  // If we have a number, but it is more than 5 years from expiring,
  // Treat it as not expiring where sort is concerned.
  if (typeof expires_in == 'number' && expires_in > EXPIRES_GRACE_PERIOD) return

  return expires_in
}
