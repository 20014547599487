import React, { FC } from 'react'

import CustomizeContainer from './CustomizeContainer'
import ProjectStats from './ProjectStats'

import styles from './FakeDetails.module.scss'

const FakeDetails: FC = () => (
  <CustomizeContainer className={styles.fakeDetails} fake>
    <section>
      <h3>{'X'}</h3>
      <p>{'X'}</p>
      <span className={styles.field}>{'X'}</span>
    </section>

    <section>
      <h3>{'X'}</h3>
      <p>{`X`}</p>
      <span className={styles.field}>{'X'}</span>
    </section>

    <section>
      <h3>{'X'}</h3>
      <p>{'X'}</p>
      <span className={styles.field}>{'X'}</span>
    </section>
    <ProjectStats fake />
  </CustomizeContainer>
)

export default FakeDetails
