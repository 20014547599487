import React, { FC, PropsWithChildren, ReactNode, useCallback } from 'react'
import cx from 'classnames'

import styles from './Select.module.scss'

export interface SelectProps {
  value?: string
  onChange: (value: string) => void
  className?: string
  disabled?: boolean
  errors?: string[]
  label?: string
  required?: boolean
  optional?: boolean
  showOptionalLabel?: boolean
  id?: string
  suffix?: ReactNode
}

/** @deprecated use CoreInput.Select or CoreInput.FancySelect */
export const Select: FC<PropsWithChildren<SelectProps>> = ({
  className,
  children,
  disabled,
  errors,
  onChange,
  value,
  label,
  id,
  required,
  optional,
  showOptionalLabel = true,
  suffix,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <div className={cx(className, styles.select)}>
      {label && (
        <label className="tw-mb-2 tw-font-medium tw-text-base-charcoal" htmlFor={id}>
          {label}
          {optional && showOptionalLabel && <span className="tw-ml-2 tw-text-gray-500">{'(Optional)'}</span>}
        </label>
      )}

      <div className={cx('tw-flex tw-items-center', { 'is-invalid': errors && errors.length })}>
        <select
          id={id}
          value={value}
          onBlur={handleChange}
          onChange={handleChange}
          className="form-control"
          disabled={disabled}
          required={required}
        >
          {children}
        </select>
        {suffix}
      </div>
    </div>
  )
}

export default Select
