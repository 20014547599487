import CoreList from 'components/Core/CoreList'
import CoreText from 'components/Core/CoreText'
import CamV3Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { BudgetOption, useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { prepareBoth } from 'utils/analyticsV2'

const BUDGET_OPTIONS: { key: BudgetOption; text: string; min?: number; max?: number }[] = [
  { key: 'below_45k', text: 'Below $45k', max: 45 },
  { key: '45k_to_100k', text: '$45k – $100k', min: 45, max: 100 },
  { key: '100k_to_150k', text: '$100k – $150k', min: 100, max: 150 },
  { key: '150k_to_200k', text: '$150k – $200k', min: 150, max: 200 },
  { key: 'above_200k', text: '$200k+', min: 200 },
]

const [trackClick, _] = prepareBoth({ screen: 'budget', family: 'onboarding' })

const CamV3Budget: FC = () => {
  const { setOnboarding } = useOnboardingProperty()
  const [budget, setBudget] = useState<BudgetOption | undefined>()
  const { next } = useFlow()

  const handlePick = useCallback((key: BudgetOption) => {
    return () => {
      trackClick({ code: 'select-budget', feature: key, content_text: BUDGET_OPTIONS[key] })

      setBudget(key)
    }
  }, [])

  useEffect(() => {
    if (!budget) return

    setOnboarding((onboarding) => {
      return { ...onboarding, budget, reset: undefined }
    })

    next()
  }, [budget, setOnboarding, next])

  return (
    <CamV3Wrapper forwardStyle="active" backStyle="active">
      <CoreText.Headline
        size="xs"
        color="primaryBlack"
        weight="heavy"
        className="tw-mb-6"
      >{`What's your budget?`}</CoreText.Headline>
      <CoreList className="tw-space-y-2">
        {BUDGET_OPTIONS.map(({ key, text }) => {
          return (
            <CoreList.Item
              className="tw-rounded-2xl tw-bg-white tw-p-6"
              key={key}
              text={text}
              onClick={handlePick(key)}
            />
          )
        })}
      </CoreList>
    </CamV3Wrapper>
  )
}

export default CamV3Budget
