import React, { FC, useEffect, useState } from 'react'

import { postJSON } from 'utils/fetch'
import { ReferralPayout } from 'recoil/payments'

import SetupAccountCard from 'pages/Payment/PaymentSuccess/SetupAccountCard'
import ReferAFriendCard from 'pages/Payment/PaymentSuccess/ReferAFriendCard'
import PaymentSuccessHeader from 'pages/Payment/PaymentSuccess/PaymentSuccessHeader'
import { LoginState, useAuth } from 'non-rendering/Authentication'
import Loading from 'components/Loading'

interface PaymentSuccessProps {
  idOrToken: string
  referralLink?: string
  referralPayouts: Array<ReferralPayout>
}

const PaymentSuccess: FC<PaymentSuccessProps> = ({ idOrToken, referralLink, referralPayouts }) => {
  const user = useAuth()
  const [signupToken, setSignupToken] = useState<string | undefined>()
  const [loadingSignupToken, setLoadingSignupToken] = useState(false)

  useEffect(() => {
    if (!idOrToken) return
    if (signupToken) return

    const _ = async () => {
      setLoadingSignupToken(true)
      const response = await postJSON(`/api/v1/payments/${idOrToken}/signup_token`, {})
      if (response.isError) {
        console.error(`Error fetching signup token ${response.jsonBody}`)
      } else {
        const token = response.jsonBody?.token
        if (token) setSignupToken(token)
      }
      setLoadingSignupToken(false)
    }
    _()
  }, [idOrToken, signupToken])

  return (
    <div className="tw-grow tw-flex tw-flex-col tw-justify-start tw-items-center">
      {loadingSignupToken ? (
        <Loading />
      ) : (
        <div className="tw-max-w-lg tw-space-y-6 tw-mx-4 tw-my-6">
          <PaymentSuccessHeader />
          {user.state != LoginState.LoggedIn && signupToken && (
            <SetupAccountCard signupToken={signupToken} idOrToken={idOrToken} />
          )}
          <ReferAFriendCard referralLink={referralLink} referralPayouts={referralPayouts} />
        </div>
      )}
    </div>
  )
}

export default PaymentSuccess
