import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'
import { useOnboardingProperty } from 'recoil/onboarding'
import { getJSON } from 'utils/fetch'

export interface MeetingAvailability {
  start_time: string
  scheduling_url: string
}

export interface AdvisorInfo {
  uri: string
  name: string
  slug: string
  email: string
  scheduling_url: string
  timezone: string
  avatar_url: string
  created_at: string
  updated_at: string
  current_organization: string
}

export type BookingDetails = {
  event: {
    start_time: string
    end_time: string
    uri: string
    event_memberships: { user: string }[]
  }
  invitee: {
    name: string
    email: string
    uri: string
    questions_and_answers: { question: string; position: number; answer: string }[]
  }
  advisor: AdvisorInfo
}

const preloadedTimesState = atom<{ [event_uri: string]: MeetingAvailability[] }>({
  key: 'preloadedTimesState',
  default: {},
})

export const findPhoneNumberFromCalendlyBooking = (bookingDetails?: BookingDetails): string | undefined => {
  if (!bookingDetails) return

  return bookingDetails.invitee.questions_and_answers.find((qa) => qa.question.toLowerCase().includes('phone'))?.answer
}

export const findAddressFromCalendlyBooking = (bookingDetails?: BookingDetails): string | undefined => {
  if (!bookingDetails) return

  return bookingDetails.invitee.questions_and_answers.find((qa) => qa.question.toLowerCase().includes('address'))
    ?.answer
}

// this function isn't tied to the global `calendlyEventUri` - we get both an `event_uri` and
// an `invitee_uri` back from the Calendly widget, we will pass those both along verbatim
export const fetchBookingDetails = async (event_uri: string, invitee_uri: string): Promise<BookingDetails> => {
  const bookingDetails = await getJSON<BookingDetails>(
    `/api/v1/advisor_booking/booking_info?${new URLSearchParams({ event_uri, invitee_uri }).toString()}`
  )

  const advisor_uri = bookingDetails.event.event_memberships[0].user
  const advisor = (
    await getJSON<{ resource: AdvisorInfo }>(
      `/api/v1/advisor_booking/advisor_info?${new URLSearchParams({ advisor_uri }).toString()}`
    )
  ).resource

  return { ...bookingDetails, advisor }
}

export const useAdvisorBooking = (
  calendlyEventUri?: string
): {
  preloadedTimes: MeetingAvailability[]
  preloadTimes: () => void
  fetchAvailableTimes: (from: Date, spanInDays?: number) => Promise<MeetingAvailability[]>
  fetchBookingDetails: (event_uri: string, invitee_uri: string) => Promise<BookingDetails>
} => {
  const cacheKey = calendlyEventUri || 'default'
  const [allPreloadedTimes, setAllPreloadedTimes] = useRecoilState(preloadedTimesState)

  const fetchAvailableTimes = useCallback(
    async (from: Date, spanInDays = 4) => {
      const start = moment(from).add(2, 'minutes')
      const end = moment(start).add(spanInDays, 'days')

      const params = {
        start_time: start.toISOString(),
        end_time: end.toISOString(),
      } as Record<string, string>
      if (calendlyEventUri) params['event_uri'] = calendlyEventUri
      const response = await getJSON<{ collection: MeetingAvailability[] }>(
        `/api/v1/advisor_booking/availability?${new URLSearchParams(params).toString()}`
      )

      return response.collection
    },
    [calendlyEventUri]
  )

  const preloadTimes = useCallback(async () => {
    const response = await fetchAvailableTimes(new Date(), 7)
    setAllPreloadedTimes((times) => {
      return { ...times, [cacheKey]: response }
    })
  }, [fetchAvailableTimes, setAllPreloadedTimes, cacheKey])

  const preloadedTimes = useMemo(() => {
    return allPreloadedTimes[cacheKey] || []
  }, [allPreloadedTimes, cacheKey])

  return { preloadedTimes, preloadTimes, fetchAvailableTimes, fetchBookingDetails }
}

export const useOnboardingCalendlyEvent = (): string | undefined => {
  const { onboarding } = useOnboardingProperty()

  return onboarding.unavailable
    ? process.env.ONBOARDING_V3_UNSERVICEABLE_CALENDLY_EVENT_URI
    : process.env.ONBOARDING_V3_CALENDLY_EVENT_URI
}
