import React, { FC, useCallback } from 'react'
import classNames from 'classnames'

import { Property } from 'recoil/properties'
import { formatCurrency } from 'utils/formatting'
import { track } from 'utils/analytics'

import TrendingUp from 'svgs/trending-up'

import ArrowLink from 'components/ArrowLink'
import FinancialOverviewChart from 'components/FinancialOverviewChart'

import styles from './styles.module.scss'

interface Props {
  className?: string
  property: Property
}

function fuzzy(number: number, precision = 3): number {
  const mod = Math.pow(10, precision)
  return Math.round(number / mod) * mod
}

const FinancialOverview: FC<Props> = ({ className, property }) => {
  const handleCtaClick = useCallback(
    (location) => () => {
      let cta = 'financial-overview'
      if (!property.loan_max || !property.estimated_value || !property.equity || property.equity_state != 'normal') {
        cta = 'edit-financial'
      }

      track('click cta', { cta, module: 'financial-overview', location })
    },
    [property]
  )

  return (
    <div className={classNames(styles.financialOverview, className)}>
      <div className={styles.propertyValue}>
        <div className={classNames(styles.value, { [styles.lowData]: !property.estimated_value })}>
          {property.estimated_value ? formatCurrency(property.estimated_value) : '$---,---'}
        </div>
        <div className={styles.label}>
          {'Current '}
          <br className={styles.break} />
          {'Value'}
        </div>
      </div>
      <div className={styles.chartContainer}>
        <FinancialOverviewChart
          className={styles.chart}
          equity={property.equity_state != 'missing_data' ? property.equity : 0}
          total={property.estimated_value}
          isLowData={property.equity_state != 'normal'}
        />
        <div className={styles.equity}>
          <div
            className={classNames(styles.value, {
              [styles.lowData]: property.equity_state == 'low_data',
              [styles.missingData]: property.equity_state == 'missing_data',
            })}
          >
            {property.equity_state == 'normal'
              ? formatCurrency(property.equity)
              : property.equity_state == 'low_data'
              ? formatCurrency(fuzzy(property.equity))
              : 'Unknown'}
          </div>
          <div className={styles.label}>
            {property.equity_state == 'low_data' ? 'Estimated Equity' : 'Total Equity'}
          </div>
        </div>
      </div>
      {property.monthly_equity_change > 0 && (
        <div className={styles.recentPayment}>
          <TrendingUp className={styles.bulletGlyph} />
          <div>
            <em>{`+${formatCurrency(property.monthly_equity_change)} `}</em>
            {'over the last 30 days'}
          </div>
        </div>
      )}

      <div className={styles.action}>
        {property.equity_state != 'normal' ? (
          <div className={styles.updateCta}>
            <ArrowLink to="finance-hub/financial-analysis" onClick={handleCtaClick('update')}>
              {'Update your details'}
            </ArrowLink>
          </div>
        ) : (
          <>
            {property.loan_max && property.estimated_value && property.equity ? (
              <ArrowLink onClick={handleCtaClick('explore')} className={styles.cta} to="finance-hub">
                {'Explore financial opportunities'}
              </ArrowLink>
            ) : (
              <ArrowLink
                onClick={handleCtaClick('complete')}
                className={(styles.cta, styles.emptyState)}
                to="finance-hub/financial-analysis"
              >
                {'Complete your financial overview'}
              </ArrowLink>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default FinancialOverview
