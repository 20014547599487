import React, { useCallback, useState, useRef, useEffect, ForwardRefRenderFunction, forwardRef } from 'react'

import { track as legacyTrack } from 'utils/analytics'
import { prepareBoth } from 'utils/analyticsV2'
import { trackClick } from '.'
import { formatPublicPropertyAddressPrediction } from 'utils/formatting'

import LocationIcon from 'svgs/location'
import SpinnerIcon from 'svgs/spinner'

import { useOnboardingProperty } from 'recoil/onboarding'

import FullAddressInput, { Prediction } from 'components/FullAddressInput'

import styles from './AddressAutocompleteField.module.scss'
import cx from 'classnames'
import Close from 'svgs/close'
import { searchGooglePlaces } from 'apis/googlePlaces'

const [, trackImpressionInelgible] = prepareBoth({
  family: 'onboarding',
  screen: 'inelgible-property',
})

interface Props {
  onSelected: () => void
  onFocus?: () => void
  onBlur?: () => void
  autoFocus?: boolean
}

const AddressAutocompleteField: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { onSelected, onFocus, onBlur, autoFocus },
  ref
) => {
  const { onboarding, setOnboarding } = useOnboardingProperty()
  const [enteredAddress, setEnteredAddress] = useState<string>(
    onboarding.publicPropertyData?.public_property
      ? formatPublicPropertyAddressPrediction(onboarding.publicPropertyData?.public_property)
      : ''
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const lastSelected = useRef<string | null>(enteredAddress)

  const onPredictionSelected = useCallback(
    async (prediction: Prediction | null) => {
      if (!prediction) return

      lastSelected.current = prediction.description

      setIsLoading(true)
      setEnteredAddress(prediction.description)
      setOnboarding((onboarding) => ({
        ...onboarding,
        attomId: undefined,
        publicPropertyData: undefined,
        unavailable: undefined,
        suggestions: undefined,
      }))

      legacyTrack('submit form', { section: 'onboarding', form: 'enter-address' })
      const response = await searchGooglePlaces({ placeId: prediction.placeId })
      await trackClick({
        code: 'continue',
        section: 'move-forward',
        content_text: prediction.description,
        flexfield_1: { marketplace: response.marketplace },
      })
      await legacyTrack('address selected')

      trackImpressionInelgible({ code: 'alternate-address' })
      setOnboarding((onboarding) => ({
        ...onboarding,
        placeId: prediction.placeId,
        attomId: response.unavailable ? response.r_query.id : response.public_property_data.public_property.attom_id,
        unavailable: response.unavailable,
        suggestions: response.unavailable ? response.suggestions : undefined,
        marketplace: response.marketplace,
        enteredAddress: prediction.description,
        selectedProjects: undefined,
        budget: undefined,
        reset: undefined,
      }))

      setIsLoading(false)

      if (onSelected) onSelected()
    },
    [setOnboarding, onSelected]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return

      if (lastSelected.current == enteredAddress && onSelected) onSelected()
    },
    [lastSelected, enteredAddress, onSelected]
  )

  const clearInput = useCallback(() => setEnteredAddress(''), [])

  const isDone = useRef<boolean>(false)
  useEffect(() => {
    if (enteredAddress.length > 0 && !isDone.current) {
      isDone.current = true
      legacyTrack('address start typing', { position: 'cam-enter-address' })
      trackClick({ code: 'enter-address', section: 'address' })
    }
  }, [enteredAddress])

  const renderPredictionDescription = useCallback(
    (prediction: Prediction) => (
      <>
        <div className={styles.predictionStreetAddress}>{prediction.mainText}</div>
        <div className={styles.predictionCityAddress}>{prediction.secondaryText}</div>
      </>
    ),
    []
  )

  const renderIcon = useCallback(
    () => (
      <div className={styles.icons}>
        <LocationIcon className={styles.icon} />
        {isLoading ? (
          <SpinnerIcon className={cx(styles.icon, styles.right)} strokeWidth={8} />
        ) : (
          enteredAddress !== '' && (
            <button type="button" onClick={clearInput} className={cx(styles.icon, styles.close)}>
              <Close className={styles.close} />
            </button>
          )
        )}
      </div>
    ),
    [isLoading, clearInput, enteredAddress]
  )

  return (
    <>
      <div className={styles.addressAutocompleteField}>
        <FullAddressInput
          className={cx(styles.search, { [styles.loading]: isLoading })}
          placeholder="Enter your address"
          id="entered_address"
          required
          onPredictionSelected={onPredictionSelected}
          value={enteredAddress}
          onChange={setEnteredAddress}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          renderDescription={renderPredictionDescription}
          renderIcon={renderIcon}
          disabled={isLoading}
          autoFocus={autoFocus}
          ref={ref}
        />
      </div>
    </>
  )
}

export default forwardRef(AddressAutocompleteField)
