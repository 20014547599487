import React, { FC } from 'react'

interface DocumentFilledProps {
  className?: string
}

const DocumentFilled: FC<DocumentFilledProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H1.99C0.89 0 0 0.9 0 2L0.00999999 16C0.00999999 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM5 5H13C13.55 5 14 5.45 14 6C14 6.55 13.55 7 13 7H5C4.45 7 4 6.55 4 6C4 5.45 4.45 5 5 5ZM8 11H5C4.45 11 4 10.55 4 10C4 9.45 4.45 9 5 9H8C8.55 9 9 9.45 9 10C9 10.55 8.55 11 8 11ZM11 16.5V12C11 11.45 11.45 11 12 11H16.5L11 16.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DocumentFilled
