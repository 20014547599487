import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { useSetRecoilState } from 'recoil'
import moment from 'moment'
import { getInitials } from 'utils/formatting'
import { prepareBoth } from 'utils/analyticsV2'
import PropertyPlanAPI from 'apis/propertyPlanApi'
import { TimeToStart, useOnboardingProperty } from 'recoil/onboarding'
import { propertiesState, updateProperty, useProperties } from 'recoil/properties'
import { useUser } from 'recoil/user'
import { useAuth } from 'non-rendering/Authentication'
import Download from 'pages/Onboarding/screens/CamV3BookOrDownload/Download'
import RenovationDetails from 'pages/Onboarding/screens/CamV3Confirm/RenovationDetails'
import CamV3RenovationReadiness from 'pages/Onboarding/screens/CamV3RenovationReadiness'
import TellMeMore from 'pages/Onboarding/screens/CamV3Confirm/TellMeMore'
import CamV3Projects from 'pages/Onboarding/screens/CamV3Projects'
import BidUploads from 'pages/Onboarding/screens/CamV3Confirm/BidUploads'
import RefreshUser from 'layouts/RefreshUser'
import CoreText from 'components/Core/CoreText'
import Loading from 'components/Loading'
import CreatingProperty from 'components/Auth/CreatingProperty'
import Logo from 'svgs/logo'
import styles from './styles.module.scss'

const [trackClick] = prepareBoth({ screen: 'meeting-confirmation', family: 'projects' })

export type ConfirmModalType = 'time_to_start' | 'projects' | 'more'

const Confirm: React.FC = () => {
  const [detailsExpanded, setDetailsExpanded] = useState(false)
  const { onboarding, finalize, fetchProperty, setOnboarding } = useOnboardingProperty()
  const { user } = useAuth()
  const { updateUser } = useUser()
  const { selectedProperty } = useProperties()
  const [modal, setModal] = useState<ConfirmModalType | null>(null)
  const triedFinalize = useRef(false)
  const setProperties = useSetRecoilState(propertiesState)

  const handlePropertyCreated = useCallback(
    (result) => {
      setOnboarding((onboarding) => ({ ...onboarding, pollable_job_token: undefined }))

      const reloadProperties = async () => {
        const newProperty = await fetchProperty(result.id)

        setProperties((properties) => {
          // It is possible that 'create' returns to us a property
          // that already exists in our list. If so, we want to *replace*
          // that existing property rather than adding this new one in.
          properties = (properties || []).map((old_prop) => (old_prop.id == newProperty.id ? newProperty : old_prop))
          if (!properties.find((old_prop) => old_prop.id == newProperty.id)) {
            properties = properties.concat([newProperty])
          }
          return properties
        })
      }
      reloadProperties()
    },
    [setOnboarding, fetchProperty, setProperties]
  )

  useEffect(() => {
    if (!onboarding.finalized && !triedFinalize.current) {
      triedFinalize.current = true
      finalize()
    }
  }, [onboarding.finalized, finalize])

  const pickModal = useCallback((modal: ConfirmModalType | null) => {
    setModal(modal)
  }, [])

  const dismissModal = useCallback(() => {
    setModal(null)
  }, [])

  const saveReadiness = useCallback(
    (timeToStart?: TimeToStart) => {
      trackClick({ code: 'renovation-info', section: 'edit', feature: 'start-renovations' })
      if (timeToStart && selectedProperty) {
        updateProperty(selectedProperty.id, { home_goals: [timeToStart] })
      }
      dismissModal()
    },
    [selectedProperty, dismissModal]
  )

  const saveProjects = useCallback(
    (selectedProjects?: number[]) => {
      if (selectedProjects) {
        trackClick({ code: 'renovation-info', section: 'edit', feature: 'projects' })
        ;(async () => {
          if (selectedProperty) {
            const api = new PropertyPlanAPI(selectedProperty.id)

            // Grab our first property plan
            const plans = await api.listPropertyPlans()
            if (plans.length > 0) {
              const existingProjects = await api.listProjects(plans[0].id)

              // Add projects to our property plan.
              const promises = [
                ...existingProjects.map((project) => {
                  return api.deleteProject(plans[0].id, project.id)
                }),
                ...selectedProjects.map((projectId) => {
                  return api.createProject(plans[0].id, {
                    project_template_id: projectId,
                  })
                }),
              ]
              Promise.all(promises)
            }
          }
        })()
      }
      dismissModal()
    },
    [selectedProperty, dismissModal]
  )

  const saveAdditionalInfo = useCallback(
    (additionalInfo?: string) => {
      if (additionalInfo) {
        updateUser({ signup_message: additionalInfo })
        trackClick({ code: 'renovation-info', section: 'edit', feature: 'more-info' })
      }
      dismissModal()
    },
    [updateUser, dismissModal]
  )

  const handleClickExpand = useCallback(() => {
    const newValue = !detailsExpanded
    const section = newValue ? 'expand' : 'collapse'

    trackClick({ code: 'renovation-info', section })
    setDetailsExpanded(newValue)
  }, [detailsExpanded])

  const meetingNotes = useMemo(() => {
    if (!user) return

    const firstName = user.full_name.split(' ')[0]
    return `Hi ${firstName}! Looking forward to learning more about your renovation. We’ll discuss the projects you’re looking at and I’ll explain how Realm can help. We’ll then be able to put together an estimate and get you in touch with contractors from our vetted, invite-only network.<br /><br /> Talk soon!`
  }, [user])

  return (
    <>
      <RefreshUser />
      <CreatingProperty token={onboarding.pollable_job_token} onSuccess={handlePropertyCreated}>
        <div className={cx(styles.container, styles.gradient)}>
          <div className={cx(styles.wrapper, modal ? styles.hide : styles.show)}>
            <div className={styles.heading}>
              <a href="/">
                <Logo className={styles.logo} />
              </a>
              <CoreText.Headline size="s">{'Meeting confirmed'}</CoreText.Headline>
              {user && (
                <CoreText.Paragraph
                  size="l"
                  weight="light"
                  color="tertiaryBlack"
                  className={styles.subheading}
                >{`An email with details has been sent to ${user.email}`}</CoreText.Paragraph>
              )}
            </div>
            {onboarding.outsideBids && onboarding.outsideBids !== 'no' && <BidUploads />}
            <div className={styles.details}>
              {!user ? (
                <div className={styles.loadingWrapper}>
                  <Loading />
                </div>
              ) : (
                <>
                  {!!onboarding.bookingDetails && (
                    <div className={styles.bubblesWrapper}>
                      <div className={styles.bubbles}>
                        {onboarding.bookingDetails.advisor.avatar_url ? (
                          <img
                            src={onboarding.bookingDetails?.advisor.avatar_url}
                            alt="Headshot of a Realm Advisor"
                            className={styles.bubble}
                          />
                        ) : (
                          <CoreText.Headline
                            size="xl"
                            weight="heavy"
                            className={cx(styles.bubble, styles.initials, styles.advisor)}
                          >
                            {getInitials(onboarding.bookingDetails.advisor.name)}
                          </CoreText.Headline>
                        )}
                        <CoreText.Headline size="xl" weight="heavy" className={cx(styles.bubble, styles.initials)}>
                          {getInitials(user.full_name)}
                        </CoreText.Headline>
                      </div>
                    </div>
                  )}
                  <CoreText.Paragraph size="xl" weight="heavy" className={styles.meetingTitle}>
                    {'Realm Intro'}
                    {!!onboarding.bookingDetails && ` with ${onboarding.bookingDetails.advisor.name}`}
                  </CoreText.Paragraph>
                  {!!onboarding.bookingDetails && (
                    <CoreText.Paragraph size="l" weight="light" className={styles.meetingTime} color="tertiaryBlack">
                      {moment(onboarding.bookingDetails.event.start_time).format('ddd, MMM D @ h:mma')}
                    </CoreText.Paragraph>
                  )}
                  <hr className={styles.divider} />
                  {!!onboarding.bookingDetails && !!meetingNotes && (
                    <>
                      <CoreText.Paragraph color="tertiaryBlack">{`Note from ${onboarding.bookingDetails.advisor.name}`}</CoreText.Paragraph>
                      <CoreText.Paragraph size="l" weight="light" className={styles.meetingNotes}>
                        <span dangerouslySetInnerHTML={{ __html: meetingNotes }} />
                      </CoreText.Paragraph>
                    </>
                  )}
                  <hr className={styles.divider} />
                  {!onboarding.unavailable && !selectedProperty ? (
                    <div className={styles.loadingWrapper}>
                      <Loading />
                    </div>
                  ) : (
                    <RenovationDetails
                      onOptionSelected={pickModal}
                      expanded={detailsExpanded}
                      onClickExpand={handleClickExpand}
                    />
                  )}
                </>
              )}
            </div>
            {!onboarding.unavailable && (
              <Download
                headerSize="large"
                customCopy="Download the app before your meeting to get project price ranges, see neighborhood real estate updates, and track your home value"
                trackingScreen="meeting-confirmation"
              />
            )}
          </div>
          <div className={cx(styles.modalWrapper, modal ? styles.show : styles.hide)}>
            {modal == 'time_to_start' ? (
              <CamV3RenovationReadiness onDismiss={saveReadiness} />
            ) : modal == 'projects' ? (
              <CamV3Projects onDismiss={saveProjects} />
            ) : (
              <TellMeMore onDismiss={saveAdditionalInfo} />
            )}
          </div>
        </div>
      </CreatingProperty>
    </>
  )
}

export default Confirm
