import React, { FC, useEffect } from 'react'

import { useFlow } from 'recoil/onboardingFlows'

const OnboardingFlowRestart: FC = () => {
  const { restart } = useFlow()
  useEffect(() => {
    restart()
  }, [restart])
  return <></>
}

export default OnboardingFlowRestart
