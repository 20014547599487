import React, { FC } from 'react'
import DoneAll from '@mui/icons-material/DoneAll'
import CoreText from 'components/Core/CoreText'
import CamV3Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import Download from 'pages/Onboarding/screens/CamV3BookOrDownload/Download'

const PaymentDownload: FC = () => {
  return (
    <CamV3Wrapper>
      <div className="tw-flex tw-flex-col tw-gap-4">
        <div className="tw-text-center tw-text-green-700">
          <DoneAll sx={{ fontSize: '3rem' }} />
        </div>
        <CoreText.Headline size="xs" weight="heavy" className="tw-text-center">
          {'Your account has been successfully created'}
        </CoreText.Headline>
        <CoreText.Paragraph size="l" weight="regular" color="tertiaryBlack" className="tw-text-center">
          {'Download the app to follow your renovation progress and enjoy a smooth journey from start to finish.'}
        </CoreText.Paragraph>
        <Download
          headerSize="small"
          trackingScreen="onboarding-payment-download"
          customCopy="See contractors matches, thoroughly review your bids, and enjoy a smooth renovation in the Realm App."
        />
      </div>
    </CamV3Wrapper>
  )
}

export default PaymentDownload
