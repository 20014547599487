import React, { FC, PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'

import { useAuth, LoginState } from 'non-rendering/Authentication'
import { propertiesState } from 'recoil/properties'

import Loading from 'components/Loading'
import WaitForAuth from './WaitForAuth'

const PropertiesOrDashboard: FC = () => {
  const properties = useRecoilValue(propertiesState)

  if ((properties || []).length == 1) window.location.replace('/dashboard')
  else window.location.replace('/properties')
  return <Loading />
}

const EnsureLoggedOut: FC<PropsWithChildren> = ({ children }) => {
  const { state } = useAuth()

  return (
    <WaitForAuth>
      {state === LoginState.LoggedIn ? (
        // If they are logged in, they need to shown a logged in page.
        <PropertiesOrDashboard />
      ) : (
        children
      )}
    </WaitForAuth>
  )
}

export default EnsureLoggedOut
