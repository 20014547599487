import React, { FC, useState, useRef, useEffect, useCallback } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

import { formatCurrency, formatNumber } from 'utils/formatting'

import { Project as ProjectData, TempProject } from 'recoil/projects'
import { AvailableErrorType } from 'recoil/projectTemplates'
import { Property } from 'recoil/properties'
import { useUser } from 'recoil/user'

import Close from 'svgs/property-plan/close'
import IncreaseValue from 'svgs/increase-value'
import FallbackIcon from 'svgs/property-plan/fallback'

import FancyButton from 'components/FancyButton'
import ExternalSvg from 'components/ExternalSvg'

import ErrorFlag from '../ErrorFlag'
import InfoFlag from '../InfoFlag'
import Arrow from '../InfoFlag/Arrow'

import styles from './styles.module.scss'
import { track } from 'utils/analytics'

function isTemporary(project: ProjectData): boolean {
  return !!(project as TempProject)._temporary
}

interface Props {
  project: ProjectData
  property: Property
  onRemove: (projectId: number) => void
  index: number
  showSqFt: boolean
}

const Project: FC<Props> = ({ project, property, onRemove, showSqFt, index }) => {
  const match = useRouteMatch()
  const componentRef = useRef<HTMLLIElement>(null)
  const { user, updateUser } = useUser()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Capture only the first time we're visible.
    if (isVisible) return
    if (!componentRef || !componentRef.current) return

    const target = componentRef.current

    let timer = 0

    const observer = new IntersectionObserver(
      (entries: Array<IntersectionObserverEntry>) => {
        if (entries.length > 0 && entries[0].isIntersecting) {
          // Provide a minor delay in setting it visible
          timer = setTimeout(() => setIsVisible(true), 1000) as unknown as number
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    )

    observer.observe(target)

    return () => {
      if (observer && target) {
        observer.unobserve(target)
      }
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isVisible])

  const handleClose = useCallback(() => {
    track('submit form', {
      section: 'property-plan',
      form: 'project',
      action: 'delete',
      template: {
        id: project.project_template_id,
        kind: project.kind,
      },
    })
    onRemove(project.id)
  }, [project, onRemove])

  const handleCustomize = useCallback(
    (name: string) => () => {
      track('submit form', {
        section: 'property-plan',
        form: 'project',
        action: 'customize',
        element: name,
        template: {
          id: project.project_template_id,
          kind: project.kind,
        },
      })
      // The first time we customize, we should update the user.
      if (!user?.project_customized) {
        updateUser({ project_customized: true })
      }
    },
    [project, user, updateUser]
  )

  const isTemp = isTemporary(project)

  const recoup = project.cost_estimate ? Math.max(0, project.additional_home_value / project.cost_estimate) : 0

  const showCustomizeFlag = !user?.project_customized && index == 0

  return (
    <li key={project.id} className={styles.project} ref={componentRef}>
      <div className={styles.content}>
        <ExternalSvg className={styles.img} uri={project.icon_svg}>
          <FallbackIcon className={styles.img} />
        </ExternalSvg>
        <div className={styles.row}>
          <div className={styles.title}>
            <span className={styles.name}>{project.name}</span>
            {showSqFt ? (
              <span className={styles.addSqFt}>{`Additional ${formatNumber(project.square_footage || 0)} sqft`}</span>
            ) : null}
          </div>
          <div className={styles.cells}>
            <div className={classNames(styles.cell, styles.homeValue)}>
              <label>{'Home Value'}</label>
              <span className={styles.value}>
                {project.additional_home_value > 0 ? `+${formatCurrency(project.additional_home_value)}` : '-'}
              </span>
            </div>
            <div className={classNames(styles.cell, styles.costEstimate)}>
              <label>{'Cost'}</label>
              <span className={styles.value}>{`${formatCurrency(project.cost_estimate_low)} - ${formatCurrency(
                project.cost_estimate_high
              )}`}</span>
            </div>
            <div className={classNames(styles.cell, styles.recoup)}>
              <label>{'% Recoup'}</label>
              <span className={styles.value}>
                {recoup > 0.75 ? <IncreaseValue /> : null}
                {`${Math.round(recoup * 100)}%`}
              </span>
            </div>
            <div className={classNames(styles.cell, styles.customize)}>
              <label>{project.customized ? 'Customized by you' : ''}</label>
              <FancyButton
                className={classNames(styles.button, { [styles.edit]: project.customized, [styles.disabled]: isTemp })}
                onClick={handleCustomize(project.customized ? 'edit-button' : 'customize-button')}
                to={`${match.url}/projects/${project.id}`}
                disabled={isTemp}
              >
                {project.customized ? 'Edit' : 'Customize'}
              </FancyButton>
              {!isTemp && isVisible && showCustomizeFlag && <Arrow className={styles.arrow} />}
            </div>
          </div>
          {showCustomizeFlag && (
            <InfoFlag>
              <strong>{'Nicely done!'}</strong>
              {' Your first project has been added. Check out all of the '}
              <Link to={`${match.url}/projects/${project.id}`} onClick={handleCustomize('customization-options-link')}>
                {'customization options'}
              </Link>
              {' that are available to make this project perfect for you.'}
            </InfoFlag>
          )}
          {!project.available.success && project.available.error?.code != AvailableErrorType.ExceedsSqFootage ? (
            project.available.error?.code != AvailableErrorType.ExceedsADUSize ? (
              <ErrorFlag errorType={project.available.error?.code} message={project.available.error?.message} />
            ) : (
              <ErrorFlag>{`This project's size exceeds your property's max permissable ADU size by ${
                (project.square_footage || 0) - (property.max_adu_square_footage || 0)
              } sq ft.`}</ErrorFlag>
            )
          ) : null}
        </div>
        {!isTemp ? (
          <button type="button" className={styles.close} onClick={handleClose}>
            <Close />
          </button>
        ) : (
          <span className={styles.closePlaceholder} />
        )}
      </div>
    </li>
  )
}

export default Project
