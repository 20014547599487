import Bath from 'svgs/bath'
import Bed from 'svgs/bed'
import { numberWithCommas, titleCase } from 'utils/formatting'
import InputWithEditButton from 'components/InputWithEditButton'
import Photos from 'pages/Dashboard/PropertyDetails/Photos'
import React, { FC, useCallback, useState } from 'react'
import { track } from 'utils/analytics'
import { editStatusState } from 'recoil/editStatus'
import { useProperties } from 'recoil/properties'
import { useSetRecoilState } from 'recoil'

interface FormErrors {
  [key: string]: string[]
}

const PropertyDetails: FC = () => {
  const { selectedProperty: property, updateSelectedProperty: updateProperty } = useProperties()
  const setStatus = useSetRecoilState(editStatusState)

  const [name, setName] = useState<string>(property?.name || '')
  const [errors, setErrors] = useState<FormErrors>({})
  const [interacted, setInteracted] = useState(false)

  const updatePropertyName = useCallback(async () => {
    try {
      await track('submit form', {
        section: 'edit',
        form: 'property-details',
        location: 'dashboard',
        interacted,
        edited: property?.name != name,
        property: {
          name: name,
        },
      })
      await updateProperty({ name })
      setErrors({})
      setStatus({ type: 'success', text: 'Saved your property name' })
    } catch (e) {
      setErrors(e.body || {})
      setStatus({ type: 'error', text: 'Something went wrong, please try again' })
      // we want to rethrow here so this is caught by the Input
      throw e
    }
  }, [name, setStatus, updateProperty, property?.name, interacted])

  const handleChangeName = useCallback((value: string) => {
    setInteracted(true)
    setName(value)
  }, [])

  if (!property) return null

  return (
    <header className="tw-relative tw-p-1">
      <Photos property={property} />

      <div className="tw-p-5 tw-space-y-2">
        <InputWithEditButton
          label="Name"
          type="text"
          errors={errors?.['name']}
          required
          value={name}
          onChange={handleChangeName}
          onConfirm={updatePropertyName}
        />

        <div className="tw-font-light tw-text-gray-500">{`${property.delivery_line_1}, ${titleCase(property.city)}, ${
          property.state
        }, ${property.zip5}`}</div>

        <div className="tw-flex tw-gap-8">
          <div className="tw-flex tw-items-center tw-gap-2">
            <div>{property.bedrooms || '?'}</div>
            <Bed />
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <div>{property.total_bathrooms || '?'}</div>
            <Bath />
          </div>
          <div className="tw-font-light">{`${
            property.square_footage ? numberWithCommas(property.square_footage) : '?'
          } sqft`}</div>
        </div>
      </div>
    </header>
  )
}

export default PropertyDetails
