import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Renovation, useRenovations } from 'recoil/renovations'
import cx from 'classnames'
import RenovationImageUpload from 'pages/EditProperty/Renovations/RenovationImageUpload'
import FancyButton from 'components/FancyButton'
import Close from 'svgs/close'
import Spinner from 'components/Spinner'
import { track } from 'utils/analytics'

import styles from './styles.module.scss'
import editStyles from '../../styles.module.scss'
import InputWithEditMode from 'components/InputWithEditMode'
import { useSetRecoilState } from 'recoil'
import { editStatusState } from 'recoil/editStatus'

export type RenovationFormProps = {
  renovation: Renovation
  onRemove: (r: Renovation) => Promise<void>
}

interface RenovationErrors {
  [key: string]: string[]
}

const MINIMUM_RENOVATION_YEAR = 1970

function getRenovationPropsChanged(
  newRenovation: Renovation,
  oldRenovation: Renovation
): { [prop: string]: string | boolean } {
  return Object.keys(newRenovation).reduce(
    (prev, key) => {
      if (newRenovation[key] == oldRenovation[key]) return prev
      // If the key is 'details', we only want to say true if it changed; we don't
      // want to send all the details to analytics.
      if (key == 'details') return { ...prev, [key]: true }
      return { ...prev, [key]: newRenovation[key] }
    },
    {} as { [key: string]: string | boolean }
  )
}

export const RenovationForm: FC<RenovationFormProps> = ({ renovation, onRemove }) => {
  const { kinds, createRenovation, updateRenovation } = useRenovations()

  const [kind, setKind] = useState<string | undefined>()
  const [squareFootage, setSquareFootage] = useState<number | undefined>()
  const [cost, setCost] = useState<number | undefined>()
  const [year, setYear] = useState<number | undefined>()
  const [contractor, setContractor] = useState<string | undefined>()
  const [details, setDetails] = useState<string | undefined>()
  const [editing, setEditing] = useState(false)
  const [rating, setRating] = useState<number | undefined>()
  const setStatus = useSetRecoilState(editStatusState)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<RenovationErrors>({})
  const [interacted, setInteracted] = useState<{ [field: string]: boolean }>({})

  useEffect(() => {
    setKind(renovation.kind)
    setSquareFootage(renovation.square_footage)
    setCost(renovation.cost)
    setYear(renovation.year)
    setContractor(renovation.contractor)
    setDetails(renovation.details)
    setRating(renovation.rating)
  }, [renovation])

  const updatedRenovation = useMemo(() => {
    return {
      id: renovation.id,
      kind: kind,
      square_footage: squareFootage,
      cost: cost,
      year: year,
      rating: rating,
      contractor: contractor,
      details: details,
    }
  }, [renovation.id, kind, squareFootage, cost, year, contractor, rating, details])

  const needsCreate = useMemo(() => {
    return !updatedRenovation.id || updatedRenovation.id < 0
  }, [updatedRenovation.id])

  const trackFormSubmit = useCallback(
    (newRenovation) => {
      // Get a list of properties that have changed
      const changedProps = getRenovationPropsChanged(newRenovation, renovation)
      // Did we edit at least one field?
      const edited = Object.keys(changedProps).length > 0
      // Did we interact with at least one field?
      const didInteract = edited || Object.keys(newRenovation).reduce((prev, key) => prev || interacted[key], false)

      track('submit form', {
        section: 'edit',
        form: 'renovation',
        action: needsCreate ? 'create' : 'update',
        interacted: didInteract,
        edited,
        renovations: [
          {
            kind: newRenovation.kind, // Include the kind
            ...changedProps,
          },
        ],
      })
    },
    [interacted, renovation, needsCreate]
  )

  const save = useCallback(async () => {
    try {
      setLoading(true)
      trackFormSubmit(updatedRenovation)
      await (needsCreate ? createRenovation(updatedRenovation) : updateRenovation(updatedRenovation))
      setStatus({ type: 'success', text: `Saved your ${kinds[kind || ''] || kind}` })
      setErrors({})
      setLoading(false)
      setEditing(false)
      setInteracted({})
    } catch (e) {
      setErrors(e.body || {})
      setStatus({ type: 'error', text: 'Something went wrong, please try again' })
      setLoading(false)
    }
  }, [updatedRenovation, needsCreate, trackFormSubmit, createRenovation, updateRenovation, setStatus, kinds, kind])

  const setStringValue = useCallback(
    (field: string, setter: (val: string) => void) => (val: string) => {
      setInteracted((prev) => ({ ...prev, [field]: true }))
      setter(val)
    },
    []
  )

  const setNumberValue = useCallback(
    (field: string, setter: (val: number | undefined) => void) => (val: string) => {
      setInteracted((prev) => ({ ...prev, [field]: true }))
      const iVal = parseInt(val)
      setter(Number.isNaN(iVal) ? undefined : iVal)
    },
    []
  )

  const getNumberValue = useCallback((val: number | null): string => {
    return val ? Math.round(val).toString() : ''
  }, [])

  const handleClickEdit = useCallback(() => {
    track('share more about project', { kind: renovation.kind })
    setEditing(true)
  }, [renovation, setEditing])

  const handleClickRemove = useCallback(() => {
    onRemove(renovation)
  }, [onRemove, renovation])

  const empty = !year && !cost

  return (
    <div className={styles.renovation}>
      <div className={cx(styles.header, editing && styles.editing)}>
        <h3 className={styles.headerText}>{kinds[renovation.kind || ''] || renovation.kind}</h3>
        {!editing && (
          <FancyButton onClick={handleClickEdit} className={cx(styles.editButton, editStyles.button)}>
            {'Edit'}
          </FancyButton>
        )}
      </div>
      <div className={styles.photos}>
        <RenovationImageUpload renovation={renovation} editing={editing} />
      </div>
      <div className={styles.body}>
        {empty && !editing ? (
          <h4 className={styles.emptyMessage}>
            {'Help us fill in some '}
            <button type="button" className={editStyles.link} onClick={handleClickEdit}>
              {'additional details'}
            </button>
            {' about this project.'}
          </h4>
        ) : (
          <div className={styles.bodyGrid}>
            <InputWithEditMode
              label="Year renovated"
              type="number"
              placeholder="e.g. 1990"
              min={MINIMUM_RENOVATION_YEAR}
              max={new Date().getFullYear()}
              value={getNumberValue(year || 0)}
              onChange={setNumberValue('year', setYear)}
              errors={errors?.year}
              editing={editing}
            />
            <InputWithEditMode
              label="Amount spent"
              placeholder="e.g. $8000"
              type="number"
              step="any"
              min={0}
              value={getNumberValue(cost || 0)}
              onChange={setNumberValue('cost', setCost)}
              errors={errors.amount}
              editing={editing}
              displayAsMoney
            />
            {(details || editing) && (
              <InputWithEditMode
                label="More details"
                type="text"
                tag="textarea"
                className={styles.details}
                placeholder="Share some additional details about this project... who was the contractor? How happy were you with the end result?"
                value={details || ''}
                onChange={setStringValue('details', setDetails)}
                editing={editing}
              />
            )}
          </div>
        )}
      </div>
      {editing && (
        <div className={styles.save}>
          <FancyButton onClick={save} className={editStyles.button}>
            <div className={styles.saveButton}>
              <div>{'Save'}</div>
              {loading && <Spinner />}
            </div>
          </FancyButton>
          <button type="button" className={styles.close} onClick={handleClickRemove}>
            <Close />
            <span className={styles.short}>{'Remove'}</span>
            <span className={styles.long}> {'Remove this project'}</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default RenovationForm
