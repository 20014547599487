import React, { FC, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { buildableArea } from 'utils/property'

import { useProperties } from 'recoil/properties'
import { usePropertyPlans } from 'recoil/propertyPlans'
import { Project as ProjectType, TempProject, useProjects } from 'recoil/projects'

import Construction from 'svgs/property-plan/construction'
import Edit from 'svgs/edit'
import Add from 'svgs/plus-circle'
import Close from 'svgs/property-plan/close'

import FancyButton from 'components/FancyButton'
import Select from 'components/Select'
import Input from 'components/Input'

import Project from './Project'
import FakeProject from './FakeProject'
import ExceedsLotSizeError from './ExceedsLotSizeError'
import { track } from 'utils/analytics'

import styles from './styles.module.scss'

const MIN_PROJECTS_FOR_CLEAR = 3

function isTemporary(project: ProjectType): boolean {
  return !!(project as TempProject)._temporary
}

const Plan: FC = () => {
  const { push } = useHistory()
  const { selectedProperty } = useProperties()
  const { selectedPropertyPlan, propertyPlans, addPropertyPlan, removePropertyPlan, updatePropertyPlan, isSwitching } =
    usePropertyPlans()
  const { projects, removeProject, removeProjects } = useProjects()
  const [editMode, setEditMode] = useState(false)
  const [editName, setEditName] = useState(selectedPropertyPlan?.name || '')

  const handleSelectPlan = useCallback(
    (propertyPlanId) => {
      if (!propertyPlans) return
      const plan = propertyPlans.find((plan) => plan?.id == propertyPlanId)
      track('property-plan change plan', { planId: propertyPlanId, planName: plan?.name })
      // A history push here is necessary over a Redirect, because the component
      // will be getting reused. Managing state to trigger a redirect will just
      // end up interfering with other redirects.
      push(`../property-plans/${propertyPlanId}`)
    },
    [push, propertyPlans]
  )
  const handleEditPlanName = useCallback(() => {
    if (!selectedPropertyPlan) return
    track('submit form', {
      section: 'property-plan',
      form: 'property-plans',
      action: 'edit-plan-name',
    })
    setEditName(selectedPropertyPlan.name)
    setEditMode(true)
  }, [setEditMode, selectedPropertyPlan])

  const handleEditPlanNameFocus = useCallback((event) => {
    event.target.select()
  }, [])

  const handleEditPlanNameChange = useCallback(
    (value) => {
      setEditName(value)
    },
    [setEditName]
  )

  const handleCancelEditPlanName = useCallback(() => {
    if (!selectedPropertyPlan) return
    track('submit form', {
      section: 'property-plan',
      form: 'property-plans',
      action: 'cancel-plan-name',
    })
    setEditMode(false)
  }, [setEditMode, selectedPropertyPlan])

  const handleSaveEditPlanName = useCallback(async () => {
    if (!selectedPropertyPlan) return
    track('submit form', {
      section: 'property-plan',
      form: 'property-plans',
      action: 'save-plan-name',
    })
    // I don't think we need to wait for it to finish.
    await updatePropertyPlan(selectedPropertyPlan.id, { name: editName })
    setEditMode(false)
  }, [setEditMode, updatePropertyPlan, selectedPropertyPlan, editName])

  const handleCreateNewPlan = useCallback(async () => {
    track('submit form', {
      section: 'property-plan',
      form: 'property-plans',
      action: 'create',
      totalPlans: (propertyPlans?.length || 0) + 1,
    })
    const plan = await addPropertyPlan({})
    if (!plan) return
    push(`../property-plans/${plan.id}`)
  }, [addPropertyPlan, push, propertyPlans])

  const handleDeletePlan = useCallback(() => {
    if (!selectedPropertyPlan) return
    track('submit form', {
      section: 'property-plan',
      form: 'property-plans',
      action: 'delete',
      totalPlans: (propertyPlans?.length || 0) - 1,
    })
    removePropertyPlan(selectedPropertyPlan.id)
  }, [removePropertyPlan, selectedPropertyPlan, propertyPlans])

  const handleClearAll = useCallback(() => {
    if (!selectedPropertyPlan) return
    track('submit form', {
      section: 'property-plan',
      form: 'property-plans',
      action: 'delete-all-projects',
    })
    removeProjects(projects.filter((p) => !isTemporary(p)).map((p) => p.id))
  }, [projects, removeProjects, selectedPropertyPlan])

  // const handleContactCtaClick = useCallback(() => {
  //   track('click cta', { cta: 'reports', module: 'project-plan' })
  // }, [])

  if (!selectedProperty) return null

  const projectExpansionArea = projects
    .filter((p) => p.increases_sq_ft && !p.exempt_from_zoning)
    .reduce((prev, p) => prev + (p.square_footage || 0), 0)
  const totalBuildableArea = buildableArea(selectedProperty)
  const exceedsBuildableArea = totalBuildableArea && projectExpansionArea > totalBuildableArea

  return (
    <section className={classNames(styles.plan, isSwitching ? styles.switching : null)}>
      <header className={classNames(editMode ? styles.editMode : null)}>
        {editMode ? (
          <Input
            className={styles.title}
            value={editName}
            onChange={handleEditPlanNameChange}
            autoFocus
            onFocus={handleEditPlanNameFocus}
          />
        ) : (propertyPlans?.length || 0) > 1 ? (
          <Select className={styles.title} onChange={handleSelectPlan} value={`${selectedPropertyPlan?.id}`}>
            {propertyPlans?.map((plan) => (
              <option key={plan?.id} value={plan?.id}>
                {plan?.name}
              </option>
            ))}
          </Select>
        ) : (
          <h2 className={styles.title}>{'Your Project Plan'}</h2>
        )}
        {editMode ? (
          <>
            <FancyButton className={classNames(styles.editButton, styles.save)} onClick={handleSaveEditPlanName}>
              {'Save'}
            </FancyButton>
            <FancyButton className={classNames(styles.editButton, styles.cancel)} onClick={handleCancelEditPlanName}>
              {'Cancel'}
            </FancyButton>
          </>
        ) : (
          <>
            {(propertyPlans?.length || 0) > 1 ? (
              <button
                type="button"
                className={classNames(styles.planButton, styles.editPlan)}
                onClick={handleEditPlanName}
                disabled={isSwitching}
              >
                <Edit />
                <span className={styles.long}>{'Edit plan name'}</span>
                <span className={styles.short}>{'Edit name'}</span>
              </button>
            ) : null}
            <button
              type="button"
              className={classNames(styles.planButton, styles.createPlan)}
              onClick={handleCreateNewPlan}
              disabled={isSwitching}
            >
              <Add />
              <span className={styles.long}>{'Create a new plan'}</span>
              <span className={styles.short}>{'New plan'}</span>
            </button>
          </>
        )}
      </header>

      {isSwitching || projects.length > 0 ? (
        <section>
          <ul className={styles.projects}>
            {isSwitching ? (
              <>
                <FakeProject />
                <FakeProject />
              </>
            ) : (
              projects.map((p, i) => (
                <Project
                  key={p.id}
                  project={p}
                  property={selectedProperty}
                  onRemove={removeProject}
                  showSqFt={(exceedsBuildableArea && p.increases_sq_ft && !p.exempt_from_zoning) || false}
                  index={i}
                />
              ))
            )}
          </ul>
          {exceedsBuildableArea ? (
            <ExceedsLotSizeError buildableSqFt={totalBuildableArea || 0} requestedSqFt={projectExpansionArea} />
          ) : null}
          <footer className={styles.planFooter}>
            <div className={styles.footerButtons}>
              {(propertyPlans?.length || 0) > 1 && !editMode ? (
                <button type="button" className={styles.deletePlan} onClick={handleDeletePlan} disabled={isSwitching}>
                  <Close />
                  {'Delete plan'}
                </button>
              ) : null}
              {projects.length >= MIN_PROJECTS_FOR_CLEAR ? (
                <button type="button" className={styles.clearAll} onClick={handleClearAll} disabled={isSwitching}>
                  {'Clear All'}
                </button>
              ) : null}
            </div>
            {/* <FancyButton onClick={handleContactCtaClick} className={styles.callToAction} to="../reports">
              {'Have an expert do analysis for you'}
            </FancyButton> */}
          </footer>
        </section>
      ) : (
        <>
          <div className={styles.noProjects}>
            <Construction />
            <div className={styles.instructions}>
              <div className={styles.title}>{'Select projects below that interest you'}</div>
              <div className={styles.details}>
                {
                  'As you add projects to your Realm Project Planner, you can view the estimated cost and impact on your home value.'
                }
              </div>
            </div>
          </div>
          <footer className={styles.noProjectsFooter}>
            <div className={styles.footerButtons}>
              {(propertyPlans?.length || 0) > 1 ? (
                <button type="button" className={styles.deletePlan} onClick={handleDeletePlan} disabled={isSwitching}>
                  <Close />
                  {'Delete plan'}
                </button>
              ) : null}
            </div>
            {/* <FancyButton onClick={handleContactCtaClick} className={styles.callToAction} to="../reports">
              {'Have an expert do analysis for you'}
            </FancyButton> */}
          </footer>
        </>
      )}
    </section>
  )
}

export default Plan
