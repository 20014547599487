import React, { FC } from 'react'
import cx from 'classnames'

import { usePropertyCompleteness } from 'recoil/propertyCompleteness'

import styles from './styles.module.scss'

export const Checklist: FC = () => {
  const { completeness } = usePropertyCompleteness()

  return (
    <div className={styles.checklist}>
      <h4>{'Things to do'}</h4>
      <ol>
        <li className={cx({ [styles.done]: completeness.steps['propertyDetails'] })}>
          {'Confirm your basic property details'}
        </li>
        <li className={cx({ [styles.done]: completeness.steps['renovations'] })}>
          {'Tell us about recently completed projects'}
        </li>
        <li className={cx({ [styles.done]: completeness.steps['documents'] })}>{'Add property related documents'}</li>
      </ol>
    </div>
  )
}

export default Checklist
