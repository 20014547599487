import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import QRCode from 'react-qr-code'
import cx from 'classnames'
import { trackClick, trackImpression } from 'utils/analyticsV2'
import { UtmParams, UTM_PARAMS_SESSION_KEY } from 'utils/analytics'
import { useOnboardingProperty } from 'recoil/onboarding'
import CoreCard from 'components/Core/CoreCard'
import CoreText from 'components/Core/CoreText'
import { useAuth } from 'non-rendering/Authentication'
import DownloadHeader from './DownloadHeader'

import styles from './styles.module.scss'

interface DownloadProps {
  headerSize: 'small' | 'large'
  copyMode?: 'primary' | 'alternate'
  customCopy?: string
  trackingScreen: string
}

const Download: FC<DownloadProps> = ({ headerSize, copyMode = 'primary', customCopy, trackingScreen }) => {
  const [utmParams, setUtmParams] = useState<UtmParams | null>(null)
  const { onboarding } = useOnboardingProperty()
  const { user } = useAuth()

  const utmProjects = useMemo(() => {
    if (onboarding.interestedInUnlistedProject) return 'projects-yes'
    if (onboarding.selectedProjects && onboarding.selectedProjects.length > 0) return 'projects-yes'

    return 'projects-no'
  }, [onboarding.interestedInUnlistedProject, onboarding.selectedProjects])

  const utmReno = useMemo(() => {
    switch (onboarding.timeToStart) {
      case 'renovation_asap':
        return 'reno-asap'
      case 'renovation_within_year':
        return 'reno-within-year'
      case 'renovation_year_plus':
        return 'reno-over-one-year'
      case 'renovation_need_help_deciding':
        return 'reno-need-help-deciding'
      case 'not_renovating':
        return 'reno-not-interested'
    }
  }, [onboarding.timeToStart])

  const utmCreative = useMemo(() => {
    let creative = `onboarding-v3a | ${utmReno} | ${utmProjects}`

    if (utmParams?.irclickid) {
      creative += ` | irclickid=${utmParams.irclickid}`
    }

    return creative
  }, [utmReno, utmProjects, utmParams])

  const mobileAppUrl = useMemo(() => {
    const deeplink = user ? `client-onboarding/${user.email}/default` : ''
    const path = `https://realmhome.com/mr/${deeplink}`
    const params = new URLSearchParams({
      adj_t: '14up9sst',
      deep_link: deeplink,
      adj_redirect_macos: 'https://apps.apple.com/us/app/realm-1-homeowner-app/id1589251076',
      adj_redirect_android: 'https://play.google.com/store/apps/details?id=com.myrealm',
      // If we don't have a campaign, fallback to 'organic'
      campaign: utmParams?.utm_campaign ? utmParams.utm_campaign : 'organic',
      creative: utmCreative,
    })
    if (utmParams?.utm_source) params.append('adgroup', utmParams.utm_source)
    return [path, params.toString()].join('?')
  }, [user, utmCreative, utmParams])

  const handleAClick = useCallback(() => {
    trackClick({ code: 'download-now', family: 'projects', screen: trackingScreen })
  }, [trackingScreen])

  useEffect(() => {
    const sUtmParams = sessionStorage?.getItem(UTM_PARAMS_SESSION_KEY)
    if (sUtmParams) {
      const utmParams = JSON.parse(sUtmParams)
      if (utmParams) {
        setUtmParams(utmParams)
      }
    }
  }, [])

  useEffect(() => {
    trackImpression({ code: 'download-now', family: 'projects', screen: trackingScreen })
  }, [trackingScreen])

  const header = useMemo(() => {
    return <DownloadHeader size={headerSize} />
  }, [headerSize])

  const bodyText = useMemo(() => {
    if (customCopy) return customCopy

    switch (copyMode) {
      case 'primary':
        return 'Download the app to get ballpark project price ranges, request contractor matches, and chat with a Realm Advisor'
      case 'alternate':
        return 'Not ready to talk yet? Download our app to get project price ranges that factor in local materials and labor costs'
    }
  }, [copyMode, customCopy])

  return (
    <CoreCard header={header} headerStyle={styles.header} className="tw-bg-neutrals-200">
      <div className={styles.cardBody}>
        <div className={styles.col}>
          <CoreText.Paragraph>{bodyText}</CoreText.Paragraph>
          <a
            onClick={handleAClick}
            href={mobileAppUrl}
            target="_blank"
            rel="noreferrer"
            className="tw-flex sm:tw-hidden tw-items-center tw-justify-center tw-rounded-full tw-text-sm tw-leading-tight tw-bg-black tw-text-white tw-font-bold tw-p-x-4 tw-h-12"
          >
            {'Download the app'}
          </a>
        </div>
        <div className={cx(styles.col, styles.desktop)}>
          <QRCode value={mobileAppUrl} className="tw-size-16" />
        </div>
      </div>
    </CoreCard>
  )
}

export default Download
