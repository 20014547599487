import { FOState } from './'
import { START_STATE, END_STATE } from './states'
import {
  FlowCollection as FlowCollectionInit,
  Flow as FlowInit,
  FlowRedirect as FlowRedirectInit,
  FlowState as FlowStateInit,
  FlowRef as FlowRefInit,
} from './flows'

class FlowLinkingError extends Error {}

export interface FlowCollection {
  [flowName: string]: Flow
}
export interface Flow {
  name: string
  states: { [key: string]: FlowState | FlowRedirect }
}
export interface FlowRedirect {
  name: string
  flow: Flow
  to: Array<FlowRef>
}
export interface FlowState {
  name: string
  flow: Flow
  url: string
  next: Array<FlowRef>

  progressStep?: number
  progressMax?: number
}
export interface FlowRef {
  state: FlowState | FlowRedirect
  if?: (state: FOState) => boolean
}

export default function processFlows(collection: FlowCollectionInit): FlowCollection {
  return (
    Object.keys(collection)
      .map((flowName) => {
        // Ensure each flow has it's name set
        const flowInit = collection[flowName]
        const flow: Flow = { states: {}, name: flowName }
        // And process its states
        const states = processFlowStates(flowInit, flow)
        flow.states = states
        return flow
      })
      // But we want to keep the flows as a map
      .reduce((memo, flow) => ({ ...memo, [flow.name]: flow }), {})
  )
}

interface ProcessFlowIntermediateState {
  name: string
  flow: Flow
  url?: string
  to?: Array<FlowRef | { state: string }>
  next?: Array<FlowRefInit>

  progressStep?: number
  progressMax?: number
}

function processFlowStates(collection: FlowInit, flow: Flow): { [stateName: string]: FlowState | FlowRedirect } {
  // Create the states
  const states = Object.keys(collection)
    .map(
      (stateName): ProcessFlowIntermediateState => {
        const stateInit = collection[stateName]
        const frStateInit = stateInit as FlowRedirectInit
        const fsStateInit = stateInit as FlowStateInit
        if (typeof stateInit === 'string') {
          // It is a redirect.
          return {
            name: stateName,
            flow: flow,
            to: [{ state: stateInit }], // Will be replaced with a ref during linking
          }
        } else if (typeof frStateInit.to === 'object') {
          // This is also a redirect
          return {
            name: stateName,
            flow: flow,
            to: frStateInit.to.map((ref) => ({
              ...ref,
              // 'state' will be replaced with a ref during linking
            })),
          }
        } else {
          return {
            name: stateName,
            flow: flow,
            url: formatUrl(fsStateInit.url),
            next: fsStateInit.next.map((ref) => ({
              ...ref,
              // 'state' will be replaced with a ref during linking
            })),
            progressStep: fsStateInit.progressStep,
            progressMax: fsStateInit.progressMax,
          }
        }
      }
    )
    // Add in a special '_end' state.
    .concat([{ name: END_STATE, url: '', flow: flow, next: [] }])
    // But we want to keep the states as a map
    .reduce((memo, state) => ({ ...memo, [state.name]: state }), {} as { [key: string]: any })

  // Link the states
  Object.values(states).forEach((state) => {
    if (typeof state.to === 'object') {
      // It is a redirect
      state.to.forEach((ref) => {
        const refState = states[ref.state]
        if (!refState) {
          throw new FlowLinkingError(
            `Failed to find reference state '${ref.state}' for 'to' rule in redirect state '${state.name}', in flow '${flow.name}'`
          )
        }
        if (isLoop(state, refState)) {
          throw new FlowLinkingError(
            `Redirect state '${state.name}' for flow '${flow.name}' creates a redirect loop with reference state '${ref.state}'.`
          )
        }
        ref.state = refState
      })
      // Must have at least one unconditional 'to', or be the END_STATE
      if (state.name != END_STATE && state.to.filter((ref) => typeof ref.if === 'undefined').length == 0) {
        throw new FlowLinkingError(
          `Redirect state '${state.name}' in flow '${flow.name}' has no unconditional 'to' reference`
        )
      }
    } else {
      // Normal state
      state.next.forEach((ref) => {
        const refState = states[ref.state]
        if (!refState) {
          throw new FlowLinkingError(
            `Failed to find reference state '${ref.state}' for next rule in state '${state.name}', in flow '${flow.name}'`
          )
        }
        ref.state = refState
      })
      // Must have at least one unconditional 'next', or be the END_STATE
      if (state.name != END_STATE && state.next.filter((ref) => typeof ref.if === 'undefined').length == 0) {
        throw new FlowLinkingError(`State '${state.name}' in flow '${flow.name}' has no unconditional 'next' reference`)
      }
    }
  })

  // Must have one and only one START_STATE
  if (Object.values(states).filter((state) => state.name == START_STATE).length != 1) {
    throw new FlowLinkingError(`Flow '${flow.name}' has no '${START_STATE}' state`)
  }

  // We need to make one more pass at the states to change their stateKey
  // from state.name to state.url for any FlowStates
  const newStates = Object.values(states).reduce((memo, state) => {
    const key = state.url || state.name

    // Don't allow duplicate keys.
    if (memo[key]) {
      throw new FlowLinkingError(
        `State '${state.name}' in flow '${flow.name}' specifies a duplicate url '${state.url}; urls must be unique in a single flow'`
      )
    }

    return { ...memo, [key]: state }
  }, {} as { [key: string]: FlowState | FlowRedirect })

  // States have been brought into type alignment at this point
  return newStates as { [stateKey: string]: FlowState | FlowRedirect }
}
function isLoop(state: FlowRedirect, refState: FlowRedirect): boolean {
  // If they're the same object, then we have a loop.
  if (refState == state) return true
  // If it isn't a redirect, then no loop.
  if (typeof refState.to !== 'object') return false

  // Test all to paths.
  return refState.to.reduce((memo, to) => {
    // Ignore conditionals for loop testing.
    // We may get a false-positive by ignoring them; we can revisit early
    // loop testing if that happens.
    return memo || isLoop(state, to.state as any)
  }, false)
}

function formatUrl(url: string): string {
  // Clear any leading slashes
  while (url.startsWith('/')) url = url.replace('/', '')
  // Clear 'onboarding' leading directory if specified.
  if (url.startsWith('onboarding/')) url = url.replace('onboarding/', '')
  // And any other leading slashes
  while (url.startsWith('/')) url = url.replace('/', '')
  return `/onboarding/${url}`
}
