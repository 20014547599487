import { useCallback, useEffect, useMemo } from 'react'
import { atom, DefaultValue, useRecoilState, useRecoilValue } from 'recoil'
import type { SetterOrUpdater } from 'recoil'

import { getJSON, patchJSON, postJSON, FetchResponse } from 'utils/fetch'
import { poll } from 'utils/poll'

import { userState, loginState, LoginState, UserStub, updateUser } from 'recoil/user'
import {
  Property,
  PropertyFeature,
  Renovation,
  HomeownerGoal,
  TopProject,
  updateProperty,
  propertiesState,
} from 'recoil/properties'
import { BuildableArea, PublicPropertyPotential } from 'recoil/publicProperty'
import { ProjectTemplate } from 'recoil/projectTemplates'
import { PropertyPlan } from 'recoil/propertyPlans'
import PropertyPlanAPI from 'apis/propertyPlanApi'
import { BookingDetails, MeetingAvailability } from 'recoil/advisorBooking'
import { searchGooglePlaces } from 'apis/googlePlaces'

const sessionStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    // Don't do this if we're SSR
    if (typeof window === 'undefined') return

    const savedValue = sessionStorage.getItem(key)
    if (savedValue != null) {
      setSelf({ ...JSON.parse(savedValue) })
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key)
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }

export type UserGoal = 'zoning' | 'upgrades' | 'history' | 'deal' | 'research' | 'offer' | 'proactive' | 'explore'

export type PropertyShare = {
  description: string
  image: string
  name: string
  user_id: number
}

export interface EditedProperty {
  bedrooms?: number
  bathrooms?: number
  half_bathrooms?: number
  square_footage?: number
  features?: PropertyFeature[]
  renovations?: Renovation[]
  home_goals?: HomeownerGoal[]
  open_home_goal?: string
}

export interface Suggestion {
  attom_id: string
  propertyaddresscity: string
  propertyaddressfull: string
  propertyaddressstate: string
  propertyaddresszip: string
  public_property_path: string
}

interface AggregateStatsPopularProject {
  project: string
  count: number
  project_template?: ProjectTemplate
}

interface AggregateStats {
  n_records: string
  median_lot_area: string
  n_lot_area: string
  median_building_area: string
  n_building_area: string
  median_stories: string
  n_stories: string
  median_rooms: string
  n_rooms: string
  median_bedrooms: string
  n_bedrooms: string
  median_bathrooms: string
  n_bathrooms: string
  median_yearbuilt: string
  n_yearbuilt: string
  median_tax_assessed_value_total: string
  n_tax_assessed_value_total: string
  median_tax_market_value_total: string
  n_tax_market_value_total: string
  median_tax_billed_amount: string
  n_tax_billed_amount: string
  percent_stories_eq_1: string
  percent_stories_eq_2: string
  percent_stories_ge_3: string
  percent_bedrooms_eq_1: string
  percent_bedrooms_eq_2: string
  percent_bedrooms_eq_3: string
  percent_bedrooms_ge_4: string
  percent_bathrooms_0_1: string
  percent_bathrooms_1_2: string
  percent_bathrooms_2_3: string
  percent_bathrooms_ge_3: string
  percent_year_built_ge_2000: string
  percent_year_built_ge_2010: string
  percent_year_built_ge_2020: string
  percent_owner_individual: string
  n_owner: string
  percent_owner_change_le_1y: string
  percent_owner_change_le_4y: string
  percent_owner_change_bt_4y_8y: string
  percent_owner_change_gt_8y: string
  n_owner_change: string
  percent_sold_le_1y: string
  percent_sold_le_4y: string
  percent_sold_bt_4y_8y: string
  percent_sold_gt_8y: string
  n_sold: string
  median_sale_amount_4m: string
  n_sale_amount_4m: string
  projects_4m: AggregateStatsPopularProject[]
  median_predicted_value: string
  n_predicted_value: string
}

export interface CityStats extends AggregateStats {
  property_address_state: string
  property_address_city: string
}

export interface ZipStats extends AggregateStats {
  property_address_zip: string
}

export const TIMES_TO_START: { key: TimeToStart; text: string; short: string; phrase: string }[] = [
  { key: 'renovation_asap', text: 'As soon as possible', short: 'ASAP', phrase: 'Start as soon as possible' },
  {
    key: 'renovation_within_year',
    text: 'Within the next year',
    short: 'Next year',
    phrase: 'Start within the next year',
  },
  {
    key: 'renovation_year_plus',
    text: 'Over 1 year from now',
    short: 'A year from now',
    phrase: 'Start 1+ year from now',
  },
  {
    key: 'renovation_need_help_deciding',
    text: 'I need help deciding',
    short: 'Need help',
    phrase: "Not sure when I'll start",
  },
  {
    key: 'not_renovating',
    text: "I'm not interested in renovations",
    short: 'Not interested in renovations',
    phrase: 'Not interested in renovations',
  },
]

// If you add a new value to this type, it will need to be added to "Primary Property Goals" in SFDC
// or something will break (hint: ping #data-eng)
export type TimeToStart =
  | 'renovation_asap'
  | 'renovation_within_year'
  | 'renovation_year_plus'
  | 'renovation_need_help_deciding'
  | 'not_renovating'

export interface PublicPropertyData {
  public_property: AttomTaxAssessor
  avm: AttomAvm
  buildable_area: BuildableArea
  potential: PublicPropertyPotential
  projects: TopProject[]
  marketplace: boolean
}

export type OutsideBidSelection = 'last_2_weeks' | 'last_6_months' | 'more_than_6_months' | 'no'
export type BudgetOption = 'below_45k' | '45k_to_100k' | '100k_to_150k' | '150k_to_200k' | 'above_200k'

export interface OnboardingState {
  flow?: string
  lastFlowState?: string

  avm?: AttomAvm
  homeownerType?: 'resident' | 'investment' | 'buy' | 'curious'
  firstScore?: number
  publicPropertyData?: PublicPropertyData
  attomId?: string
  property?: Property
  propertyShare?: PropertyShare
  selectedProjects?: Array<number>
  projectsToAdd?: ProjectTemplate[]
  projectsAdded?: ProjectTemplate[]
  goals?: UserGoal[]
  skipped?: boolean
  editedPropertyData?: EditedProperty
  projectTemplates?: ProjectTemplate[]
  propertyPlans?: PropertyPlan[]
  loadingPlans?: boolean

  unavailable?: boolean
  suggestions?: Array<AttomTaxAssessor>

  email?: string
  additionalInfo?: string
  signupMethod?: 'email' | 'apple' | 'google'
  phoneNumber?: string
  user?: UserStub
  flowStartedWithAddress?: boolean
  pollable_job_token?: string
  timeToStart?: TimeToStart
  meetingTime?: MeetingAvailability
  bookingDetails?: BookingDetails
  interestedInUnlistedProject?: boolean
  searchParams?: string
  enteredAddress?: string
  qualifiedProjects?: boolean
  marketplace?: boolean
  outsideBids?: OutsideBidSelection
  finalized?: boolean
  userErrors?: Record<string, string[]>
  placeId?: string
  budget?: BudgetOption
  reset?: 'address' | 'projects' | 'budget'
  country?: string
  screenBudget?: boolean
}

export const onboardingReadyState = atom<boolean>({
  key: 'OnboardingReady',
  default: false,
})
export const onboardingState = atom<OnboardingState>({
  key: 'Onboarding',
  default: {},
  effects_UNSTABLE: [sessionStorageEffect('onboardingState')],
})

export interface OnboardingAggregates {
  fetchedAttomId?: string
  cityStats?: CityStats
  zipStats?: ZipStats
}

export const aggregatesState = atom<OnboardingAggregates>({
  key: 'OnboardingFetchedStats',
  default: {},
})

export const fetchingAggregatesState = atom<boolean>({
  key: 'OnboardingFetchingStats',
  default: false,
})

interface PropertyPollResponse {
  error?: Record<string, unknown>
  result?: { id: number }
}

export const useOnboardingStats = (): {
  aggregateStats: OnboardingAggregates
} => {
  const [onboarding] = useRecoilState(onboardingState)
  const [aggregateStats, setAggregateStats] = useRecoilState(aggregatesState)
  const [fetchingAggregateStats, setFetchingAggregateStats] = useRecoilState(fetchingAggregatesState)

  useEffect(() => {
    const city = onboarding.publicPropertyData?.public_property.propertyaddresscity
    const state = onboarding.publicPropertyData?.public_property.propertyaddressstate
    const zip = onboarding.publicPropertyData?.public_property.propertyaddresszip
    const attomId = onboarding.publicPropertyData?.public_property.attom_id

    if (attomId && attomId != aggregateStats.fetchedAttomId && !fetchingAggregateStats) {
      setFetchingAggregateStats(true)
      ;(async () => {
        const cityStats = (await getJSON(`/api/v1/stats/city?city=${city}&state=${state}`)) as CityStats
        const zipStats = (await getJSON(`/api/v1/stats/zip?zip=${zip}`)) as ZipStats
        setAggregateStats({ ...aggregateStats, cityStats, zipStats, fetchedAttomId: attomId })
        setFetchingAggregateStats(false)
      })()
    }
  }, [
    onboarding.publicPropertyData?.public_property,
    fetchingAggregateStats,
    setFetchingAggregateStats,
    aggregateStats,
    setAggregateStats,
  ])

  return { aggregateStats }
}

const useOnboardingQueryString = (): {
  getQueryStringInt: (key: string) => number | undefined
  getQueryStringValue: (key: string) => string | undefined
} => {
  const { onboarding } = useOnboardingProperty()

  const params = useMemo(() => {
    if (!onboarding.searchParams) return

    return new URLSearchParams(onboarding.searchParams)
  }, [onboarding.searchParams])

  const getQueryStringInt = useCallback(
    (key: string) => {
      const raw = params?.get(key)
      if (!raw) return

      return parseInt(raw)
    },
    [params]
  )

  const getQueryStringValue = useCallback(
    (key: string) => {
      const raw = params?.get(key)
      if (!raw) return

      return raw
    },
    [params]
  )

  return {
    getQueryStringInt,
    getQueryStringValue,
  }
}

export const useOnboardingQueryStringInt = (key: string): number | undefined => {
  const { getQueryStringInt } = useOnboardingQueryString()

  return useMemo(() => getQueryStringInt(key), [key, getQueryStringInt])
}

export const useOnboardingQueryStringValue = (key: string): string | undefined => {
  const { getQueryStringValue } = useOnboardingQueryString()

  return useMemo(() => getQueryStringValue(key), [key, getQueryStringValue])
}

export const useOnboardingProperty = (): {
  onboarding: OnboardingState
  property: Property
  setOnboarding: SetterOrUpdater<OnboardingState>
  setEditedProperty: (property: EditedProperty | ((prevProperty: EditedProperty) => EditedProperty)) => void
  searchPublicProperty: ({
    placeId,
  }: {
    placeId: string
  }) => Promise<{ unavailableUrl?: string; publicPropertyData?: AttomTaxAssessor; marketplace?: boolean }>
  fetchPublicProperty: (attomId: string) => Promise<any>
  createProperty: () => Promise<{ pollableJobToken?: string }>
  createAndGetProperty: () => Promise<Property>
  createAndUpdateProperty: (body: Record<string, any>) => Promise<Property>
  fetchProperty: (id: number) => Promise<Property>
  updateProperty: (body: Record<string, any>) => Promise<Property>
  clearOnboarding: (preserveFlow?: boolean) => Promise<void>
  updateUserOnPropertyCreate: (propertyId) => Promise<void>
  backfillAlerts: (propertyId: number) => Promise<FetchResponse>
  addProjects: () => Promise<void>
  finalize: () => Promise<void>
} => {
  const [onboarding, setOnboarding] = useRecoilState(onboardingState)
  const [user, setUser] = useRecoilState(userState)
  const userLoginState = useRecoilValue(loginState)
  const properties = useRecoilValue(propertiesState)

  // Property should be a merge of our actual property and our edited property.
  const property: Property = useMemo(() => {
    return { ...onboarding.property, ...onboarding.editedPropertyData } as Property
  }, [onboarding.property, onboarding.editedPropertyData])

  const backfillAlerts = useCallback((propertyId: number) => {
    return postJSON(`/api/v1/properties/${propertyId}/backfill_alerts`, {})
  }, [])

  const updateUserOnPropertyCreate = useCallback(
    async (propertyId) => {
      // Don't bother if we have no user (not logged in)
      if (userLoginState != LoginState.LoggedIn || !user) return
      // Don't bother if we already have an entered_address. This means
      // it isn't their first time through
      if (user.entered_address) return

      // If the user exists, update the user based on a property being
      // created.
      const userBody = {
        entered_address: `property_id:${propertyId}`,
        goals: onboarding.goals,
        homeowner_type: onboarding.homeownerType,
        referred_by_user: onboarding.propertyShare?.user_id,
      } as any

      const response = await postJSON('/api/v1/users/me', { user: userBody })
      if (response.isError) throw 'Failed to update user'

      setUser(response.jsonBody.user)
    },
    [setUser, user, userLoginState, onboarding]
  )

  const addProjects = useCallback(async () => {
    if (!onboarding.property) return
    if (!onboarding.propertyPlans?.length) return
    if (!onboarding.projectsToAdd) return

    const plan = onboarding.propertyPlans[0]
    const propertyId = onboarding.property.id

    const newProjects = onboarding.projectsToAdd.filter((toAdd) => {
      if (!onboarding.projectsAdded) return true

      return !onboarding.projectsAdded.some((added) => added.id == toAdd.id)
    })

    const api = new PropertyPlanAPI(propertyId)
    const promises = newProjects.map((project) => {
      return api.createProject(plan.id, {
        project_template_id: project.id,
      })
    })
    await Promise.all(promises)
    setOnboarding((onboarding) => {
      const projectsToAdd = onboarding.projectsToAdd || []
      return { ...onboarding, projectsAdded: [...projectsToAdd, ...newProjects] }
    })
  }, [onboarding.property, onboarding.propertyPlans, onboarding.projectsToAdd, onboarding.projectsAdded, setOnboarding])

  const resetOnboarding = useCallback(
    (newOb: OnboardingState, preserveFlow: boolean) => {
      setOnboarding((ob) => ({ ...newOb, ...(preserveFlow ? { flow: ob.flow, lastFlowState: ob.lastFlowState } : {}) }))
    },
    [setOnboarding]
  )

  const finalize = useCallback(async () => {
    const matchingProperty = (properties || []).find((prop) => prop.attom_id == onboarding.attomId)
    if (!matchingProperty) {
      return
    }

    const requests: Promise<any>[] = []

    requests.push(
      // Update our user one last time.
      // Email only cares about completed_profile,
      // but SSO needs the rest, so submit them again.
      updateUser({
        completed_profile: true,
        entered_address: onboarding.unavailable ? onboarding.enteredAddress : `attom_id:${onboarding.attomId}`,
        goals: onboarding.goals,
        homeowner_type: onboarding.homeownerType,
        referred_by_user: onboarding.propertyShare?.user_id,
        signup_message: onboarding.additionalInfo,
        outside_bids_status: onboarding.outsideBids,
      })
    )

    if (matchingProperty) {
      // Update property with our goals
      requests.push(
        updateProperty(matchingProperty.id, {
          home_goals: property?.home_goals || [onboarding.timeToStart],
          open_home_goal: property?.open_home_goal,
        })
      )
      requests.push(
        // Add in any projects
        (async () => {
          const selectedProjects = onboarding.selectedProjects || []
          if (selectedProjects.length > 0) {
            const api = new PropertyPlanAPI(matchingProperty.id)

            // Grab our first property plan
            const plans = await api.listPropertyPlans()
            if (plans.length > 0) {
              // Add projects to our property plan.
              const promises = selectedProjects.map((projectId) => {
                return api.createProject(plans[0].id, {
                  project_template_id: projectId,
                })
              })
              await Promise.all(promises)
            }
          }
        })()
      )
    }

    const results = await Promise.all(requests)

    const updateUserResult = results[0]
    setOnboarding((prev) => ({ ...prev, user: updateUserResult.user, finalized: true }))
  }, [onboarding, property, properties, setOnboarding])

  return {
    onboarding,
    property,
    setOnboarding,
    setEditedProperty: useCallback(
      (property: EditedProperty | ((prevProperty: EditedProperty) => EditedProperty)) => {
        setOnboarding((onboarding) => {
          if (typeof property !== 'function') {
            property = () => property as EditedProperty
          }
          const edited = property(onboarding.editedPropertyData as any)
          return {
            ...onboarding,
            editedPropertyData: edited,
          }
        })
      },
      [setOnboarding]
    ),
    searchPublicProperty: useCallback(
      async ({ placeId }: { placeId: string }) => {
        const response = await searchGooglePlaces({ placeId })

        if (response.unavailable) {
          setOnboarding((onboarding) => ({ ...onboarding, unavailable: true, suggestions: response.suggestions }))
          const queryString = new URLSearchParams(response.r_query).toString()
          return { unavailableUrl: `/unavailable?${queryString}`, marketplace: response.marketplace }
        } else {
          // This here is a reset
          resetOnboarding(
            {
              attomId: response.public_property_data.public_property.attom_id,
              publicPropertyData: response.public_property_data,
            },
            true
          )
          return {
            publicPropertyData: response.public_property_data.public_property,
            marketplace: response.marketplace,
          }
        }
      },
      [resetOnboarding, setOnboarding]
    ),
    fetchPublicProperty: useCallback(
      async (attomId: string) => {
        try {
          const response = (await getJSON(`/public_properties/${attomId}`)) as any
          resetOnboarding(
            {
              attomId,
              flowStartedWithAddress: true,
              publicPropertyData: response,
            },
            true
          )
          return response
        } catch (e) {
          console.error(e)
        }
      },
      [resetOnboarding]
    ),
    createProperty: useCallback(async () => {
      const query = `?attom_id=${onboarding.attomId}`
      const response = await postJSON(`/api/v1/properties${query}`, {})
      const pollableJobToken = response.jsonBody.pollable_job_token as string
      return { pollableJobToken }
    }, [onboarding]),
    createAndGetProperty: useCallback(async (): Promise<Property> => {
      // Create
      const query = `?attom_id=${onboarding.attomId}`
      const createResponse = await postJSON(`/api/v1/properties${query}`, {})
      // If we failed to create, throw.
      if (createResponse.isError) throw 'Failed to create property'

      const pollableJobToken = createResponse.jsonBody.pollable_job_token as string

      // Poll for completion
      const pollResponse = await poll<PropertyPollResponse>({
        fn: async () => await getJSON<PropertyPollResponse>(`/api/v1/pollable_jobs/${pollableJobToken}`),
        validate: (pollResponse: PropertyPollResponse) => !!(pollResponse.error || pollResponse.result),
        interval: 2000,
        maxAttempts: 10,
      })
      if (pollResponse.error) throw 'Failed to create property (poll)'

      const propertyId = pollResponse.result?.id
      if (!propertyId) throw 'Failed to create property (propertyId)'

      await updateUserOnPropertyCreate(propertyId)
      backfillAlerts(propertyId)

      // Get property
      const property = await getJSON<Property>(`/api/v1/properties/${propertyId}`)
      setOnboarding((onboarding) => {
        const newOnboarding = { ...onboarding, property: property }
        if (property.score) newOnboarding.firstScore = property.score
        return newOnboarding
      })
      return property
    }, [backfillAlerts, onboarding, setOnboarding, updateUserOnPropertyCreate]),
    createAndUpdateProperty: useCallback(
      async (body: Record<string, any>): Promise<Property> => {
        // Create
        const query = `?attom_id=${onboarding.attomId}`
        const createResponse = await postJSON(`/api/v1/properties${query}`, {})
        // If we failed to create, throw.
        if (createResponse.isError) throw 'Failed to create property'

        const pollableJobToken = createResponse.jsonBody.pollable_job_token as string

        // Poll for completion
        const pollResponse = await poll<PropertyPollResponse>({
          fn: async () => await getJSON<PropertyPollResponse>(`/api/v1/pollable_jobs/${pollableJobToken}`),
          validate: (pollResponse: PropertyPollResponse) => !!(pollResponse.error || pollResponse.result),
          interval: 2000,
          maxAttempts: 10,
        })
        if (pollResponse.error) throw 'Failed to create property (poll)'

        await updateUserOnPropertyCreate(pollResponse.result?.id)

        // If body contains 'renovations', we need to convert this to 'renovation_attributes'
        if (body['renovations']) {
          body = { ...body, renovations_attributes: body['renovations'] }
          delete body['renovations']
        }
        // Update property
        const updateResponse = await patchJSON(`/api/v1/properties/${pollResponse.result?.id}`, { property: body })
        if (updateResponse.isError) throw 'Failed to update property'

        const property: Property = updateResponse.jsonBody
        setOnboarding((onboarding) => {
          const newOnboarding = { ...onboarding, property: property }
          if (property.score) newOnboarding.firstScore = property.score
          return newOnboarding
        })
        return property
      },
      [onboarding, setOnboarding, updateUserOnPropertyCreate]
    ),
    fetchProperty: useCallback(
      async (id: number) => {
        const property = await getJSON<Property>(`/api/v1/properties/${id}`)
        setOnboarding(
          (onboarding) =>
            ({
              ...onboarding,
              firstScore: property.score,
              property,
            }) as any
        )
        return property
      },
      [setOnboarding]
    ),
    updateProperty: useCallback(
      async (body: Record<string, any>): Promise<Property> => {
        // If body contains 'renovations', we need to convert this to 'renovation_attributes'
        if (body['renovations']) {
          body = { ...body, renovations_attributes: body['renovations'] }
          delete body['renovations']
        }
        const response = await patchJSON(`/api/v1/properties/${onboarding.property?.id}`, { property: body })
        if (response.isError) throw 'Failed to update property'

        const property: Property = response.jsonBody

        await updateUserOnPropertyCreate(property?.id)
        setOnboarding((onboarding) => ({
          ...onboarding,
          property: property,
        }))
        return property
      },
      [onboarding, setOnboarding, updateUserOnPropertyCreate]
    ),
    clearOnboarding: useCallback(
      async (preserveFlow?: boolean) => {
        resetOnboarding({}, !!preserveFlow)
      },
      [resetOnboarding]
    ),
    updateUserOnPropertyCreate,
    addProjects,
    finalize,
    backfillAlerts,
  }
}
