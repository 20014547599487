import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import SparklesThree from 'svgs/sparkles-three'
import styles from './styles.module.scss'
import cx from 'classnames'
import { formatCurrency } from 'utils/formatting'
import ArrowCircleDown from 'svgs/arrow-circle-down'
import { Link } from 'react-router-dom'

export interface LinkAddress {
  to?: string
  href?: string
}

interface InternalOrExternalLinkProps {
  linkAddress: LinkAddress
  onClick?: () => void
  className?: string
}

const InternalOrExternalLink: FC<PropsWithChildren<InternalOrExternalLinkProps>> = ({
  linkAddress,
  children,
  className,
  onClick,
}) => {
  return (
    <>
      {linkAddress.to ? (
        <Link to={linkAddress.to} className={className} onClick={onClick}>
          {children}
        </Link>
      ) : linkAddress.href ? (
        <a href={linkAddress.href} className={className} onClick={onClick}>
          {children}
        </a>
      ) : undefined}
    </>
  )
}

interface LinkIfAvailableProps {
  linkAddress?: LinkAddress
  onClick?: () => void
  dontLinkWhenLoggedIn?: boolean
}

const LinkIfAvailable: FC<PropsWithChildren<LinkIfAvailableProps>> = ({
  linkAddress,
  onClick,
  dontLinkWhenLoggedIn,
  children,
}) => {
  if (!linkAddress) return <>{children}</>

  return (
    <>
      <span data-hide-on={dontLinkWhenLoggedIn ? 'logged-in' : ''}>
        <InternalOrExternalLink linkAddress={linkAddress} onClick={onClick}>
          {children}
        </InternalOrExternalLink>
      </span>
      <span className="tw-hidden" data-hide-on={dontLinkWhenLoggedIn ? 'logged-out' : ''}>
        {children}
      </span>
    </>
  )
}

const AdditionalProjectsLabel: FC<{
  additionalProjectsLabel?: ReactNode
}> = ({ additionalProjectsLabel }) => {
  return (
    <div>
      {additionalProjectsLabel ?? (
        <>
          <b>{'10+ more projects'}</b>
          <span>{' are included in your free account.'}</span>
        </>
      )}
    </div>
  )
}

interface PropertyPotentialChartProps {
  className?: string
  currentValue: number
  currentValueLink?: LinkAddress
  potentialValue: number
  potentialValueLink?: LinkAddress
  additionalProjectsLabel?: ReactNode
  additionalProjectsLink?: LinkAddress
  projectSuggestions: {
    id: number
    name: string
    valueChange: number
  }[]
  getLinkForProject?: (id: number) => LinkAddress | undefined
  onProjectLinkClicked?: (location: string) => () => void
  dontLinkWhenLoggedIn?: boolean
}

const PropertyPotentialChart: FC<PropertyPotentialChartProps> = ({
  additionalProjectsLabel,
  projectSuggestions,
  className,
  currentValue: value,
  potentialValue: potential,
  getLinkForProject = () => undefined,
  additionalProjectsLink,
  currentValueLink,
  potentialValueLink,
  onProjectLinkClicked,
  dontLinkWhenLoggedIn,
}) => {
  const projectsToShow = useMemo(() => {
    return [projectSuggestions[0], projectSuggestions[projectSuggestions.length - 1]]
  }, [projectSuggestions])

  return (
    <div className={cx(styles.projectsTrack, className)}>
      <div className={styles.trackContainer}>
        <div className={styles.track} />

        <div className={styles.valueAndPotentialPips}>
          <LinkIfAvailable
            linkAddress={currentValueLink}
            dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}
            onClick={onProjectLinkClicked && onProjectLinkClicked('current-value')}
          >
            <div className={cx(styles.pip, styles.valuePip)} />
          </LinkIfAvailable>
          <LinkIfAvailable
            linkAddress={potentialValueLink}
            dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}
            onClick={onProjectLinkClicked && onProjectLinkClicked('potential-value')}
          >
            <div className={cx(styles.pip, styles.potentialPip)}>
              <SparklesThree />
            </div>
          </LinkIfAvailable>
        </div>

        <div className={styles.projectPips}>
          {projectsToShow.map((projectSuggestion) => {
            return (
              <span key={projectSuggestion.id}>
                <LinkIfAvailable
                  linkAddress={getLinkForProject(projectSuggestion.id)}
                  onClick={onProjectLinkClicked && onProjectLinkClicked('project-dot')}
                  dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}
                >
                  <div className={styles.pip} />
                </LinkIfAvailable>
              </span>
            )
          })}
          <LinkIfAvailable
            linkAddress={additionalProjectsLink}
            onClick={onProjectLinkClicked && onProjectLinkClicked('project-dot-additional')}
            dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}
          >
            <div className={styles.pip} />
          </LinkIfAvailable>
        </div>

        <div className={styles.projects}>
          {projectsToShow.map((projectSuggestion) => {
            return (
              <div className={styles.project} key={projectSuggestion.id}>
                <LinkIfAvailable
                  linkAddress={getLinkForProject(projectSuggestion.id)}
                  onClick={onProjectLinkClicked && onProjectLinkClicked('project')}
                  dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}
                >
                  <p className={styles.projectValue}>{`Adds ${formatCurrency(projectSuggestion.valueChange)}`}</p>
                </LinkIfAvailable>

                <p>{projectSuggestion.name}</p>
              </div>
            )
          })}
          <div className={styles.project}>
            {additionalProjectsLink ? (
              <>
                <div data-hide-on={dontLinkWhenLoggedIn ? 'logged-in' : ''}>
                  <InternalOrExternalLink
                    linkAddress={additionalProjectsLink}
                    onClick={onProjectLinkClicked && onProjectLinkClicked('project-additional')}
                  >
                    <div className={styles.potentialCta}>
                      <AdditionalProjectsLabel additionalProjectsLabel={additionalProjectsLabel} />
                      <div className={styles.icon}>
                        <ArrowCircleDown />
                      </div>
                    </div>
                  </InternalOrExternalLink>
                </div>
                <div className="tw-hidden" data-hide-on={dontLinkWhenLoggedIn ? 'logged-out' : ''}>
                  <AdditionalProjectsLabel additionalProjectsLabel={additionalProjectsLabel} />
                </div>
              </>
            ) : (
              <AdditionalProjectsLabel additionalProjectsLabel={additionalProjectsLabel} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.projectsTrackHeaders}>
        <div>
          <LinkIfAvailable linkAddress={currentValueLink} dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}>
            <div>{'Current Value'}</div>
            <div className={styles.currentValue}>{formatCurrency(value)}</div>
          </LinkIfAvailable>
        </div>
        <div className={styles.projectPotentialHeader}>
          <LinkIfAvailable linkAddress={potentialValueLink} dontLinkWhenLoggedIn={dontLinkWhenLoggedIn}>
            <div>{'Potential Value'}</div>
            <div className={styles.potentialValue}>{formatCurrency(potential)}</div>
          </LinkIfAvailable>
        </div>
      </div>
    </div>
  )
}

export default PropertyPotentialChart
