import React, { FC } from 'react'
import cx from 'classnames'

import styles from './Arrow.module.scss'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="36"
    viewBox="0 0 36 36"
    width="36"
    className={cx(styles.arrow, className)}
  >
    <g stroke="currentColor" strokeWidth="3" strokeLinecap="round" fill="none" fillRule="evenodd">
      <path
        d="m7.24395,30.74644c-1.40351,-2.7569 -2.34727,-14.30684 0.24561,-17.30869c2.59288,-3.00185 2.89785,-3.86204 23.2456,-4.62406"
        className={styles.shaft}
      />
      <path
        d="m18.35167,4.71887c4.50021,4.26006 12.57186,3.81551 12.67476,4.28834c0.1029,0.47282 -5.81791,2.05402 -11.33711,3.13561"
        className={styles.head}
      />
    </g>
  </svg>
)

export default Svg
