import React, { FC, useState, useEffect, useCallback, useRef } from 'react'
import { useLocation, useHistory } from 'react-router'

import { getJSON } from 'utils/fetch'
import { captureUTMParams } from 'utils/analytics'

import { useOnboardingProperty, PropertyShare } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { DEFAULT_FLOW, DEFAULT_INTERNAL_FLOW, END_STATE, OnboardingStartState } from 'recoil/onboardingFlows/states'
import { useProperties } from 'recoil/properties'

import { useAuth, LoginState } from 'non-rendering/Authentication'

import Loading from 'components/Loading'

const OnboardingStarter: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { state } = useAuth()
  const { properties } = useProperties()
  const { setOnboarding, clearOnboarding, fetchPublicProperty } = useOnboardingProperty()
  const { lastState, states, start, jump } = useFlow()

  const [transitionReady, setTransitionReady] = useState(false)
  const [lock, setLock] = useState(false)
  const [onboardingWasReset, setOnboardingWasReset] = useState(false)
  const transitionLock = useRef(false)

  const getFlowFromParams = useCallback((params: URLSearchParams) => {
    const flowParam = params.get('flow')
    const forceParam = params.get('force')
    if (forceParam) return flowParam
    if (flowParam === 'camv2') return 'camv3'
    return flowParam
  }, [])

  useEffect(() => {
    if (!transitionReady && !lock) {
      setLock(true)
      const run = async () => {
        // Pull attomId from state OR querystring
        const startState = location.state as OnboardingStartState
        const params = new URLSearchParams(location.search)
        // Prioritize query string
        const attomId: string | null = params.get('attom_id') || startState?.attomId || null

        if (attomId) {
          // If the user has arrived asking about a particular property,
          // look it up to make sure it is servicable.
          await fetchPublicProperty(attomId)
          if (state == LoginState.LoggedIn) {
            // We're logged in, so we need to show them their property if it matches
            const matchingProperty = (properties || []).find((property) => property.attom_id == attomId)

            if (matchingProperty) {
              // If our attom_id matches an existing property, just redirect to that
              // property.
              return history.replace(`/properties/${matchingProperty.id}/dashboard`)
            }
          }
          setOnboardingWasReset(true)
        }

        // If we received a signal to start fresh, do so.
        const startFresh = params.has('n')
        if (startFresh) {
          clearOnboarding()
          setOnboardingWasReset(true)
        }

        // Before transitioning, but after we've reset onboarding, include the
        // share.
        const shareId: string | null = params.get('share_id')
        if (shareId) {
          const propertyShare = await getJSON<PropertyShare>(`/api/v1/property_shares/${shareId}`)
          setOnboarding((onboarding) => ({ ...onboarding, propertyShare }))
        }

        setTransitionReady(true)
      }
      run()
    }
  }, [fetchPublicProperty, history, location, properties, state, transitionReady, lock, setOnboarding, clearOnboarding])

  useEffect(() => {
    if (transitionReady && !transitionLock.current) {
      transitionLock.current = true

      // Before we go anywhere, ensure we've captured our UTM parameters
      // We don't need them for anything here, but they will get lost on the
      // transition if we haven't called trackPage yet.
      captureUTMParams()

      // Pull attomId from state OR querystring
      const params = new URLSearchParams(location.search)
      const startState = location.state as OnboardingStartState
      // Prioritize query string
      const flow: string | null = getFlowFromParams(params) || startState?.flow || null

      // For flows, first see if we were specifically asked to use a flow.
      if (flow == 'event' || flow == 'homeshow') {
        window.location.href = '/event-signup'
        return
      } else if (flow) {
        // Since we're starting a new flow reset if we haven't already
        if (!onboardingWasReset) clearOnboarding()
        setOnboarding((current) => {
          return { ...current, searchParams: location.search }
        })
        // If a flow was specified, start a new flow using that.
        start(flow)
      } else if (
        !onboardingWasReset &&
        lastState &&
        lastState.name != END_STATE &&
        states.length > 0 &&
        states.some((oState) => oState.name == lastState.name)
      ) {
        // If they were last in a valid flow state, redirect to that.
        // (END_STATE is not a valid last flow state for here)
        jump(lastState.url)
      }
      // Otherwise, start them at the beginning
      else {
        // Since we're starting a new flow reset if we haven't already
        if (!onboardingWasReset) clearOnboarding()
        if (state == LoginState.LoggedIn) start(DEFAULT_INTERNAL_FLOW)
        else start(DEFAULT_FLOW)
      }
    }
  }, [
    start,
    state,
    lastState,
    states,
    jump,
    location,
    setOnboarding,
    clearOnboarding,
    properties,
    history,
    fetchPublicProperty,
    transitionReady,
    onboardingWasReset,
    getFlowFromParams,
  ])

  return <Loading />
}

export default OnboardingStarter
