import React, { FC } from 'react'
import Tooltip from 'react-tooltip'

import styles from './styles.module.scss'

const TooltipBase: FC = () => {
  return <Tooltip className={styles.tooltipBase} />
}

export default TooltipBase
