import Loading from 'components/Loading'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import React, { FC, useCallback, useMemo, useState } from 'react'

import triggerMNTNConversion from 'non-rendering/triggerMNTNConversion'
import { useCalendlyEventListener, InlineWidget as CalendlyInlineWidget } from 'react-calendly'
import {
  BookingDetails,
  findAddressFromCalendlyBooking,
  findPhoneNumberFromCalendlyBooking,
  useAdvisorBooking,
  useOnboardingCalendlyEvent,
} from 'recoil/advisorBooking'
import { useOnboardingProperty, useOnboardingQueryStringValue } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { SignUpState, signUp, useUser } from 'recoil/user'

import styles from './styles.module.scss'
import { useSetRecoilState } from 'recoil'
import { alertState } from 'recoil/alert'
import { autocompleteGooglePlaces, searchGooglePlaces } from 'apis/googlePlaces'
import { prepareBoth } from 'utils/analyticsV2'
import { useUtmParams } from 'utils/analytics'

const [trackClick, _] = prepareBoth({ family: 'onboarding', screen: 'complete-booking' })

const CompleteBooking: FC = () => {
  const { onboarding, setOnboarding } = useOnboardingProperty()
  const { user } = useUser()
  const calendlyUri = useOnboardingCalendlyEvent()
  const { fetchBookingDetails } = useAdvisorBooking(calendlyUri)
  const { next } = useFlow()
  const [isLoading, setIsLoading] = useState(false)
  const setAlert = useSetRecoilState(alertState)
  const flow = useOnboardingQueryStringValue('flow')

  const widgetUrl = useMemo(() => {
    if (!onboarding.meetingTime) return

    const fullName = user?.full_name || onboarding.user?.full_name
    const email = user?.email || onboarding.user?.email
    const phoneNumber = user?.phone_number || onboarding.user?.phone_number

    const params = {
      name: fullName || '',
      email: email && !email.endsWith('@privaterelay.appleid.com') ? email : '',
      hide_gdpr_banner: '1',
    }

    if (process.env.ONBOARDING_V3_CALENDLY_PHONE_NUMBER_KEY && phoneNumber) {
      params[process.env.ONBOARDING_V3_CALENDLY_PHONE_NUMBER_KEY] = phoneNumber
    }

    return `${onboarding.meetingTime.scheduling_url}?${new URLSearchParams(params).toString()}`
  }, [onboarding.meetingTime, user, onboarding.user])

  const getHomeShowEnteredAddress = useCallback(async (bookingDetails: BookingDetails): Promise<string | undefined> => {
    const address = findAddressFromCalendlyBooking(bookingDetails)
    if (!address) return

    try {
      const { items: predictions } = await autocompleteGooglePlaces(address)

      // try to get a prediction that matches
      const prediction = predictions.find((pred) => {
        // check if the prediction is close to the value
        // if we don't do this we will autosubmit some crazy values
        const valueParts = address.split(', ')
        const predictionParts = pred.address.split(', ')

        // they should each have at least two address parts
        if (valueParts.length < 2 || predictionParts.length < 2) return false

        // and they (typically - street address & city) should match
        if (valueParts[0].toLowerCase() != predictionParts[0].toLowerCase()) return false
        if (valueParts[1].toLowerCase() != predictionParts[1].toLowerCase()) return false

        return true
      })
      if (!prediction) return

      const result = await searchGooglePlaces({ placeId: prediction.place_id })
      const attom_id = result.unavailable ? result.r_query.id : result.public_property_data.public_property.attom_id
      if (result.unavailable && !attom_id) return `place_id:${prediction.place_id}`

      return `attom_id:${attom_id}`
    } catch (e) {
      console.error(e)
    }
  }, [])

  const nonAppSignupType = useMemo(() => {
    if (flow == 'homeshow' || flow == 'event') return 'homeshow'
    else if (onboarding.unavailable) return 'unserviceable'
  }, [flow, onboarding.unavailable])

  const utmParams = useUtmParams()

  const signupUser = useCallback(
    async (bookingDetails: BookingDetails) => {
      const attomId = !onboarding.property ? onboarding.attomId : null
      const isEvent = flow == 'event' || flow == 'homeshow'
      const entered_address = isEvent
        ? await getHomeShowEnteredAddress(bookingDetails)
        : attomId
          ? `attom_id:${attomId}`
          : onboarding.enteredAddress

      try {
        const request = {
          full_name: bookingDetails.invitee.name,
          phoneNumber: findPhoneNumberFromCalendlyBooking(bookingDetails),
          email: bookingDetails.invitee.email,
          entered_address,
          ambassador_name: '',
          onboarding: onboarding,
          propertyId: onboarding.property?.id,
          attomId: attomId || '',
          placeId: onboarding.placeId,
          non_app_signup_type: nonAppSignupType,
          bypass_signin: isEvent,
          utmParams,
        } as SignUpState

        const response = await signUp(request)
        setOnboarding((onboarding) => ({
          ...onboarding,
          bookingDetails,
          user: response?.user,
          pollable_job_token: response?.pollable_job_token,
        }))

        // If we've booked the user and signed them up, trigger the
        // conversion libraries for MNTN
        triggerMNTNConversion(response?.user.id)
      } catch (error) {
        setOnboarding((onboarding) => ({ ...onboarding, bookingDetails, userErrors: error }))
        if (error.base) {
          setAlert({ type: 'ERROR', message: error.base.join(', '), id: Date.now() })
        }
      } finally {
        setIsLoading(false)
        next()
      }
    },
    [onboarding, flow, getHomeShowEnteredAddress, nonAppSignupType, utmParams, setOnboarding, setAlert, next]
  )

  const handleEventScheduled = useCallback(
    async (eventUri: string, inviteeUri: string) => {
      setIsLoading(true)
      const bookingDetails = await fetchBookingDetails(eventUri, inviteeUri)
      trackClick({
        code: 'advisor-schedule',
        family: 'advisory',
        section: 'advisory',
        flexfield_1: { meeting_time: bookingDetails.event.start_time },
      })
      signupUser(bookingDetails)
    },
    [fetchBookingDetails, signupUser]
  )

  useCalendlyEventListener({
    onEventScheduled: (e) => handleEventScheduled(e.data.payload.event.uri, e.data.payload.invitee.uri),
  })

  return (
    <Wrapper
      allowOverflow={!isLoading && !!widgetUrl} // only when it's in form mode, when loading let it be normal
      backStyle="disabled"
      forwardStyle={onboarding.bookingDetails ? 'active' : 'disabled'}
      className={styles.wrapper}
      innerClassName={styles.inner}
    >
      {isLoading || !widgetUrl ? <Loading /> : <CalendlyInlineWidget url={widgetUrl} />}
    </Wrapper>
  )
}

export default CompleteBooking
