// Third party dependencies
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import ImgSparkles from 'svgs/property-plan/sparkles'

// Components

// Styles
import styles from './styles.module.scss'

const PurchaseResponse: FC<{ title?: string; description?: any }> = ({ title, description }) => {
  return (
    <section className={styles.purchaseResponse}>
      <ImgSparkles className={styles.sparkles} />
      <h2>{title ?? 'Thanks for purchasing your package'}</h2>
      <p>
        {description ?? (
          <>
            {'Please add important information about your property '}
            <Link to="/edit">{'here'}</Link>
            {
              ", this will give you a more accurate analysis. Once you've added information, we'll assess your property and deliver the report via email."
            }
          </>
        )}
      </p>
    </section>
  )
}

export default PurchaseResponse
