import { ProjectTemplateStub } from 'recoil/projectTemplateStubs'
import { getJSON } from 'utils/fetch'

export interface ListProjectTemplatesOptions {
  attom_id?: string
  zip5?: string
  zip3?: string
  city?: string
  state?: string
}

export default class ProjectTemplateStubsApi {
  async listProjectTemplates(options?: ListProjectTemplatesOptions): Promise<Array<ProjectTemplateStub>> {
    let path = '/api/v1/project_templates'
    const params = new URLSearchParams()
    if (options?.attom_id) params.append('attom_id', options.attom_id)
    if (options?.zip5) params.append('zip5', options.zip5)
    if (options?.zip3) params.append('zip3', options.zip3)
    if (options?.city) params.append('city', options.city)
    if (options?.state) params.append('state', options.state)

    const sParams = params.toString()
    if (sParams) path = `${path}?${sParams}`
    return await getJSON(path)
  }
}
