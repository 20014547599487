import React, { FC, useState, useEffect, useCallback } from 'react'
import { loadStripe } from '@stripe/stripe-js/pure'
import classNames from 'classnames'
import { track } from 'utils/analytics'

import { usePackages } from 'recoil/packages'
import { PackagePurchase, usePackagePurchases, PackagePurchaseStatusType } from 'recoil/packagePurchases'

import HandleURLQuery from 'non-rendering/HandleURLQuery'

import Modal from 'components/NewModal'
import DynamicContent from 'components/DynamicContent'

import FakePackage from './FakePackage'
import Package, { PackageType } from './Package'

import styles from './styles.module.scss'
import packageStyles from './Package.module.scss'
import { useRecoilValue } from 'recoil'
import { userState } from 'recoil/user'
import PurchaseResponse from 'pages/Packages/PurchaseResponse'
import { Payment } from 'recoil/payments'

const PACKAGE_TYPES = [PackageType.One, PackageType.Two, PackageType.Three, PackageType.Four]

interface Props {
  onSuccessfulPurchase: (number) => void
}

const PackageList: FC<Props> = ({ onSuccessfulPurchase }) => {
  const { packages: packages, isLoading: isPackagesLoading } = usePackages()
  const {
    packagePurchases,
    isLoading: isPackagePurchasesLoading,
    addPackagePurchase,
    updatePackagePurchase,
  } = usePackagePurchases()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)

  const [packageId, setPackageId] = useState<number | null>(null)
  const [purchase, setPurchase] = useState<PackagePurchase | null>(null)
  const [payment, setPayment] = useState<Payment | null>(null)
  const [purchaseError, setPurchaseError] = useState(false)
  const [freePackageModalVisible, setFreePackageModalVisible] = useState(false)
  const [freePackageName, setFreePackageName] = useState<string | null>(null)

  const user = useRecoilValue(userState)

  // Deal with 'loading' of resources.
  useEffect(() => {
    if (!isPackagesLoading && !isPackagePurchasesLoading) {
      setIsSwapping(true)
      setTimeout(() => setIsLoaded(true), 200)
    }
  }, [isPackagesLoading, isPackagePurchasesLoading])

  const onBuy = useCallback(
    async (packageId: number) => {
      // First, begin buying in order to disable our buttons.
      setPackageId(packageId)

      // TODO: Handle failure.
      // Ideally both the call itself fails
      // or we fail to create a purchase
      // in the expected state.
      // Then create the purchase.
      try {
        const purchase = await addPackagePurchase({ package_id: packageId })
        if (!purchase) return
        track('click buy report', {
          purchaseId: purchase.id,
          status: purchase.status,
          reportId: purchase.package_id,
          price: purchase.price?.price,
        })
        setPurchase(purchase)
        setPayment(purchase.payments[purchase.payments.length - 1])
      } catch (e) {
        setPurchaseError(true)
        setPackageId(null)
        setPayment(null)
      }
    },
    [addPackagePurchase, setPurchase]
  )

  const onBuyFree = useCallback(
    async (packageId: number) => {
      setPackageId(packageId)
      await addPackagePurchase({ package_id: packageId })
      const packageObject = packages.find((r) => r.id === packageId)
      setFreePackageName(packageObject?.name || '')
      setPackageId(null)
      setFreePackageModalVisible(true)
    },
    [addPackagePurchase, packages]
  )
  const onFreePackageModalClose = useCallback(() => setFreePackageModalVisible(false), [])

  useEffect(() => {
    ;(async () => {
      if (purchase && payment) {
        // Load in stripe
        const stripe = await loadStripe(document.body.dataset.stripePublishableKey || '')

        // Attempt to redirect to stripe.
        const result = await stripe?.redirectToCheckout({
          sessionId: payment.stripe_checkout_session_ref,
        })

        if (result?.error && user) {
          track('buy report error', {
            purchaseId: purchase.id,
            status: purchase.status,
            reportId: purchase.package_id,
            price: purchase.price?.price,
            error: result.error,
          })
          // TODO: Handle failure.
          setPurchaseError(true)
          setPackageId(null)
        }
      }
    })()
  }, [purchase, payment, user])

  // When we return, update our purchase to accept.
  const onReturnFromStripe = useCallback(
    async (key: string, value: Array<string>) => {
      if (!value || value.length == 0) return

      const purchaseId = parseInt(value[0])
      const paymentId = parseInt(value[1])

      const status = key == 'success' ? PackagePurchaseStatusType.Completed : PackagePurchaseStatusType.Canceled
      const updatedPurchase = await updatePackagePurchase(purchaseId, { status: status }, paymentId)

      // TODO: Handle failure to finalize the purchase.

      if (user && updatedPurchase) {
        await track(key == 'success' ? 'buy report' : 'buy report canceled', {
          purchaseId: updatedPurchase.id,
          status: updatedPurchase.status,
          reportId: updatedPurchase.package_id,
          price: updatedPurchase.price?.price,
        })

        if (updatedPurchase.status == PackagePurchaseStatusType.Completed) {
          setPurchase(null)
          setPayment(null)
          onSuccessfulPurchase(updatedPurchase.package_id)
        }
      }
    },
    [updatePackagePurchase, setPurchase, onSuccessfulPurchase, user]
  )

  const onErrorClose = useCallback(() => setPurchaseError(false), [setPurchaseError])

  return (
    <section className={styles.packageList}>
      <HandleURLQuery query={['success', 'cancel']} onHandle={onReturnFromStripe} preserve={false} />
      <DynamicContent contentKey="app_reports_reportlist" />
      <ul className={packageStyles.packages}>
        {isLoaded ? (
          packages
            .map((r) => r) // Sort is destructive, so copy first.
            .sort((a, b) =>
              a.click_behavior == 'read_only' && b.click_behavior != 'read_only'
                ? 1
                : a.click_behavior != 'read_only' && b.click_behavior == 'read_only'
                  ? -1
                  : 0
            )
            .map((r, i) => (
              <Package
                key={r.id}
                package={r}
                type={PACKAGE_TYPES[i % PACKAGE_TYPES.length]}
                isPurchased={packagePurchases.some(
                  (p) => p?.package_id == r.id && p.status == PackagePurchaseStatusType.Completed
                )}
                disabled={packageId != null}
                loading={packageId == r.id}
                onBuy={onBuy}
                onBuyFree={onBuyFree}
              />
            ))
        ) : (
          <>
            <FakePackage removing={isSwapping} />
            <FakePackage removing={isSwapping} />
            <FakePackage removing={isSwapping} />
            <FakePackage removing={isSwapping} />
          </>
        )}
      </ul>
      {purchaseError ? (
        <Modal onClose={onErrorClose} title={'Error While Buying Package'} buttons="Ok">
          <p>{'There was an error while purchasing your package. Please try again later.'}</p>
        </Modal>
      ) : null}
      {freePackageModalVisible && (
        <Modal onClose={onFreePackageModalClose} buttons={[{ name: 'Edit now', to: './edit' }]}>
          <div className={classNames(styles.content, styles.freePackageModal)}>
            <PurchaseResponse
              title={`Thanks for requesting a ${freePackageName} report`}
              description={
                <>
                  <p>
                    {
                      'Please edit your property details below to complete the intake process. Once you have confirmed your home stats and informed us about past renovations, we will get to work!'
                    }
                  </p>
                  <p>
                    {
                      'Your package will be uploaded to the “other documents” section within two weeks of editing your property details. '
                    }
                  </p>
                </>
              }
            />
          </div>
        </Modal>
      )}
    </section>
  )
}

export default PackageList
