import React, { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Back from 'svgs/property-plan/arrow-north'

import FancyButton from 'components/FancyButton'

import styles from './CustomizeContainer.module.scss'

interface Props {
  className?: string
  onSave?: () => void
  onCancel?: () => void
  fake?: boolean
}

const Customize: FC<PropsWithChildren<Props>> = ({ className, onSave, onCancel, fake, children }) => (
  <div className={classNames(styles.customizeContainer, fake ? styles.fake : null, className)}>
    <Link className={styles.back} to=".." onClick={onCancel}>
      <Back />
      {'Back to Your Project Planner'}
    </Link>
    <div className={styles.content}>
      {children}
      <footer>
        <FancyButton className={styles.button} to=".." onClick={onSave}>
          <span className={styles.desktop}>{'Save and return to Project Planner'}</span>
          <span className={styles.mobile}>{'Save to plan'}</span>
        </FancyButton>
        <FancyButton className={classNames(styles.button, styles.cancel)} to=".." onClick={onCancel}>
          {'Cancel'}
        </FancyButton>
      </footer>
    </div>
  </div>
)

export default Customize
