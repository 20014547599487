import React, { FC, useCallback, useMemo } from 'react'

import { track } from 'utils/analytics'
import { ProjectCustomization, ProjectSelection, generateProjectSelection } from 'recoil/projectTemplates'

import Select from 'components/Select'

const SELECTION_NONE = '_none_'

interface Props {
  className?: string
  projectId: string
  customization: ProjectCustomization
  values: Array<ProjectSelection>
  onChange: (key: string, selections: Array<ProjectSelection>) => void
}

const Exclusive: FC<Props> = ({ className, projectId, customization, values, onChange }) => {
  // Don't show chat_cta options in web
  const options = useMemo(() => customization.options.filter((option) => !option.show_chat_cta), [customization])
  const handleChange = useCallback(
    (sOptionId: string) => {
      // If they selected 'none' then we don't want to include this selection
      // anymore.
      if (sOptionId == SELECTION_NONE) {
        track('property-plan customize project select customization', {
          projectId,
          customization: customization.id,
          customizationName: customization.name,
          option: 'NONE',
          optionName: 'None',
        })
        onChange(customization.key, [])
      } else {
        const optionId = parseInt(sOptionId)

        // Find the option
        const option = options.find((option) => option.id == optionId)

        if (option) {
          // Build the new selection from the customization option
          const newSelection = generateProjectSelection(customization.key, option)
          // Stick on old cost estimate while we go out and calculate a new estimate.
          newSelection.cost_estimate = values[0]?.cost_estimate || 0

          track('property-plan customize project select customization', {
            projectId,
            customization: customization.id,
            customizationName: customization.name,
            option: option.id,
            optionName: option.name,
          })

          onChange(customization.key, [newSelection])
        }
      }
    },
    [values, customization, onChange, projectId, options]
  )

  // Convert selection value into the selected optionId
  // Grab the first (ideally only) value in the list.
  const value = values.length > 0 ? values[0].value : null
  let optionId = SELECTION_NONE
  if (value != null) {
    const selectedOption = options.find((o) => o.part.id == value)
    if (selectedOption) optionId = `${selectedOption.id}`
  }
  // If we are required, but still have 'none' selected, pick the first item
  // in the list.
  if (optionId == SELECTION_NONE && customization.required) {
    optionId = `${options[0].id}`
  }

  return (
    <Select className={className} value={optionId} onChange={handleChange}>
      {!customization.required ? <option value={SELECTION_NONE}>{'None'}</option> : null}
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  )
}

export default Exclusive
