import CoreCard from 'components/Core/CoreCard'
import CoreText from 'components/Core/CoreText'
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { onboardingState } from 'recoil/onboarding'
import { userState } from 'recoil/user'
import PhotoLibrary from '@mui/icons-material/PhotoLibrary'
import Add from '@mui/icons-material/Add'
import InsertDriveFile from '@mui/icons-material/InsertDriveFile'
import Remove from '@mui/icons-material/Remove'
import { deleteJSON, postFile } from 'utils/fetch'
import CoreList from 'components/Core/CoreList'
import CoreDivider from 'components/Core/CoreDivider'
import CoreButton from 'components/Core/CoreButton'
import { prepareBoth } from 'utils/analyticsV2'
import { Spinner } from 'components/Spinner'

const [trackClick] = prepareBoth({ screen: 'meeting-confirmation', family: 'projects' })

const BidUploads: FC = () => {
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const [user, setUser] = useRecoilState(userState)
  const [onboarding] = useRecoilState(onboardingState)
  const [uploading, setUploading] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleClickAddFiles = useCallback(() => {
    trackClick({ code: 'open-bid-file-explorer', content_text: 'Add files' })

    fileUploadRef.current?.click()
  }, [])

  const handleClickRemoveFile = useCallback(
    ({ id, name }: { name: string; id: number }) => {
      return async () => {
        try {
          setDeleting(true)
          trackClick({ code: 'remove-bid', feature: id.toString(), content_text: name })

          const resp = await deleteJSON(`/api/v1/outside_bids/${id}`)
          setUser(resp.jsonBody)
        } catch (e) {
          console.error(e)
        } finally {
          setDeleting(false)
        }
      }
    },
    [setUser]
  )

  const handleFilesPicked = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return

      const file = e.target.files[0]
      if (!file) return

      const formData = new FormData()
      formData.append('file', file)
      trackClick({ code: 'select-bid-file', feature: file.name })
      try {
        setUploading(true)
        const resp = await postFile(`/api/v1/outside_bids`, formData)
        setUser(resp.jsonBody)
      } catch (e) {
        console.error(e)
      } finally {
        setUploading(false)
      }
    },
    [setUser]
  )

  return (
    <>
      {user?.outside_bids.length == 0 ? (
        <>
          <CoreCard
            className="tw-bg-white tw-border tw-border-neutrals-300"
            title={'Upload current bids for faster matching with our vetted contractors, competitive pricing, and more'}
            description={`or email them at ${onboarding.bookingDetails?.advisor.email}`}
            buttonText="Add files"
            buttonKind="secondary"
            onButtonClick={handleClickAddFiles}
            buttonIcon={uploading ? <Spinner size="sm" /> : <Add />}
            buttonSize="l"
            icon={<PhotoLibrary />}
            iconColor="secondary"
          />
        </>
      ) : (
        <CoreCard
          className="tw-bg-white tw-border tw-border-neutrals-300"
          buttonText="Add files"
          buttonKind="secondary"
          onButtonClick={handleClickAddFiles}
          buttonIcon={<Add />}
          buttonSize="l"
        >
          <CoreText.Paragraph size="xl" weight="heavy" className="tw-mt-4 tw-text-center">
            {'Bids selected for review'}
          </CoreText.Paragraph>
          <CoreList>
            {user?.outside_bids.map((file, i) => {
              return (
                <CoreList.Item
                  leftIcon={
                    <div className="tw-rounded-full tw-bg-neutrals-200 tw-text-neutrals-1000">
                      <InsertDriveFile />
                    </div>
                  }
                  key={file.id}
                  text={file.name}
                  description={file.size}
                  right={
                    <CoreButton
                      kind="secondary"
                      size="s"
                      onClick={handleClickRemoveFile(file)}
                      icon={deleting ? <Spinner size="sm" /> : <Remove />}
                    />
                  }
                  divider={i < user.outside_bids.length - 1 ? <CoreDivider.Full /> : null}
                />
              )
            })}
            {!!user?.outside_bids.length && (
              <CoreText.Paragraph weight="light" color="tertiaryBlack" className="tw-mt-4">
                {"Any more bids to upload? If not, you are all set, we can't wait to get started."}
              </CoreText.Paragraph>
            )}
          </CoreList>
        </CoreCard>
      )}
      <input
        type="file"
        ref={fileUploadRef}
        className="tw-hidden"
        onChange={handleFilesPicked}
        accept="application/pdf"
      />
    </>
  )
}

export default BidUploads
