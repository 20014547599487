import React, { FC, useCallback } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import classNames from 'classnames'

import { usePropertyPlans } from 'recoil/propertyPlans'
import { useProjects } from 'recoil/projects'
import { useProjectTemplates } from 'recoil/projectTemplates'
import { useProjectPreviews } from 'recoil/projectPreviews'

import ScrollToTop from 'non-rendering/ScrollToTop'

import PageTitle from 'components/PageTitle'
import Overview from '../Overview'

import Customizable from './Customizable'
import FakeDetails from './FakeDetails'

import rootStyles from '../styles.module.scss'
import styles from './styles.module.scss'

const Customize: FC = () => {
  const { projectId } = useParams<{ projectId: any }>()
  const { selectedPropertyPlan, isSwitching } = usePropertyPlans()
  const { projects } = useProjects()
  const { projectTemplates } = useProjectTemplates()

  const project = projects.find((p) => p.id == projectId)
  const projectTemplate = projectTemplates.find((t) => t.id == project?.project_template_id)

  const { updatePreview, projectPreview, propertyPlanPreview } = useProjectPreviews(
    project || null,
    selectedPropertyPlan
  )

  const handleUpdatePreview = useCallback(
    (projectUpdate) => {
      if (!selectedPropertyPlan) return
      updatePreview(selectedPropertyPlan.property_id, selectedPropertyPlan.id, projectId, projectUpdate)
    },
    [updatePreview, projectId, selectedPropertyPlan]
  )

  // If the project doesn't exist, send us back to property-plan
  if (!isSwitching && !project) return <Redirect to={`..`} />

  return (
    <>
      <PageTitle title={!isSwitching ? projectTemplate?.name || '' : 'Project Planner'} />
      <ScrollToTop />
      <header className={rootStyles.content}>
        <div className={classNames(rootStyles.main, isSwitching ? styles.fake : null)}>
          <h1>{!isSwitching ? projectTemplate?.name : 'X'}</h1>
          <p>{!isSwitching ? project?.description : 'X'}</p>
        </div>
      </header>
      <section className={rootStyles.content}>
        <div className={rootStyles.sideBar}>
          <Overview propertyPlan={propertyPlanPreview} isSwitching={isSwitching} />
        </div>
        {!isSwitching && projectPreview ? (
          <div className={rootStyles.main}>
            <Customizable project={projectPreview} onUpdatePreview={handleUpdatePreview} />
          </div>
        ) : (
          <FakeDetails />
        )}
      </section>
    </>
  )
}

export default Customize
