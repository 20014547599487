import { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, atom } from 'recoil'
import { useHistory, useLocation } from 'react-router-dom'

import { onboardingState, OnboardingState } from '../onboarding'
import { Property } from '../properties'
import { loginState, User, userState } from '../user'

import { useAuth, LoginState } from 'non-rendering/Authentication'

import flows from './flows'
import { Flow, FlowRedirect, FlowState, FlowRef } from './processFlows'
import { DEFAULT_FLOW, DEFAULT_INTERNAL_FLOW, START_STATE, END_STATE } from './states'

class ResolveFlowError extends Error {
  property: 'flowName' | 'stateKey'
  constructor(message, property: 'flowName' | 'stateKey') {
    super(message)
    this.property = property
  }
}
class FindNextStateError extends Error {}
class EndStateError extends Error {}

export const flowStartState = atom<null | { flowName: string; endStateExpected?: boolean }>({
  key: 'FlowStartState',
  default: null,
})
export const flowRestartState = atom<null | { endStateExpected?: boolean }>({
  key: 'FlowRestartState',
  default: null,
})
export const flowNextState = atom<null | { endStateExpected?: boolean }>({
  key: 'FlowNextState',
  default: null,
})
export const flowJumpState = atom<null | string>({
  key: 'FlowJumpState',
  default: null,
})

export interface FlowOnboardingState {
  onboarding: OnboardingState
  property: Property
  userState: LoginState
  user: User | null
}
export type FOState = FlowOnboardingState

function getFlow(flowName: string | undefined | null): Flow | null {
  if (!flowName) return null
  return flows[flowName]
}
function getFlowState(flow: Flow, stateKey: string | undefined | null): FlowState | FlowRedirect | null {
  if (!stateKey) return null
  return flow.states[stateKey]
}

// Follow the state to the terminating name, and return that state.
function followState(
  flow: Flow,
  flowState: string | FlowState | FlowRedirect,
  foState: FOState
): [FlowState | null, Array<string>] {
  // First resolve if it is a string
  const state = typeof flowState === 'string' ? getFlowState(flow, flowState) : (flowState as FlowState | FlowRedirect)

  if (typeof state === 'object') {
    const rState = state as FlowRedirect
    const nState = state as FlowState
    // If it is a redirect, follow it.
    if (rState.to) {
      const toRef = findTo(rState, foState)
      const [finalState, path] = followState(flow, toRef.state, foState)
      return [finalState, [rState.name, ...path]]
    } else {
      // Otherwise we've reached the end
      return [nState, [nState.name]]
    }
  }

  // If we didn't find the state, then return null.
  return [null, [flowState as string]]
}

function resolveFlowState(
  flowName: string | null,
  stateKey: string | null,
  foState: FOState,
  allowEndState = false
): FlowState {
  // Test for null in our names.
  // We want to allow them to submit null, but we can't resolve it for them
  if (flowName == null) throw new ResolveFlowError('Unknown OnboardingFlow NULL', 'flowName')
  if (stateKey == null) {
    throw new ResolveFlowError(`Unknown OnboardingFlow State NULL for flow '${flowName}'`, 'stateKey')
  }

  // Grab the flow
  const flow = getFlow(flowName)
  // Unrecognized flow!
  if (!flow) {
    throw new ResolveFlowError(`Unknown OnboardingFlow '${flowName}'`, 'flowName')
  }

  // If our current state is the END_STATE, it isn't resolvable.
  if (!allowEndState && stateKey == END_STATE) {
    throw new EndStateError(`OnboardingFlow State for flow '${flowName}' is '${END_STATE}' state`)
  }

  // If our initialFlow is fine, we can determine where we go next.
  const [resolvedState, resolvedStatePath] = followState(flow, stateKey, foState)
  // Unrecognized state!
  if (!resolvedState) {
    // Give a simpler error if our given name was bad.
    if (resolvedStatePath.length == 1)
      throw new ResolveFlowError(`Unknown OnboardingFlow State '${stateKey}' for flow '${flowName}'`, 'stateKey')
    throw new ResolveFlowError(
      `Failed OnboardingFlow State redirect path ${resolvedStatePath
        .map((path) => `'${path}'`)
        .join('=>')}, for flow '${flowName}`,
      'stateKey'
    )
  }
  return resolvedState
}

// Decide if this FlowRef should be used.
function testIf(ref: FlowRef, state: FOState): boolean {
  // If no conditional, then this is the one to use.
  if (!ref.if) return true

  // Otherwise evaluate it.
  return ref.if(state)
}

function findNext(flowState: FlowState, foState: FOState): FlowRef {
  // First find the next appropriate state
  const nextRef = flowState.next.find((ref: FlowRef) => testIf(ref, foState))

  // If it doesn't exist we have a problem
  if (!nextRef) {
    throw new FindNextStateError(
      `No matching 'next' state for OnboardingFlow State '${flowState.name}' for flow '${flowState.flow.name}'`
    )
  }

  return nextRef
}

function findTo(flowRedirect: FlowRedirect, foState: FOState): FlowRef {
  // First find the next appropriate state
  const toRef = flowRedirect.to.find((ref: FlowRef) => testIf(ref, foState))

  // If it doesn't exist we have a problem
  if (!toRef) {
    throw new FindNextStateError(
      `No matching 'to' state for OnboardingFlow Redirect State '${flowRedirect.name}' for flow '${flowRedirect.flow.name}'`
    )
  }

  return toRef
}

export const useFlow = (): {
  start: (flowName: string, endStateExpected?: boolean) => void
  restart: (endStateExpected?: boolean) => void
  next: (endStateExpected?: boolean) => void
  jump: (flowStateKey: string) => void
  ended: boolean
  flow: string | null
  states: Array<FlowState>
  lastState: FlowState | null
  currentState: FlowState | null
} => {
  const history = useHistory()
  const location = useLocation()
  const { state: authState } = useAuth()
  const [next, setNext] = useRecoilState(flowNextState)
  const [start, setStart] = useRecoilState(flowStartState)
  const [restart, setRestart] = useRecoilState(flowRestartState)
  const [jump, setJump] = useRecoilState(flowJumpState)
  const [ended, setEnded] = useState(false)

  const [onboarding, setOnboarding] = useRecoilState(onboardingState)
  const user = useRecoilValue(userState)
  const userLoginState = useRecoilValue(loginState)
  // Property should be a merge of our actual property and our edited property.
  const property: Property = useMemo(() => {
    return { ...onboarding.property, ...onboarding.editedPropertyData } as Property
  }, [onboarding.property, onboarding.editedPropertyData])

  const defaultFlow = authState == LoginState.LoggedIn ? DEFAULT_INTERNAL_FLOW : DEFAULT_FLOW

  const foState: FOState = useMemo(() => {
    return {
      onboarding: onboarding,
      userState: userLoginState,
      property: property,
      user: user,
    }
  }, [onboarding, userLoginState, property, user])

  let lastState: FlowState | null
  try {
    lastState = resolveFlowState(onboarding.flow || null, onboarding.lastFlowState || null, foState)
  } catch (e) {
    // Any failure to get the last flow state means it should be null
    lastState = null
  }
  let currentState: FlowState | null
  try {
    currentState = resolveFlowState(onboarding.flow || null, location.pathname, foState)
  } catch (e) {
    // Any failure to get the current flow state means it should be null
    currentState = null
  }

  const startFn = useCallback(
    (flowName: string, endStateExpected?: boolean) => {
      endStateExpected = typeof endStateExpected === 'undefined' ? false : (endStateExpected as boolean)

      // Resolve flow
      let state: FlowState | null = null
      let flowReset = false
      try {
        state = resolveFlowState(flowName, START_STATE, foState)
      } catch (e) {
        // For failures,
        if (e instanceof ResolveFlowError) {
          if (e.property == 'flowName' && flowName != defaultFlow) {
            console.error(`${e.name}: ${e.message} - restarting as '${defaultFlow}' from beginning`, e.stack)
            // If they specified a bad flowName, then use default
            // and start at the beginning
            flowName = defaultFlow
            flowReset = true
          }
          // If the flowName was bad AND it was our default flow, then there
          // is no point in resetting the flow.
          // If the START_STATE was bad, then we have a configuration error
          // and we can't do much to fix it now. Let it bubble.
          else throw e
        } else throw e
      }
      // If we reset the flow, we need to resolve again.
      if (flowReset) {
        // Resolve the flow using the updated names.
        state = resolveFlowState(flowName, START_STATE, foState)
        // If we fail this time, let it bubble so we can deal with it.
      }

      // At this point we should definitely have a state
      const oState = state as FlowState

      // Update
      setOnboarding((prev) => ({
        ...prev,
        flow: oState.flow.name,
        lastFlowState: oState.name == END_STATE ? oState.name : oState.url,
      }))

      // If we're in the end state, there is no where to transition.
      if (oState.name == END_STATE) {
        if (endStateExpected) {
          // If it is expected, signal the state that our flow has ended,
          // allowing the page implementor to do something about it.
          setEnded(true)
        } else {
          // If we're not expecting an end state, raise an exception.
          // This should help with new flow construction, where we encounter a
          // "dead" start call that is otherwise unexplainable.
          throw new EndStateError(
            `OnboardingFlow '${oState.flow.name}' transitioned to unexpected '${oState.name}' state. If you expected an '${oState.name}' state here, be sure to pass 'true' for argument 'endStateExpected' when calling 'start'.`
          )
        }
      } else {
        // Otherwise, execute the transition
        // For 'start', we always want a URL replacement so the user can't
        // 'back' out to /onboarding again.
        history.replace(oState.url)
      }
    },
    [setOnboarding, history, defaultFlow, foState]
  )

  const nextFn = useCallback(
    (endStateExpected?: boolean) => {
      endStateExpected = typeof endStateExpected === 'undefined' ? false : (endStateExpected as boolean)

      // First resolve our current flow and state
      let flowName = onboarding.flow
      let stateKey = location.pathname // Eventually, the next state to move to.
      let state: FlowState | null = null

      // Resolve flow
      let flowReset = false
      try {
        state = resolveFlowState(flowName || null, stateKey || null, foState)
      } catch (e) {
        // For failures,
        if (e instanceof ResolveFlowError) {
          if (e.property == 'flowName') {
            console.error(`${e.name}: ${e.message} - restarting as '${defaultFlow}' from beginning`, e.stack)
            // If we don't know what flow they're in, then use default
            // and start at the beginning
            flowName = defaultFlow
            stateKey = START_STATE
            flowReset = true
          } else {
            console.error(`${e.name}: ${e.message} - restarting '${flowName}' from beginning`, e.stack)
            // If we don't know what state they're on, then use START_STATE
            stateKey = START_STATE
            flowReset = true
          }
        } else throw e
      }

      // If we aren't resetting the flow, we can navigate to the next state.
      if (!flowReset) {
        // Otherwise we can determine the next state via the conditionals
        try {
          const nextRef = findNext(state as FlowState, foState)
          // Resolve the state this ref points to. This ensures we do all
          // our error checking, and follows any redirects.
          state = resolveFlowState(
            nextRef.state.flow.name,
            (nextRef.state as FlowState).url || nextRef.state.name,
            foState,
            true
          )
        } catch (e) {
          console.error(`${e.name}: ${e.message} - restarting '${flowName}' from beginning`, e.stack)
          stateKey = START_STATE
          flowReset = true
        }
      }

      // If we reset the flow, then we just want to redirect them now.
      if (flowReset) {
        // Resolve the flow using the updated names.
        state = resolveFlowState(flowName || null, stateKey || null, foState)
        // If we fail this time, let it bubble so we can deal with it.
      }

      // One way or another, we should know where we are transitioning
      // and state should be valid now.
      const oState = state as FlowState
      // As long as our state is good, update and transition.
      setOnboarding((prev) => ({
        ...prev,
        flow: oState.flow.name,
        lastFlowState: oState.name == END_STATE ? oState.name : oState.url,
      }))

      // If we're in the end state, there is no where to transition.
      if (oState.name == END_STATE) {
        if (endStateExpected) {
          // If it is expected, signal the state that our flow has ended,
          // allowing the page implementor to do something about it.
          setEnded(true)
        } else {
          // If we're not expecting an end state, raise an exception.
          // This should help with new flow construction, where we encounter a
          // "dead" start call that is otherwise unexplainable.
          throw new EndStateError(
            `OnboardingFlow '${oState.flow.name}' transitioned to unexpected '${oState.name}' state. If you expected an '${oState.name}' state here, be sure to pass 'true' for argument 'endStateExpected' when calling 'next'.`
          )
        }
      } else {
        // Otherwise, execute the transition
        history.push(oState.url)
      }
    },
    [foState, onboarding, setOnboarding, history, location, defaultFlow]
  )

  const jumpFn = useCallback(
    (flowStateKey: string, endStateExpected?: boolean) => {
      endStateExpected = typeof endStateExpected === 'undefined' ? false : (endStateExpected as boolean)

      // If we're jumping, then we don't care about the current flow state.
      // First resolve our current flow
      let flowName = onboarding.flow
      let stateKey = flowStateKey // The next state to move to.
      let state: FlowState | null = null

      // Resolve new flow state
      let flowReset = false
      try {
        state = resolveFlowState(flowName || null, stateKey || null, foState, true)
      } catch (e) {
        // For failures,
        if (e instanceof ResolveFlowError) {
          if (e.property == 'flowName') {
            console.error(`${e.name}: ${e.message} - restarting as '${defaultFlow}' from beginning`, e.stack)
            // If we don't know what flow they're in, then use default
            // and start at the beginning
            flowName = defaultFlow
            stateKey = START_STATE
            flowReset = true
          } else {
            console.error(`${e.name}: ${e.message} - restarting '${flowName}' from beginning`, e.stack)
            // If we don't know what state they're on, then use START_STATE
            stateKey = START_STATE
            flowReset = true
          }
        } else throw e
      }

      // If we reset the flow, then we just want to redirect them now.
      if (flowReset) {
        // Resolve the flow using the updated names.
        state = resolveFlowState(flowName || null, stateKey || null, foState)
        // If we fail this time, let it bubble so we can deal with it.
      }

      // One way or another, we should know where we are transitioning
      // and state should be valid now.
      const oState = state as FlowState

      // As long as our state is good, update and transition.
      setOnboarding((prev) => ({
        ...prev,
        flow: oState.flow.name,
        lastFlowState: oState.name == END_STATE ? oState.name : oState.url,
      }))

      // If we're in the end state, there is no where to transition.
      if (oState.name == END_STATE) {
        if (endStateExpected) {
          // If it is expected, signal the state that our flow has ended,
          // allowing the page implementor to do something about it.
          setEnded(true)
        } else {
          // If we're not expecting an end state, raise an exception.
          // This should help with new flow construction, where we encounter a
          // "dead" start call that is otherwise unexplainable.
          throw new EndStateError(
            `OnboardingFlow '${oState.flow.name}' transitioned to unexpected '${oState.name}' state. If you expected an '${oState.name}' state here, be sure to pass 'true' for argument 'endStateExpected' when calling 'jump'.`
          )
        }
      } else {
        // Otherwise, execute the transition
        // Like 'start', 'jump' should use a URL replacement so the user can't
        // 'back' out to /onboarding again.
        history.replace(oState.url)
      }
    },
    [foState, onboarding, setOnboarding, history, defaultFlow]
  )

  const startLock = useRef<boolean>(false)
  useEffect(() => {
    if (start && startLock.current) {
      startLock.current = false
      setStart(null)
      startFn(start.flowName, start.endStateExpected)
    }
  }, [start, startFn, setStart])

  const restartLock = useRef<boolean>(false)
  useEffect(() => {
    if (restart && restartLock.current) {
      restartLock.current = false
      setRestart(null)
      startFn(onboarding.flow as string, restart.endStateExpected)
    }
  }, [onboarding.flow, restart, startFn, setRestart])

  const nextLock = useRef<boolean>(false)
  useEffect(() => {
    if (next && nextLock.current) {
      nextLock.current = false
      setNext(null)
      nextFn(next.endStateExpected)
    }
  }, [next, nextFn, setNext])

  const jumpLock = useRef<boolean>(false)
  useEffect(() => {
    if (jump && jumpLock.current) {
      jumpLock.current = false
      setJump(null)
      jumpFn(jump)
    }
  }, [jump, jumpFn, setJump])

  return {
    start: useCallback(
      (flowName: string, endStateExpected?: boolean) => {
        startLock.current = true
        setStart({ flowName, endStateExpected })
      },
      [setStart]
    ),
    restart: useCallback(
      (endStateExpected?: boolean) => {
        restartLock.current = true
        setRestart({ endStateExpected })
      },
      [setRestart]
    ),
    next: useCallback(
      (endStateExpected?: boolean) => {
        nextLock.current = true
        setNext({ endStateExpected })
      },
      [setNext]
    ),
    jump: useCallback(
      (flowStateKey: string) => {
        jumpLock.current = true
        setJump(flowStateKey)
      },
      [setJump]
    ),
    ended,
    flow: onboarding.flow || null,
    states: Object.values(getFlow(onboarding.flow)?.states || {}).filter(
      (state) => !!(state as FlowState).url
    ) as Array<FlowState>,
    lastState,
    currentState,
  }
}
