import React, { FC, useEffect, useRef, useState } from 'react'
import mapboxgl, { Map } from 'mapbox-gl'
import { multiPolygon } from '@turf/turf'
import { Property } from 'recoil/properties'

interface ParcelMapProps {
  property: Property
}

const loadStyle = (url, id) => {
  return new Promise<void>((resolve) => {
    if (document.getElementById(id)) {
      resolve()
      return
    }
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.id = id
    link.onload = () => {
      resolve()
    }
    link.href = url

    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
  })
}

const addGeom = (id, map, coordinates: GeoJSON.Position[][][], paint) => {
  if (map.getSource(id)) return
  const poly = multiPolygon(coordinates)

  map.addSource(id, { type: 'geojson', data: poly })
  map.addLayer({
    id: id,
    type: 'fill',
    source: id,
    layout: {},
    paint,
  })
  AnimationEvent
}

const addMarker = (id, map, lat, long) => {
  if (map.getSource(id)) return
  new mapboxgl.Marker({
    color: '#4a957a',
  })
    .setLngLat([long, lat])
    .addTo(map)
}

const ParcelMap: FC<ParcelMapProps> = ({ property }) => {
  const [isStyleLoaded, setIsStyleLoaded] = useState<boolean>(false)
  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let didCancel = false
    const _ = async () => {
      await loadStyle('https://api.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.css', 'mapbox-gl.css')
      didCancel || setIsStyleLoaded(true)
    }
    _()
    return () => {
      didCancel = true
    }
  }, [])

  useEffect(() => {
    if (!isStyleLoaded) return

    const long = parseFloat(property.long) + 0.0015

    if (!mapRef.current) return
    mapboxgl.accessToken = document.body.dataset.mapboxglAccessToken || ''
    const map = new Map({
      container: mapRef.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [long, Number(property.lat)],
      zoom: 17,
      interactive: false,
      attributionControl: false,
    })
    map.on('styledata', ({ target: map }) => {
      if (property.parcel_geometry_geom) {
        addGeom('parcel_geometry', map, property.parcel_geometry_geom.coordinates, {
          'fill-color': 'rgba(255, 255, 255, 0.2)',
        })
      }
      if (property.primary_buildable_area_geom) {
        addGeom('primary_buildable_area', map, [property.primary_buildable_area_geom.coordinates], {
          'fill-color': 'rgba(73, 164, 148, 0.6)',
        })
      }
      addMarker('marker', map, property.lat, property.long)
    })
  }, [isStyleLoaded, property])

  return <div ref={mapRef} className="tw-h-full" />
}

export default ParcelMap
