import React, { FC, useCallback, useState } from 'react'
import { InputProps } from './Input'
import styles from './InputWithEditButton.module.scss'
import InputWithEditMode from 'components/InputWithEditMode'
import Edit from 'svgs/edit'
import FancyButton from 'components/FancyButton'
import Spinner from 'components/Spinner'

export interface InputWithEditButtonProps extends InputProps {
  onConfirm: () => Promise<void>
  decorator?: string
}

const InputWithEditButton: FC<InputWithEditButtonProps> = ({ onConfirm, ...inputProps }) => {
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClickEdit = useCallback(() => {
    setEditing((editing) => !editing)
  }, [])

  const handleClickConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await onConfirm()
      setEditing(false)
    } catch (e) {
      setEditing(true)
    } finally {
      setLoading(false)
    }
  }, [onConfirm, setEditing, setLoading])

  return (
    <InputWithEditMode
      editing={editing}
      className={styles.input}
      button={
        <>
          {editing ? (
            <FancyButton className={styles.confirmButton} onClick={handleClickConfirm}>
              {loading ? <Spinner /> : 'Confirm'}
            </FancyButton>
          ) : (
            <button type="button" onClick={handleClickEdit}>
              <Edit className={styles.editButton} />
            </button>
          )}
        </>
      }
      {...inputProps}
    />
  )
}

export default InputWithEditButton
