import React, { FC, useEffect } from 'react'
import cx from 'classnames'

import { useProperties } from 'recoil/properties'
import { DEFAULT_INTERNAL_FLOW } from 'recoil/onboardingFlows/states'

import Button from 'components/FancyButton'
import ToggleSwitchButton from 'components/ToggleSwitchButton'

import styles from './styles.module.scss'

interface Props {
  isManaging: boolean
  onIsManagingChange: (isManaging: boolean) => void
}

const PropertiesButtons: FC<Props> = ({ isManaging, onIsManagingChange }) => {
  const { properties } = useProperties()

  useEffect(() => {
    if ((properties || []).length <= 1) onIsManagingChange(false)
  }, [properties, onIsManagingChange])

  return (
    <div className={styles.buttons}>
      <Button
        className={cx(styles.button, styles.addPropertyButton)}
        to={{ pathname: '/onboarding', state: { flow: DEFAULT_INTERNAL_FLOW } }}
      >
        {'Add a new property'}
      </Button>
      {(properties || []).length > 1 && (
        <div className={styles.toggleManagingButton}>
          <ToggleSwitchButton id="btnManage" onChange={onIsManagingChange} value={isManaging} className="tw-mr-2" />
          <label htmlFor="btnManage">{'Remove properties'}</label>
        </div>
      )}
    </div>
  )
}
export default PropertiesButtons
