import { useRecoilState } from 'recoil'
import { useProperties } from 'recoil/properties'
import { Renovation, RenovationImage, renovationsState, replaceRenovation, useRenovations } from 'recoil/renovations'

import { postFile, deleteFile } from 'utils/fetch'

export const useRenovationImages = (): {
  addImage: (r: Renovation, f: File) => Promise<void>
  destroyImage: (r: Renovation, i: RenovationImage) => Promise<void>
} => {
  const { selectedProperty } = useProperties()
  const [renovations, setRenovations] = useRecoilState(renovationsState)
  const { refresh } = useRenovations()

  return {
    addImage: async (r: Renovation, f: File) => {
      if (!selectedProperty) return
      const formData = new FormData()
      formData.append('file', f)
      const response = await postFile(`/api/v1/properties/${selectedProperty.id}/renovations/${r.id}/image`, formData)

      if (response.isError) {
        const error = new Error(`Non-200 status code: ${response.code}.`)
        throw Object.assign(error, { body: response.jsonBody })
      } else {
        const updated = response.jsonBody as Renovation
        setRenovations(replaceRenovation(renovations, updated))
        refresh()
      }
    },
    destroyImage: async (r: Renovation, i: RenovationImage) => {
      if (!selectedProperty) return
      const response = await deleteFile(
        `/api/v1/properties/${selectedProperty.id}/renovations/${r.id}/image?image_id=${i.id}`,
        null
      )
      if (response.isError) {
        const error = new Error(`Non-200 status code: ${response.code}.`)
        throw Object.assign(error, { body: response.jsonBody })
      } else {
        const updated = response.jsonBody as Renovation
        setRenovations(replaceRenovation(renovations, updated))
        refresh()
      }
    },
  }
}
