import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'

import CoreText from 'components/Core/CoreText'

import styles from './styles.module.scss'
import EmailSignup from './EmailSignup'
import moment from 'moment'
// import SSOLogoGoogle from 'svgs/sso-logo-google'
// import SSOLogoApple from 'svgs/sso-logo-apple'
// import { trackClick, trackImpression } from 'utils/analyticsV2'
import { track as legacyTrack } from 'utils/analytics'
// import SsoLink, { submitSsoLink, useSsoOnboardingState } from 'components/Auth/SsoLink'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import { useAuth } from 'non-rendering/Authentication'

const SignUp: React.FC = () => {
  const { next } = useFlow()
  const { user } = useAuth()

  const { onboarding, setOnboarding } = useOnboardingProperty()
  const [shouldContinue, setShouldContinue] = useState(false)
  // const onboardingState = useSsoOnboardingState('/onboarding')

  const creatingPropertyToken = useMemo(() => onboarding?.pollable_job_token || null, [onboarding.pollable_job_token])

  useEffect(() => {
    const _ = async () => {
      if (!!onboarding.user && shouldContinue) {
        setShouldContinue(false)
        await legacyTrack('account creation', {
          email: onboarding.user?.email,
          withProperty: !!creatingPropertyToken,
          is_marketplace: onboarding.marketplace,
        })
        next()
      }
    }
    _()
  }, [onboarding.user, shouldContinue, creatingPropertyToken, next, onboarding.marketplace])

  const handleSignup = useCallback(
    (creatingPropertyToken) => {
      // Onboarding needs to know if they've signed in with SSO or not.
      setOnboarding((prev) => ({ ...prev, signupMethod: 'email', pollable_job_token: creatingPropertyToken }))
      setShouldContinue(true)
    },
    [setOnboarding]
  )

  // removing SSO for now, may be added back later
  // const handleSsoVisible = useCallback((type: 'google' | 'apple') => {
  //   return () => {
  //     trackImpression({ code: 'continue', section: 'move-forward', feature: type })
  //   }
  // }, [])

  // const handleSsoClick = useCallback((type: 'google' | 'apple') => {
  //   return async (e: React.FormEvent<HTMLFormElement>) => {
  //     e.preventDefault()
  //     const form = e.currentTarget
  //     await trackClick({ code: 'select-account', section: 'select-account', feature: type })

  //     // Submit manually.
  //     submitSsoLink(form)
  //   }
  // }, [])

  return (
    <Wrapper backStyle="active" forwardStyle={user ? 'active' : 'disabled'} className={styles.wrapper}>
      <CoreText.Headline size="xs" weight="heavy">
        {'Create an account to finish booking your meeting'}
      </CoreText.Headline>
      {onboarding.meetingTime && (
        <CoreText.Paragraph weight="light" color="secondary" className={styles.subheading}>
          {moment(onboarding.meetingTime.start_time).format('ddd, MMM D @ h:mma')}
        </CoreText.Paragraph>
      )}
      {/* {!onboarding.unavailable && (
        <div className={styles.sso}>
          <SsoLink
            onClick={handleSsoClick('google')}
            onVisible={handleSsoVisible('google')}
            to={`/users/auth/google_oauth2?state=${onboardingState}`}
            innerClassName={styles.ssoLink}
            outerClassName={styles.ssoLinkWrapper}
          >
            <SSOLogoGoogle />
          </SsoLink>
          <SsoLink
            onClick={handleSsoClick('apple')}
            onVisible={handleSsoVisible('apple')}
            to={`/users/auth/apple?state=${onboardingState}`}
            innerClassName={styles.ssoLink}
            outerClassName={styles.ssoLinkWrapper}
          >
            <SSOLogoApple />
          </SsoLink>
        </div>
      )} */}
      <div className={styles.divider}>
        {!onboarding.unavailable && (
          <>
            <div className={styles.line} />
            <CoreText.Paragraph color="disabledBlack">{'or'}</CoreText.Paragraph>
            <div className={styles.line} />
          </>
        )}
      </div>
      <EmailSignup onSignup={handleSignup} />
    </Wrapper>
  )
}

export default SignUp
