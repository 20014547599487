import React, { FC, useCallback, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { userState } from 'recoil/user'

interface Props {
  onEventScheduled: (eventUri: string, inviteeUri: string) => void
}

const CalendlyWidget: FC<Props> = ({ onEventScheduled }) => {
  const user = useRecoilValue(userState)

  const handleCalendlyMessage = useCallback(
    (e) => {
      if (!e.data.event || e.data.event !== 'calendly.event_scheduled') {
        return
      }

      onEventScheduled(e.data.payload.event.uri, e.data.payload.invitee.uri)
    },
    [onEventScheduled]
  )

  useEffect(() => {
    const win = window as any
    win.Calendly.initInlineWidget({
      url: 'https://calendly.com/realmadvisor/realm-advisor-consultation',
      parentElement: document.getElementById('schedule-widget'),
      prefill: {
        name: user?.full_name,
        email: user?.email,
      },
    })

    window.addEventListener('message', handleCalendlyMessage)

    return () => window.removeEventListener('message', handleCalendlyMessage)
  }, [handleCalendlyMessage, user])

  return <></>
}

export default CalendlyWidget
