import Alert from './Alert'
import cx from 'classnames'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useProperties, Alert as IAlert } from 'recoil/properties'
import ArrowCircleDown from 'svgs/arrow-circle-down'
import { markAccessActivityFeed } from 'recoil/user'

import styles from './styles.module.scss'

const UNEXPANDED_ALERTS_COUNT = 3

const Activity: FC = () => {
  const { selectedProperty } = useProperties()
  const [expanded, setExpanded] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)

  const handleShown = useCallback(async () => {
    if (!selectedProperty) return
    await markAccessActivityFeed(selectedProperty.id)
  }, [selectedProperty])

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) => {
        if (entries.find((e) => e.isIntersecting)) {
          handleShown()
          observer?.disconnect()
        }
      })
      if (ref.current) observer.observe(ref.current)

      return () => observer?.disconnect()
    } else {
      handleShown()
    }
  }, [handleShown])

  const handleClickExpandContract = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  const alerts: IAlert[] = useMemo(() => {
    if (!selectedProperty) return []

    const allAlerts = selectedProperty.activity
    return expanded ? allAlerts : (allAlerts.slice(0, UNEXPANDED_ALERTS_COUNT) as IAlert[])
  }, [expanded, selectedProperty])

  if (!selectedProperty) return null

  return (
    <div className={cx('tw-p-6', styles.expandedActivity)}>
      <h2>{'Recent Activity'}</h2>
      {alerts.length === 0 ? (
        <p>{'No alerts yet. Stay tuned!'}</p>
      ) : (
        <div className={cx('tw-font-light tw-my-6 tw-space-y-5', styles.alerts)} ref={ref}>
          {alerts.map((alert) => (
            <Alert key={alert.id} alert={alert} />
          ))}
        </div>
      )}
      {selectedProperty.activity.length > UNEXPANDED_ALERTS_COUNT && (
        <button type="button" className={styles.expandButton} onClick={handleClickExpandContract}>
          <div>
            <ArrowCircleDown />
          </div>
          <div>{expanded ? 'Hide activity' : 'See all activity'}</div>
        </button>
      )}
    </div>
  )
}

export default Activity
