import React, { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { track } from 'utils/analytics'

import Edit from 'svgs/edit'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

const EditPropertyPrompt: FC<Props> = ({ className }) => {
  const handleOnClick = useCallback(() => {
    track('click cta', { cta: 'edit', module: 'overview', location: 'edit-property-prompt' })
  }, [])

  return (
    <div className={classNames(styles.editPropertyPrompt, className)}>
      <Edit />
      <p>
        {'Keep your '}
        <Link to="../edit" onClick={handleOnClick}>
          {'property details'}
        </Link>
        {' updated to get the most accurate recommendations'}
      </p>
    </div>
  )
}

export default EditPropertyPrompt
