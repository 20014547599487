import React, { FC, useCallback } from 'react'
import { Property, PropertyDocs, useProperties } from 'recoil/properties'
import DocumentUpload from 'pages/EditProperty/Documents/DocumentUpload'
import OtherFilesUpload from 'pages/EditProperty/Documents/OtherFilesUpload'
import Document from 'svgs/document'
import { track } from 'utils/analytics'
import styles from './styles.module.scss'

export type DocumentsFormProps = {
  property: Property
}

export const DocumentsForm: FC<DocumentsFormProps> = ({ property }) => {
  const { attachDocToSelectedProperty, deleteDocFromSelectedProperty } = useProperties()

  const changeDoc = useCallback(
    (type: keyof PropertyDocs) => {
      return async (file: File) => {
        track('change file', { section: 'recommended', type })
        await attachDocToSelectedProperty(type, file)
      }
    },
    [attachDocToSelectedProperty]
  )

  const removeDoc = useCallback(
    (type: keyof PropertyDocs) => {
      return async () => {
        track('remove file', { section: 'recommended', type })
        await deleteDocFromSelectedProperty(type)
      }
    },
    [deleteDocFromSelectedProperty]
  )

  return (
    <div className={styles.documents}>
      <h3>{'Recommended documents'}</h3>
      <h4>{'Including these will dramatically increase the accuracy of our insights'}</h4>
      <div className={styles.recommended}>
        <DocumentUpload
          type="home_appraisal"
          icon={<Document />}
          title="Home Appraisal"
          doc={property.docs.home_appraisal}
          handleChange={changeDoc('home_appraisal')}
          handleRemove={removeDoc('home_appraisal')}
        />
        <DocumentUpload
          type="home_inspection_report"
          title="Home Inspection Report"
          icon={<Document />}
          doc={property.docs.home_inspection_report}
          handleChange={changeDoc('home_inspection_report')}
          handleRemove={removeDoc('home_inspection_report')}
        />
        <DocumentUpload
          type="hoa_ccrs"
          title={`HOA CC&Rs`}
          doc={property.docs.hoa_ccrs}
          icon={<Document />}
          handleChange={changeDoc('hoa_ccrs')}
          handleRemove={removeDoc('hoa_ccrs')}
        />
      </div>
      <h3>{'Other documents'}</h3>
      <h4>{'Any additional documents and photos that would be helpful to share'}</h4>
      <div className={styles.others}>
        <OtherFilesUpload property={property} />
      </div>
    </div>
  )
}

export default DocumentsForm
