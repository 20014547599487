import React, { FC } from 'react'
import classNames from 'classnames'

import { ProjectCustomization, ProjectSelection } from 'recoil/projectTemplates'

import { formatCurrency } from 'utils/formatting'

import Exclusive from './Exclusive'
import Inclusive from './Inclusive'

import sectionStyles from '../Section.module.scss'
import styles from './styles.module.scss'

interface Props {
  projectId: string
  customization: ProjectCustomization
  values: Array<ProjectSelection>
  onChange: (key: string, selections: Array<ProjectSelection>) => void
}

const CustomizationSection: FC<Props> = ({ projectId, customization, values, onChange }) => {
  const costEstimate = values.reduce((sum, selection) => (sum += selection.cost_estimate || 0), 0)
  return (
    <section className={classNames(sectionStyles.section, styles.customizationSection)}>
      <h3>{customization.name}</h3>
      <p>{customization.description}</p>
      <div className={styles.customization}>
        {customization.inclusive ? (
          <Inclusive
            className={styles.options}
            projectId={projectId}
            customization={customization}
            values={values}
            onChange={onChange}
          />
        ) : (
          <Exclusive
            className={styles.options}
            projectId={projectId}
            customization={customization}
            values={values}
            onChange={onChange}
          />
        )}
        <div className={styles.stats}>
          <div className={classNames(styles.stat, styles.cost)}>
            <span className={styles.label}>{'Cost'}</span>
            <span className={styles.value}>{formatCurrency(costEstimate)}</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomizationSection
