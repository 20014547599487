// Third party dependencies
import React, { FC } from 'react'
import classNames from 'classnames'

import { usePropertyPlans } from 'recoil/propertyPlans'

import ScrollToTop from 'non-rendering/ScrollToTop'
import PageTitle from 'components/PageTitle'

// Components
import HandleAddProjectsQuery from './HandleAddProjectsQuery'
import Overview from './Overview'
import EditPropertyPrompt from './EditPropertyPrompt'
import Plan from './Plan'
import ProjectTemplates from './ProjectTemplates'

// import ReportUpsell from 'pages/Reports/ReportUpsell'

// Styles
import rootStyles from './styles.module.scss'
import styles from './PlanHome.module.scss'
import PageHeader from 'components/PageHeader'

const PlanHome: FC = () => {
  const { selectedPropertyPlan, isSwitching } = usePropertyPlans()
  return (
    <>
      <HandleAddProjectsQuery />
      <PageTitle title="Project Planner" />
      <ScrollToTop />
      <header className={rootStyles.header}>
        <PageHeader
          header={'Project Planner'}
          subheader={
            'Add projects to see their cost and impact on home value. Customize those projects to see how finishes, materials, and DIY impact budgets.'
          }
          className={rootStyles.main}
        />
      </header>
      <section className={rootStyles.content}>
        <div className={rootStyles.sideBar}>
          <div className={rootStyles.stickySleeve}>
            <Overview propertyPlan={selectedPropertyPlan} isSwitching={isSwitching} />
            <div className={classNames(styles.editPrompt, styles.desktop)}>
              <EditPropertyPrompt />
            </div>
          </div>
        </div>
        <div className={rootStyles.plan}>
          <Plan />
        </div>
        <div className={rootStyles.details}>
          <div className={classNames(styles.editPrompt, styles.mobile)}>
            <EditPropertyPrompt />
          </div>
        </div>
        <div className={rootStyles.templates}>
          <ProjectTemplates />
        </div>
      </section>
      {/* <ReportUpsell className={styles.reportUpsell} hook="Not sure which projects are right for you?" to="../reports" /> */}
    </>
  )
}

export default PlanHome
