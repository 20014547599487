import ProjectTemplateStubsApi, { ListProjectTemplatesOptions } from 'apis/public/projectTemplateStubs'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

export interface ProjectTemplateStub {
  id: number
  kind: string
  name: string
  description: string
  plural_copy: string
  determiner_copy: string
  action_copy: string
  singular_copy: string
  possessive_copy: string
  general_copy: string
  tags: string[]
  icon_svg: string
  image: string
}

const projectTemplateStubsState = atom<ProjectTemplateStub[] | null>({
  key: 'projectTemplateStubs',
  default: null,
})

const projectTemplateStubsLoadingState = atom<boolean>({
  key: 'projectTemplateStubsLoading',
  default: false,
})

const projectTemplateStubsErrorState = atom<string | null>({
  key: 'projectTemplateStubsError',
  default: null,
})

export type ProjectTemplateStubsOptions = ListProjectTemplatesOptions

export const useProjectTemplateStubs = (
  options?: ProjectTemplateStubsOptions
): {
  projectTemplates: ProjectTemplateStub[] | null
  loading: boolean
  error: string | null
} => {
  const [projectTemplates, setProjectTemplates] = useRecoilState(projectTemplateStubsState)
  const [loading, setLoading] = useRecoilState(projectTemplateStubsLoadingState)
  const [error, setError] = useRecoilState(projectTemplateStubsErrorState)

  const fetchTemplates = useCallback(async () => {
    setError(null)
    setLoading(true)

    const api = new ProjectTemplateStubsApi()

    try {
      const response = await api.listProjectTemplates(options)
      setProjectTemplates(response)
      setError(null)
    } catch (e) {
      setError('Error loading project templates')
    } finally {
      setLoading(false)
    }
  }, [setError, setLoading, setProjectTemplates, options])

  useEffect(() => {
    ;(async () => {
      if (error) return
      if (loading) return
      if (!projectTemplates) fetchTemplates()
    })()
  })

  return { projectTemplates, loading, error }
}
