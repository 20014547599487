import React, { FC, useCallback, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import cx from 'classnames'

import { track } from 'utils/analytics'

import { useProperties } from 'recoil/properties'
import { alertState } from 'recoil/alert'

import Modal from 'components/NewModal'

import PropertyCard from './PropertyCard'

import styles from './styles.module.scss'

interface Props {
  isManaging: boolean
}

const PropertyList: FC<Props> = ({ isManaging }) => {
  const { properties, deleteProperty } = useProperties()
  const [propertyToDelete, setPropertyToDelete] = useState<number | null>(null)
  const setAlert = useSetRecoilState(alertState)
  const [deletingProperties, setDeletingProperties] = useState<Array<number>>([])

  const handleDelete = useCallback(
    (property) => {
      setPropertyToDelete(property.id)
    },
    [setPropertyToDelete]
  )

  const handleCancelDelete = useCallback(() => {
    setPropertyToDelete(null)
  }, [])

  const handleConfirmDelete = useCallback(async () => {
    if (propertyToDelete != null) {
      track('submit form', {
        section: 'properties',
        form: 'delete-property',
        totalProperties: (properties || []).length - 1,
      })

      // Start animation
      setDeletingProperties((properties) => properties.concat(propertyToDelete))
      // Remove modal immediately.
      setPropertyToDelete(null)
      // Give some time for animation to complete.
      setTimeout(async () => {
        // Immediately remove the property when we are done animating
        // Do the actual deletion.
        const property = await deleteProperty(propertyToDelete)
        setAlert({
          type: 'SUCCESS',
          message: `Successfully removed property${property ? ` ${property.delivery_line_1}` : ''}.`,
          id: Date.now(),
        })
        // Remove from deleting list.
        setDeletingProperties((properties) => properties.filter((property) => property != propertyToDelete))
      }, 200)
    }
  }, [propertyToDelete, deleteProperty, setAlert, properties])

  return (
    <div className={styles.propertyList}>
      {(properties || []).map((property) => (
        <div
          className={cx(styles.propertySlot, { [styles.deleting]: deletingProperties.indexOf(property.id) > -1 })}
          key={property.id}
        >
          <div className={styles.animSleave}>
            <PropertyCard
              property={property}
              properties={properties || []}
              onDelete={handleDelete}
              className={styles.propertyCard}
              isManaging={isManaging}
            />
          </div>
        </div>
      ))}
      {propertyToDelete && (
        <Modal
          title="Are you sure?"
          buttons={[
            { name: 'Cancel', secondary: true },
            { name: 'Remove', onClick: handleConfirmDelete },
          ]}
          onClose={handleCancelDelete}
        >
          <p>
            {
              'You will need to contact Realm Support if you wish to recover this property after removing it from your dashboard.'
            }
          </p>
        </Modal>
      )}
    </div>
  )
}

export default PropertyList
