import React, { FC, PropsWithChildren } from 'react'

import GlobalAlert from '../components/GlobalAlert'
import OverlayMount from '../components/OverlayMount'

const Onboarding: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <GlobalAlert />
      <OverlayMount>{children}</OverlayMount>
    </>
  )
}

export default Onboarding
