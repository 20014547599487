import React, { FC, useCallback, useMemo } from 'react'
import classNames from 'classnames'

import { track } from 'utils/analytics'
import {
  ProjectCustomization,
  ProjectSelection,
  cloneProjectSelection,
  generateProjectSelection,
} from 'recoil/projectTemplates'

import InclusiveOption from './InclusiveOption'

import styles from './Inclusive.module.scss'

interface Props {
  className?: string
  projectId: string
  customization: ProjectCustomization
  values: Array<ProjectSelection>
  onChange: (key: string, selections: Array<ProjectSelection>) => void
}

const Inclusive: FC<Props> = ({ className, projectId, customization, values, onChange }) => {
  // Don't show chat_cta options in web
  const options = useMemo(() => customization.options.filter((option) => !option.show_chat_cta), [customization])
  const handleChange = useCallback(
    (optionId: number, checked: boolean) => {
      // Find the option
      const option = options.find((option) => option.id == optionId)
      if (!option) return

      const partId = option.part.id

      // If we're checked, add a selection in
      let newSelections: Array<ProjectSelection> = []
      if (checked) {
        // First clone
        newSelections = values.map((v) => cloneProjectSelection(v))
        // If we have one in here, do nothing.
        if (!newSelections.find((v) => v.value == partId)) {
          // Otherwise add.
          newSelections.push(generateProjectSelection(customization.key, option))
        }
      } else {
        // Otherwise filter the selection out
        newSelections = values.filter((v) => v.value != partId).map((v) => cloneProjectSelection(v))
      }

      track('property-plan customize project select customization', {
        projectId,
        customization: customization.id,
        customizationName: customization.name,
        option: option.id,
        optionName: option.name,
        checked: checked,
      })

      onChange(customization.key, newSelections)
    },
    [values, customization, onChange, projectId, options]
  )

  return (
    <ul className={classNames(styles.inclusive, className)}>
      {options.map((option) => {
        const isChecked = !!values.find((v) => v.value == option.part.id)
        return (
          <li key={option.id} className={styles.option}>
            <InclusiveOption cbxId={`${option.id}`} option={option} checked={isChecked} onChange={handleChange} />
            <label htmlFor={`${option.id}`}>{option.name}</label>
          </li>
        )
      })}
    </ul>
  )
}

export default Inclusive
