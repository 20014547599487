import React, { FC, useCallback } from 'react'
import { Link, Route, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

import { Package as PackageObject, PackagePriceIntervalType } from 'recoil/packages'

import { formatMoney } from 'utils/formatting'

import ImgWaves from 'svgs/property-plan/waves-2'
import ImgCircles from 'svgs/property-plan/circles'
import ImgDots from 'svgs/property-plan/dots'
import ImgLines from 'svgs/property-plan/lines'

import FancyButton from 'components/FancyButton'

import Details from './Details'
import { track } from 'utils/analytics'

// Styles
import styles from './Package.module.scss'
import TermsConsentMessage from 'components/TermsConsentMessage'

export enum PackageType {
  One,
  Two,
  Three,
  Four,
}

const CLASS_FROM_TYPE = {
  [PackageType.One]: styles.package1,
  [PackageType.Two]: styles.package2,
  [PackageType.Three]: styles.package3,
  [PackageType.Four]: styles.package4,
}
const IMG_FROM_TYPE = {
  [PackageType.One]: <ImgWaves />,
  [PackageType.Two]: <ImgLines />,
  [PackageType.Three]: <ImgDots />,
  [PackageType.Four]: <ImgCircles />,
}

const intervalTextMap = {
  [PackagePriceIntervalType.Day]: { singular: 'day', plural: 'days' },
  [PackagePriceIntervalType.Week]: { singular: 'week', plural: 'weeks' },
  [PackagePriceIntervalType.Month]: { singular: 'month', plural: 'months' },
  [PackagePriceIntervalType.Year]: { singular: 'year', plural: 'years' },
}

function formatInterval(interval: number, intervalType: PackagePriceIntervalType): string {
  const sIntervalType = intervalTextMap[intervalType]
  if (!sIntervalType) throw new Error(`Invalid interval type ${intervalType}`)

  if (interval == 1) return `${sIntervalType.singular}`
  return `${interval} ${sIntervalType.plural}`
}

function formatPrice(value: number): string {
  const ret = formatMoney(value)
  // If it ends with .00, we can drop that part.
  return ret.replace('.00', '')
}

const Footer = ({ package: packageObject, isPurchased, disabled, loading, onBuy, onBuyFree }: Props) => {
  const onBuyFreeClick = useCallback(() => onBuyFree(packageObject.id), [packageObject.id, onBuyFree])

  const onBuyClick = useCallback(() => {
    if (onBuy) onBuy(packageObject.id)
  }, [packageObject, onBuy])

  if (isPurchased) {
    return <span className={styles.purchasedMessage}>{'Package Purchased'}</span>
  }

  switch (packageObject.click_behavior) {
    case 'read_only':
      return <span className={styles.purchasedMessage}>{'Coming Soon!'}</span>
    case 'cta':
      return (
        <FancyButton
          className={classNames(styles.buyButton, loading ? styles.loading : null)}
          disabled={disabled}
          loading={loading}
          href="/contact"
        >
          {`Packages starting at ${formatPrice(packageObject.price.price)}`}
        </FancyButton>
      )
    case 'purchase':
      if (packageObject.price.price === 0) {
        return (
          <FancyButton
            className={classNames(styles.buyButton, loading ? styles.loading : null)}
            disabled={disabled}
            loading={loading}
            onClick={onBuyFreeClick}
          >
            {'Free'}
          </FancyButton>
        )
      } else {
        return (
          <>
            <span className={styles.price}>
              {formatPrice(packageObject.price.price)}
              {packageObject.price.interval != PackagePriceIntervalType.OneTime ? (
                <span className={styles.interval}>
                  {formatInterval(packageObject.price.interval_count, packageObject.price.interval)}
                </span>
              ) : null}
            </span>
            <FancyButton
              className={classNames(styles.buyButton, loading ? styles.loading : null)}
              onClick={onBuyClick}
              disabled={disabled}
              loading={loading}
            >
              {'Buy now'}
            </FancyButton>
          </>
        )
      }
  }
}

interface Props {
  package: PackageObject
  type: PackageType
  isPurchased: boolean
  loading: boolean
  disabled: boolean
  onBuy: (packageId: number) => void
  onBuyFree: (packageId: number) => void
}

const Package: FC<Props> = ({ package: packageObject, type, isPurchased, disabled, loading, onBuy, onBuyFree }) => {
  const match = useRouteMatch()

  const handleLearnMoreClick = useCallback(() => {
    track('learn more about report', { report: packageObject.name })
  }, [packageObject])

  return (
    <li className={classNames(styles.package, CLASS_FROM_TYPE[type], isPurchased ? styles.purchased : null)}>
      <div className={styles.img}>{IMG_FROM_TYPE[type]}</div>
      <div className={styles.content}>
        <h3>{packageObject.name}</h3>
        <p>
          {packageObject.description}
          <Link onClick={handleLearnMoreClick} to={`reports/${packageObject.id}`}>{`Learn more${
            packageObject.sample ? ' and download a sample' : ''
          }`}</Link>
        </p>
        <TermsConsentMessage verb="purchasing this plan" className={styles.legal} />
        <footer>
          <Footer
            package={packageObject}
            type={type}
            disabled={disabled}
            loading={loading}
            onBuy={onBuy}
            onBuyFree={onBuyFree}
            isPurchased={isPurchased}
          />
        </footer>
      </div>
      <Route path={`${match.path}/${packageObject.id}`}>
        <Details package={packageObject} />
      </Route>
    </li>
  )
}

export default Package
