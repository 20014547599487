import Carousel from 'react-slick'
import CarouselImage from './CarouselImage'
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import { Renovation, RenovationImage } from 'recoil/renovations'
import { track } from 'utils/analytics'
import { useRenovationImages } from 'recoil/renovationImages'
import Tooltip from 'components/Tooltip'
import Upload from 'svgs/upload'

import styles from './styles.module.scss'

export type RenovationImageUploadProps = {
  renovation: Renovation
  editing: boolean
}

export const RenovationImageUpload: FC<RenovationImageUploadProps> = ({ renovation, editing }) => {
  const { addImage, destroyImage } = useRenovationImages()

  const carousel = useRef<Carousel | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const add = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return
      const file = e.target.files[0]
      if (!file) return

      try {
        setIsLoading(true)
        track('change file', { section: 'renovation', renovationId: renovation.id })
        await addImage(renovation, file)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    },
    [renovation, addImage]
  )

  const remove = useCallback(
    async (image: RenovationImage) => {
      try {
        setIsLoading(true)
        track('remove file', { section: 'renovation', renovationId: renovation.id })
        await destroyImage(renovation, image)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    },
    [renovation, destroyImage]
  )

  const navToLast = useCallback(() => {
    carousel.current?.goTo((renovation.images || []).length, true)
  }, [renovation.images])

  return (
    <>
      <div className={styles.upload} style={{ backgroundColor: 'transparent' }}>
        {editing && (
          <div className={styles.label}>
            <label>{'Photos'}</label> <Tooltip overlay="Upload photos for a more accurate analysis of your project." />
          </div>
        )}
        {(renovation.images || []).length > 0 ? (
          <div className={styles.carouselContainer}>
            <Carousel ref={carousel} dots arrows={false} draggable={false}>
              {(renovation.images || []).map((image) => (
                <CarouselImage
                  key={image.id}
                  image={image}
                  remove={remove}
                  isLoading={isLoading}
                  navToLast={navToLast}
                  editing={editing}
                />
              ))}
              {editing && (
                <div className={styles.uploadImg}>
                  <input type="file" onChange={add} accept="image/*" />
                  <div className={styles.noImage}>
                    <Upload />
                    {'Click to upload'}
                  </div>
                </div>
              )}
            </Carousel>
          </div>
        ) : (
          <div className={styles.uploadImg}>
            {editing ? (
              <>
                <input type="file" onChange={add} accept="image/*" />
                <div className={styles.noImage}>
                  <Upload />
                  {'Click to upload'}
                </div>
              </>
            ) : (
              <div className={styles.placeholder}>{'Add a photo'}</div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default RenovationImageUpload
