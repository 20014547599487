import React, { FC } from 'react'

import { formatNumber } from 'utils/formatting'

import ErrorEntry from './ErrorEntry'

import styles from './ExceedsLotSizeError.module.scss'

interface Props {
  buildableSqFt: number
  requestedSqFt: number
}

const ExceedsLotSizeError: FC<Props> = ({ buildableSqFt, requestedSqFt }) => {
  return (
    <ErrorEntry className={styles.exceedsLotSizeError}>
      <div className={styles.content}>
        <span className={styles.message}>{'These projects exceed your buildable square feet limits!'}</span>
        <span className={styles.amounts}>
          <span>{`Allowed additional square feet: ${formatNumber(buildableSqFt)}`}</span>
          <span className={styles.maximum}>{`Plan additional square feet: ${formatNumber(requestedSqFt)}`}</span>
        </span>
      </div>
    </ErrorEntry>
  )
}

export default ExceedsLotSizeError
