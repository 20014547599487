import React, { FC, useCallback } from 'react'

import { ProjectSelection, ProjectCustomizationOption, cloneProjectSelection } from 'recoil/projectTemplates'

import DefaultSection from './DefaultsSection'
import SizeSection from './SizeSection'
import CustomizationSection from './CustomizationSection'

// import styles from './styles.module.scss'

interface Props {
  projectId: string
  option: ProjectCustomizationOption
  value: ProjectSelection
  onChange: (selection: ProjectSelection) => void
}

const CustomizationOptionSections: FC<Props> = ({ projectId, option, value, onChange }) => {
  const handleCustomizationChange = useCallback(
    (key: string, selections: Array<ProjectSelection>) => {
      const newRootSelection = cloneProjectSelection(value)
      // Replace All selections of the same key with the new ones specified
      const oldSelections = newRootSelection.selections || []
      newRootSelection.selections = oldSelections.filter((s) => s.key != key).concat(selections)

      onChange(newRootSelection)
    },
    [value, onChange]
  )

  // Don't show invisible customizations.
  const customizations =
    option.part.customizations?.filter((customization) => {
      // No invisible
      if (customization.invisible) return false

      // No empty
      return customization.options.filter((option) => !option.show_chat_cta).length > 0
    }) || []

  return (
    <>
      <DefaultSection
        projectId={projectId}
        defaultOption={option}
        value={value}
        onChange={onChange}
        showCustom={(option.part.customizations || []).length > 0}
      />
      <SizeSection option={option} value={value} onChange={onChange} />
      {customizations.map((customization) => {
        // Only grab the selections that match our key.
        const selections = (value.selections || []).filter((s) => s.key == customization.key)
        return (
          <CustomizationSection
            key={customization.id}
            projectId={projectId}
            customization={customization}
            values={selections}
            onChange={handleCustomizationChange}
          />
        )
      })}
    </>
  )
}

export default CustomizationOptionSections
