import React, { FC, useCallback, useEffect, useState } from 'react'
import { Attachment, Property, useProperties } from 'recoil/properties'
import styles from './styles.module.scss'

import Document from 'svgs/document'
import Upload from 'svgs/upload'
import DocumentUpload from 'pages/EditProperty/Documents/DocumentUpload'
import { track } from 'utils/analytics'

export interface OtherFilesUploadProps {
  property: Property
}

export const OtherFilesUpload: FC<OtherFilesUploadProps> = ({ property }) => {
  const { attachFilesToSelectedProperty, deleteFileFromSelectedProperty } = useProperties()

  const [attachments, setAttachments] = useState<Array<Attachment>>([])

  useEffect(() => {
    setAttachments(property.attachments)
  }, [property.attachments])

  const changeFile = useCallback(() => {
    return async (file: File) => {
      track('change file', { section: 'other' })
      await attachFilesToSelectedProperty([file])
    }
  }, [attachFilesToSelectedProperty])

  const removeFile = useCallback(() => {
    return async (fileId: number) => {
      track('remove file', { section: 'other' })
      setAttachments(attachments.filter((x) => x.id != fileId))
      await deleteFileFromSelectedProperty(fileId)
    }
  }, [attachments, setAttachments, deleteFileFromSelectedProperty])

  {
    return (
      <>
        {attachments.map((file) => {
          return (
            <DocumentUpload
              key={file.name}
              doc={file}
              icon={<Document />}
              handleChange={changeFile()}
              handleRemove={removeFile()}
            />
          )
        })}

        <div className={styles.uploadRow}>
          <DocumentUpload
            icon={<Upload />}
            title="Click to upload "
            showLabel={false}
            className={styles.otherFileUpload}
            handleChange={changeFile()}
            handleRemove={removeFile()}
          />
        </div>
      </>
    )
  }
}

export default OtherFilesUpload
