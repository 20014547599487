import React, { FC, useCallback, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import cx from 'classnames'

import propertyPath from 'utils/propertyPath'

import { useProperties, UProperty, Property } from 'recoil/properties'

import Auth from 'layouts/Auth'
import Secure from 'layouts/Secure'
import { missingInformation } from 'layouts/HasProperty'

import Tooltip from 'components/Tooltip'
import PageTitle from 'components/PageTitle'
import Loading from 'components/Loading'
import Input from 'components/Input'
import Button from 'components/Button2'

import styles from './styles.module.scss'

const MAX_ROOMS = 100
const MAX_SQUARE_FOOTAGE = 100000

const MissingInformation: FC = () => {
  const history = useHistory()
  const { selectedProperty, properties, updateSelectedProperty } = useProperties()
  const [bedrooms, setBedrooms] = useState('')
  const [bathrooms, setBathrooms] = useState('')
  const [halfBathrooms, setHalfBathrooms] = useState('')
  const [squareFootage, setSquareFootage] = useState('')
  const [errors, setErrors] = useState<{ [field: string]: string[] }>({})

  const validateProperty = useCallback(
    (update: UProperty) => {
      const bathrooms = update.bathrooms || selectedProperty?.bathrooms || 0
      const halfBathrooms = update.half_bathrooms || selectedProperty?.half_bathrooms || 0
      const bedrooms = update.bedrooms || selectedProperty?.bedrooms || 0

      // Locally validate our fields (should match or exceed server-side validation)
      if (bathrooms + halfBathrooms <= 0) {
        throw { field: 'bathrooms', error: 'Must have at least one bathroom' }
      }
      if (bedrooms <= 0) {
        throw { field: 'bedrooms', error: 'Must have at least one bedroom' }
      }
    },
    [selectedProperty]
  )

  const handleSubmit = useCallback(async () => {
    function errorParseInt(field: string, value: string) {
      const iValue: number | undefined = parseInt(value)
      if (isNaN(iValue)) throw { field: field, error: 'Must be a whole number' }
      return iValue
    }

    try {
      const updates: UProperty = {}
      if (!selectedProperty?.bedrooms) updates.bedrooms = errorParseInt('bedrooms', bedrooms)
      if (!selectedProperty?.bathrooms) updates.bathrooms = errorParseInt('bathrooms', bathrooms)
      if (typeof selectedProperty?.half_bathrooms === 'undefined')
        updates.half_bathrooms = errorParseInt('half_bathrooms', halfBathrooms)
      if (!selectedProperty?.square_footage) updates.square_footage = errorParseInt('square_footage', squareFootage)

      validateProperty(updates)

      await updateSelectedProperty(updates)
      // Redirect once we're done.
      history.push(`${propertyPath(properties || [], selectedProperty as Property)}`)
    } catch (e) {
      setErrors({ [e.field]: [e.error] })
    }
  }, [
    properties,
    selectedProperty,
    bedrooms,
    bathrooms,
    halfBathrooms,
    squareFootage,
    validateProperty,
    updateSelectedProperty,
    history,
  ])

  // Just in case we're still loading for some reason.
  if (!selectedProperty) return <Loading />
  // Redirect if we're here by mistake.
  if (!missingInformation(selectedProperty)) return <Redirect to="/" />

  return (
    <Secure>
      <PageTitle title="Missing Information" />
      <Auth>
        <Auth.Body>
          <Auth.Body.Header>{"Looks like we're missing some information!"}</Auth.Body.Header>
          <Auth.Body.Subheader>
            {'In order to give you the most accurate estimated home value, we need some important information about '}
            <span className="tw-font-bold">{selectedProperty.delivery_line_1}</span>
            {'.'}
          </Auth.Body.Subheader>

          <hr className="tw-my-6" />
          <form
            className={cx('tw-w-full tw-max-w-sm lg:tw-max-w-md tw-mt-6 lg:tw-mt-12 tw-mb-12', styles.form)}
            onSubmit={handleSubmit}
          >
            <fieldset className="tw-space-y-4">
              {!selectedProperty?.bedrooms && (
                <Input
                  errors={errors?.bedrooms}
                  id="bedrooms"
                  label="Bedrooms"
                  max={MAX_ROOMS}
                  min={1}
                  placeholder="Enter the number of bedrooms"
                  required
                  type="number"
                  value={bedrooms}
                  onChange={setBedrooms}
                />
              )}
              {!selectedProperty?.bathrooms && (
                <Input
                  errors={errors?.bathrooms}
                  id="bathrooms"
                  label="Bathrooms"
                  max={MAX_ROOMS}
                  min={1}
                  placeholder="Enter the number of bathrooms"
                  required
                  type="number"
                  value={bathrooms}
                  onChange={setBathrooms}
                />
              )}
              {typeof selectedProperty?.half_bathrooms == 'undefined' && (
                <Input
                  errors={errors?.half_bathrooms}
                  id="partial-bathrooms"
                  label="Partial bathrooms"
                  tooltip={
                    <Tooltip className="tw-top-0 tw-text-base-gray-two" overlay="A bathroom without a shower." />
                  }
                  max={MAX_ROOMS}
                  min={0}
                  placeholder="Enter the number of half bathrooms"
                  type="number"
                  value={halfBathrooms}
                  onChange={setHalfBathrooms}
                />
              )}
              {!selectedProperty?.square_footage && (
                <Input
                  errors={errors?.square_footage}
                  id="square-footage"
                  label="Home square feet"
                  max={MAX_SQUARE_FOOTAGE}
                  min={1}
                  placeholder="Enter the square footage"
                  required
                  type="number"
                  value={squareFootage}
                  onChange={setSquareFootage}
                />
              )}
            </fieldset>
            <Button className="tw-w-full tw-max-w-sm lg:tw-max-w-xs tw-mt-12 lg:tw-mt-16 tw-mx-auto" type="submit">
              {'Looks good'}
            </Button>
          </form>
        </Auth.Body>
      </Auth>
    </Secure>
  )
}

export default MissingInformation
