import React, { FC } from 'react'
import classNames from 'classnames'

import FancyButton from 'components/FancyButton'

import styles from './styles.module.scss'

const FakeProject: FC = () => (
  <li className={classNames(styles.project, styles.fake)}>
    <div className={styles.content}>
      <span className={styles.img}></span>
      <div className={styles.row}>
        <div className={styles.title}>
          <span className={styles.name}>{'X'}</span>
        </div>
        <div className={styles.cells}>
          <div className={classNames(styles.cell, styles.homeValue)}>
            <label>{'Home Value'}</label>
            <span className={styles.value}>{'X'}</span>
          </div>
          <div className={classNames(styles.cell, styles.costEstimate)}>
            <label>{'Cost'}</label>
            <span className={styles.value}>{'X'}</span>
          </div>
          <div className={classNames(styles.cell, styles.recoup)}>
            <label>{'% Recoup'}</label>
            <span className={styles.value}>{'X'}</span>
          </div>
          <div className={classNames(styles.cell, styles.customize)}>
            <label>{''}</label>
            <FancyButton className={classNames(styles.button, styles.disabled)} disabled>
              {'Customize'}
            </FancyButton>
          </div>
        </div>
      </div>
      <span className={styles.closePlaceholder} />
    </div>
  </li>
)

export default FakeProject
