import React from 'react'

import defaultConfig, { DefaultConfigType } from './defaultConfig'

export interface FirebaseContextType {
  loaded: boolean
  config: DefaultConfigType
}
const FirebaseContext = React.createContext<FirebaseContextType>({
  loaded: false,
  config: defaultConfig,
})

export default FirebaseContext
