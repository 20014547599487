import React, { FC, useState, useEffect, useCallback, useRef } from 'react'
import classNames from 'classnames'

import { track } from 'utils/analytics'
import { EXPIRES_GRACE_PERIOD } from 'utils/projectTemplates'

import { ProjectTemplate as Template } from 'recoil/projectTemplates'
import { useProjects } from 'recoil/projects'

import FallbackIcon from 'svgs/property-plan/fallback'

import CallToAction from 'components/CallToAction'
import ExternalSvg from 'components/ExternalSvg'

import ErrorFlag from '../ErrorFlag'
import NotExpiredFlag from '../NotExpiredFlag'
import InfoFlag from '../InfoFlag'
import Arrow from '../InfoFlag/Arrow2'
import { userState } from 'recoil/user'
import { useRecoilValue } from 'recoil'

import styles from './styles.module.scss'

interface Props {
  template: Template
  index: number
  onSelected: (templateId: number) => void
}

const Template: FC<Props> = (props) => {
  const { template, index, onSelected } = props
  const componentRef = useRef<HTMLLIElement>(null)
  const { projects } = useProjects()
  const user = useRecoilValue(userState)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Capture only the first time we're visible.
    if (isVisible) return
    if (!componentRef || !componentRef.current) return

    const target = componentRef.current

    let timer = 0

    const observer = new IntersectionObserver(
      (entries: Array<IntersectionObserverEntry>) => {
        if (entries.length > 0 && entries[0].isIntersecting) {
          // Provide a minor delay in setting it visible
          timer = setTimeout(() => setIsVisible(true), 1000) as unknown as number
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      }
    )

    observer.observe(target)

    return () => {
      if (observer && target) {
        observer.unobserve(target)
      }
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isVisible])
  const addProject = useCallback(
    (name: string) => (e) => {
      if (!user) return
      e.stopPropagation()

      track(
        'submit form',
        {
          section: 'property-plan',
          form: 'suggested-projects',
          action: 'create',
          element: name,
          template: {
            id: template.id,
            kind: template.kind,
          },
        },
        {
          id: 24231,
          args: {
            orderId: `project-${user.id}`,
            customerId: user.id,
          },
        }
      )
      onSelected(template.id)
    },
    [template, onSelected, user]
  )

  const addProjectFromKey = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key == 'Enter') addProject('keyboard')(e)
    },
    [addProject]
  )

  const isAdded = projects.some((p) => p.kind == template.kind)
  const addAnother = isAdded && template.available.success
  const unavailable = !isAdded && !template.available.success

  const expirable = typeof template.expires_in !== 'undefined'
  const expiring = typeof template.expires_in !== 'undefined' && template.expires_in < EXPIRES_GRACE_PERIOD

  return (
    <li
      key={template.id}
      className={classNames(styles.template, unavailable ? styles.unavailable : null)}
      ref={componentRef}
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.item}
        onClick={addProject('card')}
        onKeyPress={addProjectFromKey}
      >
        <ExternalSvg className={styles.img} uri={template.icon_svg}>
          <FallbackIcon className={styles.img} />
        </ExternalSvg>
        <div className={styles.row}>
          <div className={styles.content}>
            <div className={styles.details}>
              <h3 className={styles.name}>{template.name}</h3>
              <p className={styles.description}>{template.description}</p>
            </div>
            <div className={styles.actions}>
              {isAdded && !addAnother ? (
                <div className={classNames(styles.fakeButton)}>{'Added'}</div>
              ) : unavailable ? (
                <div className={classNames(styles.fakeButton, styles.disabled)}>{'Not approved'}</div>
              ) : (
                <>
                  <CallToAction
                    className={styles.cta}
                    value={addAnother ? `Add another` : `Add project`}
                    negative={!template.available.success}
                  />
                </>
              )}
              {index == 0 && !isAdded && !unavailable && isVisible && <Arrow className={styles.arrow} />}
            </div>
          </div>
          {index == 0 && !isAdded && !unavailable && (
            <InfoFlag>
              <strong>{'Top recommended project!'}</strong>
              {
                ' Adding projects to your plan allows you to see cost and recoup estimates, as well as full customization options. '
              }
              <button type="button" className={styles.infoLink} onClick={addProject('add-it-now-link')}>
                {'Add it now.'}
              </button>
            </InfoFlag>
          )}
          {unavailable ? (
            <ErrorFlag
              className={styles.errorFlag}
              errorType={template.available.error?.code}
              message={template.available.error?.message}
            />
          ) : expirable && !expiring ? (
            <NotExpiredFlag className={styles.errorFlag} isFromRenovation={!!template.is_related_to_renovation} />
          ) : null}
        </div>
      </div>
    </li>
  )
}

export default Template
