import React, { FC, PropsWithChildren } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userState } from 'recoil/user'
import { hasNoPropertiesState, propertiesErrorState } from 'recoil/properties'
import { DEFAULT_INTERNAL_FLOW } from 'recoil/onboardingFlows/states'

interface Props {
  isManaging: boolean
}

const HasServicableProperties: FC<PropsWithChildren<Props>> = ({ children, isManaging }) => {
  const hasNoProperties = useRecoilValue(hasNoPropertiesState)
  const propertiesError = useRecoilValue(propertiesErrorState)
  const user = useRecoilValue(userState)

  // If we were in managing mode, then we were probably deleting things.
  // We can show /onboarding in this case.
  if (isManaging && hasNoProperties) {
    return <Redirect to={{ pathname: '/onboarding', state: { flow: DEFAULT_INTERNAL_FLOW } }} />
  }

  if (propertiesError) {
    return (
      <div className="tw-bg-white tw-shadow tw-my-16 tw-py-12 tw-px-6 tw-rounded">
        <h2 className="font-title tw-mb-6">{'Realm has encountered an Error'}</h2>
        <p>{'Please try to refresh the page. We have been notified about this error and will soon fix it.'}</p>
      </div>
    )
  }

  if (hasNoProperties) {
    return (
      <div className="tw-bg-white tw-shadow tw-my-16 tw-py-12 tw-px-6 tw-rounded">
        <h2 className="font-title tw-mb-6">{'Thank you for your interest in Realm!'}</h2>
        {/* if we don't have properties, but also we don't have entered_address, then it's a SSO user, has no property outside serviceable area */}
        {user?.entered_address && (
          <>
            <p className="tw-mb-3">
              {"Your property is outside of our serviceable area or a property type we can't support."}
            </p>
            <p className="tw-mb-3">
              {"We'll email you as soon as we're live in your county or can support your property!"}
            </p>
          </>
        )}
        <p>
          <Link
            className="tw-text-moss hover:tw-underline"
            to={{ pathname: '/onboarding', search: '?n', state: { flow: DEFAULT_INTERNAL_FLOW } }}
          >{`Add a new property${user?.entered_address ? ' in our service area' : ''}`}</Link>
        </p>
      </div>
    )
  }

  return <>{children}</>
}

export default HasServicableProperties
