import React, { FC, useCallback } from 'react'

import { track } from 'utils/analytics'

import HandleUrlQuery from 'non-rendering/HandleURLQuery'

import { useProjectTemplates } from 'recoil/projectTemplates'
import { useProjects } from 'recoil/projects'
import { useRecoilValue } from 'recoil'
import { userState } from 'recoil/user'

const ADD_TEMPLATE_SEARCH_PARAM = 'add-template'

const HandleAddProjectsQuery: FC = () => {
  const { addProject } = useProjects()
  const { projectTemplates } = useProjectTemplates()

  const user = useRecoilValue(userState)

  const handleQuery = useCallback(
    async (key, value) => {
      if (!user) return
      // Find out if it is a template or a strategy
      if (key == ADD_TEMPLATE_SEARCH_PARAM) {
        // Make it easy on ourselves for now and do this serialized
        for (const templateId of value) {
          // Only attempt to add the project template if we know
          // about it locally.
          const template = projectTemplates.find((t) => t.id == templateId)
          if (template) {
            track(
              'submit form',
              {
                section: 'property-plan',
                form: 'url-query-string',
                action: 'create',
                template: {
                  id: template.id,
                  kind: template.kind,
                },
              },
              {
                id: 24231,
                args: {
                  orderId: `project-${user.id}`,
                  customerId: user.id,
                },
              }
            )
            await addProject({ project_template_id: template.id })
          } else {
            console.error(`Failed to add unknown Project Template '${templateId}' as project!`)
          }
        }
      }
    },
    [addProject, projectTemplates, user]
  )

  return <HandleUrlQuery query={[ADD_TEMPLATE_SEARCH_PARAM]} onHandle={handleQuery} />
}

export default HandleAddProjectsQuery
