import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TIMES_TO_START, TimeToStart, useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { formatAddressCasing } from 'utils/formatting'
import CoreText from 'components/Core/CoreText'

import CoreList from 'components/Core/CoreList'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import { prepareBoth } from 'utils/analyticsV2'
import { track as legacyTrack } from 'utils/analytics'

interface RenovationReadinessProps {
  onDismiss?: (timeToStart?: TimeToStart) => void
}

const ACTION_READY_STATES: TimeToStart[] = [
  'renovation_asap',
  'renovation_within_year',
  'renovation_year_plus',
  'renovation_need_help_deciding',
]

const [trackClick, trackImpression] = prepareBoth({ family: 'onboarding', screen: 'renovations' })

export const RenovationReadiness: FC<RenovationReadinessProps> = ({ onDismiss }) => {
  const { onboarding, setOnboarding } = useOnboardingProperty()
  const { next } = useFlow()
  const [timeToStart, setTimeToStart] = useState<TimeToStart | null>(null)

  const propertyAddress = useMemo(() => {
    const rawAddress = onboarding.publicPropertyData?.public_property.propertyaddressfull
    if (rawAddress) return formatAddressCasing(rawAddress)

    const parts = onboarding.enteredAddress?.split(',')
    if (!parts) return
    if (parts.length < 1) return

    return parts[0]
  }, [onboarding.publicPropertyData?.public_property.propertyaddressfull, onboarding.enteredAddress])

  const handlePick = useCallback((key: TimeToStart) => {
    return () => {
      trackClick({ code: 'start-renovation', section: key })
      setTimeToStart(key)
    }
  }, [])

  const getDismissHandler = useCallback(() => {
    if (!onDismiss) return

    return () => onDismiss()
  }, [onDismiss])

  useEffect(() => {
    if (timeToStart) {
      setOnboarding((prev) => ({ ...prev, timeToStart }))
      if (ACTION_READY_STATES.includes(timeToStart)) legacyTrack('action_ready')
      if (onDismiss) onDismiss(timeToStart)
      else next()
    }
  }, [next, onDismiss, timeToStart, setOnboarding])

  useEffect(() => {
    TIMES_TO_START.forEach((time) => trackImpression({ code: 'start-renovation', section: time.key }))
  }, [])

  return (
    <Wrapper
      backStyle={onDismiss ? undefined : 'active'}
      forwardStyle={onDismiss ? undefined : onboarding.timeToStart ? 'active' : 'disabled'}
      onDismiss={getDismissHandler()}
    >
      <CoreText.Headline
        size="xs"
        color="primaryBlack"
        weight="heavy"
        className="tw-mb-6"
      >{`When do you hope to start renovating ${propertyAddress}?`}</CoreText.Headline>
      <CoreList className="tw-space-y-2">
        {TIMES_TO_START.map(({ key, text }) => {
          return (
            <CoreList.Item
              className="tw-rounded-2xl tw-bg-white tw-p-6"
              key={key}
              text={text}
              onClick={handlePick(key)}
              selected={key == onboarding.timeToStart}
            />
          )
        })}
      </CoreList>
    </Wrapper>
  )
}

export default RenovationReadiness
