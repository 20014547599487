import CoreCard from 'components/Core/CoreCard'
import React, { FC, useCallback, useMemo } from 'react'

import Kerry from 'images/onboarding-v3/kerry.png'

import styles from './BookSmall.module.scss'

interface BookSmallProps {
  onExpand: () => void
  copyMode: 'primary' | 'alternate'
}

const BookSmall: FC<BookSmallProps> = ({ onExpand, copyMode }) => {
  const switchToFull = useCallback(() => {
    onExpand()
  }, [onExpand])

  const header = useMemo(() => {
    return <img src={Kerry} className={styles.avatar} alt="A headshot of a Realm Advisor" />
  }, [])

  const bodyText = useMemo(() => {
    if (copyMode == 'primary')
      return 'Book a meeting with a Realm Advisor to share details about your renovation so we can give you accurate pricing'

    return 'Prefer a human? Book a 30-min meeting with a Realm Advisor to discuss your renovation goals, budget, and timeline'
  }, [copyMode])

  return (
    <CoreCard
      className="tw-bg-neutrals-200"
      header={header}
      headerStyle={styles.header}
      description={bodyText}
      buttonText="Book meeting"
      onButtonClick={switchToFull}
      buttonSize="l"
    />
  )
}

export default BookSmall
