import { FC, useEffect, useRef } from 'react'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'
import { trackPage } from 'utils/analytics'

interface Props {
  routes: Array<RouteProps & { name?: string; ignoreTracking?: boolean }>
  customParams?: Record<string, string>
}
export const AnalyticsPageTracker: FC<Props> = ({ routes, customParams }) => {
  const location = useLocation()
  const customParamsRef = useRef<Record<string, string> | undefined>()

  useEffect(() => {
    customParamsRef.current = customParams
  }, [customParams])

  useEffect(() => {
    const route = routes.find((route) => matchPath(location.pathname, route))
    if (route?.ignoreTracking) return

    trackPage(route?.name, customParamsRef.current)
  }, [location.pathname, routes])

  return null
}

export default AnalyticsPageTracker
