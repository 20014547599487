import React, { FC } from 'react'

import ChangePasswordForm from 'pages/ChangePassword/ChangePasswordForm'

const MagicSignIn: FC = () => {
  const resetPasswordToken = new URLSearchParams(location.search).get('first_time_token') || undefined

  return <ChangePasswordForm resetPasswordToken={resetPasswordToken} />
}

export default MagicSignIn
