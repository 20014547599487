import React, { FC } from 'react'

const ArrowEast: FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon
        fill="currentColor"
        fillRule="nonzero"
        points="5 9 6.41 10.41 11 5.83 11 22 13 22 13 5.83 17.59 10.42 19 9 12 2"
        transform="rotate(90,12,12)"
      ></polygon>
    </g>
  </svg>
)

export default ArrowEast
