import React, { FC } from 'react'

export interface Props {
  title?: string
  subTitle?: string
  className?: string
}

const Header: FC<Props> = ({ className, title, subTitle }) => {
  return (
    <div className={className}>
      {title && (
        <h1 className="tw-font-normal tw-tracking-tight tw-text-center tw-m-0 tw-text-2xl lg:tw-leading-10 lg:tw-text-4xl">
          {title}
        </h1>
      )}

      {subTitle && (
        <p className="tw-font-normal tw-text-center tw-m-0 tw-mt-3 tw-text-gray-490 tw-max-w-sm">{subTitle}</p>
      )}
    </div>
  )
}

export default Header
