import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import CoreButton from 'components/Core/CoreButton'

import { ReferralPayout } from 'recoil/payments'

import RenovationFinanceImg from 'images/payments/renovationFinance.png'
import GreenGradientBg from 'images/payments/greenGradientBg.jpg'

import Link from '@mui/icons-material/Link'
import copyToClipboard from 'copy-to-clipboard'
import cx from 'classnames'
import { formatMoneyNoDecimals } from 'utils/formatting'

interface ReferAFriendCardProps {
  referralLink?: string
  referralPayouts: Array<ReferralPayout>
}

const ReferAFriendCard: FC<ReferAFriendCardProps> = ({ referralLink, referralPayouts }) => {
  const [copied, setCopied] = useState(false)

  const handleCopyLink = useCallback(() => {
    if (!referralLink) return

    copyToClipboard(referralLink)
    setCopied(true)
  }, [referralLink])

  useEffect(() => {
    let timeout
    if (copied) {
      timeout = setTimeout(() => setCopied(false), 1500)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [copied])

  const totalReferrer = useMemo(() => {
    return (
      (referralPayouts || []).reduce((sum, payout) => {
        if (payout.target != 'referrer') return sum

        return sum + payout.amount_in_cents
      }, 0) / 100
    )
  }, [referralPayouts])

  const totalReferee = useMemo(() => {
    return (
      (referralPayouts || []).reduce((sum, payout) => {
        if (payout.target != 'referee') return sum

        return sum + payout.amount_in_cents
      }, 0) / 100
    )
  }, [referralPayouts])

  const actionMessage = useMemo(() => {
    if ((referralPayouts || []).length == 0) return 'become a client'

    const kindToMessages = [
      { kind: 'meeting_scheduled', message: 'schedule a meeting with an advisor' },
      { kind: 'referrer_set', message: 'meet with an advisor' },
      { kind: 'meeting_held', message: 'meet with an advisor' },
      { kind: 'deposit_paid', message: 'pay a deposit' },
      { kind: 'contract_signed', message: 'start a project' },
    ]
    const order = kindToMessages.map((obj) => obj.kind)
    const map = kindToMessages.reduce((map, obj) => ({ ...map, [obj.kind]: obj.message }), {})

    const sorted = [...referralPayouts].sort((a, b) => order.indexOf(a.kind) - order.indexOf(b.kind))
    return map[sorted[0].kind]
  }, [referralPayouts])

  const actionMessageWithoutLink = useMemo(() => {
    if ((referralPayouts || []).length == 0) return 'mention your name during their Advisor meeting and become a client'

    const kindToMessages = [
      { kind: 'meeting_scheduled', message: 'mention your name during their Advisor meeting' },
      { kind: 'referrer_set', message: 'mention your name during their Advisor meeting' },
      { kind: 'meeting_held', message: 'mention your name during their Advisor meeting' },
      { kind: 'deposit_paid', message: 'mention your name during their Advisor meeting and pay a deposit' },
      {
        kind: 'contract_signed',
        message: 'mention your name during their Advisor meeting and start a project with us',
      },
    ]
    const order = kindToMessages.map((obj) => obj.kind)
    const map = kindToMessages.reduce((map, obj) => ({ ...map, [obj.kind]: obj.message }), {})

    const sorted = [...referralPayouts].sort((a, b) => order.indexOf(a.kind) - order.indexOf(b.kind))
    return map[sorted[0].kind]
  }, [referralPayouts])

  const amountMessage = useMemo(() => {
    const multipleReferrers = (referralPayouts || []).filter((payout) => payout.target == 'referrer').length > 1
    const multipleReferees = (referralPayouts || []).filter((payout) => payout.target == 'referee').length > 1

    if (totalReferrer && totalReferrer == totalReferee) {
      return `you will both receive${multipleReferrers || multipleReferees ? ' up to' : ''} ${formatMoneyNoDecimals(totalReferrer)} as a thank-you!`
    }

    let msg = ''
    if (totalReferrer > 0) {
      msg += `you will receive${multipleReferrers ? ' up to' : ''} ${formatMoneyNoDecimals(totalReferrer)}`
    }
    if (totalReferee > 0) {
      if (msg) msg += ' and '
      msg += `they will receive${multipleReferees ? ' up to' : ''} ${formatMoneyNoDecimals(totalReferee)}`
    }
    if (!msg) msg = 'they will enjoy a great renovation experience.'
    else msg += ' as a thank-you!'
    return msg
  }, [referralPayouts, totalReferrer, totalReferee])

  return (
    <div
      className="tw-rounded-5xl tw-p-6 tw-space-y-4 tw-bg-right tw-bg-white tw-bg-no-repeat"
      style={{ backgroundImage: `url(${GreenGradientBg})` }}
    >
      <div className="md:tw-flex tw-gap-4 tw-space-y-4 md:tw-space-y-0">
        <div className="tw-w-20 tw-shrink-0">
          <img className="tw-w-full" src={RenovationFinanceImg} alt="A hand with some money" />
        </div>
        <div className="tw-space-y-2">
          <div className="tw-text-lg tw-font-semibold">
            {totalReferrer > 0
              ? `Want ${formatMoneyNoDecimals(totalReferrer)} towards your renovation?`
              : totalReferee > 0
                ? `Want to give ${formatMoneyNoDecimals(totalReferee)} to a friend?`
                : 'Want to help out a friend?'}
          </div>
          <div className="tw-text-md tw-font-normal tw-text-secondary-black">
            {referralLink
              ? `Share Realm with someone you know! If they ${actionMessage}, ${amountMessage}`
              : `Share Realm with someone you know! If they ${actionMessageWithoutLink}, ${amountMessage}`}
          </div>
        </div>
      </div>
      {!!referralLink && (
        <div className="tw-relative">
          <CoreButton
            kind="ghost"
            text="Copy your share link"
            onClick={handleCopyLink}
            icon={<Link />}
            size="m"
            className="tw-w-full"
          />

          <div
            className={cx(
              'tw-absolute tw-transition-opacity tw-bg-neutrals-800 tw-text-white tw-py-1 tw-px-2 tw-text-xs tw-rounded-lg -tw-top-4 -tw-right-1 tw-shadow-lg',
              { ['tw-opacity-100']: !!copied, ['tw-opacity-0']: !copied }
            )}
          >
            {'Referral link copied to clipboard!'}
          </div>
        </div>
      )}
      <div className="tw-text-2xs tw-text-tertiary-black">
        {'Applies to new Realm customers in CA or Seattle. Referrers must sign up for Realm to receive the incentive.'}
      </div>
    </div>
  )
}

export default ReferAFriendCard
