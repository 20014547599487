import React, { CSSProperties, FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import cx from 'classnames'
import RealmLogo from 'components/MarketingSite/Header/RealmLogo'
import Close from 'svgs/close'
import CoreButton from 'components/Core/CoreButton'
import { useFlow } from 'recoil/onboardingFlows'
import Progress from 'components/Progress'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import ArrowDownward from '@mui/icons-material/ArrowDownward'

import { useHistory } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'

import styles from './styles.module.scss'
import animationStyles from '../../FlowAnimator.module.scss'
import { useWindowHeight } from 'utils/windowHeight'

interface WrapperProps {
  className?: string
  innerClassName?: string
  logoClassName?: string
  onDismiss?: () => void
  footer?: {
    text: string
    onClick: () => void
  }
  progressStyle?: 'active' | 'disabled' | 'error'
  backStyle?: 'active' | 'disabled'
  forwardStyle?: 'active' | 'disabled'
  allowOverflow?: boolean
  style?: CSSProperties
  animationStyle?: string
}

// keeping here (and not in recoil dir), as it only applies to this page
const storedProgressState = atom<number | undefined>({
  key: 'onboardingV3WrapperStoredProgress',
  default: undefined,
})

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  children,
  className,
  innerClassName,
  logoClassName,
  footer,
  onDismiss,
  progressStyle = 'active',
  backStyle,
  forwardStyle,
  allowOverflow,
  style,
  animationStyle,
}) => {
  const { currentState, next } = useFlow()
  const history = useHistory()
  const [storedProgress, setStoredProgress] = useRecoilState(storedProgressState)
  const windowHeight = useWindowHeight()

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleGoForward = useCallback(() => {
    next()
  }, [next])

  // keep the progress in recoil, and only update it on render
  // this will make it animate
  useEffect(() => {
    setStoredProgress(currentState?.progressStep)
  }, [setStoredProgress, currentState?.progressStep])

  const wrapperStyle = useMemo(() => {
    if (allowOverflow) return style

    return { height: windowHeight, ...style }
  }, [allowOverflow, style, windowHeight])

  return (
    <div className={cx(styles.wrapper, className)} style={wrapperStyle}>
      <div className={cx(styles.grid, { [styles.allowOverflow]: allowOverflow })}>
        <div className={cx(styles.logoWrapper, logoClassName, animationStyles.switch)}>
          <a href="/">
            <RealmLogo className={styles.logo} />
          </a>
        </div>
        <div className={cx(styles.nav, animationStyles.appear)}>
          <div>
            {backStyle && (
              <CoreButton
                onClick={handleGoBack}
                kind={backStyle == 'disabled' ? 'disabled' : 'secondary'}
                disabled={backStyle == 'disabled'}
                className={cx(styles.navButton, styles.back)}
                icon={<ArrowUpward />}
              />
            )}
          </div>
          <div>
            {forwardStyle && (
              <CoreButton
                onClick={handleGoForward}
                kind={forwardStyle == 'disabled' ? 'disabled' : 'secondary'}
                disabled={forwardStyle == 'disabled'}
                className={cx(styles.navButton, styles.forward)}
                icon={<ArrowDownward />}
              />
            )}
          </div>
        </div>
        <div className={styles.inner}>
          {currentState?.progressMax && (
            <Progress
              className={cx(styles.progress, animationStyles.delay, { [styles.allowOverflow]: allowOverflow })}
              indicatorClassName={cx(styles.indicator, {
                [styles.disabled]: progressStyle == 'disabled',
                [styles.error]: progressStyle == 'error',
              })}
              current={storedProgress}
              max={currentState?.progressMax}
            />
          )}

          <div
            className={cx(
              styles.scroll,
              animationStyle || animationStyles.swipe,
              { [styles.leaveFooterSpace]: !!footer, [styles.allowOverflow]: allowOverflow },
              innerClassName
            )}
          >
            {onDismiss && (
              <button type="button" className={styles.close} onClick={onDismiss}>
                <Close />
              </button>
            )}
            {children}
          </div>
          {footer && (
            <div className={styles.footerWrapper}>
              <CoreButton text={footer.text} onClick={footer.onClick} className={styles.footerButton} />
            </div>
          )}
        </div>
        <div className={styles.spacer} />
      </div>
    </div>
  )
}

export default Wrapper
