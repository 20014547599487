import cx from 'classnames'
import React, { FC } from 'react'

interface PlusCircleProps {
  className?: string
}

const PlusCircle: FC<PlusCircleProps> = ({ className }) => (
  <svg className={cx('plus-circle', className)} fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
)

export default PlusCircle
