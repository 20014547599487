import React, { FC } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

import { useFlow } from 'recoil/onboardingFlows'

import OnboardingStarter from './OnboardingStarter'

import onboardingRoutes from 'non-rendering/onboardingRoutes'
import FlowAnimator from 'pages/Onboarding/FlowAnimator'

const OnboardingFlowRoutes: FC = () => {
  const location = useLocation()
  const { flow, states } = useFlow()

  return (
    <FlowAnimator>
      <Switch location={location}>
        {states.map((state) => {
          // State must be in the component map to be routable
          const component = onboardingRoutes.find((route) => route.name == state.name)?.component
          if (!component) {
            throw new Error(`No component mapped for OnboardingFlow State '${state.name}' for flow '${flow}'.`)
          }
          return <Route key={state.name} path={state.url} component={component} />
        })}
        {/* If they ask for a page that isn't in the flow, force them
          to the current flow page; otherwise send them back to the
          beginning of the flow */}
        <Route component={OnboardingStarter} />
      </Switch>
    </FlowAnimator>
  )
}

export default OnboardingFlowRoutes
