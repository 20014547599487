import React, { FC, useMemo, useEffect, useState, PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { useLocation } from 'react-router-dom'

import { onboardingState } from 'recoil/onboarding'

export interface SsoStateProperties {
  propertyId?: number | null
  attomId?: string | null
  campaignId?: string | null
  options: Record<string, any>
  token?: string
}

export function generateSsoState(props: SsoStateProperties): string {
  const token = props.token || new Date().getTime().toString(16)
  const options = {
    ...props.options,
    attom_id: props.attomId,
    property_id: props.propertyId,
    campaign_id: props.campaignId,
    // In the previous implementation, 'token' was always a stringified version
    // of the current time. I assume this was being used as a cache breaker, so
    // I'm going to leave it in; however I don't know it is strictly necessary.
    token: token,
  }
  // This should encode the same way rails encodes via obj.to_param
  let state = to_param(options)
  // Tell the other side to decide using v2 decoding
  state = `v2-${state}`
  // To transfer across on a single SSO param, itself needs to be encoded.
  return encodeURIComponent(state)
}

function to_param(obj: any): string {
  return Object.keys(obj)
    .filter((e) => obj[e]) // Make sure it has a value.
    .reduce((memo, key) => {
      const value = obj[key]
      return [...memo, ...to_query(key, value)]
    }, [])
    .join('&')
}
function to_query(key, value): Array<string> {
  if (value instanceof Array) {
    return value.filter((aValue) => aValue).reduce((memo, aValue) => [...memo, ...to_query(`${key}[]`, aValue)], [])
  } else if (typeof value === 'object') {
    return Object.keys(value)
      .filter((oKey) => value[oKey])
      .reduce((memo, oKey) => [...memo, ...to_query(`${key}[${oKey}]`, value[oKey])], [])
  }
  return [`${encodeURIComponent(key)}=${encodeURIComponent(value)}`]
}

export function useSsoOnboardingState(returnTo?: string): string {
  const location = useLocation()
  const onboarding = useRecoilValue(onboardingState)
  return useMemo(() => {
    return generateSsoState({
      attomId:
        new URLSearchParams(location.search).get('attom_id') || (!onboarding.property ? onboarding.attomId : null),
      campaignId: new URLSearchParams(location.search).get('mc'),
      propertyId: onboarding.property?.id,
      options: {
        goals: onboarding.goals,
        homeowner_type: onboarding.homeownerType,
        referred_by_user: onboarding.propertyShare?.user_id,
        return_to: returnTo,
      },
    })
  }, [location, onboarding, returnTo])
}

export function submitSsoLink(form: HTMLFormElement): void {
  const submitForm = document.createElement('form')
  submitForm.setAttribute('style', 'display: hidden')
  submitForm.setAttribute('action', form.getAttribute('action') as string)
  if (form.getAttribute('method')) submitForm.setAttribute('method', form.getAttribute('method') as string)

  Array.from(form.elements).forEach((element) => {
    if (!element['name']) return
    const submitElement = document.createElement('input')
    submitElement.setAttribute('name', element['name'])
    submitElement.value = element['value']
    submitForm.appendChild(submitElement)
  })
  document.body.appendChild(submitForm)
  submitForm.submit()
}

interface SsoLinkProps {
  onClick?: (e: React.FormEvent<HTMLFormElement>) => void
  onVisible?: () => void
  outerClassName?: string
  innerClassName?: string
  to: string
}

const SsoLink: FC<PropsWithChildren<SsoLinkProps>> = ({
  onClick,
  onVisible,
  to,
  outerClassName,
  innerClassName,
  children,
}) => {
  const [csrfParam, setCsrfParam] = useState<string | null>(null)
  const [csrfToken, setCsrfToken] = useState<string | null>(null)

  useEffect(() => {
    if (onVisible) onVisible()
  }, [onVisible])

  useEffect(() => {
    setCsrfParam(document.querySelector('meta[name=csrf-param]')?.getAttribute('content') || null)
    setCsrfToken(document.querySelector('meta[name=csrf-token]')?.getAttribute('content') || null)
  }, [])

  return (
    <form className={outerClassName} onSubmit={onClick} action={to} method="post">
      <input type="hidden" value={csrfToken || ''} name={csrfParam || ''} />
      <button type="submit" className={innerClassName}>
        {children}
      </button>
    </form>
  )
}

export default SsoLink
