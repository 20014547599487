import Select from 'components/Select'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Renovation, useRenovations } from 'recoil/renovations'
import FancyButton from 'components/FancyButton'
import cx from 'classnames'
import { track } from 'utils/analytics'

import styles from '../styles.module.scss'
import RenovationForm from 'pages/EditProperty/Renovations/RenovationForm'

export const RenovationsAndAdditionsForm: FC = () => {
  const { renovations, kinds, queueRenovation, destroyRenovation } = useRenovations()
  const [localRenovations, setLocalRenovations] = useState<Array<Renovation>>([])
  const [kind, setKind] = useState<string>('')

  useEffect(() => {
    setLocalRenovations(renovations)
  }, [renovations])

  const add = useCallback(async () => {
    queueRenovation(kind)
    setKind('')
    track('submit form', { section: 'edit', form: 'add-renovation', renovations: [{ kind }] })
  }, [kind, queueRenovation])

  const destroy = useCallback(
    async (renovation) => {
      const revert = [...localRenovations]
      setLocalRenovations(localRenovations.filter((x) => x.id !== renovation.id))
      try {
        await destroyRenovation(renovation)
      } catch (e) {
        setLocalRenovations(revert)
      }
    },
    [destroyRenovation, localRenovations, setLocalRenovations]
  )

  const changeKind = useCallback(
    (val) => {
      setKind(val)
    },
    [setKind]
  )

  return (
    <>
      {localRenovations.map((renovation, i) => {
        return <RenovationForm key={i} renovation={renovation} onRemove={destroy} />
      })}
      <h3>{'Have you done any other projects?'}</h3>
      <h4>{'New windows, a pool, or HVAC? Add additional projects'}</h4>
      <div className={styles.renovationControls}>
        <Select onChange={changeKind} value={kind} className={styles.renovationKind}>
          <option value={''} disabled>
            {'Select a project'}
          </option>
          {Object.keys(kinds)
            .sort()
            .map((key) => {
              const name = kinds[key]
              return (
                <option key={key} value={key}>
                  {name}
                </option>
              )
            })}
        </Select>

        <div className={cx(styles.buttonWithStatus)}>
          <FancyButton className={cx(styles.button)} onClick={add} disabled={!kind}>
            {'Add'}
          </FancyButton>
        </div>
      </div>
    </>
  )
}

export default RenovationsAndAdditionsForm
