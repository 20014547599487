import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { usePropertyPlanLoader } from 'recoil/propertyPlans'

interface Props {
  children?: React.ReactNode
}

const HasPropertyPlan: FC<Props> = ({ children }) => {
  const { redirect } = usePropertyPlanLoader()

  if (redirect) {
    // Follow any redirects first
    return <Redirect to={redirect} />
  }

  return <>{children}</>
}

export default HasPropertyPlan
