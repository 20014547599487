import React, { FC } from 'react'
import classNames from 'classnames'

import FancyButton from 'components/FancyButton'

// Styles
import styles from './Package.module.scss'

const FakePackage: FC<{ removing: boolean }> = ({ removing }) => {
  return (
    <div className={classNames(styles.package, styles.fake, removing ? styles.removing : null)}>
      <div className={styles.img} />
      <div className={styles.content}>
        <h3>{'Loading Report'}</h3>
        <p>{'Loading Report'}</p>
        <footer>
          <span className={styles.price}></span>
          <FancyButton className={styles.buyButton}>{'Buy now'}</FancyButton>
        </footer>
      </div>
    </div>
  )
}

export default FakePackage
