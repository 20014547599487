import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import Card from 'components/Card'

import styles from './styles.module.scss'

const MeetingScheduled: FC = () => {
  return (
    <div className="md:tw-w-2/3 tw-mx-auto">
      <Card>
        <h2 className="font-title tw-mb-6">{'Thank you!'}</h2>
        <p className="tw-mb-6">{'Your consultation has been scheduled. We look forward to speaking with you.'}</p>
        <div className="tw-text-center">
          <Link to="/dashboard" className={styles.button}>
            {'Back to Dashboard'}
          </Link>
        </div>
      </Card>
    </div>
  )
}

export default MeetingScheduled
