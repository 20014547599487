import React, { FC, ReactNode, useCallback } from 'react'
import { usePropertyPotentialChartProjectSuggestions } from 'pages/Dashboard'
import { ProjectTemplateBase } from 'recoil/projectTemplates'
import { useProperties } from 'recoil/properties'
import PropertyPotentialChart from 'components/PropertyPotentialChart'

interface LoggedInPropertyPotentialChartProps {
  className?: string
  projects?: ProjectTemplateBase[]
  onProjectLinkClick?: (location: string) => () => void
  additionalProjectsLabel?: ReactNode
}

const LoggedInPropertyPotentialChart: FC<LoggedInPropertyPotentialChartProps> = ({
  className,
  projects,
  onProjectLinkClick,
  additionalProjectsLabel,
}) => {
  const { selectedProperty: property } = useProperties()
  const untappedPotential = (property?.potential_value || 0) - (property?.estimated_value || 0)
  projects = projects || property?.top_projects || []
  const projectSuggestions = usePropertyPotentialChartProjectSuggestions(projects, untappedPotential)

  const getLinkForProject = useCallback((id: number) => {
    return { to: `property-plans?add-template=${id}` }
  }, [])

  if (!property) return null

  const estimatedValue = property.estimated_value
  const potentialValue = property.potential_value

  return (
    <PropertyPotentialChart
      className={className}
      currentValue={estimatedValue}
      potentialValue={potentialValue || 0}
      projectSuggestions={projectSuggestions}
      onProjectLinkClicked={onProjectLinkClick}
      getLinkForProject={getLinkForProject}
      additionalProjectsLink={{ to: 'property-plans' }}
      additionalProjectsLabel={additionalProjectsLabel}
    />
  )
}

export default LoggedInPropertyPotentialChart
