import { loadStripe } from '@stripe/stripe-js/pure'
import { postJSON } from 'utils/fetch'
import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react'
import CoreButton from 'components/Core/CoreButton'

import CreditCard from '@mui/icons-material/CreditCard'

let stripePromise

interface CardPayButtonProps {
  disabled: boolean
  idOrToken: string
  setError: (msg: string) => void
}

const CardPayButton: FC<PropsWithChildren<CardPayButtonProps>> = ({ disabled, idOrToken, setError }) => {
  useEffect(() => {
    if (!stripePromise) {
      stripePromise = loadStripe(document.body.dataset.stripePublishableKey || '')
    }
  }, [])

  const handleClick = useCallback(async () => {
    const stripe = await stripePromise
    const response = await postJSON(`/api/v1/payments/${idOrToken}/checkout_session`, {})
    if (response.code !== 200) {
      setError('An unexpected error happened which prevents payment at this time.')
      return
    }
    const result = await stripe.redirectToCheckout({
      sessionId: response.jsonBody.id,
    })
    if (result.error) {
      setError(result.error.message)
    }
  }, [idOrToken, setError])

  return (
    <CoreButton
      icon={<CreditCard />}
      kind="significant"
      disabled={disabled}
      onClick={handleClick}
      text="Pay with Card"
      className="tw-w-full"
      size="m"
    />
  )
}

export default CardPayButton
