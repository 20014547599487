import React, { FC } from 'react'

interface UploadProps {
  className?: string
}

const Upload: FC<UploadProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="translate(-771 -2067) translate(200 901) translate(32 1029) translate(496 42) translate(43 95)">
                <g>
                  <path d="M0 0H24V24H0z" />
                  <path
                    fill="currentColor"
                    fillRule="nonzero"
                    d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Upload
