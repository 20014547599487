import CoreButton from 'components/Core/CoreButton'
import React, { FC, useCallback } from 'react'

import Person from '@mui/icons-material/Person'

interface SetupAccountCardProps {
  signupToken: string
  idOrToken: string
}

const SetupAccountCard: FC<SetupAccountCardProps> = ({ signupToken, idOrToken }) => {
  const handleSetupAccount = useCallback(() => {
    if (!signupToken) return

    window.location.href = `/onboarding?n&flow=payment&paymentToken=${idOrToken}&reset_password_token=${signupToken}`
  }, [idOrToken, signupToken])

  return (
    <div className="tw-rounded-5xl tw-p-6 tw-bg-neutrals-200 tw-space-y-4">
      <div className="tw-text-lg tw-font-semibold tw-leading-tight">
        {
          'Finish setting up your account to see contractors matches, thoroughly review your bids, and enjoy a smooth renovation in the Realm App.'
        }
      </div>

      <CoreButton
        text="Finish setting up my account"
        onClick={handleSetupAccount}
        icon={<Person />}
        size="m"
        className="tw-w-full"
      />
    </div>
  )
}

export default SetupAccountCard
