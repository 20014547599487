import React, { FC } from 'react'

interface ItemContentProps {
  title: string
  description: string
  reverse?: boolean
}

const ItemContent: FC<ItemContentProps> = ({ title, description, reverse }) => {
  const Title = <div className="tw-text-sm tw-font-bold tw-text-neutrals-1000">{title}</div>
  const Description = <div className="tw-text-sm tw-text-neutrals-1000">{description}</div>

  return (
    <div>
      {reverse ? (
        <>
          {Description}
          {Title}
        </>
      ) : (
        <>
          {Title}
          {Description}
        </>
      )}
    </div>
  )
}

export default ItemContent
