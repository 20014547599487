import React, { FC, useCallback, useEffect, useState } from 'react'
import { Attachment, PropertyDocs } from 'recoil/properties'
import styles from './styles.module.scss'
import classNames from 'classnames'
import Close from 'svgs/close'
import Spinner from 'components/Spinner'
import Upload from 'svgs/upload'

export interface DocumentUploadProps {
  type?: keyof PropertyDocs
  title?: string
  icon?: JSX.Element
  doc?: Attachment
  accept?: string
  className?: string
  showLabel?: boolean
  handleChange: (file: File) => Promise<void>
  handleRemove: (fileID: number) => Promise<void>
}

export const DocumentUpload: FC<DocumentUploadProps> = ({
  title,
  doc,
  icon,
  accept = '*',
  className,
  handleChange,
  handleRemove,
  showLabel = true,
}) => {
  {
    const [loading, setLoading] = useState(false)
    const [localDoc, setLocalDoc] = useState<Attachment | null>(null)

    useEffect(() => {
      setLocalDoc(doc || null)
    }, [doc])

    const change = useCallback(
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true)
        if (!e.target.files) return
        const file = e.target.files[0]

        try {
          await handleChange(file)
        } finally {
          setLoading(false)
        }
      },
      [handleChange]
    )

    const remove = useCallback(() => {
      setLocalDoc(null)
      if (doc?.id) handleRemove(doc.id)
    }, [doc, handleRemove])

    const follow = useCallback(() => {
      if (localDoc) window.open(localDoc.download_url, '_blank')
    }, [localDoc])

    return (
      <div className={classNames(styles.upload, { [styles.attached]: localDoc }, className)}>
        <div className="touchTarget">
          {loading ? (
            <div className={styles.uploading}>
              <Spinner size="sm" />
            </div>
          ) : (
            <>
              {!localDoc ? (
                <input type="file" onChange={change} accept={accept} />
              ) : (
                <input type="button" onClick={follow} />
              )}
              <div className={classNames(styles.documentLabel)}>
                {icon}
                {localDoc ? (
                  <>
                    <div className={styles.fileName}>{localDoc.name}</div>
                    <button type="button" onClick={remove}>
                      <Close />
                    </button>
                  </>
                ) : (
                  <div className={styles.placeholder}>
                    {showLabel ? <span className={styles.shortLabel}>{'Add '}</span> : undefined}
                    {title}
                    {showLabel ? (
                      <span className={styles.longLabel}>
                        <Upload />
                      </span>
                    ) : undefined}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default DocumentUpload
