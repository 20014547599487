import React, { FC, useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import cx from 'classnames'

import { track as legacyTrack } from 'utils/analytics'
import { prepareBoth } from 'utils/analyticsV2'
import { formatPublicPropertyAddress } from 'utils/formatting'

import { useOnboardingProperty, onboardingState } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { OnboardingStartState } from 'recoil/onboardingFlows/states'
import { useProperties } from 'recoil/properties'

import { useAuth } from 'non-rendering/Authentication'

import OnboardingFlowRestart from '../../OnboardingFlowRestart'
import Loading from 'components/Loading'

import styles from './styles.module.scss'
import oStyles from 'pages/Onboarding/styles.module.scss'
import Header from 'pages/Onboarding/components/Header'
import DefaultWrapper from 'pages/Onboarding/components/DefaultWrapper'

const [trackClick, trackImpression] = prepareBoth({ screen: 'inelgible-property', family: 'onboarding' })

const OnboardingAddProperty: FC = () => {
  const location = useLocation()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { fetchPublicProperty } = useOnboardingProperty()
  const { properties } = useProperties()
  const { user } = useAuth()

  const [onboarding, setOnboarding] = useRecoilState(onboardingState)
  const { flow } = useFlow()

  useEffect(() => {
    const _ = async () => {
      const urlSearchParams = new URLSearchParams(location.search)
      const attomId = urlSearchParams.get('attom_id')

      if (attomId && attomId !== onboarding.attomId) {
        setIsLoading(true)
        const result = await fetchPublicProperty(attomId)
        if (result.unavailableUrl) {
          // can't do history.push here since this is a server-generated page
          window.location.href = result.unavailableUrl
        } else {
          setIsLoading(false)
        }
      } else {
        setIsLoading(false)
      }
    }
    _()
  }, [fetchPublicProperty, location, onboarding.attomId, setOnboarding])

  useEffect(() => {
    trackImpression({ code: 'alternate-address' })
  }, [])

  const handleClick = useCallback(async () => {
    await legacyTrack('submit form', { section: 'onboarding', form: 'unservicable-property' })
    await trackClick({ code: 'alternate-address' })
  }, [])

  // If we're not unservicable, we shouldn't be here.
  if (!onboarding.unavailable) return <OnboardingFlowRestart />

  return (
    <DefaultWrapper>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={cx(styles.content, { [styles.desktopOnly]: isLoading })}>
          <div>
            <Header
              title={'Check back soon!'}
              subTitle={
                "Your property is outside of our serviceable area or is a property type we can't support. We'll email you as soon as we support properties like this one!"
              }
            />
            {(onboarding.suggestions?.length || 0) > 0 && (
              <section className={styles.didYouMeanSection}>
                <h3>{'Did you mean?'}</h3>
                <div>
                  {onboarding.suggestions?.map((suggestion) => {
                    let linkPath: string | { pathname: string; state: OnboardingStartState } = {
                      pathname: '/onboarding',
                      state: {
                        flow: flow,
                        attomId: suggestion.attom_id,
                      },
                    }
                    const existingProperty = (properties || []).find(
                      (property) => property.attom_id == suggestion.attom_id
                    )
                    if (existingProperty) {
                      linkPath = `/properties/${existingProperty.id}/dashboard`
                    }
                    return (
                      <div className={styles.suggestion} key={suggestion.attom_id}>
                        <Link to={linkPath}>{formatPublicPropertyAddress(suggestion as any)}</Link>
                      </div>
                    )
                  })}
                </div>
              </section>
            )}
          </div>
          <div className={styles.buttonbox}>
            {!user?.entered_address ? (
              <Link
                to={{ pathname: '/onboarding', state: { flow: flow } }}
                className={cx(styles.button, oStyles.greenButton)}
                onClick={handleClick}
              >
                {'Try a different address'}
              </Link>
            ) : (
              <Link to="/properties" className={cx(styles.button, oStyles.greenButton)} onClick={handleClick}>
                {'Back to Properties'}
              </Link>
            )}
          </div>
        </div>
      )}
    </DefaultWrapper>
  )
}

export default OnboardingAddProperty
