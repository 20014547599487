import React, { FC, ReactNode, useMemo } from 'react'
import cx from 'classnames'
import Input, { InputProps } from './Input'
import styles from './InputWithEditMode.module.scss'
import { formatMoneyNoDecimals } from 'utils/formatting'

export interface InputWithEditModeProps extends InputProps {
  editing: boolean
  label?: string
  displayAsMoney?: boolean
  decorator?: string
  button?: ReactNode
}

export { styles }

const InputWithEditMode: FC<InputWithEditModeProps> = ({
  editing,
  displayAsMoney,
  className,
  label,
  decorator,
  tooltip,
  button,
  ...inputProps
}) => {
  const showLabel = editing || inputProps.tag !== 'textarea'

  const value = useMemo(() => {
    const defaultValue = inputProps.tag !== 'textarea' ? 'Unknown' : ''

    const rawValue = inputProps.value || defaultValue

    return displayAsMoney && inputProps.value ? formatMoneyNoDecimals(rawValue) : rawValue
  }, [displayAsMoney, inputProps])

  return (
    <div className={className}>
      {showLabel && <label className={cx(styles.label, editing ? styles.editing : '')}>{label}</label>}
      {tooltip}
      <div className={cx(styles.controls, editing && styles.editing)}>
        {editing ? (
          <Input {...inputProps} className={styles.input} />
        ) : (
          <div className={cx(styles.bigValue, inputProps.tag, !inputProps.value && styles.defaultValue)}>
            {value} <span className={styles.decorator}>{decorator}</span>
          </div>
        )}
        {button}
      </div>
    </div>
  )
}

export default InputWithEditMode
