import { useState, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

import sharedAction, { AttemptResult, attemptSharedAction } from 'utils/sharedAction'

import DynamicContentApi from 'apis/dynamicContentApi'

export interface DynamicContent {
  key: string
  content: string
}

export interface DynamicContentMap {
  [key: string]: DynamicContent | boolean
}

export const dynamicContentsState = atom<DynamicContentMap>({
  key: 'DynamicContents',
  default: {},
})

function getParentKeys(key) {
  // Drop any "_" at the end before splitting up; we don't want a ghost parent.
  key = key[key.length - 1] ? key.substring(0, key.length - 1) : key

  // Build up a list of parent keys
  const keyParts = key.split('_')
  return keyParts
    .slice(0, keyParts.length - 1) // Don't include the last one.
    .map((keyPart) => `${keyPart}_`) // Make sure each parent has a '_' on the end
    .reduce(
      (prev, keyPart) => prev.concat(prev.length > 0 ? `${prev[prev.length - 1]}${keyPart}` : keyPart),
      [] as Array<string>
    )
}

function haveKnowledgeOnKey(map: DynamicContentMap, key: string) {
  // If we know about this key or any of its parents, then we have knowledge.
  // (order shouldn't matter; we just need one)
  return [key].concat(getParentKeys(key)).some((key) => typeof map[key] !== 'undefined')
}

/* Obtain a single piece of content */
export const useDynamicContent = (
  key: string
): {
  dynamicContent: DynamicContent | null
  isLoading: boolean
  checked: boolean
  isMissing: boolean
} => {
  const [dynamicContents, setDynamicContents] = useRecoilState(dynamicContentsState)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let cancel = false
    const runme = async () => {
      // If we don't have knowledge on this content yet...
      if (!haveKnowledgeOnKey(dynamicContents, key)) {
        setIsLoading(true)

        // Build up a list of parent keys
        const parentKeys = getParentKeys(key)

        let fetchComplete = false
        let result: DynamicContent | false | null = null
        // Test the lock on each parent, and if we get data back we use that.
        for (let i = 0; i < parentKeys.length; i++) {
          const parentKey = parentKeys[i]
          // If someone is already grabbing a parent group, wait for that
          let pResult: AttemptResult<DynamicContent[]> | null = null
          try {
            pResult = await attemptSharedAction<Array<DynamicContent>>(`getDynamicContents_${parentKey}`)
          } catch {
            // If the parent group failed, then treat it as not having been attempted.
            pResult = { success: false }
          }
          if (cancel) return // Cancel after any attempt to fetch/lock
          // If we found it, then handle it and get out. Otherwise continue
          if (pResult.success) {
            // At this point we completed a fetch that should contain our key.
            fetchComplete = true
            result = pResult.results?.find((dynamicContent) => dynamicContent.key == key) || null
            break
          }
        }

        // If we didn't finish a fetch, then we have to get it ourselves.
        if (!fetchComplete) {
          try {
            result = await sharedAction(`getDynamicContents_${key}`, () => getDynamicContent(key))
          } catch {
            // If we fail to obtain it, then treat it as it doesn't exist.
            result = false
          }
          if (cancel) return // Cancel after any attempt to fetch/lock
          // Update recoil with our results
          setDynamicContents((prev) => Object.assign({}, prev, { [key]: result }))
        }
      }
      setIsLoading(false)
    }
    runme()
    return () => {
      cancel = true
    }
  }, [key, setDynamicContents, dynamicContents])

  const retDynamicContent = typeof dynamicContents[key] === 'object' ? (dynamicContents[key] as DynamicContent) : null
  const checked = haveKnowledgeOnKey(dynamicContents, key)
  const isMissing = !dynamicContents[key]

  return { dynamicContent: retDynamicContent, isLoading, checked, isMissing }
}

/* Obtain a tree of content */
export const useDynamicContents = (
  key: string
): {
  dynamicContents: DynamicContentMap
  isLoading: boolean
  checked: boolean
  isMissing: boolean
} => {
  const [dynamicContents, setDynamicContents] = useRecoilState(dynamicContentsState)
  const [isLoading, setIsLoading] = useState(false)

  // Treat the key as a parent, by making sure it has a '_' at the end
  key = key[key.length - 1] == '_' ? key : `${key}_`

  useEffect(() => {
    let cancel = false
    const runme = async () => {
      // If we don't have knowledge on this content yet...
      if (!haveKnowledgeOnKey(dynamicContents, key)) {
        setIsLoading(true)

        // Build up a list of parent keys
        const parentKeys = getParentKeys(key)

        let fetchComplete = false
        let result: Array<DynamicContent> | null = null
        // Test the lock on each parent, and if we get data back we use that.
        for (let i = 0; i < parentKeys.length; i++) {
          const parentKey = parentKeys[i]
          // If someone is already grabbing a parent group, wait for that
          let pResult: AttemptResult<DynamicContent[]> | null = null
          try {
            pResult = await attemptSharedAction<Array<DynamicContent>>(`getDynamicContents_${parentKey}`)
          } catch {
            // If the parent group failed, then treat it as not having been attempted.
            pResult = { success: false }
          }
          if (cancel) return // Cancel after any attempt to fetch/lock
          // If we found it, then handle it and get out. Otherwise continue
          if (pResult.success) {
            // At this point we completed a fetch that should contain our keys.
            fetchComplete = true
            // Pull out only our keys.
            result = pResult.results?.filter((dynamicContent) => dynamicContent.key.startsWith(`${key}_`)) || null
            break
          }
        }

        // If we didn't finish a fetch, then we have to get it ourselves.
        if (!fetchComplete) {
          let exists = true
          try {
            result = await sharedAction(`getDynamicContents_${key}`, () => listDynamicContent(key))
          } catch {
            // If we fail to obtain it, then treat it as if they doesn't exist.
            exists = false
          }
          if (cancel) return // Cancel after any attempt to fetch/lock
          let addMap: DynamicContentMap = {}
          if (exists) {
            // toss these all into a DynamicContentMap
            addMap =
              result?.reduce((prev, dc) => Object.assign({}, prev, { [dc.key]: dc }), {} as DynamicContentMap) || {}
            // Also add the entry for our categry.
            addMap[key] = true
          } else {
            // Put in a negative entry
            addMap[key] = false
          }

          // Update recoil with our results
          setDynamicContents((prev) => Object.assign({}, prev, addMap))
        }
      }
      setIsLoading(false)
    }
    runme()
    return () => {
      cancel = true
    }
  }, [key, setDynamicContents, dynamicContents])

  // The result set to return is anything that matches our key that is an actual key
  const retDynaimcContents = Object.keys(dynamicContents).reduce((prev, existingKey) => {
    if (existingKey.startsWith(key) && typeof dynamicContents[existingKey] === 'object')
      return Object.assign({}, prev, { [existingKey]: dynamicContents[existingKey] })
    return prev
  }, {} as DynamicContentMap)

  const checked = haveKnowledgeOnKey(dynamicContents, key)
  const isMissing = !dynamicContents[key]

  return { dynamicContents: retDynaimcContents, isLoading, checked, isMissing }
}

export const getDynamicContent = async (key: string): Promise<DynamicContent> => {
  const api = new DynamicContentApi()
  return await api.getDynamicContent(key)
}

export const listDynamicContent = async (filter: string): Promise<Array<DynamicContent>> => {
  const api = new DynamicContentApi()
  return await api.listDynamicContent(filter)
}
