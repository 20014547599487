import type { RouteProps } from 'react-router-dom'
import OnboardingEnterAddress from 'pages/Onboarding/screens/EnterAddress'
import OnboardingAddProperty from 'pages/Onboarding/screens/AddProperty'
import OnboardingUnservicableProperty from 'pages/Onboarding/screens/UnservicableProperty'

import OnboardingInternalRedirect from 'pages/Onboarding/screens/InternalRedirect'
import CamV3EnterAddress from 'pages/Onboarding/screens/CamV3EnterAddress'
import CamV3RenovationReadiness from 'pages/Onboarding/screens/CamV3RenovationReadiness'
import CamV3OutsideBids from 'pages/Onboarding/screens/CamV3OutsideBids'
import CamV3OutsideBidsValueProp from 'pages/Onboarding/screens/CamV3OutsideBidsValueProp'
import CamV3Projects from 'pages/Onboarding/screens/CamV3Projects'
import CamV3BookOrDownload from 'pages/Onboarding/screens/CamV3BookOrDownload'
import CamV3SignUp from 'pages/Onboarding/screens/CamV3SignUp'
import CamV3CompleteBooking from 'pages/Onboarding/screens/CamV3CompleteBooking'
import CamV3Confirm from 'pages/Onboarding/screens/CamV3Confirm'
import CamV3Unserviceable from 'pages/Onboarding/screens/CamV3Unserviceable'

import PaymentWelcome from 'pages/Onboarding/screens/PaymentWelcome'
import PaymentDownload from 'pages/Onboarding/screens/PaymentDownload'
import CamV3Budget from 'pages/Onboarding/screens/CamV3Budget'

// Please update this master list of onboarding URLs if you make
// changes to this list:
// https://docs.google.com/spreadsheets/d/1IrRJjHC3FzWWMPOK679-p1s014PIa8ktV3_7PfVi29o/edit?usp=sharing
const onboardingRoutes: Array<RouteProps & { name?: string }> = [
  {
    path: '/onboarding/internal-redirect',
    component: OnboardingInternalRedirect,
    name: 'onboarding-internal-redirect',
  },
  { path: '/onboarding/enter-address', component: OnboardingEnterAddress, name: 'onboarding-enter-address' },
  { path: '/onboarding/add-property', component: OnboardingAddProperty, name: 'onboarding-add-property' },
  {
    path: '/onboarding/unservicable-property',
    component: OnboardingUnservicableProperty,
    name: 'onboarding-unservicable-property',
  },
  { path: '/onboarding/cam-v3-start', component: CamV3EnterAddress.Landing, name: 'cam-v3-start' },

  { path: '/onboarding/cam-v3-enter-address', component: CamV3EnterAddress.Search, name: 'cam-v3-enter-address' },
  {
    path: '/onboarding/cam-v3-renovation-readiness',
    component: CamV3RenovationReadiness,
    name: 'cam-v3-renovation-readiness',
  },
  {
    path: '/onboarding/cam-v3-outside-bids',
    component: CamV3OutsideBids,
    name: 'cam-v3-outside-bids',
  },
  {
    path: '/onboarding/cam-v3-outside-bids-value-prop',
    component: CamV3OutsideBidsValueProp,
    name: 'cam-v3-outside-bids-value-prop',
  },
  {
    path: '/onboarding/cam-v3-projects',
    component: CamV3Projects,
    name: 'cam-v3-projects',
  },
  {
    path: '/onboarding/cam-v3-book-or-download',
    component: CamV3BookOrDownload.Full,
    name: 'cam-v3-book-or-download',
  },
  {
    path: '/onboarding/cam-v3-book-first',
    component: CamV3BookOrDownload.BookFirst,
    name: 'cam-v3-book-first',
  },
  {
    path: '/onboarding/cam-v3-download-first',
    component: CamV3BookOrDownload.DownloadFirst,
    name: 'cam-v3-download-first',
  },
  {
    path: '/onboarding/cam-v3-download',
    component: CamV3BookOrDownload.DownloadOnly,
    name: 'cam-v3-download',
  },
  {
    path: '/onboarding/cam-v3-sign-up',
    component: CamV3SignUp,
    name: 'cam-v3-sign-up',
  },

  {
    path: '/onboarding/cam-v3-complete-booking',
    component: CamV3CompleteBooking,
    name: 'cam-v3-complete-booking',
  },
  {
    path: '/onboarding/cam-v3-confirm',
    component: CamV3Confirm,
    name: 'cam-v3-confirm',
  },
  {
    path: '/onboarding/cam-v3-unserviceable',
    component: CamV3Budget,
    name: 'cam-v3-budget',
  },
  {
    path: '/onboarding/cam-v3-budget',
    component: CamV3Unserviceable,
    name: 'cam-v3-unserviceable',
  },
  { path: 'payment-welcome', component: PaymentWelcome, name: 'payment-welcome' },
  { path: 'payment-download', component: PaymentDownload, name: 'payment-download' },
]

export default onboardingRoutes
