import React, { FC } from 'react'

import Input from 'components/Input'

import styles from './Section.module.scss'

interface Props {
  value: string
  onChange: (value: string) => void
}

const NameSection: FC<Props> = ({ value, onChange }) => {
  return (
    <section className={styles.section}>
      <h3>{'Name'}</h3>
      <p>{'Give your project a custom name'}</p>
      <Input value={value} onChange={onChange} />
    </section>
  )
}

export default NameSection
