import React, { FC, useState, useEffect, useMemo, PropsWithChildren } from 'react'
import { initializeApp } from 'firebase/app'
import { getRemoteConfig, getAll, fetchAndActivate } from 'firebase/remote-config'

import defaultConfig, { DefaultConfigType } from './defaultConfig'
import FirebaseContext from './context'

const firebaseConfig = {
  apiKey: process.env.FIREBASE_REMOTE_CONFIG_API_KEY,
  authDomain: process.env.FIREBASE_REMOTE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_REMOTE_CONFIG_PROJECT_ID,
  storageBucket: process.env.FIREBASE_REMOTE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_REMOTE_CONFIG_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_REMOTE_CONFIG_APP_ID,
  measurementId: process.env.FIREBASE_REMOTE_CONFIG_MEASUREMENT_ID,
}

function initializeFirebase() {
  if (!firebaseConfig.projectId) return

  const app = initializeApp(firebaseConfig)

  const remoteConfig = getRemoteConfig(app)

  // Set recommended minimum fetch interval for development
  if (process.env.NODE_ENV === 'development') {
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000
  }

  // Set defaults
  remoteConfig.defaultConfig = defaultConfig

  return remoteConfig
}

/**
 * React Global component
 *
 * This should be declared once at the top of the component tree,
 * using the <FirebaseLoader /> component. Currently this is done
 * for the map web app in `packs/main.tsx`
 */
const FirebaseLoader: FC<PropsWithChildren> = ({ children }) => {
  // Use defaultConfig until we can contact the firebase remoteConfig
  // server. This is fast, but not instant. For server-side rendered
  // pages, this won't be available until after we've delivered to the
  // client.
  const [config, setConfig] = useState<DefaultConfigType>(defaultConfig)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const load = async () => {
      // We need to initialize firebase inside useEffect. Firebase *cannot*
      // be used for server-side rendering, and *must* have a 'window'
      // available during initialization. No doubt this is due to how it
      // identifies users.
      const remoteConfig = initializeFirebase()
      if (!remoteConfig) return

      await fetchAndActivate(remoteConfig)
      const fbValues = getAll(remoteConfig)
      const newConfig = {}
      Object.entries(defaultConfig).map(([key, value]) => {
        if (typeof value === 'boolean') newConfig[key] = fbValues[key].asBoolean()
        else if (typeof value === 'number') newConfig[key] = fbValues[key].asNumber()
        else newConfig[key] = fbValues[key].asString()
      })
      setConfig((prev) => ({ ...prev, ...newConfig }))
      // Set Loaded after our config is set, so those waiting to read have
      // values when loaded changes.
      setLoaded(true)
    }
    load()
  }, [])

  const firebaseContext = useMemo(() => {
    return {
      loaded: loaded,
      config: config,
    }
  }, [config, loaded])

  return <FirebaseContext.Provider value={firebaseContext}>{children}</FirebaseContext.Provider>
}

export default FirebaseLoader
