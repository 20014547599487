import React, { FC, ReactNode } from 'react'

import Close from 'svgs/close'
import Info from 'svgs/info'

import styles from './styles.module.scss'

interface Props {
  children?: ReactNode
}

const Alert: FC<Props> = ({ children }) => (
  <div className={styles.alert}>
    <div className={styles.icon}>
      <Info />
    </div>
    <div className={styles.content}>{children}</div>
    <div className={styles.close}>
      <Close />
    </div>
  </div>
)

export default Alert
