import { useCallback, useEffect, useMemo } from 'react'
import { atom, useRecoilState } from 'recoil'

import { Model } from 'recoil/model'
import { useProjects } from 'recoil/projects'
import { useBaseProjectTemplates } from 'recoil/baseProjectTemplates'
import { useProjectTemplates } from 'recoil/projectTemplates'
import { useProperties } from 'recoil/properties'
import { usePropertyPlans } from 'recoil/propertyPlans'

import { getJSON, postJSON, patchJSON, deleteJSON } from 'utils/fetch'

export interface RenovationImage {
  id: number
  name: string
  url: string
}

export interface Renovation extends Model {
  id?: number
  kind?: string
  cost?: number
  year?: number
  square_footage?: number
  rating?: number
  contractor?: string
  details?: string
  images?: RenovationImage[]
}

export interface RenovationKind {
  [key: string]: string
}

export const renovationsState = atom<Renovation[]>({
  key: 'Renovations',
  default: [],
})

export const renovationsFetchedIdState = atom<number | null>({
  key: 'RenovationsFetchedId',
  default: null,
})

export const renovationKindsState = atom<RenovationKind>({
  key: 'RenovationKinds',
  default: {},
})

export const replaceRenovation = (all: Array<Renovation>, r: Renovation): Array<Renovation> => {
  const i = all.findIndex((renovation) => renovation.id === r.id)

  return [...all.slice(0, i), r, ...all.slice(i + 1)]
}

export const useRenovations = (): {
  renovations: Array<Renovation>
  kinds: RenovationKind
  queueRenovation: (kind: string) => void
  createRenovation: (r: Renovation) => Promise<void>
  updateRenovation: (r: Renovation) => Promise<void>
  destroyRenovation: (r: Renovation) => Promise<void>
  refresh: () => void
} => {
  const { selectedProperty, refreshProperty } = useProperties()
  const [renovations, setRenovations] = useRecoilState(renovationsState)
  const [kinds, setKinds] = useRecoilState(renovationKindsState)
  const [fetchedId, setFetchedId] = useRecoilState(renovationsFetchedIdState)
  const { refreshProjectTemplates: refreshBaseProjectTemplates } = useBaseProjectTemplates(selectedProperty as any)
  const { refreshProjectTemplates } = useProjectTemplates()
  const { refreshPropertyPlan } = usePropertyPlans()
  const { refreshProjects } = useProjects()

  const refresh = useCallback(() => {
    refreshBaseProjectTemplates()
    refreshProjectTemplates()
    refreshPropertyPlan()
    refreshProjects()
    refreshProperty(selectedProperty as any)
  }, [
    refreshBaseProjectTemplates,
    refreshProjectTemplates,
    refreshPropertyPlan,
    refreshProjects,
    refreshProperty,
    selectedProperty,
  ])

  const nextRenovationId = useMemo(() => {
    const ids = renovations.filter((x) => x.id).map((x) => x.id) as number[]
    const minId = Math.min(...ids)

    return minId < 0 ? minId - 1 : -1
  }, [renovations])

  useEffect(() => {
    if (!selectedProperty?.id) return
    const runme = async () => {
      if (fetchedId != selectedProperty.id) {
        const serverRenovations = (await getJSON(
          `/api/v1/properties/${selectedProperty.id}/renovations`
        )) as Renovation[]
        const defaultRenovations = [
          { kind: 'kitchen_renovation', id: -1 },
          { kind: 'bathroom_renovation', id: -2 },
        ] as Renovation[]
        setRenovations(serverRenovations.length > 0 ? serverRenovations : defaultRenovations)
        setFetchedId(selectedProperty.id)
      }
    }
    runme()
  }, [fetchedId, setFetchedId, setRenovations, selectedProperty?.id])

  useEffect(() => {
    if (!selectedProperty?.id) return
    const runme = async () => {
      if (Object.keys(kinds).length == 0) {
        setKinds(await getJSON(`/api/v1/properties/${selectedProperty.id}/renovations/kinds`))
      }
    }
    runme()
  }, [kinds, setKinds, selectedProperty?.id])

  return {
    renovations,
    kinds,
    queueRenovation: (kind: string) => {
      setRenovations((renovations) => {
        return [...renovations, { kind: kind, id: nextRenovationId }]
      })
    },
    createRenovation: async (r: Renovation) => {
      if (!selectedProperty) return
      const index = renovations.findIndex((x) => x.id == r.id)
      const data = { ...r, id: undefined }
      const res = await postJSON(
        `/api/v1/properties/${selectedProperty.id}/renovations`,
        data as Record<string, unknown>
      )
      if (res.isError) {
        const error = new Error(`Non-200 status code: ${res.code}.`)
        throw Object.assign(error, { body: res.jsonBody })
      }
      const serverRenovation = res.jsonBody

      const updated = [...renovations]
      updated[index] = serverRenovation

      setRenovations(updated)
      refresh()
    },
    updateRenovation: async (r: Renovation) => {
      if (!selectedProperty) return
      const res = await patchJSON(
        `/api/v1/properties/${selectedProperty.id}/renovations/${r.id}`,
        r as unknown as Record<string, unknown>
      )
      if (res.isError) {
        const error = new Error(`Non-200 status code: ${res.code}.`)
        throw Object.assign(error, { body: res.jsonBody })
      }

      const updated = res.jsonBody
      setRenovations(replaceRenovation(renovations, updated))
      refresh()
    },
    destroyRenovation: async (r: Renovation) => {
      if (!selectedProperty) return

      const revert = [...renovations]
      const updatedRenovations = renovations.filter((renovation) => renovation.id !== r.id)
      setRenovations(updatedRenovations)

      const res = await deleteJSON(`/api/v1/properties/${selectedProperty.id}/renovations/${r.id}`)
      if (res.isError) {
        setRenovations(revert)
        const error = new Error(`Non-200 status code: ${res.code}.`)
        throw Object.assign(error, { body: res.jsonBody })
      }
      refresh()
    },
    refresh,
  }
}
