import React, { FC, PropsWithChildren, ReactNode } from 'react'
import cx from 'classnames'

import CoreButton, { CoreButtonKind } from './CoreButton'
import CoreText from './CoreText'

import styles from './CoreCard.module.scss'

type CoreCardIconColor = 'primary' | 'secondary' | 'negative' | 'positive' | 'gray' | 'warning'

interface CoreCardProps {
  header?: ReactNode
  icon?: ReactNode
  iconColor?: CoreCardIconColor
  title?: string
  description?: string
  buttonIcon?: ReactNode
  buttonText?: string
  buttonKind?: CoreButtonKind
  buttonSize?: 's' | 'm' | 'l' | 'xl'
  onButtonClick?: () => void
  headerStyle?: string
  className?: string
}

const CoreCard: FC<PropsWithChildren<CoreCardProps>> = ({
  header,
  headerStyle,
  title,
  description,
  children,
  buttonIcon,
  buttonText,
  buttonKind,
  buttonSize,
  icon,
  iconColor = 'primary',
  onButtonClick,
  className,
}) => {
  return (
    <div className={cx(styles.card, className)}>
      {header ? (
        <div className={cx(styles.header, headerStyle)}>{header}</div>
      ) : (
        icon && (
          <div className={styles.iconContainer}>
            <div className={cx(styles.icon, iconColor)}>{icon}</div>
          </div>
        )
      )}
      <div className={styles.body}>
        {children ? (
          <>{children}</>
        ) : (
          <div className="tw-space-y-1">
            {title && (
              <CoreText.Paragraph size="xl" weight="heavy" className="tw-text-center">
                {title}
              </CoreText.Paragraph>
            )}
            {description && (
              <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack" className="tw-text-center">
                {description}
              </CoreText.Paragraph>
            )}
          </div>
        )}
        {buttonText && (
          <CoreButton kind={buttonKind} icon={buttonIcon} onClick={onButtonClick} text={buttonText} size={buttonSize} />
        )}
      </div>
    </div>
  )
}

export default CoreCard
