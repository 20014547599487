import React, { FC, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { postJSON } from 'utils/fetch'

import Auth from 'layouts/Auth'
import EnsureLoggedOut from 'layouts/EnsureLoggedOut'

import PageTitle from 'components/PageTitle'
import Button from 'components/Button2'
import Input from 'components/Input'
import Loading from 'components/Loading'

const ForgotPassword: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReset, setIsReset] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [email, setEmail] = useState('')

  const handleFormSubmit = useCallback(async () => {
    setIsLoading(true)
    await Promise.all([
      postJSON('/users/password', { user: { email: email } }),
      new Promise((resolve) => setTimeout(resolve, 500)), // Wait at least 500ms
    ])
    setAttempts((prev) => prev + 1)
    setIsReset(true)
    setIsLoading(false)
  }, [email])

  const handleTryAgain = useCallback(() => {
    setIsReset(false)
  }, [])

  const isWebview = !!document.body.dataset.webview

  return (
    <EnsureLoggedOut>
      <PageTitle title="Forgot Password" />
      {isLoading ? (
        <Loading className="" />
      ) : (
        <Auth>
          <Auth.Body>
            <Auth.Body.Header>{'Forgot your password?'}</Auth.Body.Header>
            <Auth.Body.Subheader>
              {isReset ? (
                <>
                  <span>{'An email with instructions has been sent to:'}</span>
                  <span className={'tw-font-sm tw-font-bold tw-mx-2 tw-block'}>{email}</span>
                </>
              ) : (
                <>{"Enter your email address and we'll send you instructions."}</>
              )}
              {!isWebview && (
                <>
                  {' '}
                  <Link to="/users/sign_in">{'Back to Sign in'}</Link>
                </>
              )}
            </Auth.Body.Subheader>

            <hr className="tw-my-6" />

            {isReset ? (
              <div>
                <p>{'No email? Check your entered address and try again'}</p>
                <Button onClick={handleTryAgain} className={'tw-text-sm tw-my-3'}>
                  {'Try again'}
                </Button>
                <p className={'tw-text-xs tw-text-gray-500'}>
                  {attempts <= 1
                    ? "Don't forget to check your spam folders!"
                    : attempts == 2
                    ? 'Are you sure you entered your email in correctly?'
                    : 'It is possible you are not in our system. Check your email carefully!'}
                </p>
              </div>
            ) : (
              <form
                onSubmit={handleFormSubmit}
                action="/users/password"
                className="tw-min-h-full tw-space-y-6"
                method="post"
              >
                <fieldset disabled={isLoading}>
                  <div className="tw-space-y-3">
                    <Input
                      autoComplete="email"
                      autoFocus
                      name="user[email]"
                      onChange={setEmail}
                      placeholder="E-mail"
                      required
                      type="email"
                      value={email}
                    />
                  </div>
                </fieldset>

                <div className="tw-flex tw-justify-center">
                  <Button type="submit" loading={isLoading}>
                    {'Reset Password'}
                  </Button>
                </div>
              </form>
            )}
          </Auth.Body>
        </Auth>
      )}
    </EnsureLoggedOut>
  )
}

export default ForgotPassword
