// Third party dependencies
import React, { FC, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import ArrowNorth from 'svgs/property-plan/arrow-north'

import Secure from 'layouts/Secure'
import HasProperty from 'layouts/HasProperty'
import Default from 'layouts/Default'

// Components
import PageTitle from 'components/PageTitle'
import FancyButton from 'components/FancyButton'
import DynamicContent, { DynamicPrefetch } from 'components/DynamicContent'

import PurchaseResponse from './PurchaseResponse'
import PackageList from './PackageList'
import Testimonials from './Testimonials'

// Styles
import styles from './styles.module.scss'

interface FooterButtonProps {
  to: string
}

interface SuccessfulPurchase {
  purchased: boolean
  report_id: number | null
}

const PROPERTY_PLANNER = 2

const FooterButton: FC<React.PropsWithChildren<FooterButtonProps>> = ({ to, children }) => (
  <FancyButton className={styles.callToAction} to={to}>
    <ArrowNorth className={styles.forwardArrow} />
    <span>{children}</span>
  </FancyButton>
)

const Packages: FC = () => {
  const [successfulPurchase, setSuccessfulPurchase] = useState<SuccessfulPurchase>({
    purchased: false,
    report_id: null,
  })

  const onSuccessfulPurchase = useCallback(
    (report_id: number) => {
      setSuccessfulPurchase({
        purchased: true,
        report_id: report_id,
      })
    },
    [setSuccessfulPurchase]
  )
  return (
    <Secure>
      <HasProperty>
        <Default>
          <PageTitle title="Reports" />
          <DynamicPrefetch contentKey="app_reports">
            <div className={styles.content}>
              <header>
                <DynamicContent contentKey="app_reports_header" />
              </header>
              {!successfulPurchase.purchased ? null : successfulPurchase.report_id == PROPERTY_PLANNER ? (
                <PurchaseResponse
                  description={
                    <>
                      {'Please select your projects and add important information about your property '}
                      <Link to={{ pathname: 'https://airtable.com/shr5dWlMdyIL6p9Bm' }} target="_blank">
                        {'here'}
                      </Link>
                      {
                        ". Once you've completed the projects form, we'll assess your property and deliver the report via email."
                      }
                    </>
                  }
                />
              ) : (
                <PurchaseResponse />
              )}
              <PackageList onSuccessfulPurchase={onSuccessfulPurchase} />
              <Testimonials />
              <footer>
                <DynamicContent
                  contentKey="app_reports_footer"
                  overrides={{ CallToAction: { component: FooterButton, props: { to: '/contact' } } }}
                />
              </footer>
            </div>
          </DynamicPrefetch>
        </Default>
      </HasProperty>
    </Secure>
  )
}

export default Packages
