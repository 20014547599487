import { useState, useCallback, useEffect, useRef } from 'react'

import { track, addStickyProps, addTraits } from 'utils/analytics'

import { ExperienceActivatedEventHandler, ConvertQueue, Experiment } from './types'

export { Experiment }

declare global {
  interface Window {
    _conv_q: ConvertQueue
    convert: {
      currentData: {
        experiments: {
          [id: string]: {
            first_time: boolean
            variation_id: number
            variation_name: string // This isn't the name we set unfortunately.
            variation_name_parts: {
              changes: string[]
              sections: string[]
            }
          }
        }
      }
    }
  }
}

function convertQueue(): ConvertQueue {
  window._conv_q = window._conv_q || []
  return window._conv_q
}

function useTimeout(): [start: (callback: () => void, timeout: number) => void, cancel: () => void] {
  const [callback, setCallback] = useState<(() => void) | null>(null)
  const [totalTimeout, setTotalTimeout] = useState<number | null>(null)
  const startTimeRef = useRef<number>(0)

  const start = useCallback((callback: () => void, timeout: number) => {
    startTimeRef.current = Date.now()
    setCallback(() => callback)
    setTotalTimeout(timeout)
  }, [])

  const cancel = useCallback(() => {
    setCallback(null)
    setTotalTimeout(null)
  }, [])

  useEffect(() => {
    if (callback == null || totalTimeout == null) return

    const ellapsed = Date.now() - startTimeRef.current
    const timeoutId = window.setTimeout(callback, Math.max(0, totalTimeout - ellapsed))
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [callback, totalTimeout])
  return [start, cancel]
}

/**
 * Callers can assume calling 'enrollUserInExperiment' will always enroll,
 * even if there is a communication failure with Convert. In this case the
 * user will be enrolled in the Default (0) variant, but may experience up to
 * a 3 second delay.
 * 'selectedVariant' will be null until enrolled.
 */
export const useExperiment = (experimentId: number): [number | undefined, () => void] => {
  const [selectedVariant, setSelectedVariant] = useState<number | undefined>(undefined)
  const [startTimeout, cancelTimeout] = useTimeout()

  const enrollCallback = useCallback(
    (variant: number | null, experimentId: number) => {
      // Immediately cancel the timeout, so it doesn't fire after a callback
      cancelTimeout()

      const trackArgs: any = { experimentId: experimentId, variant: variant }
      // Grab the name of the experiment and variant to submit with our
      // tracking.
      const expName = Object.keys(Experiments).find((key) => Experiments[key].id == experimentId)
      if (expName) {
        trackArgs['experimentName'] = expName
        const variants = Experiments[expName].variants
        const allVariants = Object.entries(variants)
        let variantName: string | null = null
        if (allVariants.length == 0) throw `Experiment '${experimentId}' was set up with 0 variants!`
        if (variant == null) {
          variant = allVariants[0][1]
          variantName = allVariants[0][0]
        } else variantName = allVariants.find((entry) => entry[1] == variant)?.[0] || null

        if (variantName) {
          trackArgs['variantName'] = variantName
        } else throw `Experiment '${experimentId}' is missing variant '${variant}'`
      }

      track('enroll experiment', trackArgs)
      setSelectedVariant(variant as number)
    },
    [cancelTimeout]
  )

  const trackExperiment = useCallback(async (variant: number, experimentId: number) => {
    const expName = Object.keys(Experiments).find((key) => Experiments[key].id == experimentId)
    if (expName) {
      const experiment = Experiments[expName]
      const variantName = Object.keys(experiment.variants).find((key) => experiment.variants[key] == Number(variant))
      if (variantName) {
        const expProps = { [`${expName}_${experiment.id}`]: variantName }

        await addTraits(expProps)
        await addStickyProps(expProps)
      }
    }
  }, [])
  const convertCallbackEventHandler: ExperienceActivatedEventHandler = useCallback(
    ({ data }) => {
      const experimentId = Number(data.experience_id)
      const variantId = Number(data.variation_id)
      trackExperiment(variantId, experimentId)
      enrollCallback(variantId, experimentId)
    },
    [enrollCallback, trackExperiment]
  )

  const enrollUserInExperiment = useCallback(() => {
    // Don't enroll if the experiment has already been enrolled
    if (typeof selectedVariant !== 'undefined') {
      return
    }

    // If we've already been enrolled in a previous instance, just use that
    if (window.convert?.currentData?.experiments) {
      const variant = window.convert.currentData.experiments[`${experimentId}`]
      if (variant) {
        trackExperiment(variant.variation_id, experimentId)
        setSelectedVariant(variant.variation_id)
        return
      }
    }

    // Set up a timer to be triggered if we never get enrolled for some reason
    // (Network connection, experiment expired, debugging etc.)
    // If we receive a callback late (after the timeout), we'll let it replace
    // the timeout call.
    // Always select the default on the timeout.
    startTimeout(() => enrollCallback(null, experimentId), 3000)

    // Don't enroll unless Convert is enabled (we do this by checking for the presence of the convert property)
    if (window.convert == null) {
      return
    }

    // Use the callback to listen for the enrollment event
    convertQueue().push({
      what: 'addListener',
      params: { event: 'experience.activated', handler: convertCallbackEventHandler },
    })

    // Set the variable to tell Convert we're enrolling.
    // If the experiment Location isn't set up to use it, it will be ignored.
    window[`convert_exp_${experimentId}_enroll`] = true

    // Tell convert to recheck for enrollment.
    try {
      convertQueue().push(['executeExperiment', `${experimentId}`])
    } catch (e) {
      console.warn(`Failed to enroll in experiment '${experimentId}' due to an error`, e)
    }
  }, [selectedVariant, startTimeout, experimentId, enrollCallback, trackExperiment, convertCallbackEventHandler])

  return [selectedVariant, enrollUserInExperiment]
}

// List experiments from Convert.com below
// The Experiment Id can be obtained from the second column of the "experiences" table,
// or from the "experience" summary, listed as "ID" (this is the same as the "Experience Id")
//
// The Variant Ids can be most easily obtained from viewing the Experience Summary,
// then clicking the "..." option next to the Variation, and selecting "Preview".
// A modal will appear with a link; the `convert_v` parameter is the ID of the variation
// shown in the "Variation" drop down; change this drop-down to get all variant ids.
export const Experiments: { [key: string]: Experiment } = {
  /*
  ExampleExperiment: {
    id: 100123456,
    variants: {
      Original: 100123456,
      Variant: 100123457,
    },
  },
  */
  RestrictMeetingTimes: {
    id: 100446688,
    variants: {
      Original: 1004115847,
      Variant: 1004115848,
    },
  },
  ScreenForBudget: {
    id: 100457529,
    variants: {
      Original: 1004142994,
      Variant: 1004142995,
    },
  },
}
