import React, { FC, PropsWithChildren } from 'react'

const IconWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="tw-bg-neutrals-200 tw-rounded-lg tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center">
      {children}
    </div>
  )
}

export default IconWrapper
