import React, { FC, useCallback } from 'react'

import { track } from 'utils/analytics'

import AppleLogo from 'svgs/sso-logo-apple'
import GoogleLogo from 'svgs/sso-logo-google'

import SsoLink, { useSsoOnboardingState } from './SsoLink'

interface SsoLinksProps {
  actionLabel: 'Sign in' | 'Sign up'
  links?: Array<'apple' | 'google'>
}

const SsoLinks: FC<SsoLinksProps> = ({ actionLabel, links = ['apple', 'google'] }) => {
  const state = useSsoOnboardingState()

  const handleSsoClick = useCallback(
    (via) => {
      if (actionLabel === 'Sign in') {
        track('signin attempt', { via })
      }
    },
    [actionLabel]
  )

  const handleAppleClick = useCallback(() => {
    handleSsoClick('apple')
  }, [handleSsoClick])
  const handleGoogleClick = useCallback(() => {
    handleSsoClick('google')
  }, [handleSsoClick])

  if (links.length == 0) return null

  return (
    <>
      <div className="tw-space-y-3">
        {links.includes('apple') && (
          <SsoLink
            to={`/users/auth/apple?state=${state}`}
            outerClassName="tw-flex"
            innerClassName="tw-relative tw-grow tw-flex tw-align-middle tw-justify-center tw-rounded-full tw-max-w-xs tw-p-3 tw-mx-auto tw-bg-black tw-text-white visited:tw-text-white hover:tw-text-white"
            onClick={handleAppleClick}
          >
            <AppleLogo className="tw-absolute tw-w-12 tw-left-0 tw-top-0 tw-ml-3" />
            {`${actionLabel} with Apple`}
          </SsoLink>
        )}
        {links.includes('google') && (
          <SsoLink
            to={`/users/auth/google_oauth2?state=${state}`}
            outerClassName="tw-flex"
            innerClassName="tw-relative tw-grow tw-flex tw-align-middle tw-justify-center tw-rounded-full tw-max-w-xs tw-p-3 tw-mx-auto tw-text-black visited:tw-text-black hover:tw-text-black tw-border"
            onClick={handleGoogleClick}
          >
            <GoogleLogo className="tw-absolute tw-w-12 tw-left-0 tw-top-0 tw-ml-3" />
            {`${actionLabel} with Google`}
          </SsoLink>
        )}
      </div>

      <div className="tw-my-6 tw-relative">
        <div className="tw-absolute tw-inset-0 tw-flex tw-items-center">
          <div className="tw-w-full tw-border-t tw-border-base-gray-two"></div>
        </div>
        <div className="tw-relative tw-flex tw-justify-center tw-leading-5">
          <span className="tw-px-2 tw-bg-white tw-text-base-gray-three">{'or'}</span>
        </div>
      </div>
    </>
  )
}

export default SsoLinks
