import React, { FC } from 'react'

const NUM_LINES = 50

const WIDTH = 500

const PATH_STARTS = new Array(NUM_LINES).fill(0).map((r, i) => (i * WIDTH) / NUM_LINES)

const PATH = PATH_STARTS.map((s) => `M0 ${s} L${WIDTH} ${s}`).join(' ')

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="500px" height="500px" viewBox="0 0 500 500">
    <g>
      <g stroke="currentColor" strokeWidth="1.5" strokeDasharray="6">
        <path d={PATH} />
      </g>
    </g>
  </svg>
)

export default Svg
