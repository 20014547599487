import BookLarge from './BookLarge'
import BookSmall from './BookSmall'
import CaseStudies from './CaseStudies'
import Download from './Download'
import React, { FC } from 'react'
import CoreText from 'components/Core/CoreText'

import styles from './styles.module.scss'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import { useFlow } from 'recoil/onboardingFlows'
import { useOnboardingProperty, useOnboardingQueryStringValue } from 'recoil/onboarding'

const trackingScreen = 'book-meeting-download-app'

const Full: FC = () => {
  const { onboarding } = useOnboardingProperty()
  const flow = useOnboardingQueryStringValue('flow')

  return (
    <Wrapper
      backStyle="active"
      forwardStyle={onboarding.meetingTime ? 'active' : 'disabled'}
      className={styles.wrapper}
    >
      <div className={styles.header}>
        <CoreText.Headline size="xs" weight="heavy" color="primaryBlack">
          {'Book a 30-min meeting with a Realm Advisor'}
        </CoreText.Headline>
        <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack" className={styles.subheading}>
          {'Share more about your renovation so a Realm Advisor can give you accurate pricing'}
        </CoreText.Paragraph>
      </div>
      <BookLarge />
      <CaseStudies />
      {!onboarding.unavailable && flow != 'event' && flow != 'homeshow' && (
        <Download trackingScreen={'trackingScreen'} headerSize="large" copyMode="alternate" />
      )}
    </Wrapper>
  )
}

const BookFirst: FC = () => {
  const { next } = useFlow()
  const { onboarding } = useOnboardingProperty()

  return (
    <Wrapper
      backStyle="active"
      forwardStyle={onboarding.meetingTime ? 'active' : 'disabled'}
      className={styles.wrapper}
      progressStyle="disabled"
    >
      <div className={styles.header}>
        <CoreText.Headline size="xs" weight="heavy" color="primaryBlack">
          {'What would you like to do?'}
        </CoreText.Headline>
      </div>
      <BookSmall copyMode="primary" onExpand={next} />
      {!onboarding.unavailable && <Download trackingScreen={trackingScreen} headerSize="small" copyMode="alternate" />}
    </Wrapper>
  )
}

const DownloadFirst: FC = () => {
  const { next } = useFlow()
  const { onboarding } = useOnboardingProperty()

  return (
    <Wrapper
      backStyle="active"
      forwardStyle={onboarding.meetingTime ? 'active' : 'disabled'}
      className={styles.wrapper}
      progressStyle="disabled"
    >
      <div className={styles.header}>
        <CoreText.Headline size="xs" weight="heavy" color="primaryBlack">
          {'What would you like to do?'}
        </CoreText.Headline>
      </div>
      {!onboarding.unavailable && <Download trackingScreen={trackingScreen} headerSize="small" copyMode="primary" />}
      <BookSmall copyMode="alternate" onExpand={next} />
    </Wrapper>
  )
}

const DownloadOnly: FC = () => {
  return (
    <Wrapper backStyle="active" forwardStyle="disabled" className={styles.wrapper}>
      <div className={styles.header}>
        <CoreText.Headline size="xs" weight="heavy" color="primaryBlack">
          {'Continue with our award winning app'}
        </CoreText.Headline>
      </div>
      <Download trackingScreen={'trackingScreen'} headerSize="large" copyMode="alternate" />
    </Wrapper>
  )
}

const BookOrDownload = {
  Full: Full,
  BookFirst: BookFirst,
  DownloadFirst: DownloadFirst,
  DownloadOnly: DownloadOnly,
}

export default BookOrDownload
