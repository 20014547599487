import { FC, useEffect } from 'react'
import { track } from 'utils/analytics'

export const AnalyticsPerfTracker: FC = () => {
  useEffect(() => {
    if (!window?.performance || !document) return

    // We want to gather statistics _after_ page loading has finished.
    // We should be able to get there by waiting for the load event
    // to fire, then using a timeout, to ensure all subsequent loads
    // are also complete.

    let cancel = false
    const load = () => {
      setTimeout(() => {
        if (cancel) return
        const entries = window.performance.getEntriesByType('navigation')
        if (!entries || entries.length == 0) return

        const perf = entries[0] as PerformanceNavigationTiming

        track('performance', {
          // So we know what they loaded and how
          loadType: perf.type,
          uri: perf.name,
          // Useful derived durations
          totalTime: perf.duration,
          serverTime: perf.responseEnd - perf.requestStart,
          renderTime: perf.loadEventEnd - perf.domContentLoadedEventStart,

          // All detailed durations, mostly in order
          redirectTime: perf.redirectEnd - perf.redirectStart,
          cacheTime: perf.domainLookupStart - perf.fetchStart,
          domainLookupTime: perf.domainLookupEnd - perf.domainLookupStart,
          connectTime: perf.connectEnd - perf.connectStart,
          requestTime: perf.responseStart - perf.requestStart,
          responseTime: perf.responseEnd - perf.responseStart,
          unloadEventTime: perf.unloadEventEnd - perf.unloadEventStart, // Odd duck
          blockingResourcesTime: perf.domInteractive - perf.responseEnd,
          domContentLoadedTime: perf.domComplete - perf.domInteractive,
          loadEventTime: perf.loadEventEnd - perf.loadEventStart,
        })
      }, 1000) // Wait 1 second after loading to be sure slow machines are done
    }

    if (document.readyState == 'complete') {
      load()
    } else {
      window.addEventListener('load', load)
    }

    return () => {
      cancel = true
    }
  }, [])

  return null
}

export default AnalyticsPerfTracker
