import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import { formatCurrency } from 'utils/formatting'

import { Project } from 'recoil/projects'

import styles from './ProjectStats.module.scss'

interface Props {
  project?: Project
  fake?: boolean
}

function formatHomeValue(value) {
  return `${value >= 0 ? '+' : '-'}${formatCurrency(value)}`
}

const ProjectStats: FC<PropsWithChildren<Props>> = ({ project, fake }) => (
  <section className={classNames(styles.projectStats, fake ? styles.fake : null)}>
    <h3>{fake ? 'XX' : project?.name}</h3>
    <div className={styles.stats}>
      <div className={classNames(styles.stat, styles.homeValue)}>
        <span className={styles.label}>{'Home Value'}</span>
        <span className={styles.value}>{fake ? '+XXXX' : formatHomeValue(project?.additional_home_value)}</span>
      </div>
      <div className={classNames(styles.stat, styles.cost)}>
        <span className={styles.label}>{'Cost'}</span>
        <span className={styles.value}>
          {fake
            ? 'XXXX'
            : `${formatCurrency(project?.cost_estimate_low || '')} - ${formatCurrency(
                project?.cost_estimate_high || ''
              )}`}
        </span>
      </div>
    </div>
  </section>
)

export default ProjectStats
