import { FC, useEffect } from 'react'

import { loginChecked, userState } from 'recoil/user'
import { useRecoilState, useRecoilValue } from 'recoil'

interface RefreshUserProps {
  hard?: boolean
}

const RefreshUser: FC<RefreshUserProps> = ({ hard }) => {
  const user = useRecoilValue(userState)
  const [checked, setChecked] = useRecoilState(loginChecked)

  useEffect(() => {
    if (!user && checked) {
      const newUrl = window.location.href
      hard ? (window.location.href = newUrl) : setChecked(false)
    }
  }, [user, checked, setChecked, hard])

  return null
}

export default RefreshUser
