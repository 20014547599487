import React, { FC } from 'react'
import classnames from 'classnames'
import Input from 'components/Input'
import Button from 'components/Button2'
import Submittable from 'components/Submittable'
import SsoLinks from 'components/Auth/SsoLinks'
import FullAddressInput, { Prediction } from 'components/FullAddressInput'

import styles from './styles.module.scss'
import TermsConsentMessage from 'components/TermsConsentMessage'

export interface SignupFormFields {
  full_name: string
  ambassador_name: string
  email: string
  password: string
  entered_address: string
}

export interface SignupFormProps {
  noAddress?: boolean
  showAmbassadorField?: boolean
  ctaCopy: string
  form: SignupFormFields
  onChange: (fieldName: keyof SignupFormFields) => (value: any) => void
  onPredictionSelected: (prediction: Prediction | null) => void
  onSubmit: () => void
  errors?: Record<string, string[]>
  isLoading: boolean
  ssoOptions?: Array<'apple' | 'google'>
}

const SignupForm: FC<SignupFormProps> = ({
  noAddress,
  showAmbassadorField,
  form,
  ctaCopy,
  onChange,
  onPredictionSelected,
  onSubmit,
  errors = {},
  isLoading,
  ssoOptions = ['apple', 'google'],
}) => {
  const fieldProps = (f: keyof SignupFormFields): { onChange: (value: any) => void; value: any } => {
    return {
      onChange: onChange(f),
      value: form[f],
    }
  }

  return (
    <>
      <SsoLinks actionLabel="Sign up" links={ssoOptions} />
      <Submittable onSubmit={onSubmit}>
        <fieldset disabled={isLoading} className={classnames(styles.signUp, 'tw-space-y-3')}>
          <Input
            label="Name"
            id="full_name"
            placeholder="Your Name"
            required
            errors={errors.full_name}
            {...fieldProps('full_name')}
          />

          {showAmbassadorField && (
            <Input
              label="Ambassador Name"
              id="ambassador_name"
              required
              errors={errors.ambassador_name}
              {...fieldProps('ambassador_name')}
            />
          )}

          <Input
            autoComplete="email"
            label="Email"
            id="email"
            placeholder="example@email.com"
            required
            errors={errors.email}
            {...fieldProps('email')}
          />

          <Input
            className="tw-mb-6"
            errors={errors.password}
            label="Password"
            id="password"
            placeholder="********"
            required
            type="password"
            {...fieldProps('password')}
          />

          {!noAddress && (
            <FullAddressInput
              className={styles.search}
              label="Address"
              placeholder="123 Main St, Anytown, CA, 90210"
              id="entered_address"
              required
              errors={errors.entered_address}
              onPredictionSelected={onPredictionSelected}
              {...fieldProps('entered_address')}
            />
          )}
        </fieldset>

        <div className="tw-mt-6 tw-flex tw-justify-center">
          <Button type="submit" className="submitButtonExtraCSS" loading={isLoading}>
            {ctaCopy}
          </Button>
        </div>
        <TermsConsentMessage className="tw-text-gray-600 tw-text-xs tw-text-center tw-mt-4" />
      </Submittable>
    </>
  )
}

export default SignupForm
