import React, { FC, ReactNode, useCallback } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { track } from 'utils/analytics'

import Alert from 'svgs/alert'

import { AvailableErrorType } from 'recoil/projectTemplates'

import styles from './styles.module.scss'

const availableErrorToMessage = {
  [AvailableErrorType.Duplicate]: 'This project is a duplicate of one you have already added.',
  [AvailableErrorType.ExceedsSqFootage]: 'This project exceeds your buildable square feet limits.',
  [AvailableErrorType.ExceedsSqFootageFloor1]:
    'This project exceeds your buildable square feet limit on the first floor.',
  [AvailableErrorType.ExceedsSqFootageFloor2]:
    'This project exceeds your buildable square feet limit on the second floor.',
  [AvailableErrorType.NoAttic]: 'This property does not have an attic.',
  [AvailableErrorType.NoBasement]: 'This property does not have a basement.',
  [AvailableErrorType.NoGarage]: 'This property does not have a garage.',
  [AvailableErrorType.ExceedsADUCount]: "This project exceeds your property's ADU limit.",
  [AvailableErrorType.ExceedsADUSize]: "This project's size exceeds your property's max permissable ADU size.",
}
const defaultAvailableError = 'This project cannot be built on this property.'

interface Props {
  errorType?: AvailableErrorType
  message?: string
  children?: ReactNode
  className?: string
}

const ErrorFlag: FC<Props> = ({ errorType, message, children, className }) => {
  const handleOnClick = useCallback(() => {
    track('click cta', { cta: 'edit', module: 'project-requirements', error: errorType })
  }, [errorType])

  const error = (
    <>
      <span>{availableErrorToMessage[errorType || ''] || message || defaultAvailableError}</span>
      <span>
        {' If this is inaccurate, '}
        <Link to={'../edit'} onClick={handleOnClick}>
          {'edit these details'}
        </Link>
        {' and we will refresh our analysis.'}
      </span>
    </>
  )

  return (
    <div className={classNames(styles.errorFlag, className)}>
      <Alert />
      <div>{children || error}</div>
    </div>
  )
}

export default ErrorFlag
