import React, { FC, ReactNode, useCallback } from 'react'
import CoreText from 'components/Core/CoreText'

import Pencil from 'svgs/pencil'

import styles from './RenovationDetailRow.module.scss'

interface RenovationDetailRowProps {
  icon: ReactNode
  line1: string
  line2?: string
  onClick?: () => void
}

const RenovationDetailRow: FC<RenovationDetailRowProps> = ({ icon, line1, line2, onClick }) => {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key == 'Enter' && onClick) onClick()
    },
    [onClick]
  )

  return (
    <div className={styles.container} onClick={onClick} role="button" tabIndex={0} onKeyDown={handleKeyDown}>
      <div className={styles.iconWrapper}>{icon}</div>
      <div className={styles.text}>
        <CoreText.Paragraph size="m" weight="light">
          {line1}
        </CoreText.Paragraph>
        {line2 && (
          <CoreText.Paragraph size="m" weight="light">
            {line2}
          </CoreText.Paragraph>
        )}
      </div>
      {onClick && (
        <div className={styles.buttonWrapper}>
          <Pencil />
        </div>
      )}
    </div>
  )
}

export default RenovationDetailRow
