import React, { FC } from 'react'

interface Props {
  className?: string
  checked?: boolean
}

/* Stay outside until we need to dynamically change the base values */
const diameter = 24
const stroke = 1
const checkStroke = 5
const radioRadius = 8.5
const checkRadius = 6

const radius = diameter / 2

const Svg: FC<Props> = ({ className, checked }) => {
  const strokeVal = checked ? checkStroke : stroke
  const radioRadiusVal = checked ? checkRadius : radioRadius
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={`${diameter}`}
      height={`${diameter}`}
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      <g fill="none" fillRule="evenodd">
        <circle
          stroke="currentColor"
          strokeWidth={`${strokeVal}`}
          cx={`${radius}`}
          cy={`${radius}`}
          r={`${radioRadiusVal}`}
        />
      </g>
    </svg>
  )
}

export default Svg
