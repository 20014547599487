import React, { FC, useCallback, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { track as legacyTrack } from 'utils/analytics'
import { trackClick as trackClickBase, prepare } from 'utils/analyticsV2'

import { useAuth, LoginState } from 'non-rendering/Authentication'
import { useOnboardingProperty } from 'recoil/onboarding'
import { useProperties } from 'recoil/properties'
import { useFlow } from 'recoil/onboardingFlows'

import FullAddressInput, { Prediction } from 'components/FullAddressInput'
import Loading from 'components/Loading'

import styles from './styles.module.scss'
import DefaultWrapper from 'pages/Onboarding/components/DefaultWrapper'
import cx from 'classnames'

const trackClick = prepare({ screen: 'enter-address', family: 'onboarding' }, trackClickBase)

const OnboardingEnterAddress: FC = () => {
  const history = useHistory()

  const { state: userState } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [enteredAddress, setEnteredAddress] = useState<string>('')
  const lastSelected = useRef<Prediction | null>(null)
  const { properties } = useProperties()
  const { searchPublicProperty, clearOnboarding } = useOnboardingProperty()
  const { next } = useFlow()

  const onPredictionSelected = useCallback(
    async (prediction: Prediction | null) => {
      if (!prediction) return

      // In some circumstances, the FullAddressInput control can issue two
      // onPredictionSelected events with the same prediction. This will
      // screw us up by redirecting twice with a delay between them.
      // We need to stop this.
      // The prediction needs to be different from the last one to continue.
      if (lastSelected.current === prediction) return
      lastSelected.current = prediction

      // Clear previous onboarding property, if any.
      await clearOnboarding(true)

      setIsLoading(true)
      legacyTrack('submit form', { section: 'onboarding', form: 'enter-address' })
      const result = await searchPublicProperty(prediction)

      await trackClick({
        code: 'continue',
        section: 'move-forward',
        content_text: prediction.description,
        flexfield_1: { marketplace: result },
      })

      if (userState == LoginState.LoggedIn) {
        // We're logged in.
        const matchingProperty = (properties || []).find(
          (property) => property.attom_id == result.publicPropertyData?.attom_id
        )

        if (matchingProperty) {
          // If our attom_id matches an existing property, just redirect to that
          // property.
          return history.push(`/properties/${matchingProperty.id}/dashboard`)
        } else {
          next()
        }
      }
      // We're not logged in
      else if (result.unavailableUrl) {
        // can't do history.push here since this is a server-generated page
        window.location.href = result.unavailableUrl
      } else {
        next()
      }
    },
    [clearOnboarding, searchPublicProperty, userState, history, properties, next]
  )

  const isDone = useRef<boolean>(false)
  useEffect(() => {
    if (enteredAddress.length > 0 && !isDone.current) {
      isDone.current = true
      trackClick({ code: 'enter-address', section: 'address' })
    }
  }, [enteredAddress])

  return (
    <DefaultWrapper className={cx({ [styles.wrapper]: !isLoading })} logoClassName={cx({ [styles.logo]: !isLoading })}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1 className="tw-font-normal tw-tracking-tight tw-text-center tw-m-0 tw-mb-8 lg:tw-mb-12 lg:tw-leading-10 lg:tw-text-4xl">
            {(properties || []).length > 0
              ? 'Add another property, and manage it all under the same account.'
              : 'Discover your home’s actual value and how you can tap its potential—all in less than a minute.'}
          </h1>
          <FullAddressInput
            className={styles.search}
            placeholder="Enter your address"
            id="entered_address"
            required
            onPredictionSelected={onPredictionSelected}
            value={enteredAddress}
            onChange={setEnteredAddress}
          />
        </>
      )}
    </DefaultWrapper>
  )
}

export default OnboardingEnterAddress
