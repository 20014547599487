import React, { FC } from 'react'
import { PublicPropertyComparable, publicPropertyIndexedState } from 'recoil/publicProperty'
import Bath from 'svgs/bath'
import Bed from 'svgs/bed'
import { formatAddressCasing, formatCurrency } from 'utils/formatting'
import cx from 'classnames'
import styles from './styles.module.scss'
import { generateIndexedUrlPathForPublicProperty, generateUrlPathForPublicProperty } from 'utils/publicProperty'
import { useRecoilValue } from 'recoil'
import { PublicPropertyAvatar } from 'components/PropertyAvatar'

interface ComparablesProps {
  className?: string
  comparables: PublicPropertyComparable[]
}

const Comparables: FC<ComparablesProps> = ({ className, comparables }) => {
  const indexed = useRecoilValue(publicPropertyIndexedState)
  if (!comparables) return null

  return (
    <div className={cx(className, styles.comparables)}>
      {comparables.map((comparable) => (
        <div className={styles.comparable} key={comparable.tax_assessor.attom_id}>
          <a
            href={
              indexed
                ? generateIndexedUrlPathForPublicProperty(comparable.tax_assessor)
                : generateUrlPathForPublicProperty(comparable.tax_assessor)
            }
          >
            <PublicPropertyAvatar
              className={styles.comparableImage}
              publicProperty={comparable.tax_assessor}
              width={592}
              height={364}
            />
          </a>
          <div className={styles.comparableAddress}>
            {formatAddressCasing(comparable.tax_assessor.propertyaddressfull)}
          </div>
          <div className={styles.comparableValue}>{formatCurrency(comparable.avm.estimatedvalue)}</div>
          <div className={styles.comparableDetails}>
            <div className={styles.comparableDetail}>
              {comparable.bedrooms}
              <Bed />
            </div>
            <div className={styles.comparableDetail}>
              {comparable.bathrooms}
              <Bath />
            </div>
            <div className={styles.comparableDetail}>{`${comparable.square_footage} sqft`}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Comparables
