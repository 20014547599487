import classNames from 'classnames'
import Card from 'components/Card'
import React, { FC, useCallback } from 'react'
import BarChart from './BarChart'
import { useProperties } from 'recoil/properties'

import { usePropertyCompleteness } from 'recoil/propertyCompleteness'
import { formatMoneyNoDecimals } from 'utils/formatting'
import sharedStyles from '../styles.module.scss'
import styles from './styles.module.scss'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { track } from 'utils/analytics'
import { PropertyAvatar } from 'components/PropertyAvatar'
import IntercomCta from 'components/IntercomCta'

export const PropertyCompleteness: FC = () => {
  const { selectedProperty } = useProperties()
  const { completeness } = usePropertyCompleteness()

  const handleViewChangesClick = useCallback(() => {
    track('click cta', {
      cta: 'dashboard',
      module: 'property-completeness',
      moduleState: selectedProperty?.edit_state?.dirty ? 'dirty' : 'clean',
    })
  }, [selectedProperty])

  if (!selectedProperty) return null

  const difference =
    selectedProperty.estimated_value - (selectedProperty.edit_state?.original_avm || selectedProperty.estimated_value)
  const dirty = selectedProperty.edit_state?.dirty
  const decrease = difference < 0

  return (
    <div className={styles.completeness}>
      <Card className={classNames(sharedStyles.card, styles.card)}>
        <PropertyAvatar className={styles.avatar} property={selectedProperty} width={160} height={160} mapbox />
        <div className={styles.address}>{selectedProperty.delivery_line_1}</div>
        <div className={styles.cityStateZip}>
          {selectedProperty.city}
          {', '}
          {selectedProperty.state}
          {', '}
          {selectedProperty.zip5}
        </div>
        <BarChart className="my-3" score={completeness.score.val} maxScore={completeness.maxScore} />
        <div className={styles.strength}>
          <div className={styles.label}>{'Property Profile Strength'}</div>
          <div className={styles.level}>{completeness.score.level}</div>
        </div>
        <div className="d-none d-lg-block">
          <div className={cx(styles.avm, decrease && styles.decrease)}>
            <div className={styles.avmLabel}>{'Property Value'}</div>
            <div className={cx(styles.avmValue, dirty && styles.dirty)}>
              {formatMoneyNoDecimals(selectedProperty.estimated_value)}
            </div>
            {dirty && (
              <div className={styles.avmDifference}>
                {difference === 0 ? (
                  <>{'Based on your edits your home value has not changed so far.'}</>
                ) : (
                  <>
                    {`Based on your edits your home value has ${decrease ? 'decreased' : 'increased'} by `}
                    <span className={styles.moneyDifference}>
                      {formatMoneyNoDecimals(decrease ? -difference : difference)}
                    </span>
                    {'.'}
                  </>
                )}
              </div>
            )}
          </div>

          <Link className={cx(styles.cta, dirty && styles.dirty)} to={'dashboard'} onClick={handleViewChangesClick}>
            {dirty ? 'View changes on your Dashboard' : 'Back to your Dashboard'}
          </Link>
        </div>
      </Card>
      {selectedProperty.marketplace && (
        <div className={styles.helpBox}>
          {'Need help editing your property details?'}
          <IntercomCta className={styles.advisorCta} location="in-page">
            {'Chat with a Realm Advisor'}
          </IntercomCta>
        </div>
      )}
    </div>
  )
}

export default PropertyCompleteness
