import React, { FC, useCallback } from 'react'
import classNames from 'classnames'

import { Package } from 'recoil/packages'

import Document from 'svgs/document'

import FancyButton from 'components/FancyButton'
import Modal from 'components/NewModal'
import Markdown from 'components/Markdown'
import Video from 'components/WistiaVideo'

import styles from './Package.module.scss'

interface Props {
  package: Package
}

const Details: FC<Props> = ({ package: packageObject }) => {
  const renderButtons = useCallback(() => {
    return (
      <div className={styles.detailsButtons}>
        {packageObject.sample ? (
          <FancyButton
            className={classNames(styles.detailsButton, styles.download)}
            href={packageObject.sample}
            target="_blank"
            download={`Sample ${packageObject.name}`}
          >
            <div className={styles.downloadDetails}>
              <Document className={styles.icon} />
              {'Download Sample'}
            </div>
          </FancyButton>
        ) : null}
        <FancyButton className={styles.detailsButton} to="../packages">
          {'Ok'}
        </FancyButton>
      </div>
    )
  }, [packageObject])
  // So we need to go to the parent resource and then back to 'packages'
  // here, because supplying just '.' ends up adding a trailing '/' character
  // which is no bueno.
  return (
    <Modal onClose={{ to: '../packages' }} title={packageObject.name} buttons={renderButtons}>
      <Markdown
        options={{
          overrides: {
            Video: {
              component: Video,
            },
          },
        }}
      >
        {packageObject.details}
      </Markdown>
    </Modal>
  )
}

export default Details
