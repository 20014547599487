import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import { Model } from 'recoil/model'
import { useProperties } from 'recoil/properties'

import { patchJSON, postJSON, getJSON } from 'utils/fetch'

export interface UserComment extends Model {
  id?: number
  text?: string
}

export const userCommentState = atom<UserComment | null>({
  key: 'UserComment',
  default: null,
})

export const userCommentFetchedIdState = atom<number | null>({
  key: 'UserCommentFetchedId',
  default: null,
})

export const useUserComment = (): {
  userComment: UserComment | null
  addUserComment: (comment: UserComment) => Promise<void>
  updateUserComment: (comment: UserComment) => Promise<void>
} => {
  const { selectedProperty } = useProperties()
  const [userComment, setUserComment] = useRecoilState(userCommentState)
  const [fetchedId, setFetchedId] = useRecoilState(userCommentFetchedIdState)

  useEffect(() => {
    if (!selectedProperty?.id) return
    const runme = async () => {
      if (fetchedId !== selectedProperty.id) {
        const res = await getJSON(`/api/v1/properties/${selectedProperty.id}/user_comments`)
        const all = res as Array<UserComment>

        if (all.length > 0) {
          // this should always return at most 1 user comment
          // (since we're only allowing 1 rn) - in the future there may be more?
          setUserComment(all[0])
        } else {
          setUserComment(null)
        }
        setFetchedId(selectedProperty.id)
      }
    }
    runme()
  }, [fetchedId, setFetchedId, setUserComment, selectedProperty?.id])

  return {
    userComment,
    addUserComment: async (c: UserComment) => {
      if (!selectedProperty) return
      const res = await postJSON(
        `/api/v1/properties/${selectedProperty.id}/user_comments`,
        c as unknown as Record<string, unknown>
      )
      if (res.isError) {
        const error = new Error(`Non-200 status code: ${res.code}.`)
        throw Object.assign(error, { body: res.jsonBody })
      }
      const userComment = res.jsonBody
      setUserComment(userComment)
    },
    updateUserComment: async (c: UserComment) => {
      if (!selectedProperty) return
      const res = await patchJSON(
        `/api/v1/properties/${selectedProperty.id}/user_comments/${c.id}`,
        c as unknown as Record<string, unknown>
      )
      if (res.isError) {
        const error = new Error(`Non-200 status code: ${res.code}.`)
        throw Object.assign(error, { body: res.jsonBody })
      }
      const userComment = res.jsonBody
      setUserComment(userComment)
    },
  }
}
