import React, { FC, useCallback, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { track } from 'utils/analytics'

import { useAuth, LoginState } from 'non-rendering/Authentication'

import { useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { useProperties, Property, propertiesState } from 'recoil/properties'

import OnboardingFlowRestart from '../../OnboardingFlowRestart'

import CreatingProperty from 'components/Auth/CreatingProperty'
import Loading from 'components/Loading'

import styles from './styles.module.scss'
import DefaultWrapper from 'pages/Onboarding/components/DefaultWrapper'

const OnboardingAddProperty: FC = () => {
  const history = useHistory()

  const { state: userState } = useAuth()
  const { properties } = useProperties()
  const setProperties = useSetRecoilState(propertiesState)
  const addHasStarted = useRef(false)
  const isOnPage = useRef(true)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [creatingPropertyToken, setCreatingPropertyToken] = useState<string>()

  const {
    onboarding,
    createProperty,
    fetchProperty,
    updateUserOnPropertyCreate,
    clearOnboarding,
  } = useOnboardingProperty()
  const { next, restart } = useFlow()

  useEffect(() => {
    isOnPage.current = true
    return () => {
      isOnPage.current = false
    }
  })

  const addProperty = useCallback(async () => {
    setIsCreating(true)

    // Issue submit form even for leaving this panel
    const eventAttributes: any = {
      section: 'onboarding',
      form: 'add-property',
    }
    // But it may also count as us creating a property.
    // Total is current number + 1 for this new one being added.
    if (userState == LoginState.LoggedIn) eventAttributes.totalProperties = (properties || []).length + 1
    track('submit form', eventAttributes)

    const response = await createProperty()
    if (response.pollableJobToken) {
      setCreatingPropertyToken(response.pollableJobToken)
    } else {
      restart()
    }
  }, [createProperty, restart, properties, userState])

  // Start creating the property immediately.
  useEffect(() => {
    if (!addHasStarted.current) {
      addHasStarted.current = true
      setTimeout(() => addProperty(), 500)
    }
  }, [addProperty])

  const completeOnboarding = useCallback(
    async (newProperty: Property) => {
      // Update user
      await updateUserOnPropertyCreate(newProperty.id)

      // Get it added to our list of properties.
      setProperties((properties) => {
        // It is possible that 'create' returns to us a property
        // that already exists in our list. If so, we want to *replace*
        // that existing property rather than adding this new one in.
        properties = (properties || []).map((old_prop) => (old_prop.id == newProperty.id ? newProperty : old_prop))
        if (!properties.find((old_prop) => old_prop.id == newProperty.id)) {
          properties = properties.concat([newProperty])
        }
        return properties
      })

      // Clear onboarding before continuing.
      clearOnboarding()

      // Then go to that property's dashboard
      history.push(`/properties/${newProperty.id}/dashboard`)
    },
    [updateUserOnPropertyCreate, setProperties, clearOnboarding, history]
  )

  const handlePropertyCreated = useCallback(
    async (result: { id: number }) => {
      const newProperty = await fetchProperty(result.id)
      if (userState == LoginState.LoggedIn) {
        await completeOnboarding(newProperty)
      } else {
        next()
      }
    },
    [fetchProperty, userState, completeOnboarding, next]
  )

  if (!onboarding.publicPropertyData?.public_property) {
    return <OnboardingFlowRestart />
  }

  return (
    <DefaultWrapper>
      {isCreating ? (
        <CreatingProperty
          className={styles.createWait}
          token={creatingPropertyToken}
          onSuccess={handlePropertyCreated}
        />
      ) : (
        <Loading />
      )}
    </DefaultWrapper>
  )
}

export default OnboardingAddProperty
