import CoreList from 'components/Core/CoreList'
import CoreText from 'components/Core/CoreText'
import CamV3Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import React, { FC, Fragment, ReactNode, useCallback, useState } from 'react'
import Gavel from '@mui/icons-material/Gavel'
import People from '@mui/icons-material/People'
import TransferWithinAStation from '@mui/icons-material/TransferWithinAStation'
import MobileFriendly from '@mui/icons-material/MobileFriendly'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import CompareArrows from '@mui/icons-material/CompareArrows'
import Chat from '@mui/icons-material/Chat'
import Assessment from '@mui/icons-material/Assessment'
import Search from '@mui/icons-material/Search'
import Handshake from '@mui/icons-material/Handshake'
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn'
import ImageSearch from '@mui/icons-material/ImageSearch'
import Handyman from '@mui/icons-material/Handyman'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'

import cx from 'classnames'
import CoreDivider from 'components/Core/CoreDivider'
import { useFlow } from 'recoil/onboardingFlows'
import { prepareBoth } from 'utils/analyticsV2'

interface ValuePropStep {
  icon: ReactNode
  text: string
  trackAs: string
  children: {
    icon: ReactNode
    headline?: string
    description: string | ReactNode
    divider?: boolean
  }[]
}

const VALUE_PROPS: ValuePropStep[] = [
  {
    icon: <Gavel />,
    text: '3 additional competitive bids in 7 days*',
    trackAs: 'competitive-bids',
    children: [
      {
        icon: <People />,
        headline: 'Highly vetted contractors',
        description:
          'Less than 20% of contractors are selected to be in Realm’s network, and they are re-reviewed monthly.',
        divider: true,
      },
      {
        icon: <TransferWithinAStation />,
        headline: 'Effortless contractor visits',
        description:
          'Realm arranges site visits with vetted vendors, whether you need architects, contractors, and/or design-builders**. After the site visits, they’ll prepare competitive bids.',
        divider: true,
      },
      {
        icon: <MobileFriendly />,
        headline: 'Utilize the Realm mobile app as your 24/7 renovation companion',
        description:
          'Effortlessly schedule site visits, review contractor profiles, and review bids and contracts using the Realm App.',
      },
      {
        icon: <InfoOutlined />,
        description: (
          <ol>
            <li>{'*For projects with a typical scope, bids are returned 7 days following each site visit'}</li>
            <li>
              {
                '**Realm currently does not provide advisory service for work carried out by handymen, sub-contractors, etc'
              }
            </li>
          </ol>
        ),
      },
    ],
  },
  {
    icon: <CompareArrows />,
    text: 'Comprehensive bids review',
    trackAs: 'bid-review',
    children: [
      {
        icon: <Chat />,
        headline: 'Work with highly experienced advisors',
        description:
          'We’ll walk through each bid with you, answer any questions, and help decide which contractor is right for you.',

        divider: true,
      },
      {
        icon: <Assessment />,
        headline: 'Bid assessment',
        description: 'We carefully analyze bids, ensuring accuracy and highlighting unique features.',
        divider: true,
      },
      {
        icon: <Search />,
        headline: 'Selecting the best bid',
        description:
          'We identify the best bids and contractors. Our selection goes beyond the scope, considering price, speed, track record, and style.',
      },
    ],
  },
  {
    icon: <Handshake />,
    text: 'Contract negotiation',
    trackAs: 'contract-negotiation',
    children: [
      {
        icon: <AssignmentTurnedIn />,
        headline: 'Contractor verification',
        description:
          'We confirm contractor details, like making sure your contractor is working under the correct license, for your peace of mind.',
        divider: true,
      },
      {
        icon: <ImageSearch />,
        headline: 'Contract evaluation & Terms clarification',
        description:
          'Our experts meticulously review contracts, and complex language is explained for your understanding.',
        divider: true,
      },
      {
        icon: <Handyman />,
        headline: 'Support after constructions starts',
        description:
          'Even after construction starts, we’re still here to answer questions and provide support and guidance.',
      },
    ],
  },
]

const [trackClick, _] = prepareBoth({ screen: 'outside-bids-value-prop', family: 'onboarding' })

const CamV3OutsideBidsValueProp: FC = () => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const { next } = useFlow()

  const handleNext = useCallback(() => {
    next()
  }, [next])

  const handleToggleExpand = useCallback(
    (index: number) => {
      return () => {
        const item = VALUE_PROPS[index]
        const itemExpanded = expanded[index]

        trackClick({
          code: `${itemExpanded ? 'collapse' : 'expand'}-value-prop`,
          feature: item.trackAs,
          content_text: item.text,
        })

        setExpanded({ ...expanded, [index]: !itemExpanded })
      }
    },
    [setExpanded, expanded]
  )

  return (
    <CamV3Wrapper forwardStyle="active" backStyle="active" footer={{ text: 'Next', onClick: handleNext }}>
      <CoreText.Headline size="xs" color="primaryBlack" weight="heavy" className="tw-mb-6">
        {'Get 3 competitive bids from Realm’s highly vetted contractors'}
      </CoreText.Headline>
      <CoreText.Paragraph size="m" color="tertiaryBlack" weight="light">
        {'Share details about your renovation so we can give you accurate pricing'}
      </CoreText.Paragraph>
      <CoreList className="tw-my-4">
        {VALUE_PROPS.map(({ icon, text, children }, i) => {
          const itemExpanded = !!expanded[i]

          return (
            <Fragment key={text}>
              <CoreList.Item
                className="tw-bg-transparent"
                text={text}
                leftIcon={
                  <div
                    className={cx(
                      'tw-rounded-full',
                      itemExpanded ? 'tw-bg-black tw-text-white' : 'tw-bg-neutrals-200 tw-text-neutrals-1000'
                    )}
                  >
                    {icon}
                  </div>
                }
                leftIconContained
                onClick={handleToggleExpand(i)}
                right={itemExpanded ? <ExpandLess /> : <ExpandMore />}
              />
              {itemExpanded && (
                <>
                  {children.map(({ icon: childIcon, headline, description, divider }) => {
                    return (
                      <CoreList.Item
                        className="tw-bg-transparent"
                        key={headline}
                        center={
                          <>
                            {headline && (
                              <CoreText.Paragraph size="m" weight="regular" color="secondaryBlack">
                                {headline}
                              </CoreText.Paragraph>
                            )}
                            <CoreText.Paragraph size="m" weight="light" color="tertiaryBlack">
                              {description}
                            </CoreText.Paragraph>
                          </>
                        }
                        leftIcon={
                          <div className="tw-rounded-full tw-bg-neutrals-200 tw-text-neutrals-1000">{childIcon}</div>
                        }
                        leftIconContained
                        divider={!!divider && <CoreDivider.Full />}
                      />
                    )
                  })}
                </>
              )}
            </Fragment>
          )
        })}
      </CoreList>
    </CamV3Wrapper>
  )
}

export default CamV3OutsideBidsValueProp
