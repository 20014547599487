import React, { createRef, FC, useCallback, useEffect } from 'react'
import { useFlow } from 'recoil/onboardingFlows'

import styles from './styles.module.scss'
import animationStyles from '../../FlowAnimator.module.scss'

import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import cx from 'classnames'
import ArrowEast from 'pages/Properties/PropertyCard/icons/arrow-east'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import { prepareBoth } from 'utils/analyticsV2'

const [trackClick] = prepareBoth({ family: 'onboarding', screen: 'enter-address' })
export { trackClick }
import { useAdvisorBooking, useOnboardingCalendlyEvent } from 'recoil/advisorBooking'
import AddressAutocompleteField from './AddressAutocompleteField'
import { useOnboardingProperty } from 'recoil/onboarding'
import { getJSON } from 'utils/fetch'
import { Experiments, useExperiment } from 'experiments'

const Landing: FC = () => {
  const { next } = useFlow()
  const calendlyUri = useOnboardingCalendlyEvent()
  const { preloadedTimes, preloadTimes } = useAdvisorBooking(calendlyUri)
  const inputRef = createRef<HTMLInputElement>()
  const [selectedVariant, enrollUserInExperiment] = useExperiment(Experiments.ScreenForBudget.id)
  const { setOnboarding } = useOnboardingProperty()

  useEffect(() => enrollUserInExperiment(), [enrollUserInExperiment])

  useEffect(() => {
    setOnboarding((o) => ({
      ...o,
      screenBudget: selectedVariant == Experiments.ScreenForBudget.variants.Variant,
    }))
  }, [setOnboarding, selectedVariant])

  // we will see the initial times for the Book or Download Screen here
  // because we have to load for search anyway
  useEffect(() => {
    if (preloadedTimes.length == 0) {
      preloadTimes()
    }
  }, [preloadedTimes, preloadTimes])

  const handleSelection = useCallback(async () => {
    next()
  }, [next])

  const handleFocus = useCallback(() => {
    trackClick({ code: 'enter-address', section: 'address', feature: 'transition' })
    next()
  }, [next])

  const handleClickSearch = useCallback(() => {
    trackClick({ code: 'enter-address', section: 'address', feature: 'transition' })
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <Wrapper
      animationStyle={animationStyles.none}
      innerClassName={styles.wrapperInner}
      logoClassName={styles.wrapperLogo}
    >
      <div className={styles.container}>
        <div className={styles.videoContainer}>
          <video
            autoPlay
            muted
            loop
            playsInline
            disablePictureInPicture
            className={cx(styles.rounded, animationStyles.appear)}
          >
            <source src="/videos/onboarding-start-express-meeting.mp4" type="video/mp4" />
          </video>
          <div className={cx(styles.gradient, styles.rounded, animationStyles.enterAddressVideo)} />
          <div className={styles.inner}>
            <div className={cx(styles.copyWrapper, animationStyles.appear)}>
              <CoreText.Headline size="s" weight="heavy" className={styles.heading}>
                {'Let a renovation expert price your project'}
              </CoreText.Headline>
              <CoreText.Paragraph size="l" weight="light" className={styles.subheading}>
                {"After pricing, we'll source you 3 fair bids from our invite-only contractor network"}
              </CoreText.Paragraph>
            </div>
            <div className={cx(styles.searchButtonWrapper, animationStyles.appear)}>
              <CoreButton icon={<ArrowEast />} className={styles.searchButton} onClick={handleClickSearch} />
            </div>
            <div className={cx(styles.addressInputWrapper, styles.desktop, animationStyles.enterAddressInput)}>
              <AddressAutocompleteField onSelected={handleSelection} onFocus={handleFocus} />
            </div>
          </div>
        </div>
        <div className={cx(styles.addressInputWrapper, styles.mobile, animationStyles.enterAddressInput)}>
          <AddressAutocompleteField ref={inputRef} onSelected={handleSelection} onFocus={handleFocus} />
        </div>
        <div className={cx(styles.spacer, animationStyles.enterAddressSpacer)} />
      </div>
    </Wrapper>
  )
}

const Search: FC = () => {
  const { next } = useFlow()
  const { onboarding, setOnboarding } = useOnboardingProperty()

  const handleSelection = useCallback(async () => {
    next()
  }, [next])

  useEffect(() => {
    const _ = async () => {
      if (onboarding.country) return

      const result = await getJSON<{ country: string }>('/api/v1/geolocate_ip')
      setOnboarding((onboarding) => ({ ...onboarding, country: result.country }))
    }
    _()
  }, [onboarding.country, setOnboarding])

  return (
    <Wrapper
      animationStyle={cx(animationStyles.delay, animationStyles.swipeOut)}
      backStyle="active"
      forwardStyle={onboarding.publicPropertyData?.public_property ? 'active' : 'disabled'}
    >
      <AddressAutocompleteField autoFocus onSelected={handleSelection} />
    </Wrapper>
  )
}

const EnterAddress = {
  Landing,
  Search,
}

export default EnterAddress
