import React, { FC } from 'react'
import DoneAll from '@mui/icons-material/DoneAll'
import CoreText from 'components/Core/CoreText'

const PaymentSuccessHeader: FC = () => {
  return (
    <div className="tw-space-y-2 tw-text-center">
      <div className="tw-text-green-500 tw-text-5xl">
        <DoneAll fontSize="inherit" />
      </div>
      <CoreText.Headline size="xs">{'Payment successful'}</CoreText.Headline>
      <CoreText.Paragraph size="l" color="tertiaryBlack">
        {"You're one step closer to realizing your renovation goals."}
      </CoreText.Paragraph>
    </div>
  )
}

export default PaymentSuccessHeader
