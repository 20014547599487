import Input from 'components/Input'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { TopProject } from 'recoil/properties'
import SearchIcon from 'svgs/search'
import CoreText from 'components/Core/CoreText'
import CoreList from 'components/Core/CoreList'
import Handyman from '@mui/icons-material/Handyman'

import styles from './styles.module.scss'
import { ProjectTemplateStub, useProjectTemplateStubs } from 'recoil/projectTemplateStubs'
import Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import { prepareBoth } from 'utils/analyticsV2'
import ProjectListItem from 'pages/Onboarding/screens/CamV3Projects/ProjectListIem'

const [trackClick] = prepareBoth({ family: 'projects', screen: 'onboarding-projects' })

const QUALIFIED_PROJECTS = [
  'pool',
  '1st_floor_addition',
  'addition_living_room',
  'attic_conversion_NEW',
  'pool_house',
  'adu_mobile',
  'bathroom',
  'addition_bedroom',
  'siding',
  'addition_primary_suite',
  'garage_conversion_+_extension_NEW',
  'garage_conversion_NEW',
  'garage_conversion_space',
  'garage_renovation',
  'bathroom_conversion_NEW',
  'basement_conversion_NEW',
  'Outdoor_Kitchen',
  'outdoor_livingroom',
  'kitchen',
  'landscaping',
]

interface ProjectsProps {
  onDismiss?: (selectedProjects?: number[]) => void
}

const Projects: React.FC<ProjectsProps> = ({ onDismiss }) => {
  const [selectedProjects, setSelectedProjects] = useState<(TopProject | ProjectTemplateStub)[]>([])
  const [somethingElse, setSomethingElse] = useState<boolean>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { onboarding, setOnboarding } = useOnboardingProperty()
  const { projectTemplates: allTemplates } = useProjectTemplateStubs({
    zip5: onboarding.publicPropertyData?.public_property?.propertyaddresszip,
    city: onboarding.publicPropertyData?.public_property?.propertyaddresscity,
    state: onboarding.publicPropertyData?.public_property?.propertyaddressstate,
  })
  const { next } = useFlow()

  useEffect(() => {
    if (onboarding.selectedProjects) {
      const inflated = onboarding.selectedProjects.map((x) => allTemplates?.find((y) => x == y.id))
      const filtered = inflated.filter((x) => !!x) as ProjectTemplateStub[]
      if (inflated) setSelectedProjects(filtered)
    }
  }, [onboarding.selectedProjects, allTemplates])

  const searching = !!searchTerm.trim()

  const suggestedProjects = useMemo(() => {
    if (!allTemplates) return []

    return allTemplates.filter((x) => onboarding.publicPropertyData?.projects.some((y) => x.id == y.id))
  }, [onboarding.publicPropertyData?.projects, allTemplates])

  const filteredProjects = useMemo(() => {
    if (!allTemplates) return []

    return allTemplates.filter((template) => {
      if (template.name.toLowerCase().includes(searchTerm.toLowerCase())) return true

      return false
    })
  }, [searchTerm, allTemplates])

  const handleAddProject = useCallback(
    (project: TopProject | ProjectTemplateStub) => {
      const match = selectedProjects.find((x) => x.id == project.id)
      if (match) {
        trackClick({ code: 'add-project', section: 'projects', feature: 'deselect', content_text: project.name })
        const updated = selectedProjects.filter((x) => x.id !== project.id)
        setSelectedProjects(updated)
      } else {
        trackClick({ code: 'add-project', section: 'projects', feature: 'select', content_text: project.name })
        setSelectedProjects([...selectedProjects, project])
      }
    },
    [selectedProjects]
  )
  const nextText = useMemo(() => {
    if (onDismiss) return 'Save'
    if (selectedProjects && selectedProjects.length) return 'Continue'
    if (somethingElse) return 'Continue'

    return 'Skip'
  }, [selectedProjects, somethingElse, onDismiss])

  const qualifiedProjects = useMemo(() => {
    return selectedProjects.some((project) => QUALIFIED_PROJECTS.includes(project.kind))
  }, [selectedProjects])

  const handleNext = useCallback(() => {
    setOnboarding((prev) => ({
      ...prev,
      selectedProjects: selectedProjects.map((x) => x.id),
      qualifiedProjects,
      interestedInUnlistedProject: somethingElse,
      reset: undefined,
    }))

    const code = nextText == 'Skip' ? 'skip' : 'add-project'
    const feature = selectedProjects.map((x) => x.name).join(',')

    trackClick({ code, section: 'projects', feature })
    if (onDismiss) onDismiss(selectedProjects.map((x) => x.id))
    else next()
  }, [setOnboarding, next, nextText, onDismiss, selectedProjects, somethingElse, qualifiedProjects])

  const handleSomethingElse = useCallback(() => {
    setSomethingElse((val) => !val)
  }, [setSomethingElse])

  const handleSearchChanged = useCallback(
    (val: string) => {
      setSearchTerm(val)
    },
    [setSearchTerm]
  )

  const getDismissHandler = useCallback(() => {
    if (!onDismiss) return

    return () => onDismiss()
  }, [onDismiss])

  const SomethingElseItem = useCallback(() => {
    return (
      <CoreList.Item
        key="something_else"
        text="Something else"
        onClick={handleSomethingElse}
        selected={somethingElse}
        description="Not listed? No problem. We can help with any project"
        className="tw-my-2 tw-rounded-2xl"
        leftIcon={<Handyman />}
        leftIconContained
        rightCheck
      />
    )
  }, [somethingElse, handleSomethingElse])

  const isSelected = useCallback(
    (project: TopProject | ProjectTemplateStub) => {
      return selectedProjects.some((x) => x.id == project.id)
    },
    [selectedProjects]
  )

  const footer = useMemo(() => {
    return {
      text: nextText,
      onClick: handleNext,
    }
  }, [nextText, handleNext])

  return (
    <Wrapper
      backStyle={onDismiss ? undefined : 'active'}
      forwardStyle={onDismiss ? undefined : 'active'}
      className={styles.wrapper}
      onDismiss={getDismissHandler()}
      footer={footer}
    >
      <CoreText.Headline size="xs" weight="heavy" className={styles.heading}>
        {'What projects are you considering?'}
      </CoreText.Headline>
      <div className={styles.search}>
        <Input
          className={styles.input}
          onChange={handleSearchChanged}
          inputMode="search"
          placeholder='Try "kitchen" or "addition"'
        />
        <SearchIcon className={styles.icon} />
      </div>
      {searching ? (
        <CoreList className="tw-space-y-2">
          {filteredProjects.map((project) => {
            return (
              <ProjectListItem
                project={project}
                key={project.id}
                selected={isSelected(project)}
                onClick={handleAddProject}
              />
            )
          })}
          <SomethingElseItem />
        </CoreList>
      ) : (
        <>
          <CoreText.Paragraph size="m" color="secondaryBlack" weight="heavy" className={styles.subheading}>
            {'Suggested'}
          </CoreText.Paragraph>
          {suggestedProjects && (
            <CoreList className="tw-space-y-2">
              {suggestedProjects.map((project) => {
                return (
                  <ProjectListItem
                    project={project}
                    key={project.id}
                    selected={isSelected(project)}
                    onClick={handleAddProject}
                  />
                )
              })}
              <SomethingElseItem />
            </CoreList>
          )}
          <CoreText.Paragraph size="m" color="secondaryBlack" weight="heavy" className={styles.subheading}>
            {'All'}
          </CoreText.Paragraph>
          {allTemplates && (
            <CoreList className="tw-space-y-2">
              {allTemplates.map((project) => {
                return (
                  <ProjectListItem
                    project={project}
                    key={project.id}
                    selected={isSelected(project)}
                    onClick={handleAddProject}
                  />
                )
              })}
              <SomethingElseItem />
            </CoreList>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default Projects
