import CoreList from 'components/Core/CoreList'
import CoreText from 'components/Core/CoreText'
import CamV3Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { OutsideBidSelection, useOnboardingProperty } from 'recoil/onboarding'
import { useFlow } from 'recoil/onboardingFlows'
import { prepareBoth } from 'utils/analyticsV2'

const OUTSIDE_BID_OPTIONS: { key: OutsideBidSelection; text: string }[] = [
  { key: 'last_2_weeks', text: 'Yes, in the last 2 weeks' },
  { key: 'last_6_months', text: 'Yes, in the last 6 months' },
  { key: 'more_than_6_months', text: 'Yes, more than 6 months ago' },
  { key: 'no', text: "No, I haven't received any bids yet" },
]

const [trackClick, _] = prepareBoth({ screen: 'outside-bids', family: 'onboarding' })

const CamV3OutsideBids: FC = () => {
  const { setOnboarding } = useOnboardingProperty()
  const [outsideBids, setOutsideBids] = useState<OutsideBidSelection | undefined>()
  const { next } = useFlow()

  const handlePick = useCallback((key: OutsideBidSelection) => {
    return () => {
      trackClick({ code: 'select-outside-bid-status', feature: key, content_text: OUTSIDE_BID_OPTIONS[key] })

      setOutsideBids(key)
    }
  }, [])

  useEffect(() => {
    if (!outsideBids) return

    setOnboarding((onboarding) => {
      return { ...onboarding, outsideBids }
    })

    next()
  }, [outsideBids, setOnboarding, next])

  return (
    <CamV3Wrapper forwardStyle="active" backStyle="active">
      <CoreText.Headline
        size="xs"
        color="primaryBlack"
        weight="heavy"
        className="tw-mb-6"
      >{`Have you started getting bids for your project?`}</CoreText.Headline>
      <CoreList className="tw-space-y-2">
        {OUTSIDE_BID_OPTIONS.map(({ key, text }) => {
          return (
            <CoreList.Item
              className="tw-rounded-2xl tw-bg-white tw-p-6"
              key={key}
              text={text}
              onClick={handlePick(key)}
            />
          )
        })}
      </CoreList>
    </CamV3Wrapper>
  )
}

export default CamV3OutsideBids
