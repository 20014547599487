import React, { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { track } from 'utils/analytics'

import Alert from 'svgs/alert'

import styles from './styles.module.scss'

interface Props {
  isFromRenovation: boolean
  className?: string
}

const NotExpiredFlag: FC<Props> = ({ isFromRenovation, className }) => {
  const handleOnClick = useCallback(() => {
    track('click cta', {
      cta: 'edit',
      module: 'project-requirements',
      notExpiredSource: isFromRenovation ? 'renovation' : 'original',
    })
  }, [isFromRenovation])

  return (
    <div className={classNames(styles.notExpiredFlag, className)}>
      <Alert />
      {isFromRenovation ? (
        <div>
          <Link to={'../edit'} onClick={handleOnClick}>
            {'It looks like you have'}
          </Link>
          <span>
            {' recently completed a similar project and may receive less of a Home Value Increase for your home.'}
          </span>
          <span>
            {' If not, please '}
            <Link to={'../edit'} onClick={handleOnClick}>
              {'edit these details'}
            </Link>
            {'.'}
          </span>
        </div>
      ) : (
        <div>
          <Link to={'../edit'} onClick={handleOnClick}>
            {'It looks like your home had'}
          </Link>
          <span>
            {
              ' a similar project completed when it was built and may receive less of a Home Value Increase for this one.'
            }
          </span>
          <span>
            {' If not, please '}
            <Link to={'../edit'} onClick={handleOnClick}>
              {'edit these details'}
            </Link>
            {'.'}
          </span>
        </div>
      )}
    </div>
  )
}

export default NotExpiredFlag
