export default [
  {
    id: 'jackie-interior-remodel',
    title: 'Updating an 80 year old home',
    deck: "Jackie's home renovation with Amberly",
    context: 'San Mateo, CA',
    url: '/project-case-studies/jackie-interior-remodel',
    images: [
      {
        name: 'desktop_1x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=0,166,5024,3014\u0026w=600\u0026h=360',
        dimensions: { width: 600, height: 360 },
      },
      {
        name: 'mobile_2x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=0,335,5024,2679\u0026w=750\u0026h=400',
        dimensions: { width: 750, height: 400 },
      },
      {
        name: 'mobile_1x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=0,335,5024,2679\u0026w=375\u0026h=200',
        dimensions: { width: 375, height: 200 },
      },
      {
        name: 'thumbnail_1x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=0,145,5024,3092\u0026w=104\u0026h=64',
        dimensions: { width: 104, height: 64 },
      },
      {
        name: 'story_1x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=1156,0,2708,3351\u0026w=375\u0026h=464',
        dimensions: { width: 375, height: 464 },
      },
      {
        name: 'story_2x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=1159,0,2708,3351\u0026w=750\u0026h=928',
        dimensions: { width: 750, height: 928 },
      },
      {
        name: 'thumbnail_2x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=0,121,5024,3092\u0026w=208\u0026h=128',
        dimensions: { width: 208, height: 128 },
      },
      {
        name: 'desktop_2x',
        url: 'https://images.prismic.io/realm/b21c13b6-c914-4e28-a6ad-372477880236_4I1A1892.jpg?auto=compress,format\u0026rect=0,167,5024,3014\u0026w=1200\u0026h=720',
        dimensions: { width: 1200, height: 720 },
      },
    ],
    customers: [
      {
        identifier: 'Jackie',
        display_name: 'Jackie',
        display_title: 'Realm customer',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/d843d5d6-16c5-4608-a9bb-26248506dbce_VokcfOWP.jpg?auto=compress,format\u0026rect=1822,972,1135,1135\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/d843d5d6-16c5-4608-a9bb-26248506dbce_VokcfOWP.jpg?auto=compress,format\u0026rect=1823,942,1135,1135\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/d843d5d6-16c5-4608-a9bb-26248506dbce_VokcfOWP.jpg?auto=compress,format\u0026rect=1826,988,1135,1135\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/d843d5d6-16c5-4608-a9bb-26248506dbce_VokcfOWP.jpg?auto=compress,format\u0026rect=1814,974,1135,1135\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
    advisors: [
      {
        identifier: 'amberly',
        display_name: 'Amberly',
        display_title: 'Realm Advisor',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,93,803,803\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,101,803,803\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,101,803,803\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,106,803,803\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
  },
  {
    id: 'tricia-bathroom-renovation',
    title: 'A big upgrade (with storage!)',
    deck: "Tricia's bathroom renovation with Will",
    context: 'San Jose, CA',
    url: '/project-case-studies/tricia-bathroom-renovation',
    images: [
      {
        name: 'desktop_1x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=0,95,5167,3100\u0026w=600\u0026h=360',
        dimensions: { width: 600, height: 360 },
      },
      {
        name: 'mobile_2x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=0,152,5167,2756\u0026w=750\u0026h=400',
        dimensions: { width: 750, height: 400 },
      },
      {
        name: 'mobile_1x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=0,195,5167,2756\u0026w=375\u0026h=200',
        dimensions: { width: 375, height: 200 },
      },
      {
        name: 'thumbnail_1x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=0,63,5167,3180\u0026w=104\u0026h=64',
        dimensions: { width: 104, height: 64 },
      },
      {
        name: 'story_1x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=1730,0,3132,3875\u0026w=375\u0026h=464',
        dimensions: { width: 375, height: 464 },
      },
      {
        name: 'story_2x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=1737,0,3132,3875\u0026w=750\u0026h=928',
        dimensions: { width: 750, height: 928 },
      },
      {
        name: 'thumbnail_2x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=0,49,5167,3180\u0026w=208\u0026h=128',
        dimensions: { width: 208, height: 128 },
      },
      {
        name: 'desktop_2x',
        url: 'https://images.prismic.io/realm/eac05679-beb0-4cd6-ae53-76b4cb306a06_DH_Katie+Rolnick-001_edited.jpg?auto=compress,format\u0026rect=0,145,5167,3100\u0026w=1200\u0026h=720',
        dimensions: { width: 1200, height: 720 },
      },
    ],
    customers: [
      {
        identifier: 'tricia',
        display_name: 'Tricia',
        display_title: 'Realm customer',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/5032ac47-3d0e-4823-a68e-ae03a30e35bd_4I1A8970.jpg?auto=compress,format\u0026rect=668,0,3123,3123\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/5032ac47-3d0e-4823-a68e-ae03a30e35bd_4I1A8970.jpg?auto=compress,format\u0026rect=599,0,3123,3123\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/5032ac47-3d0e-4823-a68e-ae03a30e35bd_4I1A8970.jpg?auto=compress,format\u0026rect=599,0,3123,3123\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/5032ac47-3d0e-4823-a68e-ae03a30e35bd_4I1A8970.jpg?auto=compress,format\u0026rect=594,0,3123,3123\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
    advisors: [
      {
        identifier: 'will',
        display_name: 'Will',
        display_title: 'Realm Advisor',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/ca2fc052-e955-4f3f-9fa1-3de264a40bfe_Will.jpg?auto=compress,format\u0026rect=0,243,1159,1159\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/ca2fc052-e955-4f3f-9fa1-3de264a40bfe_Will.jpg?auto=compress,format\u0026rect=0,230,1159,1159\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/ca2fc052-e955-4f3f-9fa1-3de264a40bfe_Will.jpg?auto=compress,format\u0026rect=0,232,1159,1159\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/ca2fc052-e955-4f3f-9fa1-3de264a40bfe_Will.jpg?auto=compress,format\u0026rect=0,232,1159,1159\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
  },
  {
    id: 'julie-rob-garage-adu',
    title: 'Taking the leap with a rental ADU ',
    deck: "Julie and Rob's second-story garage ADU with Kerry",
    context: 'Orange County, CA',
    url: '/project-case-studies/julie-rob-garage-adu',
    images: [
      {
        name: 'desktop_1x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,576,1280,768\u0026w=600\u0026h=360',
        dimensions: { width: 600, height: 360 },
      },
      {
        name: 'mobile_2x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,618,1280,683\u0026w=750\u0026h=400',
        dimensions: { width: 750, height: 400 },
      },
      {
        name: 'mobile_1x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,618,1280,683\u0026w=375\u0026h=200',
        dimensions: { width: 375, height: 200 },
      },
      {
        name: 'thumbnail_1x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,566,1280,788\u0026w=104\u0026h=64',
        dimensions: { width: 104, height: 64 },
      },
      {
        name: 'story_1x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,167,1280,1584\u0026w=375\u0026h=464',
        dimensions: { width: 375, height: 464 },
      },
      {
        name: 'story_2x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,167,1280,1584\u0026w=750\u0026h=928',
        dimensions: { width: 750, height: 928 },
      },
      {
        name: 'thumbnail_2x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,566,1280,788\u0026w=208\u0026h=128',
        dimensions: { width: 208, height: 128 },
      },
      {
        name: 'desktop_2x',
        url: 'https://images.prismic.io/realm/249dca30-d3b8-4e6a-9285-f7b75059f9a3_IMG_00005.jpeg?auto=compress,format\u0026rect=0,576,1280,768\u0026w=1200\u0026h=720',
        dimensions: { width: 1200, height: 720 },
      },
    ],
    customers: [
      {
        identifier: 'julie',
        display_name: 'Julie',
        display_title: 'Realm customer',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/6eee0876-c9ff-4238-8677-7d54de7aeb73_Real_este_740aa753-bfd5-48df-968f-1315d7a37e75-022.jpg?auto=compress,format\u0026rect=1000,0,4000,4000\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/6eee0876-c9ff-4238-8677-7d54de7aeb73_Real_este_740aa753-bfd5-48df-968f-1315d7a37e75-022.jpg?auto=compress,format\u0026rect=1000,0,4000,4000\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/6eee0876-c9ff-4238-8677-7d54de7aeb73_Real_este_740aa753-bfd5-48df-968f-1315d7a37e75-022.jpg?auto=compress,format\u0026rect=1000,0,4000,4000\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/6eee0876-c9ff-4238-8677-7d54de7aeb73_Real_este_740aa753-bfd5-48df-968f-1315d7a37e75-022.jpg?auto=compress,format\u0026rect=1000,0,4000,4000\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
    advisors: [
      {
        identifier: 'kerry',
        display_name: 'Kerry',
        display_title: 'Realm Advisor',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,618,3840,3840\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,682,3840,3840\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,671,3840,3840\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,639,3840,3840\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
  },
  {
    id: 'chris-jennifer-landscaping',
    title: 'From dirt pit to backyard paradise',
    deck: "Chris and Jennifer's landscaping project with Amberly",
    context: 'San Francisco Bay Area, CA',
    url: '/project-case-studies/chris-jennifer-landscaping',
    images: [
      {
        name: 'desktop_1x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=0,318,4792,2875\u0026w=600\u0026h=360',
        dimensions: { width: 600, height: 360 },
      },
      {
        name: 'mobile_2x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=0,576,4792,2556\u0026w=750\u0026h=400',
        dimensions: { width: 750, height: 400 },
      },
      {
        name: 'mobile_1x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=0,571,4792,2556\u0026w=375\u0026h=200',
        dimensions: { width: 375, height: 200 },
      },
      {
        name: 'thumbnail_1x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=0,244,4792,2949\u0026w=104\u0026h=64',
        dimensions: { width: 104, height: 64 },
      },
      {
        name: 'story_1x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=734,0,2582,3195\u0026w=375\u0026h=464',
        dimensions: { width: 375, height: 464 },
      },
      {
        name: 'story_2x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=538,0,2582,3195\u0026w=750\u0026h=928',
        dimensions: { width: 750, height: 928 },
      },
      {
        name: 'thumbnail_2x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=0,244,4792,2949\u0026w=208\u0026h=128',
        dimensions: { width: 208, height: 128 },
      },
      {
        name: 'desktop_2x',
        url: 'https://images.prismic.io/realm/f81a4420-a1ad-4a95-8a01-eb257f6ecf63_R_Katie+Rolnick014.jpg?auto=compress,format\u0026rect=0,318,4792,2875\u0026w=1200\u0026h=720',
        dimensions: { width: 1200, height: 720 },
      },
    ],
    customers: [
      {
        identifier: 'chris',
        display_name: 'Chris',
        display_title: 'Realm customer',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/bede19d9-b21d-4d6b-b1a2-b569ca03bdf3_R_Katie+Rolnick029.jpg?auto=compress,format\u0026rect=1400,726,2471,2471\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/bede19d9-b21d-4d6b-b1a2-b569ca03bdf3_R_Katie+Rolnick029.jpg?auto=compress,format\u0026rect=1523,777,2363,2363\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/bede19d9-b21d-4d6b-b1a2-b569ca03bdf3_R_Katie+Rolnick029.jpg?auto=compress,format\u0026rect=1445,765,2390,2390\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/bede19d9-b21d-4d6b-b1a2-b569ca03bdf3_R_Katie+Rolnick029.jpg?auto=compress,format\u0026rect=1503,964,2370,2370\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
    advisors: [
      {
        identifier: 'amberly',
        display_name: 'Amberly',
        display_title: 'Realm Advisor',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,71,803,803\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,68,803,803\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,75,803,803\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/cf1aa86d-6329-4fef-8dd4-000323e95a36_person2.png?auto=compress,format\u0026rect=0,73,803,803\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
  },
  {
    id: 'alex-erica-interior-remodel',
    title: 'A major mid-century transformation',
    deck: "Alex and Erica's interior remodel with Andy",
    context: 'Los Angeles, CA',
    url: '/project-case-studies/alex-erica-interior-remodel',
    images: [
      {
        name: 'desktop_1x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=0,253,7655,4593\u0026w=600\u0026h=360',
        dimensions: { width: 600, height: 360 },
      },
      {
        name: 'mobile_2x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=0,892,7655,4083\u0026w=750\u0026h=400',
        dimensions: { width: 750, height: 400 },
      },
      {
        name: 'mobile_1x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=0,723,7655,4083\u0026w=375\u0026h=200',
        dimensions: { width: 375, height: 200 },
      },
      {
        name: 'thumbnail_1x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=0,218,7655,4711\u0026w=104\u0026h=64',
        dimensions: { width: 104, height: 64 },
      },
      {
        name: 'story_1x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=1304,0,4127,5106\u0026w=375\u0026h=464',
        dimensions: { width: 375, height: 464 },
      },
      {
        name: 'story_2x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=1310,0,4127,5106\u0026w=750\u0026h=928',
        dimensions: { width: 750, height: 928 },
      },
      {
        name: 'thumbnail_2x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=0,181,7655,4711\u0026w=208\u0026h=128',
        dimensions: { width: 208, height: 128 },
      },
      {
        name: 'desktop_2x',
        url: 'https://images.prismic.io/realm/0d432ee2-d1a9-41b7-b055-f4157d4c09e7_8939_Yolanda_Avenue_Northridge_CA_20221204+-+005.jpg?auto=compress,format\u0026rect=0,253,7655,4593\u0026w=1200\u0026h=720',
        dimensions: { width: 1200, height: 720 },
      },
    ],
    customers: [
      {
        identifier: 'alex',
        display_name: 'Alex',
        display_title: 'Realm customer',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/09f226b1-250b-4b73-b0d9-5815cb7954f5_8939_Yolanda_Avenue_Northridge_CA_20221204+-+084.jpg?auto=compress,format\u0026rect=1980,938,3965,3965\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/09f226b1-250b-4b73-b0d9-5815cb7954f5_8939_Yolanda_Avenue_Northridge_CA_20221204+-+084.jpg?auto=compress,format\u0026rect=1910,1034,4124,4124\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/09f226b1-250b-4b73-b0d9-5815cb7954f5_8939_Yolanda_Avenue_Northridge_CA_20221204+-+084.jpg?auto=compress,format\u0026rect=1832,995,4136,4136\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/09f226b1-250b-4b73-b0d9-5815cb7954f5_8939_Yolanda_Avenue_Northridge_CA_20221204+-+084.jpg?auto=compress,format\u0026rect=1925,1045,3959,3959\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
    advisors: [
      {
        identifier: 'andy',
        display_name: 'Andy',
        display_title: 'Realm Advisor',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/ea4b71d3-a9c9-4116-831f-1ea2cf6d3e22_Andy+Headshot.png?auto=compress,format\u0026rect=0,9,600,600\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/ea4b71d3-a9c9-4116-831f-1ea2cf6d3e22_Andy+Headshot.png?auto=compress,format\u0026rect=0,11,600,600\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/ea4b71d3-a9c9-4116-831f-1ea2cf6d3e22_Andy+Headshot.png?auto=compress,format\u0026rect=0,10,600,600\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/ea4b71d3-a9c9-4116-831f-1ea2cf6d3e22_Andy+Headshot.png?auto=compress,format\u0026rect=0,14,600,600\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
  },
  {
    id: 'robyn-lukas-garage-adu',
    title: 'Can adding space really be this easy?',
    deck: "Robyn \u0026 Lukas' garage conversion ADU with Kerry",
    context: 'Los Angeles, CA',
    url: '/project-case-studies/robyn-lukas-garage-adu',
    images: [
      {
        name: 'desktop_1x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=0,306,6475,3885\u0026w=600\u0026h=360',
        dimensions: { width: 600, height: 360 },
      },
      {
        name: 'mobile_2x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=0,448,6720,3584\u0026w=750\u0026h=400',
        dimensions: { width: 750, height: 400 },
      },
      {
        name: 'mobile_1x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=0,448,6720,3584\u0026w=375\u0026h=200',
        dimensions: { width: 375, height: 200 },
      },
      {
        name: 'thumbnail_1x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=0,194,6720,4135\u0026w=104\u0026h=64',
        dimensions: { width: 104, height: 64 },
      },
      {
        name: 'story_1x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=1545,0,3621,4480\u0026w=375\u0026h=464',
        dimensions: { width: 375, height: 464 },
      },
      {
        name: 'story_2x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=1550,0,3621,4480\u0026w=750\u0026h=928',
        dimensions: { width: 750, height: 928 },
      },
      {
        name: 'thumbnail_2x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=0,162,6720,4135\u0026w=208\u0026h=128',
        dimensions: { width: 208, height: 128 },
      },
      {
        name: 'desktop_2x',
        url: 'https://images.prismic.io/realm/749a5081-0292-4aed-82f8-9f1ef1875990_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1009.jpg?auto=compress,format\u0026rect=0,224,6720,4032\u0026w=1200\u0026h=720',
        dimensions: { width: 1200, height: 720 },
      },
    ],
    customers: [
      {
        identifier: 'robyn',
        display_name: 'Robyn',
        display_title: 'Realm customer',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/d8df083b-ae2c-4ebe-8f3d-50a8ae93f31e_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1003.jpg?auto=compress,format\u0026rect=2133,528,2190,2190\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/d8df083b-ae2c-4ebe-8f3d-50a8ae93f31e_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1003.jpg?auto=compress,format\u0026rect=1888,521,2533,2533\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/d8df083b-ae2c-4ebe-8f3d-50a8ae93f31e_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1003.jpg?auto=compress,format\u0026rect=1764,470,2701,2701\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/d8df083b-ae2c-4ebe-8f3d-50a8ae93f31e_022221RobynLukasforREALMbyTom%40LAdigitalPhoto-1003.jpg?auto=compress,format\u0026rect=1938,500,2343,2343\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
    advisors: [
      {
        identifier: 'kerry',
        display_name: 'Kerry',
        display_title: 'Realm Advisor',
        images: [
          {
            name: 'image_1x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,555,3840,3840\u0026w=56\u0026h=56',
            dimensions: { width: 56, height: 56 },
          },
          {
            name: 'image_2x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,576,3840,3840\u0026w=112\u0026h=112',
            dimensions: { width: 112, height: 112 },
          },
          {
            name: 'big_1x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,534,3840,3840\u0026w=160\u0026h=160',
            dimensions: { width: 160, height: 160 },
          },
          {
            name: 'big_2x',
            url: 'https://images.prismic.io/realm/a8ab4c49-1c46-45e9-9c41-cb550ea9b3b6_Kerry.jpg?auto=compress,format\u0026rect=0,618,3840,3840\u0026w=320\u0026h=320',
            dimensions: { width: 320, height: 320 },
          },
        ],
      },
    ],
  },
]
