import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { propertiesState } from 'recoil/properties'
import { PROPERTY_ROOT } from 'utils/propertyPath'

import Secure from 'layouts/Secure'

const DefaultRedirect: FC = () => {
  // Keep using the recoil value directly here instead of the useProperties
  // or usePropertyLoader
  const properties = useRecoilValue(propertiesState)
  const url = (properties || []).length == 1 ? 'dashboard' : PROPERTY_ROOT

  return <Redirect to={url} />
}

const Root: FC = () => (
  <Secure>
    <DefaultRedirect />
  </Secure>
)

export default Root
