import cx from 'classnames'
import React, { FC, useCallback } from 'react'
import { PropertyHead } from 'recoil/properties'
import { Link } from 'react-router-dom'
import { formatMoneyNoDecimals, numberWithCommas } from 'utils/formatting'
import propertyPath from 'utils/propertyPath'
import Bath from 'svgs/bath'
import Bed from 'svgs/bed'
import Close from 'svgs/close'

import styles from './styles.module.scss'
import { PropertyAvatar } from 'components/PropertyAvatar'

export interface PropertyCardProps {
  className?: string
  property: PropertyHead
  properties: Array<PropertyHead>
  onDelete: (property: PropertyHead) => void
  isManaging: boolean
}

export const PropertyCard: FC<PropertyCardProps> = ({ className, property, properties, onDelete, isManaging }) => {
  const path = propertyPath(properties, property)

  const dashboardPath = `${path}/dashboard`

  const handleDeleteClick = useCallback(() => {
    onDelete(property)
  }, [property, onDelete])

  return (
    <div className={cx(styles.propertyCard, { [styles.managing]: isManaging }, className)}>
      <Link to={dashboardPath}>
        <div className={styles.imageWrapper}>
          <PropertyAvatar property={property} width={400} height={250} mapbox />
        </div>
      </Link>
      <div className={styles.info}>
        <Link to={dashboardPath} className={styles.details}>
          <span className={styles.title}>{property.delivery_line_1}</span>
          <div className={styles.stats}>
            <div className={styles.stat}>
              {property.bedrooms} <Bed />
            </div>
            <div className={styles.stat}>
              {property.total_bathrooms} <Bath />
            </div>
            <div className={styles.stat}>{`${
              property.square_footage ? numberWithCommas(property.square_footage) : '?'
            } sqft`}</div>
          </div>
        </Link>
        <hr />
        <Link to={dashboardPath} className={styles.potential}>
          <span className={styles.label}>{'Total untapped potential'}</span>
          <span className={styles.value}>
            {formatMoneyNoDecimals((property.potential_value || 0) - property.estimated_value)}
            {'+'}
          </span>
        </Link>
      </div>
      <div className={styles.wash} />
      <button type="button" className={styles.delete} onClick={handleDeleteClick} disabled={!isManaging}>
        <Close />
      </button>
    </div>
  )
}

export default PropertyCard
