import { PackagePurchase } from './packagePurchases'

export interface Payment {
  id: number
  amount_in_cents: number
  lead_payment: boolean
  description: string
  status: 'pending' | 'failed' | 'succeeded' | 'canceled'
  created_at: string
  package_purchase?: PackagePurchase & { property_id: number }
  stripe_checkout_session_ref: string
  stripe_charge_ref: string
  user_id?: number
  user: {
    id: number
    full_name?: string
    email: string
    phone_number?: string
    reset_password_token?: string
    referral_link?: string
    referral_payouts: Array<ReferralPayout>
  }

  default_referral_payouts: Array<ReferralPayout>
}

export interface ReferralPayout {
  kind: 'meeting_scheduled' | 'referrer_set' | 'meeting_held' | 'deposit_paid' | 'contract_signed'
  target: 'referrer' | 'referee'
  amount_in_cents: number
}

export const paymentStatusColor = (status: 'pending' | 'failed' | 'succeeded' | 'canceled'): string => {
  if (status == 'failed') return 'tw-text-red-800 tw-bg-red-200'
  if (status == 'succeeded') return 'tw-text-green-800 tw-bg-green-200'
  if (status == 'pending') return 'tw-text-yellow-800 tw-bg-yellow-200'
  return 'tw-text-gray-800 tw-bg-gray-200'
}
