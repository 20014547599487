import { useRef, useState, useEffect, useCallback } from 'react'

import PropertyPlanApi from 'apis/propertyPlanApi'

import { Project, UProject } from './projects'
import { PropertyPlan } from './propertyPlans'

export const useProjectPreviews = (
  defaultProjectPreview: Project | null,
  defaultPropertyPlanPreview: PropertyPlan | null
): {
  updatePreview: (propertyId: number, propertyPlanId: number, projectId: number, projectUpdate: UProject | null) => void
  projectPreview: Project | null
  propertyPlanPreview: PropertyPlan | null
  loading: boolean
} => {
  const [projectPreview, setProjectPreview] = useState<Project | null>(null)
  const [propertyPlanPreview, setPropertyPlanPreview] = useState<PropertyPlan | null>(null)
  const [requestReady, setRequestReady] = useState(false)

  const request = useRef<{
    propertyId: number
    propertyPlanId: number
    projectId: number
    projectUpdate: UProject
  } | null>(null)

  // Our target has changed
  const updatePreview = useCallback(
    (propertyId, propertyPlanId, projectId, projectUpdate) => {
      // Set the target to the request
      request.current = { propertyId, propertyPlanId, projectId, projectUpdate }
      setRequestReady(true)
    },
    [setRequestReady]
  )

  // Process our request when signaled.
  useEffect(() => {
    let cancel = false
    if (requestReady) {
      const evaluate = async () => {
        if (cancel) return

        // Grab the request item
        const requestItem = request.current
        if (requestItem == null) return
        // Consume the request
        request.current = null
        const results = await previewUpdateProject(
          requestItem.propertyId,
          requestItem.propertyPlanId,
          requestItem.projectId,
          requestItem.projectUpdate
        )
        if (cancel) return

        // If after completing, we have another request waiting, we need to reinvoke
        // We don't have to wait for this to finish; let the outer closure close off.
        if (request.current != null) {
          evaluate()
        } else {
          // If no one is waiting, then we can update with our results.
          setProjectPreview(results.project)
          setPropertyPlanPreview(results.property_plan)
          // And signal that we're no longer waiting for a request.
          setRequestReady(false)
        }
      }

      evaluate()
    }

    return () => {
      cancel = true
    }
    // We want to listen on changes to 'requestReady', as our signal
    // to process the request. If another is in flight, the requestReady
    // boolean won't change, and therefore won't reset the function.
  }, [requestReady, setRequestReady])

  return {
    updatePreview,
    projectPreview: projectPreview || defaultProjectPreview,
    propertyPlanPreview: propertyPlanPreview || defaultPropertyPlanPreview,
    loading: requestReady,
  }
}

export const previewUpdateProject = async (
  propertyId: number,
  propertyPlanId: number,
  projectId: number,
  project: UProject
): Promise<{ project: Project; property_plan: PropertyPlan }> => {
  const api = new PropertyPlanApi(propertyId)
  return await api.previewUpdateProject(propertyPlanId, projectId, project)
}
