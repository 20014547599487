import React, { FC, useCallback } from 'react'

import { ProjectCustomizationOption } from 'recoil/projectTemplates'

import Checkbox from 'components/Checkbox'

interface Props {
  cbxId: string
  option: ProjectCustomizationOption
  checked: boolean
  onChange: (optionId: number, checked: boolean) => void
}

const InclusiveOption: FC<Props> = ({ cbxId, option, checked, onChange }) => {
  const handleChange = useCallback(
    (e) => {
      onChange(option.id, e.target.checked)
    },
    [option, onChange]
  )

  return <Checkbox id={cbxId} checked={checked} onChange={handleChange} />
}

export default InclusiveOption
