// https://caniuse.com/mdn-api_crypto_randomuuid - In particular, Safari didn't have this before 15.4 (March 2022)
if (!('randomUUID' in crypto)) {
  // https://stackoverflow.com/a/2117523
  ;(crypto as any).randomUUID = function () {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      // https://caniuse.com/getrandomvalues - this one is over 10 years, we just rely on it
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
    )
  }
}
