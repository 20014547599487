import React, { FC, useCallback, useState, useEffect, useRef } from 'react'

import { track } from 'utils/analytics'
import { ProjectCustomizationOption, ProjectSelection, cloneProjectSelection } from 'recoil/projectTemplates'

import RadioButton from 'components/RadioButton'

import styles from './Section.module.scss'

const CUSTOM_VALUE = '_custom_'

function generateId() {
  return `defaultSection_${Date.now()}_${Math.random()}`
}

function isSelectionEqual(selectionA: ProjectSelection, selectionB: ProjectSelection): boolean {
  return (
    (selectionA.value == selectionB.value &&
      ((!selectionA.selections && !selectionB.selections) ||
        (selectionA.selections?.length == selectionB.selections?.length &&
          selectionA.selections?.reduce((prev, childSelA) => {
            if (!prev) return prev
            const childSelBs = selectionB.selections?.filter((c) => c.key == childSelA.key)
            if (childSelBs?.length == 0) return false
            return childSelBs?.some((childSelB) => isSelectionEqual(childSelA, childSelB))
          }, true)))) ||
    false
  )
}

interface Props {
  projectId: string
  defaultOption: ProjectCustomizationOption
  value: ProjectSelection
  onChange: (selection: ProjectSelection) => void
  showCustom: boolean
}

const DefaultsSection: FC<Props> = ({ projectId, defaultOption, value, onChange, showCustom }) => {
  const idRef = useRef(generateId())
  const componentId = idRef.current
  // Always starts as false; used to indicate custom is selected,
  // when the user clicks custom, but has otherwise made no changes to
  // differentiate the selected state from one of the defaults.
  const [customSelected, setCustomSelected] = useState(false)

  useEffect(() => {
    setCustomSelected(false)
  }, [value])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value == CUSTOM_VALUE) {
        track('property-plan customize project select default-option', {
          projectId,
          option: 'CUSTOM',
          optionName: 'Custom',
        })
        setCustomSelected(true)
      } else {
        const id = parseInt(e.target.value)
        const defaultSelection = defaultOption.defaults?.find((o) => o.id == id)
        if (defaultSelection) {
          track('property-plan customize project select default-option', {
            projectId,
            option: defaultSelection.id,
            optionName: defaultSelection.name,
          })
          const selection = defaultSelection.selection
          const newSelection = cloneProjectSelection(selection)
          // Pull size from current value.
          newSelection.size = [...value.size]
          delete newSelection.id
          onChange(newSelection)
        }
      }
    },
    [onChange, setCustomSelected, defaultOption, projectId, value]
  )

  // If we have one or zero options, then there is no reason to show options.
  if ((defaultOption.defaults?.length || 0) < 2) return null

  // Check if any options are selected
  const selections = defaultOption.defaults?.reduce((prev, defaultSelection) => {
    // Don't include any select_default items
    if (defaultSelection.select_default) return prev

    return Object.assign({}, prev, {
      [defaultSelection.id]: !customSelected && isSelectionEqual(defaultSelection.selection, value),
    })
  }, {} as { [id: number]: boolean })
  // Add custom value in; true if all other selections are false.
  if (selections) {
    selections[CUSTOM_VALUE] = Object.keys(selections).every((k) => !selections[k])
  }

  const selectionDefaults = (defaultOption.defaults || []).filter(
    (defaultSelection) => !defaultSelection.select_default
  )

  return (
    <section className={styles.section}>
      <h3>{defaultOption.name}</h3>
      <p>{defaultOption.description}</p>
      <ul className={styles.radioSet}>
        {selectionDefaults.map((defaultSelection) => {
          const domId = `rbt_${componentId}_${defaultSelection.id}`
          const isChecked = selections && selections[defaultSelection.id]
          return (
            <li key={defaultSelection.id} className={styles.radioOption}>
              <RadioButton
                id={domId}
                className={styles.radio}
                value={defaultSelection.id}
                name={componentId}
                checked={isChecked}
                onChange={handleChange}
              />
              <label htmlFor={domId} className={styles.label}>
                <span className={styles.radioName}>{defaultSelection.name}</span>
                <span className={styles.radioDescription}>{` - ${defaultSelection.description}`}</span>
              </label>
            </li>
          )
        })}
        {showCustom && (
          <li className={styles.radioOption}>
            <RadioButton
              id={`rbt_${componentId}custom`}
              className={styles.radio}
              value={CUSTOM_VALUE}
              name={componentId}
              checked={selections && selections[CUSTOM_VALUE]}
              onChange={handleChange}
            />
            <label htmlFor={`rbt_${componentId}custom`} className={styles.label}>
              {'Custom'}
            </label>
          </li>
        )}
      </ul>
    </section>
  )
}

export default DefaultsSection
