import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'

import Construction from 'svgs/construction'

import styles from './styles.module.scss'

interface Props {
  children?: ReactNode
  className?: string
}

const InfoFlag: FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames(styles.infoFlag, className)}>
      <Construction />
      <div>{children}</div>
    </div>
  )
}

export default InfoFlag
