import React, { FC, useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { track } from 'utils/analytics'
import { useProjects, Project, UProject } from 'recoil/projects'
import { useProjectTemplates, ProjectSelection } from 'recoil/projectTemplates'

import CustomizeContainer from '../CustomizeContainer'
import ProjectStats from '../ProjectStats'
import NameSection from './NameSection'
import CustomizationOptionSections from './CustomizationOptionSections'

import styles from './styles.module.scss'

interface Props {
  project: Project
  onUpdatePreview: (projectUpdate: UProject) => void
}

const Customizable: FC<Props> = ({ project: previewProject, onUpdatePreview }) => {
  const { projectId } = useParams<{ projectId: any }>()
  const { projects, updateProject } = useProjects()
  const { projectTemplates } = useProjectTemplates()

  const project = projects.find((p) => p.id == projectId)
  const projectTemplate = projectTemplates.find((t) => t.id == project?.project_template_id)

  const [name, setName] = useState(project?.name)
  const [selection, setSelection] = useState<ProjectSelection | null>(null)

  const handleCancel = useCallback(() => {
    track('submit form', {
      section: 'property-plan',
      form: 'customize',
      action: 'cancel',
      template: { id: projectTemplate?.id, kind: projectTemplate?.kind },
    })
  }, [projectTemplate])

  const handleSave = useCallback(() => {
    track('submit form', {
      section: 'property-plan',
      form: 'customize',
      action: 'save',
      template: { id: projectTemplate?.id, kind: projectTemplate?.kind },
    })
    // Do the saving.
    // Make sure 'size' is within our bounds.
    const update: UProject = {
      customized: true,
    }
    if (project?.name != name) {
      track('property-plan customize project save name', { projectId })
      update.name = name
    }
    if (selection) {
      track('property-plan customize project save selections', { projectId })
      update.selection = selection
    } else if (previewProject?.selection) {
      track('property-plan customize project save selections', { projectId })
      update.selection = previewProject.selection
    }
    updateProject(projectId, update)
  }, [updateProject, projectId, selection, name, previewProject, project, projectTemplate])

  useEffect(() => {
    if (selection) {
      onUpdatePreview({ customized: true, selection })
    }
  }, [selection, onUpdatePreview])

  useEffect(() => {
    if (previewProject && previewProject != project) {
      setSelection(null)
    }
  }, [previewProject, setSelection, project])

  if (!projectTemplate?.default_option) return null

  const selectionValue =
    selection ||
    previewProject?.selection ||
    project?.selection ||
    projectTemplate.default_option.defaults?.find((d) => d.is_default)?.selection

  if (!selectionValue) return null

  return (
    <CustomizeContainer className={styles.customizable} onCancel={handleCancel} onSave={handleSave}>
      <NameSection value={name || ''} onChange={setName} />
      <CustomizationOptionSections
        projectId={projectId}
        option={projectTemplate.default_option}
        value={selectionValue}
        onChange={setSelection}
      />
      <ProjectStats project={previewProject} />
    </CustomizeContainer>
  )
}

export default Customizable
