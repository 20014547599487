import CoreText from 'components/Core/CoreText'

import CaseStudyViewer from 'pages/_serverRendered/HomepageV2212/Sections/CustomerSocialProof/Testimonials/CaseStudyViewer'
import React, { FC, useCallback, useEffect, useState } from 'react'
import Play from 'svgs/play-icon'
import { prepareBoth } from 'utils/analyticsV2'
import caseStudies from './hardcodedCaseStudies'

import styles from './styles.module.scss'

const [trackClick, trackImpression] = prepareBoth({ screen: 'book-meeting-download-app', family: 'projects' })

const CaseStudies: FC = () => {
  const [activeCaseStudy, setActiveCaseStudy] = useState<string | null>(null)

  const dismissCaseStudy = useCallback(() => {
    setActiveCaseStudy(null)
  }, [])

  const handleShowCaseStudy = useCallback((id: string) => {
    return (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      trackClick({ code: 'case-study', section: 'project-info', content_text: id })
      setActiveCaseStudy(id)
    }
  }, [])

  useEffect(() => {
    caseStudies.forEach((caseStudy) => {
      trackImpression({ code: 'case-study', section: 'project-info', content_text: caseStudy.id })
    })
  }, [])

  return (
    <div className={styles.container}>
      <CoreText.Paragraph color="primaryBlack" size="xl" weight="heavy" className={styles.heading}>
        {'See how Realm Advisors have helped other homeowners'}
      </CoreText.Paragraph>
      <div className={styles.caseStudiesWrapper}>
        <div className={styles.caseStudies}>
          {caseStudies.map((caseStudy) => {
            return (
              <div
                className={styles.caseStudy}
                role="button"
                key={caseStudy.id}
                onClick={handleShowCaseStudy(caseStudy.id)}
                onKeyDown={handleShowCaseStudy(caseStudy.id)}
                tabIndex={0}
              >
                <img src={caseStudy.images[0].url} alt={caseStudy.title} className={styles.image} />
                <CoreText.Paragraph size="m" color="primaryWhite" className={styles.title}>
                  {caseStudy.title}
                </CoreText.Paragraph>
                <div className={styles.gradient} />
                <Play className={styles.playIcon} />
              </div>
            )
          })}
        </div>
      </div>
      {activeCaseStudy && <CaseStudyViewer id={activeCaseStudy} onClose={dismissCaseStudy} />}
    </div>
  )
}

export default CaseStudies
