import React, { FC, PropsWithChildren } from 'react'
import RealmLogo from 'components/MarketingSite/Header/RealmLogo'
import cx from 'classnames'
import styles from './styles.module.scss'

interface DefaultWrapperProps {
  className?: string
  logoClassName?: string
}

const DefaultWrapper: FC<PropsWithChildren<DefaultWrapperProps>> = ({ children, className, logoClassName }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      <RealmLogo className={cx(styles.logo, logoClassName)} />
      <div className={styles.inner}>{children}</div>
    </div>
  )
}

export default DefaultWrapper
