import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import CamV3Wrapper from 'pages/Onboarding/components/CamV3Wrapper'
import CoreText from 'components/Core/CoreText'
import CoreInput from 'components/Core/CoreInput'

import { useFlow } from 'recoil/onboardingFlows'
import { useOnboardingQueryStringValue } from 'recoil/onboarding'
import Loading from 'components/Loading'

import { getJSON } from 'utils/fetch'
import { Payment } from 'recoil/payments'
import ChangePasswordForm, { ChangePasswordInputArgs } from 'pages/ChangePassword/ChangePasswordForm'
import CoreButton from 'components/Core/CoreButton'
import TermsConsentMessage from 'components/TermsConsentMessage'

const PaymentWelcome: FC = () => {
  const { next } = useFlow()
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const paymentToken = useOnboardingQueryStringValue('paymentToken')
  const resetPasswordToken = useOnboardingQueryStringValue('reset_password_token')

  useEffect(() => {
    const _ = async () => {
      setLoading(true)

      const payment = await getJSON<Payment>(`/api/v1/payments/${paymentToken}`)

      setEmail(payment.user.email)
      if (payment.user.full_name) setFullName(payment.user.full_name)

      setLoading(false)
    }
    _()
  }, [paymentToken])

  const renderPasswordInput = useCallback(({ value, errors, onChangeText }: ChangePasswordInputArgs) => {
    return (
      <CoreInput.Text
        value={value}
        className="tw-my-4"
        onChangeText={onChangeText}
        label="Password"
        hint={errors?.join(', ')}
        kind={errors?.length > 0 ? 'alert' : 'enabled'}
        type="password"
        name="user[password]"
        required
      />
    )
  }, [])

  const renderConfirmPasswordInput = useCallback(({ value, errors, onChangeText }: ChangePasswordInputArgs) => {
    return (
      <CoreInput.Text
        value={value}
        className="tw-my-4"
        onChangeText={onChangeText}
        label="Confirm password"
        hint={errors?.join(', ')}
        kind={errors?.length > 0 ? 'alert' : 'enabled'}
        type="password"
        name="user[password_confirmation]"
        required
      />
    )
  }, [])

  const renderButton = useCallback(() => {
    return (
      <>
        <TermsConsentMessage className="tw-text-xs tw-font-normal" />
        <CoreButton type="submit" kind="primary" text="Submit" className="tw-mt-2 tw-w-full" />
      </>
    )
  }, [])

  const renderWrapper = useCallback((children: ReactNode) => {
    return <div>{children}</div>
  }, [])

  return (
    <CamV3Wrapper>
      <CoreText.Headline size="xs" weight="heavy" className="tw-text-center">
        {'Finish setting up your account'}
      </CoreText.Headline>
      <CoreText.Paragraph size="l" weight="regular" color="tertiaryBlack" className="tw-text-center">
        {'Create your account to get started with the Realm app'}
      </CoreText.Paragraph>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="tw-space-y-3 tw-mt-10">
            <CoreInput.Text value={fullName} className="tw-my-4" label="Full name" disabled></CoreInput.Text>
            <CoreInput.Text value={email} className="tw-my-4" label="Email" disabled></CoreInput.Text>
          </div>
          <ChangePasswordForm
            renderPasswordInput={renderPasswordInput}
            renderConfirmPasswordInput={renderConfirmPasswordInput}
            renderWrapper={renderWrapper}
            renderButton={renderButton}
            onSuccess={next}
            onLoading={setLoading}
            resetPasswordToken={resetPasswordToken}
          />
        </>
      )}
    </CamV3Wrapper>
  )
}

export default PaymentWelcome
