// TODO: To eliminate the 'Inner' control, we can move the
// 'property' recoil reference into each of the subsections
// that it is being passed to.

// Third party dependencies
import React, { FC, PropsWithChildren, useCallback } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

// Recoil state
import { useProperties } from 'recoil/properties'
import { ProjectTemplateBase } from 'recoil/projectTemplates'
import { displayAddress } from 'utils/property'
import { formatCurrency } from 'utils/formatting'
import { usePropertyCompleteness } from 'recoil/propertyCompleteness'

// General utils
import { track } from 'utils/analytics'

// Images
// import Document from 'svgs/document'
import Edit from 'svgs/edit'
import SparklesTwo from 'svgs/sparkles-two'

// Components
import BarChart from 'pages/EditProperty/PropertyCompleteness/BarChart'
import FullBleed from 'layouts/FullBleed'
import Secure from 'layouts/Secure'
import HasProperty from 'layouts/HasProperty'

import Card from 'components/Card'
import PageTitle from 'components/PageTitle'
import Tooltip from 'components/Tooltip'
import Comparables from 'components/Comparables'
import IntercomCta from 'components/IntercomCta'
import LoggedInPropertyPotentialChart from 'components/LoggedInPropertyPotentialChart'

// import ReportUpsell from 'pages/Reports/ReportUpsell'
import Alert from './Alert'
import FinancialOverview from './FinancialOverview'
import Hero from './Hero'
import PropertyPlan from './PropertyPlan'
import PropertyDetails from 'pages/Dashboard/PropertyDetails'
import Contractors from 'pages/Dashboard/Contractors'
import Activity from 'pages/Dashboard/Activity'

// Styles
import styles from './styles.module.scss'

const Inner: FC = () => {
  const { selectedProperty: property } = useProperties()

  const handleClickCta = useCallback(() => {
    track('click cta', { cta: 'edit-financial', module: 'financial-overview', location: 'tooltip' })
  }, [])
  const { completeness } = usePropertyCompleteness()

  const handleImproveClick = useCallback(() => {
    track('click cta', { cta: 'edit', module: 'property-details' })
  }, [])

  const handleClickEdit = useCallback(() => {
    track('click cta', { cta: 'edit', module: 'edit-prompt' })
  }, [])

  if (!property) return null

  return (
    <div className="tw-min-h-screen">
      <PageTitle title={displayAddress(property)} />
      <section className={styles.hero}>
        <Hero property={property} />
      </section>
      <div className={cx(styles.content, 'tw-mx-auto tw-px-4 sm:tw-px-6 lg:tw-px-8 tw-grow')}>
        <section className={styles.details}>
          <Card className={cx(styles.card, styles.noPadding)}>
            <PropertyDetails />
            <div className="tw-p-5 tw-bg-gold-97">
              <div className="tw-flex tw-justify-between">
                <div>
                  <div className="tw-text-lg">{completeness.score.level}</div>
                  <div className="tw-text-gray-600 tw-font-light">{'Home Profile Strength'}</div>
                </div>
                <div>
                  <Link
                    className={cx('tw-flex tw-gap-2', styles.editLink)}
                    onClick={handleImproveClick}
                    to="edit"
                    title="Edit to Improve"
                  >
                    <Edit className="tw-text-gray-400" />
                    <div>{'Edit to improve'}</div>
                  </Link>
                </div>
              </div>
              <BarChart className="tw-mt-3 tw-mx-10" score={completeness.score.val} maxScore={completeness.maxScore} />
            </div>
            <Activity />
          </Card>
        </section>
        {property.marketplace && (
          <section className={styles.helpBox}>
            {"We're here to help!"}
            <IntercomCta className={styles.advisorCta} location="in-page">
              {'Chat with a Realm Advisor'}
            </IntercomCta>
          </section>
        )}
        <section className={styles.potential}>
          <PotentialValueCard />
        </section>
        <section className={styles.projects}>
          <Card className={styles.card}>
            <PropertyPlan className={styles.propertyPlan} property={property} />
          </Card>
        </section>
        <section className={styles.financialOverview}>
          <Card className={styles.card}>
            <h2>{'Financial Overview'}</h2>
            <Tooltip
              trackAs="financial-overview"
              overlay={
                <div>
                  {'Once we know '}
                  <Link onClick={handleClickCta} to="finance-hub/financial-analysis">
                    {'your interest rate'}
                  </Link>
                  {
                    ', we can track your equity over time and show you personalized options to unlock savings or tap into value. '
                  }
                  <Link onClick={handleClickCta} to="finance-hub/financial-analysis">
                    {'Click here'}
                  </Link>
                  {' to update your info.'}
                </div>
              }
            />
            <FinancialOverview className={styles.financialOverview} property={property} />
          </Card>
        </section>
        <section className={styles.contractorsAndEdit}>
          <Contractors />
          <Alert>
            <div className={styles.alertTitle}>{'Are we missing something?'}</div>
            <div className={styles.alertContent}>
              <Link to={`edit`} onClick={handleClickEdit}>
                {'Edit your property'}
              </Link>
              {' and add more home details to get more accurate recommendations'}
            </div>
          </Alert>
        </section>
      </div>
    </div>
  )
}

interface ProjectSuggestion {
  id: number
  name: string
  valueChange: number
}

function limitProjectsByMaxValue(projects: Array<ProjectSuggestion>, maxValue: number): Array<ProjectSuggestion> {
  let total = 0
  return projects.reduce((prev, project) => {
    if (total + project.valueChange <= maxValue) {
      total += project.valueChange
      return prev.concat(project)
    }
    return prev
  }, [] as Array<ProjectSuggestion>)
}

export const usePropertyPotentialChartProjectSuggestions = (
  projects: ProjectTemplateBase[],
  maxValue: number
): Array<ProjectSuggestion> => {
  const projectSuggestions = projects
    .filter((project) => project != null && project.additional_home_value > 0)
    .map((project) => ({
      id: project?.id || 0,
      name: project?.name || '',
      valueChange: project?.additional_home_value || 0,
    }))
    .sort((project) => -project.valueChange)

  // Limit suggestions to only those that add up to under max_value
  if (maxValue) {
    return limitProjectsByMaxValue(projectSuggestions, maxValue)
  }

  return projectSuggestions
}

const PotentialValueCard: FC = () => {
  const { selectedProperty: property } = useProperties()
  const untappedPotential = (property?.potential_value || 0) - (property?.estimated_value || 0)
  const projectSuggestions = usePropertyPotentialChartProjectSuggestions(
    property?.top_projects || [],
    untappedPotential
  )

  const handleLinkToPlan = useCallback(
    (location: string) => () => {
      track('click cta', { cta: 'property-plan', module: 'property-value-current-and-potential', location })
    },
    []
  )

  if (!property) return null
  if (projectSuggestions.length < 2) return null

  return (
    <Card className={styles.card}>
      <Tooltip
        trackAs="potential-value"
        overlay="We calculate the maximum value your home could be worth by aggregating the value of all possible home improvements allowed on your property."
      />
      <h2 className={styles.title}>
        <span>{'Property Value Current & Potential'}</span>
        <SparklesTwo />
      </h2>
      <p className={styles.untappedPotential}>
        <span>{'You have '}</span>
        <b>{formatCurrency(untappedPotential)}</b>
        <span>{' total untapped potential!'}</span>
      </p>
      <LoggedInPropertyPotentialChart
        className={styles.loggedInPropertyPotentialChart}
        onProjectLinkClick={handleLinkToPlan}
        additionalProjectsLabel={'Choose from 10+ more recommended projects for your property'}
      />
      {property.comparables?.length && (
        <>
          <div className={cx(styles.comparables, styles.cardTitle)}>
            <h2>{'Comparable Homes'}</h2>
            <h3>{'View nearby homes with your neighborhood'}</h3>
          </div>
          <Comparables comparables={property.comparables} />
        </>
      )}
    </Card>
  )
}

export const LabelWithSuffix: FC<PropsWithChildren<{ suffix: string }>> = ({ children, suffix }) => (
  <span>
    {children}
    <span className={styles.suffix}>{suffix}</span>
  </span>
)

const Dashboard: FC = () => {
  return (
    <Secure>
      <HasProperty>
        <FullBleed>
          <Inner />
        </FullBleed>
      </HasProperty>
    </Secure>
  )
}

export default Dashboard
