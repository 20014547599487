import React, { FC, useCallback } from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'
import PlusCircle from 'svgs/plus-circle'
import { ProjectTemplate } from 'recoil/projectTemplates'
import { TopProject } from 'recoil/properties'
import { formatCurrency } from 'utils/formatting'
import ExternalSvg from 'components/ExternalSvg'
import FallbackIcon from 'svgs/property-plan/fallback'

interface ProjectCardProps {
  template?: ProjectTemplate
  topProject: TopProject
  addedToPlan: boolean
  addToPlan: () => void
  primary: boolean
}

function formatCost(minCost: number, maxCost: number): string {
  // Fuzzy each cost to the same precision
  let precision = 3 // default to 1000
  if (minCost < 10 || maxCost < 10) precision = 0
  else if (minCost < 100 || maxCost < 100) precision = 1
  else if (minCost < 1000 || maxCost < 1000) precision = 2

  const mul = Math.pow(10, precision)
  const fMinCost = Math.round(minCost / mul) * mul
  const fMaxCost = Math.round(maxCost / mul) * mul

  // If they're the same, don't return a range.
  if (fMinCost == fMaxCost) return formatCurrency(fMinCost)

  return `${formatCurrency(fMinCost)} - ${formatCurrency(fMaxCost)}`
}

const ProjectCard: FC<ProjectCardProps> = ({
  topProject,
  template,
  addedToPlan,
  addToPlan,
  primary,
}: ProjectCardProps) => {
  const handleClickAddToPlan = useCallback(() => {
    if (!template) return
    if (addedToPlan) return

    addToPlan()
  }, [addToPlan, addedToPlan, template])

  if (!template) return null

  const title = template.name
  const image = template.icon_svg
  const minCost = topProject.cost_estimate_low
  const maxCost = topProject.cost_estimate_high

  return (
    <li className={classNames(styles.project, primary && styles.primary, addedToPlan && styles.active)}>
      <div className={styles.icon}>
        <ExternalSvg uri={image}>
          <FallbackIcon />
        </ExternalSvg>
      </div>
      <div className={styles.details}>
        <div className={styles.title}>{title}</div>
        <div className={styles.cost}>{formatCost(minCost, maxCost)}</div>
        <div className={styles.costLabel}>{'Cost Estimate'}</div>
      </div>
      <div className={styles.actions}>
        <button type="button" className={classNames(styles.add)} onClick={handleClickAddToPlan} disabled={addedToPlan}>
          <PlusCircle className={styles.plus} />
          <span>{' Add to my plan'}</span>
        </button>
      </div>
    </li>
  )
}

export default ProjectCard
