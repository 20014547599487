import React, { FC, FormEvent, ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react'
import PageTitle from 'components/PageTitle'
import Button from 'components/Button2'
import Input from 'components/Input'
import Auth from 'layouts/Auth'
import { FormErrors } from 'utils/forms'
import { patchJSON } from 'utils/fetch'
import { Link } from 'react-router-dom'
import EnsureLoggedOut from 'layouts/EnsureLoggedOut'

interface ChangePasswordState {
  form: {
    password: string
    password_confirmation: string
  }
  errors: Record<string, string[]>
  isLoading: boolean
}

interface ChangePasswordAction {
  type: 'setFields' | 'setIsLoading' | 'setErrors'
  payload: any
}

const reducer = (state: ChangePasswordState, action: ChangePasswordAction): ChangePasswordState => {
  switch (action.type) {
    case 'setFields':
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.key as keyof ChangePasswordState['form']]: action.payload.val,
        },
      }
    case 'setIsLoading':
      return {
        ...state,
        isLoading: action.payload,
      }
    case 'setErrors':
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      }
    default:
      throw new Error()
  }
}

const initialState: ChangePasswordState = {
  errors: {},
  form: {
    password: '',
    password_confirmation: '',
  },
  isLoading: false,
}

export interface ChangePasswordInputArgs {
  value: string
  errors: string[]
  onChangeText: (value: string) => void
}

interface ChangePasswordFormProps {
  resetPasswordToken?: string
  renderPasswordInput?: (args: ChangePasswordInputArgs) => ReactNode
  renderConfirmPasswordInput?: (args: ChangePasswordInputArgs) => ReactNode
  renderWrapper?: (children: ReactNode) => JSX.Element
  renderButton?: () => ReactNode
  onSuccess?: () => void
  onLoading?: (value: boolean) => void
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  resetPasswordToken: resetPasswordTokenProp,
  renderPasswordInput,
  renderConfirmPasswordInput,
  renderWrapper,
  renderButton,
  onSuccess,
  onLoading,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const resetPasswordToken = useMemo(() => {
    if (resetPasswordTokenProp) return resetPasswordTokenProp

    return new URLSearchParams(location.search).get('reset_password_token')
  }, [resetPasswordTokenProp])

  useEffect(() => {
    onLoading?.(state.isLoading)
  }, [onLoading, state.isLoading])

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const errors: FormErrors = {}
      if (state.form.password.length < 8) {
        errors.password = ['Password must be at least 8 characters.']
      }
      if (state.form.password !== state.form.password_confirmation) {
        errors.password_confirmation = ['Password confirmation must match.']
      }
      if (Object.keys(errors).length > 0) {
        dispatch({ type: 'setErrors', payload: errors })
      } else {
        dispatch({ type: 'setIsLoading', payload: true })
        const csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
        const csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
        const body = {
          [csrfParam || '']: csrfToken,
          user: {
            reset_password_token: resetPasswordToken,
            password: state.form.password,
            password_confirmation: state.form.password_confirmation,
          },
        }
        const response = await patchJSON('/users/password', body)
        if (response.isError) {
          dispatch({ type: 'setErrors', payload: response.jsonBody.errors })
        } else {
          // user is now signed in, redirect to properties page
          onSuccess ? onSuccess() : (window.location.href = '/properties')
        }
      }
    },
    [state.form, resetPasswordToken, onSuccess]
  )

  const handleChangeText = useCallback(
    (key: keyof ChangePasswordState['form']) => (value: string) => {
      dispatch({ type: 'setFields', payload: { key, value } })
    },
    [dispatch]
  )

  const Wrapper = useCallback(
    ({ children }) => {
      if (renderWrapper) return renderWrapper(children)

      return (
        <Auth>
          <Auth.Body>
            <Auth.Body.Header>{'Change your password'}</Auth.Body.Header>
            <hr className="tw-my-6" />
            {children}
          </Auth.Body>
        </Auth>
      ) as JSX.Element
    },
    [renderWrapper]
  )

  return (
    <EnsureLoggedOut>
      <PageTitle title="Change Password" />
      <Wrapper>
        {state.errors.reset_password_token && (
          <p className="tw-mb-6">
            {'Your reset token has expired, please initiate the process again. '}
            <Link className="tw-block" to="/users/password/new">
              {'Forgot password'}
            </Link>
          </p>
        )}

        <form onSubmit={handleSubmit} className="tw-space-y-6">
          <fieldset disabled={state.isLoading}>
            <div className="tw-space-y-3">
              {renderPasswordInput?.({
                value: state.form.password,
                errors: state.errors.password,
                onChangeText: handleChangeText('password'),
              }) || (
                <Input
                  autoComplete="password"
                  autoFocus
                  errors={state.errors.password}
                  name="user[password]"
                  onChange={handleChangeText('password')}
                  placeholder="Password"
                  required
                  type="password"
                  value={state.form.password}
                />
              )}
              {renderConfirmPasswordInput?.({
                value: state.form.password_confirmation,
                errors: state.errors.password_confirmation,
                onChangeText: handleChangeText('password_confirmation'),
              }) || (
                <Input
                  autoComplete="password_confirmation"
                  errors={state.errors.password_confirmation}
                  name="user[password_confirmation]"
                  onChange={handleChangeText('password_confirmation')}
                  placeholder="Confirm password"
                  required
                  type="password"
                  value={state.form.password_confirmation}
                />
              )}
            </div>
          </fieldset>

          {renderButton?.() || (
            <div className="tw-flex tw-justify-center">
              <Button type="submit" loading={state.isLoading}>
                {'Change Password'}
              </Button>
            </div>
          )}
        </form>
      </Wrapper>
    </EnsureLoggedOut>
  )
}

export default ChangePasswordForm
