import React, { FC } from 'react'

import { Property } from 'recoil/properties'
// import propertyPath from 'utils/propertyPath'

import PropertyMap from './ParcelMap'

import styles from './styles.module.scss'

export interface Props {
  property: Property
}

const Hero: FC<Props> = ({ property }) => {
  return (
    <header className={styles.hero}>
      <div className={styles.map}>
        <PropertyMap property={property} />
      </div>
    </header>
  )
}

export default Hero
