import Input from 'components/Input'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useUserComment } from 'recoil/userComments'
import cx from 'classnames'
import { Status } from 'utils/status'
import FancyButton from 'components/FancyButton'
import { useProperties } from 'recoil/properties'
import Spinner from 'components/Spinner'
import { track } from 'utils/analytics'

import styles from '../styles.module.scss'

export const CommentsForm: FC = () => {
  const { selectedProperty } = useProperties()
  const { userComment, addUserComment, updateUserComment } = useUserComment()
  const [commentText, setCommentText] = useState('')
  const [interacted, setInteracted] = useState(false)
  const [status, setStatus] = useState<Status | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setCommentText(userComment?.text || '')
  }, [userComment, selectedProperty?.id])

  const handleCommentChange = useCallback((text) => {
    setInteracted(true)
    setCommentText(text)
  }, [])

  const save = useCallback(async () => {
    try {
      setLoading(true)
      const edited = !userComment || userComment.text != commentText
      track('submit form', { section: 'edit', form: 'property-comments', edited: edited, interacted: interacted })
      if (userComment && userComment.id) {
        await updateUserComment({ ...userComment, text: commentText })
        setStatus({ type: 'success', text: 'Saved your comments' })
      } else {
        await addUserComment({ ...userComment, text: commentText })
        setStatus({ type: 'success', text: 'Saved your comments' })
      }
    } catch (e) {
      setStatus({ type: 'error', text: 'Something went wrong, please try again.' })
    }
    setLoading(false)
    setInteracted(false)
  }, [commentText, userComment, interacted, addUserComment, updateUserComment])

  return (
    <>
      <div className="row">
        <div className="col">
          <Input
            tag="textarea"
            value={commentText}
            onChange={handleCommentChange}
            placeholder="Planning a renovation? Generating rental income? Taking equity out? We're here to help."
          />
        </div>
      </div>
      <div className="row tw-mt-4">
        <div className={cx('col', styles.buttonWithStatus)}>
          <FancyButton className={styles.button} onClick={save}>
            {'Save'}
            {loading ? <Spinner /> : undefined}
          </FancyButton>
          {status ? (
            <div className={cx(styles.buttonStatus, styles[status.type])}>{status.text}</div>
          ) : (
            <div className={styles.buttonStatus} />
          )}
        </div>
      </div>
    </>
  )
}

export default CommentsForm
