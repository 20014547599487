import Carousel from 'react-slick'
import cx from 'classnames'
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import { Property, useProperties } from 'recoil/properties'
import { track } from 'utils/analytics'
import { PropertyAvatar } from 'components/PropertyAvatar'
import CarouselPhoto from './CarouselPhoto'
import BlankPhoto from 'svgs/blank-photo'

import styles from './Photos.module.scss'

interface PhotosProps {
  property: Property
}

const Photos: FC<PhotosProps> = ({ property }) => {
  const { attachDocToSelectedProperty, deleteDocFromSelectedProperty } = useProperties()

  const carousel = useRef<Carousel | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navToNext = useCallback(() => {
    carousel.current?.next()
  }, [])

  const handleAddPhoto = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return
      const file = e.target.files[0]
      if (!file) return

      try {
        setIsLoading(true)
        await track('add photo', { propertyId: property.id })
        await attachDocToSelectedProperty('photos', file)
        setIsLoading(false)
        navToNext()
      } catch (e) {
        setIsLoading(false)
      }
    },
    [attachDocToSelectedProperty, navToNext, property.id]
  )

  const handleRemovePhoto = useCallback(
    async (photoId: number) => {
      try {
        setIsLoading(true)
        await track('remove photo', { propertyId: property.id })
        await deleteDocFromSelectedProperty('photos', photoId)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
      }
    },
    [deleteDocFromSelectedProperty, property.id]
  )

  return (
    <Carousel className="tw-rounded-t-3xl tw-overflow-hidden" ref={carousel} dots arrows={false} draggable={false}>
      {(property.photos || []).map((photo) => (
        <CarouselPhoto
          key={photo.id}
          isLoading={isLoading}
          onAddPhoto={handleAddPhoto}
          onRemovePhoto={handleRemovePhoto}
          photoId={photo.id}
        >
          <img
            src={photo.url}
            alt={photo.name}
            className={cx('tw-rounded-t-3xl tw-w-full tw-object-cover', styles.slideDimensions)}
          />
        </CarouselPhoto>
      ))}
      <CarouselPhoto onAddPhoto={handleAddPhoto} isLoading={isLoading}>
        <PropertyAvatar
          className={cx('tw-rounded-t-3xl tw-w-full tw-object-cover', styles.slideDimensions)}
          property={property}
          width={400}
          height={280}
          customDefault={
            <div className={styles.bigUploadPrompt}>
              <div className={styles.content}>
                <BlankPhoto className={styles.icon} />
                <div>{'Upload photos of your property'}</div>
              </div>
              <input
                accept="image/*"
                className={styles.upload}
                disabled={isLoading}
                onChange={handleAddPhoto}
                type="file"
              />
            </div>
          }
        />
      </CarouselPhoto>
    </Carousel>
  )
}

export default Photos
