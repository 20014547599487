import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-785 -565) translate(200 472) translate(219 93)">
            <g>
              <path d="M0 0H32V32H0z" transform="translate(366)" />
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M21.205 17.4l7.995 7.996-2.828 2.828-7.995-7.995 2.828-2.828zM10.347 4.654c1.56-1.56 4.093-1.56 5.653 0L13.173 7.48l1.88 1.88-.946.947 2.373 2.373 2.467-2.467c-.174-.48-.28-1-.28-1.546C18.667 6.093 20.76 4 23.333 4c.774 0 1.494.213 2.134.547l-3.6 3.6 1.986 1.986 3.6-3.6c.334.64.547 1.36.547 2.134 0 2.573-2.093 4.666-4.667 4.666-.546 0-1.066-.106-1.546-.28L6.613 28.213l-2.826-2.826 9.88-9.88-2.374-2.374-.946.947-1.88-1.88v3.773l-.947.947L2.8 12.2l.947-.947h3.76l-1.88-1.88z"
                transform="translate(366)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
