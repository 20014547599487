import React, { FC } from 'react'
import styles from './DownloadHeader.module.scss'

import cx from 'classnames'
import DownloadApp from 'images/onboarding-v3/DownloadApp.png'

interface DownloadHeaderProps {
  size: 'small' | 'large'
}

const DownloadHeader: FC<DownloadHeaderProps> = ({ size }) => {
  switch (size) {
    case 'small':
      return (
        <img
          src={DownloadApp}
          className={cx(styles.image, styles.small)}
          alt="Screenshot of the Realm app's home screen"
        />
      )
    case 'large':
      return (
        <img
          src={DownloadApp}
          className={cx(styles.image, styles.large)}
          alt="Screenshot of the Realm app's home screen"
        />
      )
  }
}

export default DownloadHeader
