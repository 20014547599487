import React, { FC, useState, useCallback } from 'react'
import classNames from 'classnames'

import { track } from 'utils/analytics'

import { usePropertyPlans } from 'recoil/propertyPlans'
import { useProjectTemplates, ProjectTemplate } from 'recoil/projectTemplates'
import { useProjects } from 'recoil/projects'

import Template from './Template'
import FakeTemplate from './FakeTemplate'
import Filters, { Filter, ALL_FILTER } from './Filters'

import styles from './styles.module.scss'
import propertyPlanStyles from '../styles.module.scss'
import { cost_sort, frequency_sort, priority_sort, recoup_sort, recommended_sort } from 'utils/projectTemplates'

enum SortOrderType {
  Frequency,
  Priority,
  Recoup,
  Cost,
  Recommended,
}

const sortMap = {
  [SortOrderType.Frequency]: frequency_sort,
  [SortOrderType.Recoup]: recoup_sort,
  [SortOrderType.Priority]: priority_sort,
  [SortOrderType.Cost]: cost_sort,
  [SortOrderType.Recommended]: recommended_sort,
}

function templateFilter(filter: Filter) {
  return (template: ProjectTemplate) => {
    if (filter.tag) {
      if (filter.tag == ALL_FILTER) return true
      return template.tags.includes(filter.tag)
    }

    if (filter.search == '') return true
    return (
      template.name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()) ||
      (template.description && template.description.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase()))
    )
  }
}

function removeDuplicates(array: Array<string>) {
  return Object.keys(
    array.reduce((prev, item) => Object.assign({}, prev, { [item]: item }), {} as { [item: string]: string })
  )
}

const ProjectTemplates: FC = () => {
  const { addProject } = useProjects()
  const { isSwitching } = usePropertyPlans()
  const { latestProjectTemplates: projectTemplates } = useProjectTemplates()
  const [sortOrder, setSortOrder] = useState(SortOrderType.Frequency)
  const [filter, setFilter] = useState<Filter>({ tag: ALL_FILTER, search: '' })

  const onSelected = useCallback(
    async (templateId: number) => {
      await addProject({ project_template_id: templateId })
    },
    [addProject]
  )

  const onSortByFrequency = useCallback(() => {
    track('property-plan sort templates', { sortBy: 'frequency' })
    setSortOrder(SortOrderType.Frequency)
  }, [setSortOrder])
  const onSortByRecoup = useCallback(() => {
    track('property-plan sort templates', { sortBy: 'recoup' })
    setSortOrder(SortOrderType.Recoup)
  }, [setSortOrder])
  const onSortByCost = useCallback(() => {
    track('property-plan sort templates', { sortBy: 'cost' })
    setSortOrder(SortOrderType.Cost)
  }, [setSortOrder])
  const onSortByRecommended = useCallback(() => {
    track('property-plan sort templates', { sortBy: 'recommended' })
    setSortOrder(SortOrderType.Recommended)
  }, [setSortOrder])

  const templates = projectTemplates.filter(templateFilter(filter)).sort(sortMap[sortOrder] || priority_sort)

  const allFilters = removeDuplicates(
    projectTemplates.map((template) => template.tags).reduce((prev, tags) => prev.concat(tags), [])
  )

  return (
    <>
      <div
        className={classNames(propertyPlanStyles.main, styles.projectTemplates, isSwitching ? styles.switching : null)}
      >
        <header>
          <h2>{'Suggested Projects'}</h2>
          <div className={styles.sorting}>
            <span>{'Sort by:'}</span>
            <button
              type="button"
              className={classNames(sortOrder == SortOrderType.Frequency ? styles.selected : null)}
              onClick={onSortByFrequency}
            >
              {'Popular Near You'}
            </button>
            <span>{'/'}</span>
            <button
              type="button"
              className={classNames(sortOrder == SortOrderType.Recommended ? styles.selected : null)}
              onClick={onSortByRecommended}
            >
              {'Recommended For You'}
            </button>
            <span>{'/'}</span>
            <button
              type="button"
              className={classNames(sortOrder == SortOrderType.Recoup ? styles.selected : null)}
              onClick={onSortByRecoup}
            >
              {'% Recoup'}
            </button>
            <span>{'/'}</span>
            <button
              type="button"
              className={classNames(sortOrder == SortOrderType.Cost ? styles.selected : null)}
              onClick={onSortByCost}
            >
              {'Cost'}
            </button>
          </div>
          <Filters tags={allFilters} filter={filter} onChange={setFilter} />
        </header>

        <ul className={styles.templates}>
          {isSwitching ? (
            <>
              <FakeTemplate />
              <FakeTemplate />
              <FakeTemplate />
            </>
          ) : (
            templates.map((p, i) => <Template key={p.id} template={p} index={i} onSelected={onSelected} />)
          )}
        </ul>
      </div>
    </>
  )
}

export default ProjectTemplates
