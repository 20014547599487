import React, { FC, PropsWithChildren, useMemo } from 'react'
import { Payment, paymentStatusColor } from 'recoil/payments'
import { formatMoney } from 'utils/formatting'
import Alert from 'components/Alert'
import cx from 'classnames'
import DefaultPackageIconImg from 'images/packages/defaultPackageIcon.png'

interface PaymentCardProps {
  payment: Payment
  error?: string | null
  className?: string
  compact?: boolean
}

export const PAYMENT_MAX_CARD_AMOUNT = 500000

const PaymentCard: FC<PropsWithChildren<PaymentCardProps>> = ({ payment, error, className, compact, children }) => {
  const name = useMemo(() => {
    if (!payment) return
    if (!payment.package_purchase) return

    return payment.package_purchase.package.name
  }, [payment])

  const description = useMemo(() => {
    if (!payment) return
    if (!payment.package_purchase) return payment.description

    return payment.package_purchase.package.description
  }, [payment])

  const amount = useMemo(() => {
    if (!payment) return

    return formatMoney(payment.amount_in_cents / 100)
  }, [payment])

  const listPrice = useMemo(() => {
    if (!payment) return
    if (!payment.package_purchase) return

    return formatMoney(payment.package_purchase.price.price)
  }, [payment])

  return (
    <div className={cx('tw-bg-white tw-rounded-lg tw-p-6 tw-shadow-lg tw-flex tw-flex-col', className)}>
      <div className="tw-mb-4">
        <img
          className="tw-rounded-lg tw-w-full tw-h-56 tw-object-cover"
          src={payment.package_purchase?.package.web_card_image || DefaultPackageIconImg}
          alt={'An icon for the package'}
        />
      </div>
      {payment.amount_in_cents > PAYMENT_MAX_CARD_AMOUNT && (
        <Alert>{'Note: payments above $5k must be paid with ACH.'}</Alert>
      )}
      {error ? (
        <div className="tw-my-2 tw-text-red-600">{error}</div>
      ) : payment.stripe_charge_ref && payment.status == 'pending' ? (
        <div className="tw-my-2 tw-bg-yellow-200">
          {'The payment is being processed. This might take up to 7 days.'}
        </div>
      ) : null}
      <div className={cx('tw-flex tw-flex-col tw-gap-4 tw-grow', { ['md:tw-grid-cols-2 md:tw-grid']: !compact })}>
        <div className="tw-space-y-2">
          <div className="tw-grow tw-text-2xl tw-tracking-tighter tw-font-semibold">{name}</div>
          {description && <div className="tw-text-tertiary-black tw-font-normal">{description}</div>}
        </div>
        <div className="tw-space-y-2 tw-mt-auto tw-justify-self-end">
          <div className="tw-flex tw-items-start">
            <div className="tw-space-x-2">
              <span className="tw-text-2xl tw-font-semibold tw-tracking-tighter">{amount}</span>
              {!!listPrice && amount != listPrice && (
                <span className="tw-text-sm tw-line-through tw-text-tertiary-black">{listPrice}</span>
              )}
            </div>
            <div
              className={cx(
                'tw-inline-block tw-ml-auto tw-px-2 tw-py-1 tw-rounded-lg tw-text-sm tw-capitalize tw-font-semibold tw-text-white',
                paymentStatusColor(payment.status)
              )}
            >
              {payment.status}
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PaymentCard
