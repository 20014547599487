import React, { FC, useCallback, useState } from 'react'
import { UProperty, useProperties } from 'recoil/properties'
import { track } from 'utils/analytics'

import styles from './styles.module.scss'
import Tooltip from 'components/Tooltip'
import InputWithEditButton from 'components/InputWithEditButton'
import { useSetRecoilState } from 'recoil'
import { editStatusState } from 'recoil/editStatus'

const MAX_ROOMS = 100
const MAX_SQUARE_FOOTAGE = 100000
const MAX_STORIES = 200
const MAX_GARAGE_CAR_COUNT = 20

interface PropertyError {
  [key: string]: string[]
}

function safeParseInt(val: string): number {
  const iVal = parseInt(val)
  return Number.isNaN(iVal) ? 0 : iVal
}

export const PropertyDetailsForm: FC = () => {
  const { selectedProperty, updateSelectedProperty } = useProperties()

  const [name, setName] = useState<string>(selectedProperty?.name || '')
  const [bedrooms, setBedrooms] = useState<string>(`${selectedProperty?.bedrooms || 0}`)
  const [bathrooms, setBathrooms] = useState<string>(`${selectedProperty?.bathrooms || 0}`)
  const [halfBathrooms, setHalfBathrooms] = useState<string>(`${selectedProperty?.half_bathrooms || 0}`)
  const [squareFootage, setSquareFootage] = useState<string>(`${selectedProperty?.square_footage || 0}`)
  const [numberOfStories, setNumberOfStories] = useState<string>(`${selectedProperty?.number_of_stories || 0}`)
  const [garageCarCount, setGarageCarCount] = useState<string>(`${selectedProperty?.garage_car_count || 0}`)
  const [lotSize, setLotSize] = useState<string>(`${selectedProperty?.lot_size || 0}`)
  const [hoaName, setHoaName] = useState<string>(selectedProperty?.hoa_name || '')
  const setStatus = useSetRecoilState(editStatusState)
  const [errors, setErrors] = useState<PropertyError>({})
  const [interacted, setInteracted] = useState<{ [field: string]: boolean }>({})

  const updatedProperty = useCallback(() => {
    return {
      name,
      bedrooms: safeParseInt(bedrooms),
      bathrooms: safeParseInt(bathrooms),
      half_bathrooms: safeParseInt(halfBathrooms),
      square_footage: safeParseInt(squareFootage),
      number_of_stories: safeParseInt(numberOfStories),
      garage_car_count: safeParseInt(garageCarCount),
      hoa_name: hoaName,
      lot_size: safeParseInt(lotSize),
    } as UProperty
  }, [name, bedrooms, bathrooms, halfBathrooms, squareFootage, numberOfStories, garageCarCount, hoaName, lotSize])

  const trackFormSubmit = useCallback(
    (field, value) => {
      const edited = selectedProperty == null || selectedProperty[field] != value
      track('submit form', {
        section: 'edit',
        form: 'property-details',
        interacted: interacted[field] || false,
        edited,
        property: {
          [field]: value,
        },
      })
    },
    [interacted, selectedProperty]
  )

  const update = useCallback(
    (field: string) => async () => {
      try {
        const value = updatedProperty()[field]
        trackFormSubmit(field, value)
        // Only want to save the field that is being confirmed, not all those currently
        // in edit mode.
        await updateSelectedProperty({ [field]: value })
        setErrors({})
        setStatus({ type: 'success', text: 'Saved your property details' })
        // Reset interacted
        setInteracted((prev) => {
          delete prev[field]
          return prev
        })
      } catch (e) {
        setErrors(e.body || {})
        setStatus({ type: 'error', text: 'Something went wrong, please try again' })
        // we want to rethrow here so this is caught by the Input
        throw e
      }
    },
    [updatedProperty, trackFormSubmit, updateSelectedProperty, setStatus]
  )

  const setValue = useCallback(
    (field: string, setter: (val: string) => void) => (val: string) => {
      setInteracted((prev) => ({ ...prev, [field]: true }))
      setter(val)
    },
    []
  )

  return (
    <>
      <div className={styles.propertyDetails}>
        <InputWithEditButton
          label="Name"
          type="text"
          errors={errors?.['name']}
          required
          value={name}
          onChange={setValue('name', setName)}
          className={styles.input}
          onConfirm={update('name')}
        />
        <div />
        <InputWithEditButton
          label="Bedrooms"
          type="number"
          min={0}
          max={MAX_ROOMS}
          errors={errors?.['bedrooms']}
          required
          placeholder="e.g. 2"
          value={bedrooms}
          onChange={setValue('bedrooms', setBedrooms)}
          className={styles.input}
          onConfirm={update('bedrooms')}
        />
        <InputWithEditButton
          label="Bathrooms"
          type="number"
          min={0}
          max={MAX_ROOMS}
          errors={errors?.['bathrooms']}
          required
          placeholder="e.g. 3"
          value={bathrooms}
          onChange={setValue('bathrooms', setBathrooms)}
          className={styles.input}
          onConfirm={update('bathrooms')}
        />
        <InputWithEditButton
          type="number"
          min={0}
          max={MAX_ROOMS}
          errors={errors?.['half_bathrooms']}
          required
          label="Partial bathrooms"
          tooltip={<Tooltip className="tw-top-0 tw-text-base-gray-two" overlay="A bathroom without a shower." />}
          placeholder="e.g. 1"
          value={halfBathrooms}
          onChange={setValue('half_bathrooms', setHalfBathrooms)}
          className={styles.input}
          onConfirm={update('half_bathrooms')}
        />
        <InputWithEditButton
          label="Home square feet"
          min={0}
          max={MAX_SQUARE_FOOTAGE}
          type="number"
          errors={errors?.['square_footage']}
          decorator={'sq ft'}
          placeholder="e.g. 2400"
          value={squareFootage}
          onChange={setValue('square_footage', setSquareFootage)}
          className={styles.input}
          onConfirm={update('square_footage')}
        />
        <InputWithEditButton
          label="Stories"
          type="number"
          min={1}
          max={MAX_STORIES}
          errors={errors?.['number_of_stories']}
          placeholder="e.g. 1"
          value={numberOfStories}
          onChange={setValue('number_of_stories', setNumberOfStories)}
          className={styles.input}
          onConfirm={update('number_of_stories')}
        />
        <InputWithEditButton
          label="Garage (number of cars)"
          type="number"
          min={1}
          max={MAX_GARAGE_CAR_COUNT}
          errors={errors?.['garage_car_count']}
          placeholder="e.g. 2"
          value={garageCarCount}
          onChange={setValue('garage_car_count', setGarageCarCount)}
          className={styles.input}
          onConfirm={update('garage_car_count')}
        />
        <InputWithEditButton
          label="HOA name"
          type="text"
          placeholder="e.g. Ivy St Association"
          errors={errors?.['hoa_name']}
          value={hoaName || ''}
          onChange={setValue('hoa_name', setHoaName)}
          className={styles.input}
          onConfirm={update('hoa_name')}
        />
        <InputWithEditButton
          label="Lot size"
          type="number"
          placeholder="e.g. Ivy St Association"
          errors={errors?.['lot_size']}
          decorator={'sq ft'}
          value={lotSize}
          onChange={setValue('lot_size', setLotSize)}
          className={styles.input}
          onConfirm={update('lot_size')}
        />
      </div>
    </>
  )
}

export default PropertyDetailsForm
