import { START_STATE, END_STATE } from '../states'
import { Flow } from '.'
import { FOState } from 'recoil/onboardingFlows'

const BOOK_OR_DOWNLOAD_STEPS = [
  {
    if: (state: FOState): boolean => {
      if (!state.onboarding.unavailable) return false
      if (state.onboarding.timeToStart == 'not_renovating') return true

      return !state.onboarding.qualifiedProjects && state.onboarding.budget == 'below_45k'
    },
    state: 'cam-v3-unserviceable',
  },
  {
    if: (state: FOState): boolean => {
      if (!state.onboarding.screenBudget) return false

      return state.onboarding.budget == 'below_45k'
    },
    state: 'cam-v3-download',
  },
  {
    state: 'cam-v3-book-or-download',
  },
]

// CA Marketplace landing page flow, v3 (Version 3)
const flow: Flow = {
  [START_STATE]: {
    to: [
      {
        if: (state: FOState): boolean => {
          if (!state.onboarding.publicPropertyData) return false
          else return state.onboarding.publicPropertyData.marketplace === true
        },
        state: 'cam-v3-renovation-readiness',
      },
      {
        if: (state: FOState): boolean => {
          if (!state.onboarding.publicPropertyData) return false
          else return state.onboarding.publicPropertyData.marketplace === false
        },
        state: 'cam-v3-download',
      },
      {
        state: 'cam-v3-start',
      },
    ],
  },
  ['cam-v3-start']: {
    url: '/cam-v3-start',
    next: [
      {
        state: 'cam-v3-enter-address',
      },
    ],
    progressStep: 0,
  },
  ['cam-v3-enter-address']: {
    url: '/cam-v3-enter-address',
    next: [
      {
        if: (state: FOState): boolean => {
          const skipGeofence = new URLSearchParams(state.onboarding.searchParams).get('sg') || false

          if (!skipGeofence && state.onboarding.country && state.onboarding.country != 'US') return true
          if (!state.onboarding.unavailable) return false

          return state.onboarding.marketplace === false
        },
        state: 'cam-v3-unserviceable',
      },
      {
        if: (state: FOState): boolean => {
          if (state.onboarding.unavailable) return false

          return state.onboarding.marketplace === false
        },
        state: 'cam-v3-download',
      },
      {
        state: 'cam-v3-renovation-readiness',
      },
    ],
    progressStep: 1,
    progressMax: 6,
  },
  ['cam-v3-unserviceable']: {
    url: '/cam-v3-unserviceable',
    progressStep: 1.5,
    progressMax: 6,
    next: [
      {
        if: (state: FOState): boolean => state.onboarding.reset == 'projects',
        state: 'cam-v3-projects',
      },
      {
        if: (state: FOState): boolean => state.onboarding.reset == 'budget',
        state: 'cam-v3-budget',
      },
      {
        if: (state: FOState): boolean => state.onboarding.reset == 'address',
        state: 'cam-v3-enter-address',
      },
      { state: END_STATE },
    ],
  },
  ['cam-v3-renovation-readiness']: {
    url: '/cam-v3-renovation-readiness',
    progressStep: 2,
    progressMax: 6,
    next: [
      {
        if: (state: FOState): boolean => {
          if (state.onboarding.unavailable) return false

          return state.onboarding.timeToStart == 'not_renovating'
        },
        state: 'cam-v3-download',
      },
      {
        if: (state: FOState): boolean => {
          if (!state.onboarding.unavailable) return false

          return state.onboarding.timeToStart == 'not_renovating'
        },
        state: 'cam-v3-unserviceable',
      },
      {
        state: 'cam-v3-outside-bids',
      },
    ],
  },
  ['cam-v3-outside-bids']: {
    url: '/cam-v3-outside-bids',
    progressStep: 3,
    progressMax: 6,
    next: [
      {
        if: (state: FOState): boolean => {
          if (state.onboarding.outsideBids == 'no') return true

          return !!state.onboarding.unavailable
        },
        state: 'cam-v3-projects',
      },
      {
        if: (state: FOState): boolean => !!state.onboarding.screenBudget,
        state: 'cam-v3-budget',
      },
      {
        state: 'cam-v3-outside-bids-value-prop',
      },
    ],
  },
  ['cam-v3-outside-bids-value-prop']: {
    url: '/cam-v3-outside-bids-value-prop',
    progressStep: 3,
    progressMax: 6,
    next: BOOK_OR_DOWNLOAD_STEPS,
  },
  ['cam-v3-projects']: {
    url: '/cam-v3-projects',
    progressStep: 4,
    progressMax: 6,
    next: [
      {
        if: (state: FOState): boolean =>
          state.onboarding.screenBudget || (!!state.onboarding.unavailable && !state.onboarding.budget),
        state: 'cam-v3-budget',
      },
      ...BOOK_OR_DOWNLOAD_STEPS,
    ],
  },
  ['cam-v3-budget']: {
    url: '/cam-v3-budget',
    progressStep: 4,
    progressMax: 6,
    next: BOOK_OR_DOWNLOAD_STEPS,
  },
  ['cam-v3-book-or-download']: {
    url: '/cam-v3-book-or-download',
    progressStep: 5,
    progressMax: 6,
    next: [
      {
        state: 'cam-v3-complete-booking',
      },
    ],
  },
  ['cam-v3-download']: {
    url: '/cam-v3-download',
    progressStep: 5,
    progressMax: 6,
    next: [
      {
        state: END_STATE,
      },
    ],
  },
  ['cam-v3-sign-up']: {
    url: '/cam-v3-sign-up',
    progressStep: 6,
    progressMax: 6,
    next: [
      {
        state: 'cam-v3-confirm',
      },
    ],
  },

  ['cam-v3-complete-booking']: {
    url: '/cam-v3-complete-booking',
    next: [
      {
        if: (state: FOState): boolean => {
          return !!state.onboarding.unavailable
        },
        state: 'cam-v3-confirm',
      },
      {
        if: (state: FOState): boolean => {
          return !!state.onboarding.user
        },
        state: 'cam-v3-confirm',
      },
      {
        state: 'cam-v3-sign-up',
      },
    ],
  },
  ['cam-v3-confirm']: {
    url: '/cam-v3-confirm',
    next: [
      {
        state: END_STATE,
      },
    ],
  },
}

export default flow
