import React, { FC } from 'react'
import cx from 'classnames'
import styles from './Progress.module.scss'

interface ProgressProps {
  className?: string
  indicatorClassName?: string
  current?: number
  max: number
}

const Progress: FC<ProgressProps> = ({ className, indicatorClassName, current, max }) => {
  return (
    <div className={cx(styles.progress, className)}>
      <span
        className={cx(styles.indicator, indicatorClassName)}
        style={{ width: `${((current || 0) / max) * 100}%` }}
      />
    </div>
  )
}

export default Progress
