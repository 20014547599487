import 'stylesheets/application.scss'

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'utils/crypto'
import 'utils/sentry'

import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import TooltipBase from 'components/Tooltip/base'
import MainRouter from 'non-rendering/MainRouter'
import Authentication from 'non-rendering/Authentication'
import AnalyticsPerfTracker from 'non-rendering/AnalyticsPerfTracker'
import FirebaseLoader from 'non-rendering/firebase/FirebaseLoader'

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('main')
  if (container) {
    const root = createRoot(container)
    root.render(
      <StrictMode>
        <RecoilRoot>
          <FirebaseLoader>
            <Authentication>
              <TooltipBase />
              <MainRouter />
            </Authentication>
            <AnalyticsPerfTracker />
          </FirebaseLoader>
        </RecoilRoot>
      </StrictMode>
    )
  }
})
