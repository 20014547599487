const defaultConfig = {
  // Add each config property we want access to here.
  // Properties not listed in the defaultConfig are not
  // accessible. Ensure the type of the property's default value
  // is the same type as the remote value it matches.
  // For example...
  // advisory_calendly_url: '', // String to match the remote string.
}

export type DefaultConfigType = typeof defaultConfig

export default defaultConfig
