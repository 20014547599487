import classNames from 'classnames'

import React, { FC } from 'react'

import styles from './styles.module.scss'
import Sparkles2 from 'svgs/sparkles-two'

export interface CompletenessBarChartProps {
  score: number
  maxScore: number
  className?: string
}

const range = (max: number) => {
  const r: number[] = []
  for (let i = 0; i < max; i++) {
    r.push(i)
  }
  return r
}

export const CompletenessBarChart: FC<CompletenessBarChartProps> = ({ score, maxScore, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={classNames(styles.bubble, { [styles.selected]: score === maxScore })}>
        <Sparkles2 />
      </div>
      <div className={styles.barWrapper}>
        {range(maxScore).map((i) => {
          return <div key={i} className={classNames(styles.chunk, { [styles.selected]: i < score })} />
        })}
      </div>
    </div>
  )
}

export default CompletenessBarChart
