import React, { FC } from 'react'

const START_RADIUS = 16.75
const RADIUS_INCREASE = 32
const NUM_CIRCLES = 8

const RADII = new Array(NUM_CIRCLES).fill(START_RADIUS).map((r, i) => r + i * RADIUS_INCREASE)

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="484px" height="484px" viewBox="0 0 484 484">
    <g fill="none" fillRule="evenodd" strokeWidth="1.5" stroke="currentColor" transform="translate(242, 242)">
      {RADII.map((r, i) => (
        <circle key={i} r={r}></circle>
      ))}
    </g>
  </svg>
)

export default Svg
