import React, { FC, useState, useEffect } from 'react'

import Default from 'layouts/Default'
import Secure from 'layouts/Secure'

import PageTitle from 'components/PageTitle'

import HasServicableProperties from './HasServicableProperties'
import PropertiesButtons from './PropertiesButtons'
import PropertyList from './PropertyList'

import styles from './styles.module.scss'

const Properties: FC = () => {
  const [isManaging, setIsManaging] = useState(false)

  // Detect 'escape' button being hit to exit managing mode
  useEffect(() => {
    const onEscDown = (e: KeyboardEvent) => {
      if (e.defaultPrevented) return
      if ((e.code == 'Escape' || e.key == 'Escape') && isManaging) {
        setIsManaging(false)
      }
    }

    if (window && isManaging) window.addEventListener('keydown', onEscDown)
    return () => {
      if (window) window.removeEventListener('keydown', onEscDown)
    }
  }, [isManaging])

  return (
    <Secure>
      <Default propertyAgnostic>
        <HasServicableProperties isManaging={isManaging}>
          <PageTitle title="Properties" />
          <div className={styles.title}>
            <h2 className={styles.head}>{'Properties'}</h2>
          </div>
          <PropertiesButtons isManaging={isManaging} onIsManagingChange={setIsManaging} />
          <PropertyList isManaging={isManaging} />
        </HasServicableProperties>
      </Default>
    </Secure>
  )
}

export default Properties
