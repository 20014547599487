import React, { FC, PropsWithChildren } from 'react'

import { useAuth } from 'non-rendering/Authentication'

import Loading from 'components/Loading'

const WaitForAuth: FC<PropsWithChildren> = ({ children }) => {
  const { checked } = useAuth()

  if (!checked) {
    return <Loading />
  }
  return <>{children}</>
}

export default WaitForAuth
