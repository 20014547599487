import { FOState } from '../'
import { START_STATE, END_STATE } from '../states'
import { Flow } from './'

const flow: Flow = {
  [START_STATE]: {
    to: [
      {
        state: 'onboarding-unservicable-property',
        if: (state: FOState): boolean => state.onboarding.unavailable == true,
      },
      {
        state: 'onboarding-add-property',
        if: (state: FOState): boolean => !!state.onboarding.attomId,
      },
      {
        state: 'onboarding-enter-address',
      },
    ],
  },
  ['onboarding-enter-address']: {
    url: '/enter-address',
    next: [
      {
        state: 'onboarding-unservicable-property',
        if: (state: FOState): boolean => state.onboarding.unavailable == true,
      },
      {
        state: 'onboarding-add-property',
      },
    ],
  },
  ['onboarding-add-property']: {
    url: '/add-property',
    next: [
      {
        state: 'onboarding-unservicable-property',
        if: (state: FOState): boolean => state.onboarding.unavailable == true,
      },
      {
        state: END_STATE,
      },
    ],
  },
  ['onboarding-unservicable-property']: {
    url: '/unservicable-property',
    next: [
      {
        state: END_STATE,
      },
    ],
  },
}

export default flow
