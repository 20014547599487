import React, { FC } from 'react'

interface CloseProps {
  className?: string
}

const Close: FC<CloseProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-624 -2733) translate(200 2511) translate(404 202)">
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="translate(20 20)" />
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M8 16h8v2H8v-2zm0-4h8v2H8v-2zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"
                transform="translate(20 20)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Close
